// import * as envConfig from "../../envConfig";
// import axios from "axios";
import { GafOktaManager } from "../auth/gaf-okta-auth";
import AppTracking from "../../AppTracking";
var moment = require("moment");

const TIMEOUT = 180000; // 180000 ms = 3 mins
// Common GET Request
export const doGetRequest = (url, data) => {
    const header = headers();
    return timeoutPromise(
        fetch(url, {
            method: "GET",
            headers: header,
            body: data,
        }),
        TIMEOUT,
        504
    ).then(statusCheck);
};

// Common POST Request
export const doPostRequest = (url, data) => {
    const header = headers();
    return timeoutPromise(
        fetch(url, {
            method: "POST",
            headers: header,
            body: data,
        }),
        TIMEOUT,
        504
    ).then(statusCheck);
};

// Common POST for Blob type response
export const DownloadPostRequest = (url, data, responseType) => {
    const header = headers({
        "content-description": "File Download",
        // "content-disposition": "attachment",
        // ...responseType
    });
    return timeoutPromise(
        fetch(url, {
            method: "POST",
            headers: header,
            body: data,
        }),
        TIMEOUT,
        504
    ).then(statusCheck);
};

// Blueprint File Upload - Account user
export const postBPFileUpload = (url, files) => {
    const { accessToken, idToken } = GafOktaManager.storage.info;
    const formData = new FormData();
    files.forEach((file, index) => {
        formData.append(`files`, file);
    });
    const header = {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${accessToken}`,
        Authorization1: `${idToken}`,
        TrackingId: `${AppTracking.id}`,
    }
    return fetch(url, {
        method: "POST",
        headers: header,
        body:  formData
    }).then(statusCheck);
}

// Blueprint File Upload - Guest user
export const postGuestBPFileUploadRequest = (url, files) => {
    const header = {
        Captcha: JSON.parse(sessionStorage.getItem("Captcha")),
        "Access-Control-Allow-Origin": "*",
    }
   
    // header["Access-Control-Allow-Headers"] =
    //     "Origin, X-Requested-With, Content-Type, Accept";
    const formData = new FormData();
    files.forEach((file, index) => {
        formData.append(`files`, file);
    });
    return fetch(url, {
            method: "POST",
            headers: header,
            body: formData,
        })
    .then(statusCheck);
};


const headers = (additionalHeaders = {}, type?: any) => {
    const { accessToken, idToken } = GafOktaManager.storage.info;

    const headersObj = {
        "Content-Type": "application/json; charset=UTF-8",

        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${accessToken}`,
        Authorization1: `${idToken}`,
        TrackingId: `${AppTracking.id}`,
        ...additionalHeaders,
    } as any;

    return headersObj;
};
/**
 * @description function to handle the time out error
 * @param  {Promise} promise - promise
 * @param  {number} timeout - millseconds
 * @param  {string} error - errorcode
 * @return
 */
function timeoutPromise(promise, timeout, error) {
    return new Promise((resolve, reject) => {
        const clearTimeOut = setTimeout(() => {
            const err = { status: error, message: "Request TimeOut" };
            reject(err);
        }, timeout);
        promise.then(
            (data) => {
                clearTimeout(clearTimeOut);
                resolve(data);
            },
            (data) => {
                clearTimeout(clearTimeOut);
                reject(data);
            }
        );
    });
}

export const statusCheck = (data) => {
    const { status } = data;
    if (status === 204) {
        const res = {};
        const resData = { status, ...res };
        window["AppInsightTrace"].trackTrace("API status code: " + status);

        return Promise.resolve(resData);
    } else if (status === 401) {
        const res = { message: "Your authorization expired." };
        const resData = { status, ...res };
        window["AppInsightTrace"].trackException("API status code: " + status);
        window["AppInsightTrace"].trackTrace(
            "For Promise reject API status code: " + status
        );

        return Promise.reject(resData);
    } else if (status === 403) {
        const res = { message: "You are not authorized for this page." };
        const resData = { status, ...res };
        window["AppInsightTrace"].trackException("API status code: " + status);
        window["AppInsightTrace"].trackTrace(
            "For Promise reject API status code: " + status
        );

        return Promise.reject(resData);
    } else {
        return data
            .json()
            .then((res) => {
                const resData = { status, ...res };
                if (data.status >= 200 && data.status < 300) {
                    window["AppInsightTrace"].trackTrace(
                        "API status code: " + status
                    );
                    return Promise.resolve(resData);
                }
                window["AppInsightTrace"].trackException(
                    "API status code: " + status
                );
                window["AppInsightTrace"].trackTrace(
                    "For Promise reject API status code: " + status
                );
                return Promise.reject(resData);
            })
            .catch((error) => {
                let resData = { status, ...error };
                if (error) {
                    resData = { status, ...error };
                    if (status >= 500) {
                        let res = { message: "Can not fetch data from api." };
                        let resData = { status, ...res };
                        window["AppInsightTrace"].trackException(error);
                        return Promise.reject(resData);
                    }
                    return Promise.reject(resData);
                }
                return Promise.reject(resData);
            });
    }
};

// Post request to get User Profile
export const postUserProfile = (
    url,
    emailAddress,
    firstName,
    lastName,
    extendedProfile
) => {
    return timeoutPromise(
        fetch(url, {
            method: "POST",
            headers: headers(),
            body: JSON.stringify({
                emailAddress: emailAddress,
                firstName: firstName,
                lastName: lastName,
                extendedProfile: extendedProfile,
            }),
        }),
        TIMEOUT,
        504
    ).then(statusCheck);

    // return fetchData;
};

// For Open-Close status.
// https://quickmeasureapiint.gaf.com/api/v1.0/SiteStatus
export const postSiteStatus = (url, utcDate) => {
    window["AppInsightTrace"].trackTrace(
        `API call to get Site Status Request payload: :{ body ={
            utcDate: ${utcDate},
        }`
    );
    return timeoutPromise(
        fetch(url, {
            method: "POST",
            headers: headers(),
            body: JSON.stringify({
                requestDateTime: utcDate,
            }),
        }),
        TIMEOUT,
        504
    ).then(statusCheck);
};

// ------My Order Page-------
// Fetch request for My Orders
export const doPostOrders: any = (
    url,
    pageSize,
    currentPage,
    searchEmail,
    searchAddress
) => {
    window["AppInsightTrace"].trackTrace(
        `API call to search for order. Request payload : {(body = {
            fullAddress: ${searchAddress},
            searchBy: "Email",
            searchText: ${searchEmail},
            applyGrouping: true,
            applyPaging: true,
            applyFilter: true,
            applySearch: true,
            paging: {
                pageNo: ${currentPage},
                pageSize: ${pageSize},
            },
            sortBy: "CreatedOn",
            sortOrder: "descending",
        })}`
    );
    return timeoutPromise(
        fetch(url, {
            method: "POST",
            headers: headers(),
            body: JSON.stringify({
                fullAddress: searchAddress,
                searchBy: "Email",
                searchText: searchEmail,
                applyPaging: true,
                applyGrouping: true,
                applyFilter: true,
                applySearch: true,
                paging: {
                    pageNo: currentPage,
                    pageSize: pageSize,
                },
                sortBy: "CreatedOn",
                sortOrder: "descending",
            }),
        }),
        TIMEOUT,
        504
    ).then(statusCheck);
};

// Cancel Order - My order page
export const postCancelOrder: any = (url, orderId, emailAddress) => {
    window["AppInsightTrace"].trackTrace(
        `API call to Cancel order. Request payload : {(body = {
           orderId:${orderId},
           emailAddress:${emailAddress}
        })}`
    );
    return timeoutPromise(
        fetch(url, {
            method: "POST",
            headers: headers(),
            body: JSON.stringify({
                orderId: orderId,
                emailAddress: emailAddress,
            }),
        }),
        TIMEOUT,
        504
    ).then(statusCheck);
};

// Download report for Contractor
export const getDownLoadReport = (url) => {
    window["AppInsightTrace"].trackTrace(
        `API call to download report. No request payload.`
    );
    return fetch(url, {
        method: "GET",
        headers: headers({
            "content-description": "File Download",
            "content-disposition": "attachment",
        }),
    })
        .then((res) => {
            window["AppInsightTrace"].trackTrace(
                `API call to download report, Status: Success`
            );
            return res.blob();
        })
        .catch((err) => {
            window["AppInsightTrace"].trackTrace(
                `Error in API call to download report, ${err}`
            );
            console.log("Error in download report api call", err);
            return Promise.reject(err);
        });
};

// CSV file down load for MyOrder Page
// ​/api​/v1.0​/Orders/export
export const postCsvMyOrders = (url, emailAddress, searchText) => {
    window["AppInsightTrace"].trackTrace(
        `API call to download CSV file for MyOrders. Request payload : {
            body :{
                emailAddress:${emailAddress},
                fullAddress:${searchText}
            }
        }`
    );
    return fetch(url, {
        method: "POST",
        headers: headers({
            "content-description": "File Download",
            "content-disposition": "attachment",
        }),
        body: JSON.stringify({
            emailAddress: emailAddress,
            fullAddress: searchText,
        }),
    })
        .then((res) => {
            window["AppInsightTrace"].trackTrace(
                `API call to download CSV file for MyOrders, Status: Success`
            );
            return res.blob();
        })
        .catch((err) => {
            window["AppInsightTrace"].trackTrace(
                `Error in API call to download CSV file for MyOrders, ${err}`
            );
            console.log("Error in post request for CSV download api call", err);
            return Promise.reject(err);
        });
};

// --------- My Receipts Page -------------
// Fetch reuest for For Receipts
export const doPost: any = (url, pageNo, pageSize, searchEmail) => {
    window["AppInsightTrace"].trackTrace(`API call to get Receipts`);
    window["AppInsightTrace"].trackTrace(
        `API call to get Receipts. Request payload : {
            body = {
                transactionType: "BC",
                searchBy: "Email",
                searchText: ${searchEmail},
                applyPaging: true,
                applyFilter: true,
                applySearch: true,
                paging: {
                    pageNo: ${pageNo},
                    pageSize: ${pageSize},
                },
                sortBy: "CreatedOn",
                sortOrder: "descending",
            };
        }`
    );
    return timeoutPromise(
        fetch(url, {
            method: "POST",
            headers: headers(),
            body: JSON.stringify({
                transactionType: "BC",
                searchBy: "Email",
                searchText: searchEmail,
                applyPaging: true,
                applyFilter: true,
                applySearch: true,
                paging: {
                    pageNo: pageNo,
                    pageSize: pageSize,
                },
                sortBy: "CreatedOn",
                sortOrder: "descending",
            }),
        }),
        TIMEOUT,
        504
    ).then(statusCheck);
};

// CSV file down load for MyReceipt Page
// ​/api​/v1.0​/Orders/export
export const postCsvMyReceipts = (url, emailAddress) => {
    window["AppInsightTrace"].trackTrace(
        `API call to download CSV file for MyReceipts. Request payload : {
            body :{
                emailAddress:${emailAddress},
            }
        }`
    );
    return fetch(url, {
        method: "POST",
        headers: headers({
            "content-description": "File Download",
            "content-disposition": "attachment",
        }),
        body: JSON.stringify({ emailAddress: emailAddress }),
    })
        .then((res) => {
            window["AppInsightTrace"].trackTrace(
                `API call to download CSV file for MyReceipts, Status: Success`
            );
            return res.blob();
        })
        .catch((err) => {
            window["AppInsightTrace"].trackTrace(
                `Error in API call to download CSV file for MyReceipts, ${err}`
            );
            console.log("Error in post request for CSV download api call", err);
            return Promise.reject(err);
        });
};

// ------ Redeem Promocode---------
// Request for redeem promocode
export const getPromoCode = (url, promoCode, email) => {
    const apiName = "Promocodes";
    window["AppInsightTrace"]
        .trackTrace(`API call to get Promocodes to redeem. Request payload : {
            body = {
                    promoCode: ${promoCode},
                    emailAddress: ${email},
                }`);
    return timeoutPromise(
        fetch(url, {
            method: "POST",
            headers: headers(),
            body: JSON.stringify({
                promoCode: promoCode,
                emailAddress: email,
            }),
        }),
        TIMEOUT,
        504
    ).then(statusCheck);
};

// -----------------------------------------------------------------------
// ----Concierge Pages - Customers -------------
// Post request to get Customer User Profile - Admin Pages
export const postCustomerUserProfile = (
    url,
    emailAddress,
    firstName,
    lastName,
    extendedProfile
) => {
    window["AppInsightTrace"].trackTrace(
        `API call to get UserProfile. Request payload : {(body = {
            emailAddress: ${emailAddress},
            firstName: ${firstName},
            lastName: ${lastName},
            extendedProfile: ${extendedProfile},
        })}`
    );
    return timeoutPromise(
        fetch(url, {
            method: "POST",
            headers: headers(),
            body: JSON.stringify({
                emailAddress: emailAddress,
                firstName: firstName,
                lastName: lastName,
                extendedProfile: extendedProfile,
            }),
        }),
        TIMEOUT,
        504
    ).then(statusCheck);

    // return fetchData;
};

// Customer Search - Fetch all customers records
export const postCustomerSearch = (url, pageNo, searchText) => {
    window["AppInsightTrace"]
        .trackTrace(`API call to get all customers records. Request payload : {
        body :{
            searchBy: "All",
            searchText: ${searchText},
            applyPaging: true,
            applyFilter: true,
            applySearch: true,
            paging: {
                pageNo: ${pageNo},
                pageSize: 10,
            },
            sortBy: "CreatedOn",
            sortOrder: "descending",
        }
    }`);
    return timeoutPromise(
        fetch(url, {
            method: "POST",
            headers: headers(),
            body: JSON.stringify({
                searchBy: "All",
                searchText: searchText,
                applyPaging: true,
                applyFilter: true,
                applySearch: true,
                paging: {
                    pageNo: pageNo,
                    pageSize: 10,
                },
                sortBy: "CreatedOn",
                sortOrder: "descending",
            }),
        }),
        TIMEOUT,
        504
    ).then(statusCheck);
};

// ------RoofReport Page-------------
// Fetch all orders for RoofReport Page
// /api/v1.0/allorders/search
export const postAllOrdersSearch = (
    url,
    searchText,
    pageNo,
    source,
    product,
    subscribername
) => {
    const toDate = new Date().toISOString(); //Current date
    const fromDate = moment().subtract(1, "days").toISOString(); // Current date - 24hours, i.e., yesterday
    const filter =
        searchText == ""
            ? { fromDate: fromDate, toDate: toDate, viewBy: 0 }
            : null;
    window["AppInsightTrace"]
        .trackTrace(`API call to get all orders. Request payload : {
            body :{
                    fullAddress: ${searchText},
                    source:${source},
                    product:${product},
                    subscribername:${subscribername},
                    searchBy: "Address",
                    searchText: "",
                    applyGrouping: true,
                    applyPaging: true,
                    applyFilter: true,
                    applySearch: true,
                    paging: {
                        pageNo: ${pageNo},
                        pageSize: 10,
                    },
                    sortBy: "CreatedOn",
                    sortOrder: "descending",
                    filter: {
                        fromDate: ${fromDate},
                        toDate: ${toDate},
                        viewBy: 0
                      },
                }
    }`);
    return timeoutPromise(
        fetch(url, {
            method: "POST",
            headers: headers(),
            body: JSON.stringify({
                fullAddress: searchText,
                source: source,
                product: product,
                subscribername: subscribername,
                searchBy: "Address",
                searchText: "",
                applyGrouping: true,
                applyPaging: true,
                applyFilter: true,
                applySearch: true,
                paging: {
                    pageNo: pageNo,
                    pageSize: 10,
                },
                sortBy: "CreatedOn",
                sortOrder: "descending",
                filter: filter,
            }),
        }),
        TIMEOUT,
        504
    ).then(statusCheck);
};

// Post request to get ReportCount and Total Revenue.
export const postReportRevenueCount: any = (url, reportDateFrom, source) => {
    window["AppInsightTrace"].trackTrace(
        `API call for Total Report Count and Total Revenue.`
    );
    window["AppInsightTrace"].trackTrace(
        `API call to get Total Report Count and Total Revenue. Request payload : {
            body = {
                reportDateFrom: ${reportDateFrom},
                source: ${source},
            };
        }`
    );
    return timeoutPromise(
        fetch(url, {
            method: "POST",
            headers: headers(),
            body: JSON.stringify({
                reportDateFrom: reportDateFrom,
                source: source,
            }),
        }),
        TIMEOUT,
        504
    ).then(statusCheck);
};

// ------- Customer Details Page----------

// Fetch receipts for Admin pages - Customer Details.
export const postCustomersReceipts: any = (
    url,
    pageNo,
    pageSize,
    searchEmail
) => {
    window["AppInsightTrace"].trackTrace(`API call to get Receipts`);
    window["AppInsightTrace"].trackTrace(
        `API call to get Receipts. Request payload : {
            body = {
                transactionType: "BC",
                searchBy: "Email",
                searchText: ${searchEmail},
                applyPaging: true,
                applyFilter: true,
                applySearch: true,
                paging: {
                    pageNo: ${pageNo},
                    pageSize: ${pageSize},
                },
                sortBy: "CreatedOn",
                sortOrder: "descending",
            };
        }`
    );
    return timeoutPromise(
        fetch(url, {
            method: "POST",
            headers: headers(),
            body: JSON.stringify({
                transactionType: "BC",
                searchBy: "Email",
                searchText: searchEmail,
                applyPaging: true,
                applyFilter: true,
                applySearch: true,
                paging: {
                    pageNo: pageNo,
                    pageSize: pageSize,
                },
                sortBy: "CreatedOn",
                sortOrder: "descending",
            }),
        }),
        TIMEOUT,
        504
    ).then(statusCheck);
};

// Fetch request for Cutomer details Orders
export const postCustomerOrders: any = (
    url,
    pageSize,
    currentPage,
    searchEmail,
    searchAddress,
    source,
    subscribername
) => {
    window["AppInsightTrace"].trackTrace(
        `API call to search for customers order. Request payload : {(body = {
            fullAddress: ${searchAddress},
            source:${source},
            subscribername :${subscribername}
            searchBy: "Email",
            searchText: ${searchEmail},
            applyGrouping: true,
            applyPaging: true,
            applyFilter: true,
            applySearch: true,
            paging: {
                pageNo: ${currentPage},
                pageSize: ${pageSize},
            },
            sortBy: "CreatedOn",
            sortOrder: "descending",
        })}`
    );
    return timeoutPromise(
        fetch(url, {
            method: "POST",
            headers: headers(),
            body: JSON.stringify({
                fullAddress: searchAddress,
                source: source,
                subscribername: subscribername,
                searchBy: "Email",
                searchText: searchEmail,
                applyGrouping: true,
                applyPaging: true,
                applyFilter: true,
                applySearch: true,
                paging: {
                    pageNo: currentPage,
                    pageSize: pageSize,
                },
                sortBy: "CreatedOn",
                sortOrder: "descending",
            }),
        }),
        TIMEOUT,
        504
    ).then(statusCheck);
};

// Fetch reqest to get promodes
export const postPromocodes = (url, emailAddress, pageNo) => {
    window["AppInsightTrace"]
        .trackTrace(`API call to get all Promocodes. Request payload : {
    body :{
        promoCodeType: "all",
        searchBy: "Email",
        searchText: ${emailAddress},
        applyPaging: true,
        applyFilter: true,
        applySearch: true,
        paging: {
            pageNo: ${pageNo},
            pageSize: 10,
        },
        sortBy: "CreatedOn",
        sortOrder: "descending",
    }`);
    return timeoutPromise(
        fetch(url, {
            method: "POST",
            headers: headers(),
            body: JSON.stringify({
                promoCodeType: "all",
                searchBy: "Email",
                searchText: emailAddress,
                applyPaging: true,
                applyFilter: true,
                applySearch: true,
                paging: {
                    pageNo: pageNo,
                    pageSize: 10,
                },
                sortBy: "CreatedOn",
                sortOrder: "descending",
            }),
        }),
        TIMEOUT,
        504
    ).then(statusCheck);
};

// Update Parent
// api​/v1.0​/UpdateParent​/{childEmailAddress},{parentEmailAddress},{executedByEmailAddress},{requestType}
export const postUpdateParent = (url) => {
    window["AppInsightTrace"].trackTrace(
        `API call to Update parent. Request without payload`
    );

    return timeoutPromise(
        fetch(url, {
            method: "POST",
            headers: headers(),
        }),
        TIMEOUT,
        504
    ).then(statusCheck);
};

// For getting special pricing
// https://quickmeasureapiint.gaf.com/api/v1.0/GetProfilesForSpecialPrice
export const getSpecialPricing = (url) => {
    window["AppInsightTrace"].trackTrace(
        `API call to get speical pricing. It's GET request. No payload.`
    );
    return timeoutPromise(
        fetch(url, {
            method: "GET",
            headers: headers(),
        }),
        TIMEOUT,
        504
    ).then(statusCheck);
};

// Update User ForSpecialPrice
// https://quickmeasureapiint.gaf.com/api/v1.0/UpdateUserForSpecialPrice
export const updateUserForSpecialPrice = (
    url,
    userEmail,
    profileID,
    executedByEmailAddress
) => {
    window["AppInsightTrace"]
        .trackTrace(`API call to update special pricing for user. Request payload : {
    body :{
        userEmailAddress: ${userEmail},
        profileID: ${profileID},
        executedByEmailAddress: ${executedByEmailAddress},
    }`);
    return timeoutPromise(
        fetch(url, {
            method: "POST",
            headers: headers(),
            body: JSON.stringify({
                userEmailAddress: userEmail,
                profileID: profileID,
                executedByEmailAddress: executedByEmailAddress,
            }),
        }),
        TIMEOUT,
        504
    ).then(statusCheck);
};

// Payment refund api call
// https://quickmeasureapiint.gaf.com/api/v1.0/payments/refund
export const postRefund = (
    url,
    transactionID,
    emailAddress,
    amount,
    executedByEmailAddress
) => {
    window["AppInsightTrace"]
        .trackTrace(`API call for refund payment. Reqeuest payload: {
    body = {  originalTransactionID: ${transactionID},
        emailAddress: ${emailAddress},
        amount: ${amount},
        executedByEmailAddress: ${executedByEmailAddress} }`);

    return timeoutPromise(
        fetch(url, {
            method: "POST",
            headers: headers(),
            body: JSON.stringify({
                originalTransactionID: transactionID,
                emailAddress: emailAddress,
                amount: amount,
                executedByEmailAddress: executedByEmailAddress,
            }),
        }),
        TIMEOUT,
        504
    ).then(statusCheck);
};

// Reject order
// https://quickmeasureapiint.gaf.com/api/v1.0/orders/reject
export const postRejectOrder = (
    url,
    orderId,
    rejectReason,
    rejectedByEmailAddress
) => {
    window["AppInsightTrace"]
        .trackTrace(`API call for reject order. Reqeuest payload: {
                body = {
                    orderId: ${orderId},
                    rejectReason: ${rejectReason},
                    rejectedByEmailAddress: ${rejectedByEmailAddress},
                }`);
    return timeoutPromise(
        fetch(url, {
            method: "POST",
            headers: headers(),
            body: JSON.stringify({
                orderId: orderId,
                rejectReason: rejectReason,
                rejectedByEmailAddress: rejectedByEmailAddress,
            }),
        }),
        TIMEOUT,
        504
    ).then(statusCheck);
};

//------- Payments Pages ------------------------------

// For payment update
// https://quickmeasureapiint.gaf.com/api/v1.0/payments/update
export const updatePaymentApi = (
    url,
    userEmail,
    transactionRefNo,
    transactionStatus
) => {
    window["AppInsightTrace"]
        .trackTrace(`API call for Payment update. Request payload : {
                    body :{
                        emailAddress: ${userEmail},
                        transactionReference: ${transactionRefNo},
                        transactionStatus: ${transactionStatus},
                    }`);
    const header = headers();
    header["Captcha"] = JSON.parse(sessionStorage.getItem("Captcha"));
    return timeoutPromise(
        fetch(url, {
            method: "POST",
            headers: header,
            body: JSON.stringify({
                emailAddress: userEmail,
                transactionReference: transactionRefNo,
                transactionStatus: transactionStatus,
            }),
        }),
        TIMEOUT,
        504
    ).then(statusCheck);
};

//---------Generate PromoCodes Page --------------
// Generate Promocodes
// https://quickmeasureapiint.gaf.com/api/v1.0/promocode/generate
export const generatePromoCodes = (
    url,
    numberOfPromoCodes,
    requesterEmailAddress,
    productCode,
    fromDate,
    toDate,
    assignToUserEmailAddress,
    amount, promoCodeType
) => {
    window["AppInsightTrace"]
        .trackTrace(`API call to genereate Promocodes. Reqeuest payload: {
        body = {
            numberOfPromoCodesToGenerate: ${numberOfPromoCodes},
            requesterEmailAddress: ${requesterEmailAddress},
            productCode:${productCode},
            fromDate: ${fromDate},
            toDate: ${toDate},
            assignToUserEmailAddress: ${assignToUserEmailAddress},
            amount: ${amount},
            promoCodeType: ${promoCodeType},
        }
    }`);
    return timeoutPromise(
        fetch(url, {
            method: "POST",
            headers: headers(),
            body: JSON.stringify({
                numberOfPromoCodesToGenerate: numberOfPromoCodes,
                requesterEmailAddress: requesterEmailAddress,
                productCode: productCode,
                fromDate: fromDate,
                toDate: toDate,
                assignToUserEmailAddress: assignToUserEmailAddress,
                amount: amount,
                promoCodeType: promoCodeType,
            }),
        }),
        TIMEOUT,
        504
    ).then(statusCheck);
};

// CSV file down load for promocodes
// ​/api​/v1.0​/promocode​/getpromocodesforbatch​/{batchID}
export const postCsvDownLoad = (url, batchID) => {
    window["AppInsightTrace"].trackTrace(
        `API call to download genereated Promocodes CSV file. Request payload : {
            body :{
                batchID: ${batchID}
            }
        }`
    );
    return fetch(url, {
        method: "POST",
        headers: headers({
            "content-description": "File Download",
            "content-disposition": "attachment",
        }),

        body: JSON.stringify({ batchID: batchID }),
    })
        .then((res) => {
            window["AppInsightTrace"].trackTrace(
                `API call to download genereated Promocodes CSV file, Status: Success`
            );
            return res.blob();
        })
        .catch((err) => {
            window["AppInsightTrace"].trackTrace(
                `Error in API call to download genereated Promocodes CSV file, ${err}`
            );
            console.log("Error in post request for CSV download api call", err);
            return Promise.reject(err);
        });
};

// Send Error Report api call
// /api/v1.0/SendErrorReport
export const postSendErrorReport = (
    url,
    encodedEmail,
    subject,
    errorDesc,
    errorDateTime,
    userEmailAddress
) => {
    window["AppInsightTrace"].trackTrace(`API call for send Error Report.`);
    return timeoutPromise(
        fetch(url, {
            method: "POST",
            headers: headers(),
            body: JSON.stringify({
                emailAddress: encodedEmail,
                subject: subject,
                errorDesc: errorDesc,
                errorDateTime: errorDateTime,
                userEmailAddress: userEmailAddress,
            }),
        }),
        TIMEOUT,
        504
    ).then(statusCheck);
};

// Download report for Admins Pages
export const getDownLoadReportCustomers = (url) => {
    window["AppInsightTrace"].trackTrace(
        `API call to download report for Admin Pages. No request payload.`
    );

    return fetch(url, {
        method: "GET",
        headers: headers({
            "content-description": "File Download",
            "content-disposition": "attachment",
            Captcha: JSON.parse(sessionStorage.getItem("Captcha")),
        }),
    })
        .then((res) => {
            window["AppInsightTrace"].trackTrace(
                `API call to download report for Admin Pages, Status: Success`
            );
            return res.blob();
        })
        .catch((err) => {
            window["AppInsightTrace"].trackTrace(
                `Error in API call to download report for Admin Pages, ${err}`
            );
            console.log(
                "Error in download report api call for Admin Pages",
                err
            );
            return Promise.reject(err);
        });
};
// ----- Operational Reports Page ----------
//Post request to get export report - orders/customers/transaction
export const postExportReport = (url, reportType, fromDate, toDate) => {
    window["AppInsightTrace"].trackTrace(
        `API call for Export Report. Request payload : {(body = {
            reportType: ${reportType},
            fromDate: ${fromDate},
            toDate: ${toDate},
        })}`
    );
    return timeoutPromise(
        fetch(url, {
            method: "POST",
            headers: headers(),
            body: JSON.stringify({
                reportType: reportType,
                fromDate: fromDate,
                toDate: toDate,
            }),
        }),
        TIMEOUT,
        504
    ).then(statusCheck);
};

export const postReportWithDatesOnly = (url, fromDate, toDate) => {
    window["AppInsightTrace"].trackTrace(
        `API call for Month End Report. Request payload : {(body = {
            fromDate: ${fromDate},
            toDate: ${toDate},
        })}`
    );
    return timeoutPromise(
        fetch(url, {
            method: "POST",
            headers: headers(),
            body: JSON.stringify({
                fromDate: fromDate,
                toDate: toDate,
            }),
        }),
        TIMEOUT,
        504
    ).then(statusCheck);
};

//Post request to get export report -ProductOwner Report, Regional Report, Daily Mgmt Report and TM DailyActivityReport
export const postSendReport_POwner_Regional = (url, reqBody) => {
    window["AppInsightTrace"].trackTrace(
        `API call for Send Report for ProductOwner Report, Regional Report, Daily Mgmt Report and TM DailyActivityReport. Request payload : {body = ${JSON.stringify(
            reqBody
        )} }`
    );
    return timeoutPromise(
        fetch(url, {
            method: "POST",
            headers: headers(),
            body: JSON.stringify(reqBody),
        }),
        TIMEOUT,
        504
    ).then(statusCheck);
};

// Common GUEST POST Request for Guest
export const postRequest = (url, data) => {
    const header = headers();
    header["Captcha"] = JSON.parse(sessionStorage.getItem("Captcha"));
    header["Access-Control-Allow-Origin"] = "*";
    header["Access-Control-Allow-Headers"] =
        "Origin, X-Requested-With, Content-Type, Accept";
    return timeoutPromise(
        fetch(url, {
            method: "POST",
            headers: header,
            body: data,
        }),
        TIMEOUT,
        504
    ).then(statusCheck);
};

// Common GET Request For Guest
export const getRequest = (url, data) => {
    const header = headers();
    header["Captcha"] = JSON.parse(sessionStorage.getItem("Captcha"));
    return timeoutPromise(
        fetch(url, {
            method: "GET",
            headers: header,
            body: data,
        }),
        TIMEOUT,
        504
    ).then(statusCheck);
};
