import * as React from "react";
import { Modal, Button, FormControl } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { GafOktaManager } from "../../auth/gaf-okta-auth";
import * as TagManager from 'react-gtm-module';

import ErrorModal from "../ErrorModal";

import { getSuceessPromoCodeModal } from "../../containers/HomePage/actionCreater";
import { updateAvailableCredits } from "../../containers/HomePage/actionCreater";
import { turnOn, turnOff } from "../Loader/actionCreater";


import { getPromoCode, postUserProfile } from "../../utils/utils";
import { apiCallSendErrorReport } from "../../utils/ApiFunctions";
import { Log } from "../../utils/validation";

import { baseUrl, maxRedeemCodeAttempt } from "../../../envConfig";

interface IPromoCodeModal {
    promoCodeInput: string;
    error: boolean;
    success: boolean;
    message: string;
    successPromoCodeModal: boolean;
    showErrorModal: boolean;
    errorCode: string;
    amount: number;
}

export class PromoCodeModal extends React.Component<any, IPromoCodeModal> {
    constructor(props: IPromoCodeModal) {
        super(props);
        this.state = {
            promoCodeInput: "",
            error: false,
            success: false,
            message: "",
            successPromoCodeModal: false,
            showErrorModal: false,
            errorCode: '',
            amount: 0,
        };
    }

    componentDidMount(): void {
        if (!window.sessionStorage.getItem('clickCount')) {
            window.sessionStorage.setItem('clickCount', JSON.stringify(0))
        }
    }

    validatePromoCode = () => {
        const { promoCodeInput } = this.state;
        const { emailAddress } = this.props;
        const encodedEmail = btoa(emailAddress.toLowerCase());
        const { isDebug } = this.props;
        const apiUrl = baseUrl + "/promocode/redeem";
        this.props.turnOn()
        const promoCodeData: any = getPromoCode(
            apiUrl,
            promoCodeInput,
            encodedEmail
        );
        promoCodeData.then((res) => {
            this.props.turnOff();
            Log(res, isDebug);
            window["AppInsightTrace"].trackTrace(`Api call to redeem promocode, Success : True`);

            this.setState(
                {
                    success: res.success,
                    message: res.message,
                    amount: res.amount,
                },
                () => {
                    this.checkPromoCode(this.state.success, this.state.message, this.state.amount);
                }
            );
        }).catch(err => {
            this.props.turnOff();
            window["AppInsightTrace"].trackTrace(`Api call to redeem promocode, Success : False`);

            let errObj = {
                apiUrl: apiUrl,
                status: err.status,
                promoCodeInput: promoCodeInput,
                email: emailAddress,
                message: err.message,
                err: err.stack,
                errorDescription: `Error in Redeem Code api call.`
            }
            this.setState({
                showErrorModal: true,
                errorCode: 'genericError'
            });
            apiCallSendErrorReport('Redeem Code Modal', errObj);

            Log(err, isDebug);
        });
    };

    checkPromoCode = (success, message, amount) => {
        const { getSuceessPromoCodeModal, close } = this.props;
        const promoCodeAmt = parseFloat(amount).toFixed(2);
        const emailAddress = this.props.emailAddress;
        if (success) {
            const modalData = {
                success: true,
                message: `$${promoCodeAmt} has been added to your account.`,
            };
            getSuceessPromoCodeModal(modalData);
            // updating credit after redeem code success
            this.updateCredits();

            setTimeout(() => {
                modalData.success = false;
                getSuceessPromoCodeModal(modalData);
            }, 5000);

            // ----------------------------------
            // GTM settings
            const tagManagerArgs = {
                dataLayer: {
                    event: 'clickRedeemCodeSuccess',
                    code: this.state.promoCodeInput,
                },
                dataLayerName: 'PageDataLayer'
            }
            TagManager.dataLayer(tagManagerArgs);
            // -------------------------------------

            window["AppInsightTrace"].trackTrace(`${emailAddress} - Promocode successfully redeemed. $${promoCodeAmt} added to account`);
            close();
        } else {
            this.setState({
                message: message,
                error: true,
            });
            
            let clickCount = JSON.parse(window.sessionStorage.getItem('clickCount'));
            window.sessionStorage.setItem('clickCount', JSON.stringify(clickCount + 1))
            // ----------------------------------
            // GTM settings
            const tagManagerArgs = {
                dataLayer: {
                    event: 'clickRedeemCodeUnSuccess',
                    code: this.state.promoCodeInput,
                },
                dataLayerName: 'PageDataLayer'
            }
            TagManager.dataLayer(tagManagerArgs);
            // -------------------------------------
        }
    };

    encodeEmail = () => {
        const emailAddress = this.props.emailAddress;

        let encodeEmail = btoa(emailAddress.toLowerCase());
        return encodeEmail;
    };
    updateCredits = () => {
        let encodeEmail = this.encodeEmail();

        const userFirstName = GafOktaManager.storage.info.profile.firstName !== undefined ? GafOktaManager.storage.info.profile.firstName : "FirstName";
        const userLastName = GafOktaManager.storage.info.profile.lastName !== undefined ? GafOktaManager.storage.info.profile.lastName : "LastName";
        const extendedProfile = false;
        const { isDebug } = this.props;
        // calling userProfile api-extended false to get current available credit after redeem promocode.
        const profileUrl = baseUrl + `/user/userprofile`;

        window["AppInsightTrace"].trackTrace(`API call to get user updated credit after redeem code, userProfile/extended:false`);

        postUserProfile(profileUrl, encodeEmail, userFirstName, userLastName, extendedProfile).then((res) => {
            window["AppInsightTrace"].trackTrace(`API call to get user updated credit after redeem code, userProfile/extended:false, Success : True`);

            Log(res, isDebug);
            // let availableCredits = res.result[0].availableCredits;
            let availableBalance = res.result[0].availableBalance;
            this.props.updateAvailableCredits(availableBalance);
        }).catch(err => {
            if (err.status !== 204) {
                window["AppInsightTrace"].trackTrace(`API call to get user updated credit after redeem code, userProfile/extended:false, Success : False`);

                let errObj = {
                    apiUrl: profileUrl,
                    status: err.status,
                    email: this.props.emailAddress,
                    firstName: userFirstName,
                    lastName: userLastName,
                    extendedProfile: extendedProfile,
                    message: err.message,
                    err: err.stack,
                    errorDescription: `Error in UserProfile api call.`
                }
                this.setState({
                    errorCode: 'genericError',
                    showErrorModal: true
                });
                apiCallSendErrorReport('Redeem Code Modal', errObj);
            }
            Log(err, isDebug)
        });
    };
    handleInputText(evt) {
        this.setState({
            promoCodeInput: evt.target.value,
            message: "",
            error: false,
        });
    }

    // Only allowing three attempt to enter promocode during one session.
    handleSubmitClick = (promoCode): any => {
        const { emailAddress } = this.props;
        window["AppInsightTrace"].trackEvent(`User- ${emailAddress} - Redeem Code - Submit button clicked`);
        // let clickCount = JSON.parse(window.sessionStorage.getItem('clickCount'));
        if (promoCode == "") {
            this.setState({
                message: "Please enter the promocode value.",
                error: true,
            });
        } else {
            // window.sessionStorage.setItem('clickCount', JSON.stringify(clickCount + 1))
            if (promoCode.match("^[a-zA-Z0-9]*$") && promoCode.length === 6) {
                if (JSON.parse(window.sessionStorage.getItem('clickCount')) <= maxRedeemCodeAttempt) {
                    this.validatePromoCode();
                } else {
                    this.setState({
                        message: "Sorry you have attempted an invalid code too many times. Please try again later.",
                        error: true,
                    });
                }
            } else {
                this.setState({
                    message: "Sorry, this code is invalid.",
                    error: true,
                });
            }
        }

    }

    hideErrorModal = () => {
        this.setState({ showErrorModal: false, errorCode: null });
    };
    render() {
        const { error, promoCodeInput, message } = this.state;
        const formControlClass = error
            ? "mr-sm-2 ParagraphAndLabel form-control-error mb-0"
            : "mr-sm-2 ParagraphAndLabel";

        return (
            <React.Fragment>
                <Modal
                    className="redeemCode-modal"
                    show={this.props.show}
                    onHide={() => this.props.close()}
                    animation={false}
                    data-keyboard="false"
                    backdrop="static"
                >
                    <Modal.Header
                        className="redeemCode-modal-header border-bottom-0 pb-0"
                        closeButton
                    >
                        <div className="container">
                            <Modal.Title>
                                <h6>Redeem Code</h6>
                            </Modal.Title>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="redeemCode-modal-body pt-0">
                        <div className="container">
                            <p className="ParagraphAndLabel">
                                Please enter your promo code to add funds to
                                your account.
                            </p>
                            <FormControl
                                type="text"
                                placeholder="Please enter a code"
                                maxLength={6}
                                className={formControlClass}
                                value={promoCodeInput}
                                onChange={(evt) => this.handleInputText(evt)}
                                autoFocus
                            />
                            <p className={`mb-0 ${error ? "error" : ""}`}>{message}</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="border-top-0 ">
                        <div className="container d-flex justify-content-center">
                            <Button
                                className="ButtonCopy gaf-btn-secondary"
                                onClick={() =>
                                    this.handleSubmitClick(promoCodeInput)
                                }
                            >
                                Submit
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>

                {this.state.showErrorModal && (
                    <ErrorModal
                        errorCode={this.state.errorCode}
                        close={() => this.hideErrorModal()}
                        show={this.state.showErrorModal}
                    />
                )}
            </React.Fragment>
        );
    }
}

// export default PromoCodeModal;

export function mapDispatchToProps(dispatch) {
    return {
        getSuceessPromoCodeModal: bindActionCreators(
            getSuceessPromoCodeModal,
            dispatch
        ),
        updateAvailableCredits: bindActionCreators(
            updateAvailableCredits,
            dispatch
        ),
        turnOff: bindActionCreators(turnOff, dispatch),
        turnOn: bindActionCreators(turnOn, dispatch),
    };
}
export function mapStateToProps(state: any) {
    const { emailAddress } = state.HomePage.userProfile;
    const { isDebug } = state.Header;
    return { emailAddress, isDebug };
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(PromoCodeModal) as React.ComponentType<any>
);
