// import * as envConfig from "../../envConfig";
import AppTracking from "../../AppTracking";
const TIMEOUT = 120000; // 120000 ms = 2 mins

const headers = (additionalHeaders = {}, type?: any) => {
    // const userCaptcha = JSON.stringify(sessionStorage.getItem("captcha"));
    // console.log(userCaptcha);

    const headersObj = {
        "Content-Type": "application/json; charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        TrackingId: `${AppTracking.id}`,
        ...additionalHeaders,
    } as any;

    return headersObj;
};

/**
 * @description function to handle the time out error
 * @param  {Promise} promise - promise
 * @param  {number} timeout - millseconds
 * @param  {string} error - errorcode
 * @return
 */
function timeoutPromise(promise, timeout, error) {
    return new Promise((resolve, reject) => {
        const clearTimeOut = setTimeout(() => {
            const err = { status: error, message: "Request TimeOut" };
            reject(err);
        }, timeout);
        promise.then(
            (data) => {
                clearTimeout(clearTimeOut);
                resolve(data);
            },
            (data) => {
                clearTimeout(clearTimeOut);
                reject(data);
            }
        );
    });
}

export const statusCheck = (data) => {
    const { status } = data;
    if (status === 204) {
        const res = {};
        const resData = { status, ...res };
        window["AppInsightTrace"].trackTrace("API status code: " + status);

        return Promise.resolve(resData);
    } else if (status === 401) {
        const res = { message: "Your authorization expired." };
        const resData = { status, ...res };
        window["AppInsightTrace"].trackException("API status code: " + status);
        window["AppInsightTrace"].trackTrace(
            "For Promise reject API status code: " + status
        );

        return Promise.reject(resData);
    } else if (status === 403) {
        const res = { message: "You are not authorized for this page." };
        const resData = { status, ...res };
        window["AppInsightTrace"].trackException("API status code: " + status);
        window["AppInsightTrace"].trackTrace(
            "For Promise reject API status code: " + status
        );

        return Promise.reject(resData);
    } else {
        return data
            .json()
            .then((res) => {
                const resData = { status, ...res };
                if (data.status >= 200 && data.status < 300) {
                    window["AppInsightTrace"].trackTrace(
                        "API status code: " + status
                    );
                    return Promise.resolve(resData);
                }
                window["AppInsightTrace"].trackException(
                    "API status code: " + status
                );
                window["AppInsightTrace"].trackTrace(
                    "For Promise reject API status code: " + status
                );
                return Promise.reject(resData);
            })
            .catch((error) => {
                let resData = { status, ...error };
                console.log(error);
                if (error) {
                    resData = { status, ...error };
                    if (status >= 500) {
                        let res = { message: "Can not fetch data from api." };
                        let resData = { status, ...res };
                        window["AppInsightTrace"].trackException(error);
                        return Promise.reject(resData);
                    }
                    return Promise.reject(resData);
                }
                return Promise.reject(resData);
            });
    }
};

// Post request to get User Profile
export const postGuestUserProfile = (
    url,
    emailAddress,
    firstName,
    lastName,
    extendedProfile,
    token
) => {
    const userCaptcha = token;

    return timeoutPromise(
        fetch(url, {
            method: "POST",
            headers: headers({
                captcha: `${userCaptcha}`,
            }),
            body: JSON.stringify({
                emailAddress: emailAddress,
                firstName: firstName,
                lastName: lastName,
                extendedProfile: extendedProfile,
            }),
        }),
        TIMEOUT,
        504
    ).then(statusCheck);

    // return fetchData;
};

// Post request for Site Status
export const postGuestSiteStatus = (url, utcDate, token) => {
    const userCaptcha = token;

    return timeoutPromise(
        fetch(url, {
            method: "POST",
            headers: headers({
                captcha: `${userCaptcha}`,
            }),
            body: JSON.stringify({
                requestDateTime: utcDate,
            }),
        }),
        TIMEOUT,
        504
    ).then(statusCheck);

    // return fetchData;
};

// Post request for Covergae Check
export const postGuestCheckCoverge = (url, email, lat, lng, token) => {
    const userCaptcha = token;

    return timeoutPromise(
        fetch(url, {
            method: "POST",
            headers: headers({
                captcha: `${userCaptcha}`,
            }),
            body: JSON.stringify({
                emailAddress: email,
                productCode: "SF",
                latitude: lat,
                longitude: lng,
            }),
        }),
        TIMEOUT,
        504
    ).then(statusCheck);

    // return fetchData;
};

// ------- Guest HomePage--------------------------
// Post request for Place Order
export const postOrderGuest = (
    url,
    emailAddress,
    productCode,
    products,
    numberOfOrders,
    lat,
    lng,
    fullAddress,
    address1,
    address2,
    city,
    stateOrProvince,
    postalCode,
    county,
    country,
    recipientEmailAddresses,
    instructions,
    isServiceOpen,
    checkForDuplicate,
    amount,
    isGuestOrder,
    geocodeLocations,
    customerName,
    customerEmailAddress,
    customerPhoneNumber,
    customerOtherInfo,
    sessionId,
    token
) => {
    window["AppInsightTrace"].trackTrace(`API call to place order for Guest.`);
    window["AppInsightTrace"].trackTrace(
        `API call to place order,Request payload :{ body ={
            emailAddress: ${emailAddress},
            productCode: ${productCode},
            products: ${products},
            numberOfOrders:${numberOfOrders},
            address1: ${address1},
            address2: ${address2},
            city: ${city},
            stateOrProvince: ${stateOrProvince},
            postalCode: ${postalCode},
            fipscode: null,
            county: ${county},
            country:${country}
            latitude: ${lat},
            longitude: ${lng},
            fullAddress: ${fullAddress},
            recipientEmailAddresses: ${recipientEmailAddresses},
            instructions: ${instructions},
            isServiceOpen: ${isServiceOpen},
            checkForDuplicate:${checkForDuplicate},
            amount:${amount},
            isGuestOrder:${isGuestOrder}
            geocodeLocations: ${geocodeLocations},
            customerName: ${customerName},
            customerEmailAddress: ${customerEmailAddress},
            customerPhoneNumber: ${customerPhoneNumber},
            customerOtherInfo: ${customerOtherInfo},
            sessionId: ${sessionId},
        }} .`
    );
    const userCaptcha = token;

    return timeoutPromise(
        fetch(url, {
            method: "POST",
            headers: headers({
                captcha: `${userCaptcha}`,
            }),
            body: JSON.stringify({
                emailAddress: emailAddress,
                productCode: productCode,
                products: products,
                numberOfOrders: numberOfOrders,
                address1: address1,
                address2: address2,
                city: city,
                stateOrProvince: stateOrProvince,
                postalCode: postalCode,
                fipscode: null,
                county: county,
                country: country,
                latitude: lat,
                longitude: lng,
                fullAddress: fullAddress,
                recipientEmailAddresses: recipientEmailAddresses,
                instructions: instructions,
                isServiceOpen: isServiceOpen,
                checkForDuplicate: checkForDuplicate,
                amount: amount,
                isGuestOrder: isGuestOrder,
                geocodeLocations: geocodeLocations,
                customerName: customerName,
                customerEmailAddress: customerEmailAddress,
                customerPhoneNumber: customerPhoneNumber,
                customerOtherInfo: customerOtherInfo,
                sessionId : sessionId ? sessionId : null
            }),
        }),
        TIMEOUT,
        504
    ).then(statusCheck);
};

// Post request to check existing user
export const postSearchUser = (url, emailAddress, searchType, token) => {
    window["AppInsightTrace"].trackTrace(
        `API call to check existing user Guest.`
    );
    window["AppInsightTrace"].trackTrace(
        `API call to Search User, Request payload :{ body ={
            emailAddress: ${emailAddress},
            searchType:${searchType}
        }} .`
    );
    const userCaptcha = token;

    return timeoutPromise(
        fetch(url, {
            method: "POST",
            headers: headers({
                captcha: `${userCaptcha}`,
            }),
            body: JSON.stringify({
                emailAddress: emailAddress,
                searchType: searchType,
            }),
        }),
        TIMEOUT,
        504
    ).then(statusCheck);
};

// Post request for Guest UpdateOrder
export const guestUpdateOrder = (
    url,
    emailAddress,
    orderId,
    orderIds,
    isPaymentSuccessful,
    transactionReference,
    token
) => {
    window["AppInsightTrace"].trackTrace(
        `API call on payment success - Guest UpdateOrder.`
    );
    window["AppInsightTrace"].trackTrace(
        `API call to Search User, Request payload :{ body ={
            emailAddress: ${emailAddress},
            orderId:${orderId},
            orderIds: ${orderIds},
            isPaymentSuccessful:${isPaymentSuccessful},
            transactionReference:${transactionReference}
        }} .`
    );
    const userCaptcha = token;

    return timeoutPromise(
        fetch(url, {
            method: "POST",
            headers: headers({
                captcha: `${userCaptcha}`,
            }),
            body: JSON.stringify({
                emailAddress: emailAddress,
                orderId: orderId,
                orderIds: orderIds,
                isPaymentSuccessful: isPaymentSuccessful,
                transactionReference: transactionReference,
            }),
        }),
        TIMEOUT,
        504
    ).then(statusCheck);
};
