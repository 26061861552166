import { string } from "prop-types";
import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import * as APPLICATION_ADMIN_ACTIONS from "../containers/concierge/ApplicationAdmin/actionTypes";

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
export interface ApplicationAdminState {
    ActTypes: any;
    isGettingAccountTypes: boolean;
    isError: boolean;
    selectedAct: any;
    saveAct: any;
    isSavingAccountTypes: boolean;
    isActSaved: boolean;
    isAddNewActBtnClicked: boolean;
}

export interface ApplicationAdmin {
    actData: any;
    type: any;
    payload: any;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = ApplicationAdmin;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestAPI: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            actData: "",
            type: APPLICATION_ADMIN_ACTIONS.GET_ACT_TYPES,
            payload: "",
        });
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultProps: ApplicationAdminState = {
    ActTypes: {},
    isGettingAccountTypes: false,
    isError: false,
    selectedAct: {},
    saveAct: {},
    isSavingAccountTypes: false,
    isActSaved: false,
    isAddNewActBtnClicked: false,
};

export const reducer: Reducer<ApplicationAdminState> = (
    state: ApplicationAdminState = defaultProps,
    incomingAction: Action
) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case APPLICATION_ADMIN_ACTIONS.GET_ACT_TYPES:
            return {
                ...state,
                ActTypes: action.payload,
                isGettingAccountTypes: true,
                isError: false,
            };
        case APPLICATION_ADMIN_ACTIONS.GET_ACT_TYPES_FAILURE:
            return {
                ...state,
                ActTypes: action.payload,
                isGettingAccountTypes: false,
                isError: true,
            };
        case APPLICATION_ADMIN_ACTIONS.GET_ACT_TYPES_SUCCESS:
            return {
                ...state,
                ActTypes: action.payload,
                isGettingAccountTypes: false,
                isError: false,
            };
        case APPLICATION_ADMIN_ACTIONS.SELECTED_ACT:
            return {
                ...state,
                selectedAct: action.payload,
            };
        case APPLICATION_ADMIN_ACTIONS.SAVE_ACT_TYPES:
            return {
                ...state,
                saveAct: action.payload,
                isSavingAccountTypes: true,
                isError: false,
                isActSaved: false,
            };
        case APPLICATION_ADMIN_ACTIONS.SAVE_ACT_TYPES_FAILURE:
            return {
                ...state,
                saveAct: action.payload,
                isSavingAccountTypes: false,
                isError: true,
                isActSaved: false,
            };
        case APPLICATION_ADMIN_ACTIONS.SAVE_ACT_TYPES_SUCCESS:
            return {
                ...state,
                saveAct: action.payload,
                isSavingAccountTypes: false,
                isError: false,
                isActSaved: true,
            };
        case APPLICATION_ADMIN_ACTIONS.ADD_NEW_ACT:
            return {
                ...state,
                isAddNewActBtnClicked: action.payload,
            };
        default:
        // The following line guarantees that every action in the KnownAction union has been covered by a case above
        // const exhaustiveCheck: never = action;
    }

    return state || defaultProps;
};
