import { put, takeEvery, call } from "redux-saga/effects";

import * as ResubmitActionCreator from "./actionCreater";
import * as RESUBMIT_ACTIONS from "./actionTypes";

import { baseUrl } from "../../../../envConfig";
import { doGetRequest, doPostRequest } from "../../../utils/utils";

export function* getReProcessOrders(action) {
    const endpointUrl = baseUrl + `/GetReProcessOutBoundOrders`;
    try {
        const response = yield doPostRequest(
            endpointUrl,
            JSON.stringify(action.payload)
        );
        window["AppInsightTrace"].trackTrace(
            `POST request to load Re-Process OutBound Orders : Resubmit Page : payload` +
                JSON.stringify(action.payload)
        );
        if (response) {
            window["AppInsightTrace"].trackTrace(
                `POST request to load Re-Process OutBound Orders : Resubmit Page- Success: ` +
                    JSON.stringify(response)
            );
            yield put(
                ResubmitActionCreator.getReProcessOrdersSuccess(response)
            );
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `POST request to load Re-Process OutBound Orders : Resubmit Page - Failed: ` +
                JSON.stringify(err)
        );

        yield put(ResubmitActionCreator.getReProcessOrdersFailure(err));
    }
}

export function* reProcessOrders(action) {
    const endpointUrl = baseUrl + `/ReprocessOutBoundOrders`;
    try {
        const response = yield doPostRequest(
            endpointUrl,
            JSON.stringify(action.payload)
        );
        window["AppInsightTrace"].trackTrace(
            `POST request to Re-Process OutBound Orders : Resubmit Page : payload` +
                JSON.stringify(action.payload)
        );
        if (response) {
            window["AppInsightTrace"].trackTrace(
                `POST request to Re-Process OutBound Orders : Resubmit Page- Success: ` +
                    JSON.stringify(response)
            );
            yield put(ResubmitActionCreator.reProcessOrdersSuccess(response));
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `POST request Re-Process OutBound Orders : Resubmit Page - Failed: ` +
                JSON.stringify(err)
        );

        yield put(ResubmitActionCreator.reProcessOrdersFailure(err));
    }
}

export function* ResubmitWatcher() {
    yield takeEvery(RESUBMIT_ACTIONS.GET_RE_PROCESS_ORDERS, getReProcessOrders);
    yield takeEvery(RESUBMIT_ACTIONS.RE_PROCESS_ORDERS, reProcessOrders);
}
