import * as GET_CUSTOMERS_ROOF_REPORTS from "./actionTypes";

export function getCustomersRoofReports(data): any {
    return {
        payload: data,
        type: GET_CUSTOMERS_ROOF_REPORTS.CUSTOMERS_ROOF_REPORTS,
    };
}

export function getRoofReportSearchText(data): any {
    return {
        payload: data,
        type: GET_CUSTOMERS_ROOF_REPORTS.ROOF_REPORTS_SEARCH_TEXT,
    };
}

export function getRequesterEmail(data): any {
    return {
        payload: data,
        type: GET_CUSTOMERS_ROOF_REPORTS.ROOF_REPORTS_EMAIL_ADDRESS,
    };
}
export function requesterEmailClicked(data): any {
    return {
        payload: data,
        type: GET_CUSTOMERS_ROOF_REPORTS.ROOF_REPORTS_EMAIL_CLICKED,
    };
}
export function refundReport(data): any {
    return {
        payload: data,
        type: GET_CUSTOMERS_ROOF_REPORTS.REFUND_REPORT
    }
}
export function refundReportSuccess(data): any {
    return {
        payload: data,
        type: GET_CUSTOMERS_ROOF_REPORTS.REFUND_REPORT_SUCCESS
    }
}
export function refundReportFailed(err): any {
    return {
        payload: err,
        type: GET_CUSTOMERS_ROOF_REPORTS.REFUND_REPORT_FAILED
    }
}
export function resetRefundReport(): any{
    return{
        type: GET_CUSTOMERS_ROOF_REPORTS.RESET_REFUND_REPORT
    }
}
export function resendEmail(data): any {
    return {
        payload: data,
        type: GET_CUSTOMERS_ROOF_REPORTS.RESEND_EMAIL
    }
}
export function resendEmailSuccess(data): any {
    return {
        payload: data,
        type: GET_CUSTOMERS_ROOF_REPORTS.RESEND_EMAIL_SUCCESS
    }
}
export function resendEmailFailed(err): any {
    return {
        payload: err,
        type: GET_CUSTOMERS_ROOF_REPORTS.RESEND_EMAIL_FAILED
    }
}
export function resetResendEmail(): any{
    return{
        type: GET_CUSTOMERS_ROOF_REPORTS.RESET_RESEND_EMAIL
    }
}
export function transferOrder(data): any {
    return {
        payload: data,
        type: GET_CUSTOMERS_ROOF_REPORTS.TRANSFER_ORDER
    }
}
export function transferOrderSuccess(data): any {
    return {
        payload: data,
        type: GET_CUSTOMERS_ROOF_REPORTS.TRANSFER_ORDER_SUCCESS
    }
}
export function transferOrderFailed(err): any {
    return {
        payload: err,
        type: GET_CUSTOMERS_ROOF_REPORTS.TRANSFER_ORDER_FAILED
    }
}
export function resetTransferOrder(): any{
    return{
        type: GET_CUSTOMERS_ROOF_REPORTS.RESET_TRANSFER_ORDER
    }
}
export function searchProfile(data): any {
    return {
        payload: data,
        type: GET_CUSTOMERS_ROOF_REPORTS.SEARCH_USER_PROFILE
    }
}
export function searchProfileSuccess(data): any {
    return {
        payload: data,
        type: GET_CUSTOMERS_ROOF_REPORTS.SEARCH_USER_PROFILE_SUCCESS
    }
}
export function searchProfileFailed(err): any {
    return {
        payload: err,
        type: GET_CUSTOMERS_ROOF_REPORTS.SEARCH_USER_PROFILE_FAILED
    }
}
export function resetSearchProfile(): any{
    return{
        type: GET_CUSTOMERS_ROOF_REPORTS.RESET_SEARCH_USER_PROFILE
    }
}
