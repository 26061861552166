import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import * as HeaderActions from "../components/Header/actionTypes";

//import IProductsProps from '../models/HomePage/IProductSelection'
// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
export interface HeaderState {
    isDebug: boolean;
    paymentRequest: any;
    isSendingPaymentRequest: boolean;
    isPaymentRequestError: boolean;
    isPaymentRequestSuccess: boolean;
    contactDetails: any;
    isLoadingContactDetails: boolean;
    isContactDetailsSuccess: boolean;
    isContactDetailsError: boolean;
    zenDeskVal: any;
    isLoadingZenDeskVal: boolean;
    isZenDeskValSuccess: boolean;
    isZenDeskValError: boolean;
}

export interface header {
    type: any;
    payload: any;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = header;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestAPI: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: HeaderActions.CHECK_DEBUG,
            payload: "test",
        });
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultProps: HeaderState = {
    isDebug: false,
    paymentRequest: {},
    isSendingPaymentRequest: false,
    isPaymentRequestError: false,
    isPaymentRequestSuccess: false,
    contactDetails: {},
    isLoadingContactDetails: false,
    isContactDetailsSuccess: false,
    isContactDetailsError: false,
    zenDeskVal: {},
    isLoadingZenDeskVal: false,
    isZenDeskValSuccess: false,
    isZenDeskValError: false,
};

export const reducer: Reducer<HeaderState> = (
    state: HeaderState = defaultProps,
    incomingAction: Action
) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case HeaderActions.CHECK_DEBUG:
            return {
                ...state,
                isDebug: action.payload,
            };
        case HeaderActions.INITIATE_PAYMENT_REQUEST:
            return {
                ...state,
                paymentRequest: action.payload,
                isSendingPaymentRequest: true,
                isPaymentRequestError: false,
                isPaymentRequestSuccess: false,
            };
        case HeaderActions.INITIATE_PAYMENT_REQUEST_FAILURE:
            return {
                ...state,
                paymentRequest: action.payload,
                isSendingPaymentRequest: false,
                isPaymentRequestError: true,
                isPaymentRequestSuccess: false,
            };
        case HeaderActions.INITIATE_PAYMENT_REQUEST_SUCCESS:
            return {
                ...state,
                paymentRequest: action.payload,
                isSendingPaymentRequest: false,
                isPaymentRequestError: false,
                isPaymentRequestSuccess: true,
            };
        case HeaderActions.LOOKUP_CONTACT_DETAILS:
            return {
                ...state,
                contactDetails: {},
                isLoadingContactDetails: true,
                isContactDetailsError: false,
                isContactDetailsSuccess: false,
            };
        case HeaderActions.GUEST_LOOKUP_CONTACT_DETAILS:
            return {
                ...state,
                contactDetails: {},
                isLoadingContactDetails: true,
                isContactDetailsError: false,
                isContactDetailsSuccess: false,
            };
        case HeaderActions.LOOKUP_CONTACT_DETAILS_SUCCESS:
            return {
                ...state,
                contactDetails: action.payload,
                isLoadingContactDetails: false,
                isContactDetailsError: false,
                isContactDetailsSuccess: true,
            };
        case HeaderActions.LOOKUP_CONTACT_DETAILS_FAILURE:
            return {
                ...state,
                contactDetails: action.payload,
                isLoadingContactDetails: false,
                isContactDetailsError: true,
                isContactDetailsSuccess: false,
            };
        case HeaderActions.LOOKUP_ZENDESK_VAL:
            return {
                ...state,
                zenDeskVal: {},
                isLoadingZenDeskVal: true,
                isZenDeskValError: false,
                isZenDeskValSuccess: false,
            };
        case HeaderActions.GUEST_LOOKUP_ZENDESK_VAL:
            return {
                ...state,
                zenDeskVal: {},
                isLoadingZenDeskVal: true,
                isZenDeskValError: false,
                isZenDeskValSuccess: false,
            };
        case HeaderActions.LOOKUP_ZENDESK_VAL_SUCCESS:
            return {
                ...state,
                zenDeskVal: action.payload,
                isLoadingZenDeskVal: false,
                isZenDeskValError: false,
                isZenDeskValSuccess: true,
            };
        case HeaderActions.LOOKUP_ZENDESK_VAL_FAILURE:
            return {
                ...state,
                zenDeskVal: action.payload,
                isLoadingZenDeskVal: false,
                isZenDeskValError: true,
                isZenDeskValSuccess: false,
            };
        default:
    }

    return state || defaultProps;
};
