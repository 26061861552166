import * as CUSTOMERS_ACTION_TYPES from "./actionTypes";

export function getCustomersRecords(data): any {
    return {
        payload: data,
        type: CUSTOMERS_ACTION_TYPES.GET_CUSTOMERS_SEARCH_RECORDS,
    };
}
export function sendCustomerRecord(data): any {
    return {
        payload: data,
        type: CUSTOMERS_ACTION_TYPES.SEND_DATA_TO_CUSTOMER_DETAIL_PAGE,
    };
}

export function saveSearchText(data): any {
    return {
        payload: data,
        type: CUSTOMERS_ACTION_TYPES.SEARCH_TEXT,
    };
}

export function getCustomersSearchPageNo(data): any {
    return {
        payload: data,
        type: CUSTOMERS_ACTION_TYPES.PAGE_NO,
    };
}

export function hasRenewTokenData(data): any {
    return {
        payload: data,
        type: CUSTOMERS_ACTION_TYPES.HAS_RENEW_TOKEN,
    };
}

export function isUserProfileRefreshed(data): any {
    return {
        payload: data,
        type: CUSTOMERS_ACTION_TYPES.IS_USER_PROFILE_REFRESHED,
    };
}
