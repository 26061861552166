import * as React from "react";
import { lazy, Suspense } from "react";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { GafOktaManager } from "../auth/gaf-okta-auth";
import * as TagManager from 'react-gtm-module';

import { ApplicationState } from "../reducers";
import store from "./../configureStore";
import Header from "./Header";
import Footer from "./Footer";
import Loader from "./Loader";
import ErrorBoundary from "./ErrorBoundary";

//--------
// Okta pages
import Home from "./home";
import { HomeNotLoggedIn } from "./home-not-logged-in";
import HomeLoggedIn from "./home-logged-in";
import { Callback } from "./callback";

//------------------
// For Contractro Pages
const GuestUserHomePage = lazy(() => import(/*webpackChunkName: "GuestHomePage" */ '../containers/GuestUser'));
const HomePage = lazy(() => import(/* webpackChunkName: "HomePage" */ '../containers/HomePage'));
const MyOrders = lazy(() => import(/* webpackChunkName: "MyOrders" */ '../containers/MyOrders'));
const Report3D = lazy(() => import(/* webpackChunkName: "Report3D" */ '../containers/Report3D'));
const SupportRequests = lazy(() => import(/* webpackChunkName: "SupportRequests" */ '../containers/SupportRequests'));
const TrackOrder = lazy(() => import(/* webpackChunkName: "TrackOrder" */ "../containers/TrackOrder"));
const CreateAccount = lazy(() => import(/* webpackChunkName: "CreateAccount" */ "../containers/CreateAccount"));
const MyReceipts = lazy(() => import(/* webpackChunkName: "MyReceipts" */ '../containers/MyReceipts'));
const Faqs = lazy(() => import(/* webpackChunkName: "Faqs" */ '../containers/Faqs'));
const SiteMaintenance = lazy(() => import(/* webpackChunkName: "SiteMaintenance" */ './Site-maintenance'));
const CutOverPage = lazy(() => import(/* webpackChunkName: "CutOverPage" */ './CutoverPage'));
const NotauthorizedPage = lazy(() => import(/* webpackChunkName: "NotAuthorizedPage" */ '../containers/NotauthorizedPage'));
const CovergaeMap = lazy(() => import(/* webpackChunkName: "CoveragePage" */ "../containers/CovergeMap"));
const PaymentSuccess = lazy(() => import(/* webpackChunkName: "PaymentSuccess" */ "./PaymentSuccess"));
const PaymentError = lazy(() => import(/* webpackChunkName: "PaymentError" */ "./PaymentError"));
const PaymentCancel = lazy(() => import(/* webpackChunkName: "PaymentCancel" */ "./PaymentCancel"));
const PageNotFound = lazy(() => import(/* webpackChunkName: "PageNotFoundPage" */ "./PageNotFound"));
const DownloadReport = lazy(() => import(/* webpackChunkName: "DownLoadReport" */ "./DownLoadReport"));
const ComponentToGuestPrint = lazy(() => import(/* webpackChunkName: "ComponentToGuestPrint" */ "./PrintableComponent/guestPrint"));
const MyPreferences = lazy(() => import(/* webpackChunkName: "MyPreferencesPage" */ "../containers/MyPreferences"));
// For Concierge Pages
const Customers = lazy(() => import(/* webpackChunkName: "CustomersPage" */ "../containers/concierge/Customers"));
const CustomerDetails = lazy(() => import(/* webpackChunkName: "CustomersDetailsPage" */ "../containers/concierge/CustomerDetails"));
const Promocodes = lazy(() => import(/* webpackChunkName: "PromoCodesPage" */ "../containers/concierge/Promocodes"));
const Roofreports = lazy(() => import(/* webpackChunkName: "RoofReportsPage" */ "../containers/concierge/Roofreports"));
const Reports = lazy(() => import(/* webpackChunkName: "OperationalReportPage" */ "../containers/concierge/Reports"));
const SubscriberAdmin = lazy(() => import(/* webpackChunkName: "SubscriberAdministrationPage" */ "../containers/concierge/SubscriberAdmin"));
const AddEditSubscriber = lazy(() => import(/* webpackChunkName: "addEditSubscriber Page" */ "../containers/concierge/AddEditSubscriber"));
const ApplicationAdmin = lazy(() => import(/* webpackChunkName: "ApplicationAdmin Page" */ "../containers/concierge/ApplicationAdmin"));
const AccountTypeAdministration = lazy(() => import(/* webpackChunkName: "AccountType Administration Page" */ "../containers/concierge/AccountTypeAdministration"));
const AddEditAccountType = lazy(() => import(/* webpackChunkName: "Add Edit AccountType Page" */ "../containers/concierge/AddEditAccountType"));
const Services = lazy(() => import(/* webpackChunkName: "Services Page" */ "../containers/concierge/Services"));
const AddEditService = lazy(() => import(/* webpackChunkName: "AddEditService Page" */ "../containers/concierge/AddEditService"));
const ServiceBundles = lazy(() => import(/* webpackChunkName: "Service Bundle Page" */ "../containers/concierge/ServiceBundles"));
const AddEditServiceBundles = lazy(() => import(/* webpackChunkName: "AddEditServiceBundles Page" */ "../containers/concierge/AddEditServiceBundles"));
const Resubmit = lazy(() => import(/* webpackChunkName: "Resubmit Page" */ "../containers/concierge/Resubmit"));
import CacheAdministration from "../containers/concierge/CacheAdministration";

// envConfig
import * as envConfig from "../../envConfig";
import PDLocal from "../containers/GuestUser/PDLocal";




const {
    routes: {
        guestUser, homePage, trackOrder, createAccount, logInPage, myOrderPage, myReceiptPage,
        faqs, coverageMap, siteMaintenance, notAuthorized, customers, customerDetails,
        promocodes, roofreports, paymentSuccess, paymentError, paymentCancel, pageNotFound,
        downLoadReport, supportRequests, cutOverPage, reports, subscriberAdministration,
        addEditSubscriber, report3D, report3D_Contractor, report3D_PropertyOwner, applicationAdmin, accountTypeAdministration,
        addEditAccountType, myPreferences, services, addEditService, serviceBundles, addEditServiceBundles, reSubmit, cacheAdministration,
        vendorHomePage,
        },
        googleTrackingId, newGoogleTrackingId, isMaintenanceMode
} = envConfig;

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) => (
            (GafOktaManager.isAuthenticated === true
                ? <Component {...props} />
                : (
                    <>
                        {GafOktaManager.login()}
                    </>
                )
            )
        )}
    />
);

const PublicRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) => (
            (GafOktaManager.isAuthenticated !== true
                ? <Component {...props} />
                : <Redirect to="/page-not-found" />
            )
        )}
    />
);
const GuestOrUserRoute = ({ component: Component, ...rest }) => {
    const pathName = window.location.pathname && (window.location.pathname === "/" ? window.location.pathname : window.location.pathname.toLowerCase());
    sessionStorage.setItem('pathName', pathName);
    return (
    <Route
        {...rest}
        render={(props) => (
            (
                (window.location.pathname == "guest-home-page" || window.location.pathname === "/thd" || GafOktaManager.isAuthenticated === false)
                    ? <Component {...props}/>
                    : <>
                        {GafOktaManager.login()}
                    </>
            )
        )}
    />
)};


export class Layout extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        // Current PROD GTM Container
        const tagManagerArgs = {
            gtmId: googleTrackingId,
            event: 'pageView',
            page: window.location.pathname,
            dataLayerName: "PageDataLayer"
        }
        TagManager.initialize(tagManagerArgs);
        // ----------------
        // New GTM Container
        const newTagManagerArgs = {
            gtmId: newGoogleTrackingId,
            event: 'pageView',
            page: window.location.pathname,
            dataLayerName: "PageDataLayer"
        }
        TagManager.initialize(newTagManagerArgs)
        // -----

    }

    public render() {
        const pathNameArray = ['/site-maintenance', '/cutover', 'guest-print-receipt']

        return (
            <React.Fragment >
                <ErrorBoundary>
                    {
                        (pathNameArray.includes(window.location.pathname) !== true) && (
                            <Header />
                        )}
                </ErrorBoundary>
                < div
                    className={this.props.guestUser === true ? "content" : "content gafUserContent"}
                >
                    <ErrorBoundary key={window.location.pathname}>
                        <Suspense fallback={<Loader />}>
                            <Switch>
                                <GuestOrUserRoute exact path="/" component={Home} />

                                <GuestOrUserRoute exact path={vendorHomePage.url} component={Home} />

                                <Route
                                    exact path="/callback"
                                    component={Callback}
                                />
                                <Route
                                    exact path="/homenotloggedin"
                                    component={HomeNotLoggedIn}
                                />
                                <Route
                                    exact path="/homeloggedin"
                                    component={HomeLoggedIn}
                                />
                                <Route
                                    exact path="/guest-print-receipt"
                                    component={ComponentToGuestPrint}
                                />

                                <PublicRoute
                                    exact path={guestUser.url}
                                    component={GuestUserHomePage}
                                />
                                <PublicRoute
                                    exact path={trackOrder.url}
                                    component={TrackOrder}
                                />
                                <PublicRoute
                                    exact path={createAccount.url}
                                    component={CreateAccount}
                                />

                                <PrivateRoute
                                    exact path={homePage.url}
                                    component={HomePage}
                                    type="private"
                                />
                                <PrivateRoute
                                    exact path={myOrderPage.url}
                                    component={MyOrders}
                                />
                                <PrivateRoute
                                    exact path={myReceiptPage.url}
                                    component={MyReceipts}
                                />
                                <PrivateRoute
                                    exact path={myPreferences.url}
                                    component={MyPreferences}
                                />
                                <PrivateRoute
                                    exact path={`${downLoadReport.url}/:reportName`}
                                    component={DownloadReport}
                                />
                                {/* For Concierge Routes */}
                                <PrivateRoute
                                    exact path={customers.url}
                                    component={Customers}
                                />
                                <PrivateRoute
                                    exact path={customerDetails.url}
                                    component={CustomerDetails}
                                />
                                <PrivateRoute
                                    exact path={promocodes.url}
                                    component={Promocodes}
                                />
                                <PrivateRoute
                                    exact path={roofreports.url}
                                    component={Roofreports}
                                />

                                <PrivateRoute
                                    exact path={reports.url}
                                    component={Reports}
                                />
                                <PrivateRoute
                                    exact path={subscriberAdministration.url}
                                    component={SubscriberAdmin}
                                />
                                <PrivateRoute
                                    exact path={addEditSubscriber.url}
                                    component={AddEditSubscriber}
                                />
                                <PrivateRoute
                                    exact path={supportRequests.url}
                                    component={SupportRequests}
                                />
                                <PrivateRoute
                                    exact path={applicationAdmin.url}
                                    component={ApplicationAdmin}
                                />
                                <PrivateRoute
                                    exact path={accountTypeAdministration.url}
                                    component={AccountTypeAdministration}
                                />
                                <PrivateRoute
                                    exact path={addEditAccountType.url}
                                    component={AddEditAccountType}
                                />
                                <PrivateRoute
                                    exact path={services.url}
                                    component={Services}
                                />
                                <PrivateRoute
                                    exact path={addEditService.url}
                                    component={AddEditService}
                                />
                                <PrivateRoute
                                    exact path={serviceBundles.url}
                                    component={ServiceBundles}
                                />
                                <PrivateRoute
                                    exact path={addEditServiceBundles.url}
                                    component={AddEditServiceBundles}
                                />
                                <PrivateRoute
                                    exact path={reSubmit.url}
                                    component={Resubmit}
                                />
                                <PrivateRoute
                                    exact path={cacheAdministration.url}
                                    component={CacheAdministration}
                                />
                                <Route
                                    exact path="/PDLocal"
                                    component={PDLocal}
                                />
                                <Route
                                    exact path={`${report3D.url}/:id`}
                                    component={Report3D}
                                />
                                <Route
                                    exact path={`${report3D_Contractor.url}/:id`}
                                    component={Report3D}
                                />
                                <Route
                                    exact path={`${report3D_PropertyOwner.url}/:id`}
                                    component={Report3D}
                                />
                                <Route
                                    exact path={faqs.url}
                                    component={Faqs}
                                />
                                <Route
                                    exact path={coverageMap.url}
                                    component={CovergaeMap}
                                />
                                <Route
                                    exact path={paymentSuccess.url}
                                    component={PaymentSuccess}
                                />
                                <Route
                                    exact path={paymentError.url}
                                    component={PaymentError}
                                />
                                <Route
                                    exact path={paymentCancel.url}
                                    component={PaymentCancel}
                                />
                                <Route
                                    exact path={siteMaintenance.url}
                                    component={SiteMaintenance}
                                />
                                <Route
                                    exact path={notAuthorized.url}
                                    component={NotauthorizedPage}
                                />
                                <Route
                                    component={PageNotFound}
                                />
                                {/* <Route
                                exact
                                path={supportRequests.url}
                                render={() => <Suspense fallback={<Loader />}> <SupportRequests /></Suspense>}
                            /> */}
                            </Switch>

                        </Suspense>
                    </ErrorBoundary>
                </div>
                <ErrorBoundary>
                    {
                        (window.location.pathname !== "/site-maintenance" && window.location.pathname !== "/cutover" && window.location.pathname !== "/guest-print-receipt") ? (
                            <Footer />
                        ) : null
                    }
                </ErrorBoundary>
            </React.Fragment >
        );
    }
}
export function mapStateToProps(state: any) {
    const { guestUser } = state.HomePage;
    return { guestUser };
}

export default withRouter(
    connect(
        (state: ApplicationState) => state.HomePage)(Layout) as React.ComponentType<any>
);
