import { put, takeEvery, call } from "redux-saga/effects";

import * as ApplicationAdminActionCreator from "./actionCreater";
import * as APPLICATION_ADMIN_ACTIONS from "./actionTypes";

import { baseUrl } from "../../../../envConfig";
import { doGetRequest, doPostRequest } from "../../../utils/utils";

export function* getActTypes() {
    const endpointUrl = baseUrl + "/lookup?lookupName=GuestAccountType";
    try {
        const response = yield doGetRequest(endpointUrl, null);
        window["AppInsightTrace"].trackTrace(
            `GET Act Type - request for Account Type Admin Page: `
        );
        if (response) {
            window["AppInsightTrace"].trackTrace(
                `GET Act Type - success for Account Type Admin Page: ` +
                    JSON.stringify(response)
            );
            yield put(
                ApplicationAdminActionCreator.getActTypesSuccess(response)
            );
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `GET Act Type - Failed for Account Type Admin Page: ` +
                JSON.stringify(err)
        );

        yield put(ApplicationAdminActionCreator.getActTypesFailure(err));
    }
}

export function* saveActTypes(action) {
    const endpointUrl = baseUrl + "/lookup";

    try {
        const response = yield doPostRequest(
            endpointUrl,
            JSON.stringify(action.payload)
        );
        window["AppInsightTrace"].trackTrace(
            `Add/Edit Act Type - POST request for Add/Edit Account Type Page - Payload: ` +
                JSON.stringify(action.payload)
        );

        if (response) {
            window["AppInsightTrace"].trackTrace(
                `Add/Edit Act Type - POST request success for Add/Edit Account Type Page : ` +
                    JSON.stringify(response)
            );

            yield put(
                ApplicationAdminActionCreator.saveActTypesSuccess(response)
            );
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `Add/Edit Act Type - post request Failed for Add/Edit Account Type Page : ` +
                JSON.stringify(err)
        );
        yield put(ApplicationAdminActionCreator.saveActTypesFailure(err));
    }
}

export function* ApplicationAdminWatcher() {
    yield takeEvery(APPLICATION_ADMIN_ACTIONS.GET_ACT_TYPES, getActTypes);
    yield takeEvery(APPLICATION_ADMIN_ACTIONS.SAVE_ACT_TYPES, saveActTypes);
}
