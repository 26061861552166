import { put, takeEvery, call } from "redux-saga/effects";

import * as ServiceActionCreator from "./actionCreater";
import * as SERVICE_ACTIONS from "./actionTypes";

import { baseUrl } from "../../../../envConfig";
import { doGetRequest, doPostRequest } from "../../../utils/utils";

export function* getAllServices(action) {
    let endpointUrl;
    if (action.payload !== undefined) {
        endpointUrl = baseUrl + `/services?propertyType=${action.payload}`;
    } else {
        endpointUrl = baseUrl + `/services`;
    }
    try {
        const response = yield doGetRequest(endpointUrl, null);
        window["AppInsightTrace"].trackTrace(
            `GET Services - request Admin Page: `
        );
        if (response) {
            window["AppInsightTrace"].trackTrace(
                `GET Services - success for Admin Page: ` +
                    JSON.stringify(response)
            );
            yield put(ServiceActionCreator.getAllServicesSuccess(response));
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `GET Services Failed for Admin Page: ` + JSON.stringify(err)
        );

        yield put(ServiceActionCreator.getAllServicesFailure(err));
    }
}

export function* addEditServices(action) {
    const endpointUrl = baseUrl + "/services";

    try {
        const response = yield doPostRequest(
            endpointUrl,
            JSON.stringify(action.payload)
        );
        window["AppInsightTrace"].trackTrace(
            `Add/Edit Services - POST request- Payload: ` +
                JSON.stringify(action.payload)
        );

        if (response) {
            window["AppInsightTrace"].trackTrace(
                `Add/Edit Services - POST request success: ` +
                    JSON.stringify(response)
            );

            yield put(ServiceActionCreator.addEditServicesSuccess(response));
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `Add/Edit Services - post request Failed: ` + JSON.stringify(err)
        );
        yield put(ServiceActionCreator.addEditServicesFailure(err));
    }
}

export function* ServiceWatcher() {
    yield takeEvery(SERVICE_ACTIONS.GET_SERVICES, getAllServices);
    yield takeEvery(SERVICE_ACTIONS.ADD_EDIT_SERVICES, addEditServices);
}
