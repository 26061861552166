import * as HOMEPAGE_ACTIONS from "./actionTypes";

// export function addingAnimationSucess(data): any {
//     return {
//         data,
//         type: HOMEPAGE_ACTIONS.GET_SITECORE_DATA_SUCCESS,
//     };
// }

export function getCoordinate(data): any {
    return {
        payload: data,
        type: HOMEPAGE_ACTIONS.GET_COORDINATE,
    };
}

export function getSuceessPromoCodeModal(data): any {
    return {
        payload: data,
        type: HOMEPAGE_ACTIONS.SUCCESS_PROMOCODE_MODAL,
    };
}

export function getFullAddress(data) {
    return {
        payload: data,
        type: HOMEPAGE_ACTIONS.GET_FULLADDRESS,
    };
}

export function getUserProfileData(data) {
    return {
        payload: data,
        type: HOMEPAGE_ACTIONS.USER_PROFILE_DATA,
    };
}

export function updateAvailableCredits(data) {
    return {
        payload: data,
        type: HOMEPAGE_ACTIONS.UPDATE_AVAILABLE_CREDIT,
    };
}

export function clearSearchAddress(data) {
    return {
        payload: data,
        type: HOMEPAGE_ACTIONS.SEARCH_ADDRESS_VALUE,
    };
}

export function getOpenClose(data) {
    return {
        payload: data,
        type: HOMEPAGE_ACTIONS.GET_OPEN_CLOSE,
    };
}

export function placeOrder(data) {
    return {
        type: HOMEPAGE_ACTIONS.PLACE_ORDER,
        payload: data,
    };
}

export function placeOrderSuccess(data) {
    return {
        type: HOMEPAGE_ACTIONS.PLACE_ORDER_SUCCESS,
        payload: data,
    };
}

export function placeOrderFailure(err) {
    return {
        type: HOMEPAGE_ACTIONS.PLACE_ORDER_FAILED,
        payload: err,
    };
}

export function resetPlaceOrder() {
    return {
        type: HOMEPAGE_ACTIONS.RESET_PLACE_ORDER,
    };
}

export function isUserGuestUser(data) {
    return {
        payload: data,
        type: HOMEPAGE_ACTIONS.GUEST_USER,
    };
}

export function shouldDisplayMarker(data) {
    return {
        payload: data,
        type: HOMEPAGE_ACTIONS.SHOULD_DISPLAY_MARKER,
    };
}

export function shouldDisableZoomBtns(data) {
    return {
        payload: data,
        type: HOMEPAGE_ACTIONS.SHOULD_DISABLE_ZOOM_BTN,
    };
}

export function totalMarkerPins(data) {
    return {
        payload: data,
        type: HOMEPAGE_ACTIONS.TOTAL_MARKER_PINS,
    };
}

export function totalMarkersWithPinsAndLabel(data) {
    return {
        payload: data,
        type: HOMEPAGE_ACTIONS.TOTAL_MARKERS_WITH_PINS_AND_LABEL,
    };
}

export function countCreditsAsPerPins(data) {
    return {
        payload: data,
        type: HOMEPAGE_ACTIONS.CREDITS_AS_PER_TOTAL_PINS,
    };
}

export function shouldDisableAdjustPinBtn(data) {
    return {
        payload: data,
        type: HOMEPAGE_ACTIONS.SHOULD_DISABLE_ADJUST_PIN_BTN,
    };
}

export function shouldDisableRemovePinBtn(data) {
    return {
        payload: data,
        type: HOMEPAGE_ACTIONS.SHOULD_DISABLE_REMOVE_PIN_BTN,
    };
}

export function adjustPinBtnClked(data) {
    return {
        payload: data,
        type: HOMEPAGE_ACTIONS.ADJUST_PIN_BTN_CLICKED,
    };
}

export function removePinBtnClked(data) {
    return {
        payload: data,
        type: HOMEPAGE_ACTIONS.REMOVE_PIN_BTN_CLICKED,
    };
}

export function addPinBtnClked(data) {
    return {
        payload: data,
        type: HOMEPAGE_ACTIONS.ADD_PIN_BTN_CLICKED,
    };
}

export function mapClicked(data) {
    return {
        payload: data,
        type: HOMEPAGE_ACTIONS.MAP_CLICKED,
    };
}

export function shouldDisableAddPinBtn(data) {
    return {
        payload: data,
        type: HOMEPAGE_ACTIONS.SHOULD_DISABLE_ADD_PIN_BTN,
    };
}

export function shouldDisableSubmitOrderBtn(data) {
    return {
        payload: data,
        type: HOMEPAGE_ACTIONS.SHOULD_DISABLE_SUBMIT_ORDER_BTN,
    };
}

export function updateTAndC(data): any {
    return {
        type: HOMEPAGE_ACTIONS.UPDATE_T_AND_C,
        payload: data,
    };
}

export function updateTAndCSuccess(data): any {
    return {
        type: HOMEPAGE_ACTIONS.UPDATE_T_AND_C_SUCCESS,
        payload: data,
    };
}

export function updateTAndCFailure(err): any {
    return {
        type: HOMEPAGE_ACTIONS.UPDATE_T_AND_C_FAILED,
        payload: err,
    };
}

export function checkCoverage(data): any {
    return {
        type: HOMEPAGE_ACTIONS.CHECK_COVERAGE,
        payload: data,
    };
}

export function checkCoverageGuest(data): any {
    return {
        type: HOMEPAGE_ACTIONS.CHECK_COVERAGE_GUEST,
        payload: data,
    };
}

export function checkCoverageSuccess(data): any {
    return {
        type: HOMEPAGE_ACTIONS.CHECK_COVERAGE_SUCCESS,
        payload: data,
    };
}

export function checkCoverageFailure(err): any {
    return {
        type: HOMEPAGE_ACTIONS.CHECK_COVERAGE_FAILED,
        payload: err,
    };
}

export function getUserProfileInfo(data): any {
    return {
        type: HOMEPAGE_ACTIONS.GET_USER_PROFILE,
        payload: data,
    };
}

export function getUserProfileInfoSuccess(data): any {
    return {
        type: HOMEPAGE_ACTIONS.GET_USER_PROFILE_SUCCESS,
        payload: data,
    };
}

export function getUserProfileInfoFailure(err): any {
    return {
        type: HOMEPAGE_ACTIONS.GET_USER_PROFILE_FAILED,
        payload: err,
    };
}

export function resetCheckCoverage(): any {
    return {
        type: HOMEPAGE_ACTIONS.RESET_CHECK_COVERAGE,
    };
}

export function setNotifications(data): any {
    return {
        type: HOMEPAGE_ACTIONS.SET_NOTIFICATION,
        payload: data,
    };
}

export function setNotificationsSuccess(data): any {
    return {
        type: HOMEPAGE_ACTIONS.SET_NOTIFICATION_SUCCESS,
        payload: data,
    };
}

export function setNotificationsFailure(err): any {
    return {
        type: HOMEPAGE_ACTIONS.SET_NOTIFICATION_FAILED,
        payload: err,
    };
}

export function getTandCllookupData(data): any {
    return {
        type: HOMEPAGE_ACTIONS.GET_T_AND_C_LOOKUP,
        payload: data,
    };
}

export function getTandCllookupDataSuccess(data): any {
    return {
        type: HOMEPAGE_ACTIONS.GET_T_AND_C_LOOKUP_SUCCESS,
        payload: data,
    };
}

export function getTandCllookupDataFailure(err): any {
    return {
        type: HOMEPAGE_ACTIONS.GET_T_AND_C_LOOKUP_FAILED,
        payload: err,
    };
}

export function getScopeConnectQuestions(): any {
    return {
        type: HOMEPAGE_ACTIONS.GET_SCOPE_CONNECT_QUESTIONS,
    };
}

export function getScopeConnectQuestionsSuccess(data): any {
    return {
        type: HOMEPAGE_ACTIONS.GET_SCOPE_CONNECT_QUESTIONS_SUCCESS,
        payload: data,
    };
}

export function getScopeConnectQuestionsFailure(err): any {
    return {
        type: HOMEPAGE_ACTIONS.GET_SCOPE_CONNECT_QUESTIONS_FAILED,
        payload: err,
    };
}

export function getPilotProducts(data): any {
    return {
        type: HOMEPAGE_ACTIONS.GET_PILOT_PRODUCTS,
        payload: data,
    };
}

export function getPilotProductsSuccess(data): any {
    return {
        type: HOMEPAGE_ACTIONS.GET_PILOT_PRODUCTS_SUCCESS,
        payload: data,
    };
}

export function getPilotProductsFailure(err): any {
    return {
        type: HOMEPAGE_ACTIONS.GET_PILOT_PRODUCTS_FAILED,
        payload: err,
    };
}

export function getPilotProductsStates(data): any {
    return {
        type: HOMEPAGE_ACTIONS.GET_PILOT_PRODUCTS_STATES,
        payload: data,
    };
}

export function getPilotProductsStatesSuccess(data): any {
    return {
        type: HOMEPAGE_ACTIONS.GET_PILOT_PRODUCTS_SUCCESS_STATES,
        payload: data,
    };
}

export function getPilotProductsStatesFailure(err): any {
    return {
        type: HOMEPAGE_ACTIONS.GET_PILOT_PRODUCTS_FAILED_STATES,
        payload: err,
    };
}

export function bluePrintFileUpload(data): any {
    return {
        type: HOMEPAGE_ACTIONS.BLUE_PRINT_FILE_UPLOAD,
        payload: {file: data},
    };
}

export function bluePrintFileUploadSuccess(data): any {
    return {
        type: HOMEPAGE_ACTIONS.BLUE_PRINT_FILE_UPLOAD_SUCCESS,
        payload: data,
    };
}

export function bluePrintFileUploadFailure(err): any {
    return {
        type: HOMEPAGE_ACTIONS.BLUE_PRINT_FILE_UPLOAD_FAILED,
        payload: err,
    };
}

export function resetBluePrintFileUpload() {
    return {
        type: HOMEPAGE_ACTIONS.RESEST_BLUE_PRINT_FILE_UPLOAD,
    }
}