import { put, takeEvery, call } from "redux-saga/effects";

import * as createAccountCreators from "./actionCreater";
import * as CREATEACCOUNT from "./actionTypes";

import { baseUrl } from "../../../envConfig";
import { getRequest, postRequest } from "../../utils/utils";

export function* getAccountTypes() {
    const endpointUrl = baseUrl + "/guest/AccountTypes";
    try {
        const response = yield getRequest(endpointUrl, null);
        if (response) {
            yield put(createAccountCreators.getAccountTypesSuccess(response));
        }
    } catch (err) {
        yield put(createAccountCreators.getAccountTypesFailure(err));
    }
}

export function* createAccount(action) {
    const endpointUrl = baseUrl + "/guest/createaccount"; //guest/createaccount
    try {
        const response = yield postRequest(
            endpointUrl,
            JSON.stringify(action.payload)
        );
        window["AppInsightTrace"].trackTrace(`createAccount post request for create account page - Guest Create Account Payload: ` + action.payload);
        if (response) {
            yield put(
                createAccountCreators.createAccountSuccess(
                    response,
                    action.payload
                )
            );
            window["AppInsightTrace"].trackTrace(`createAccount success for create account page - Guest Create Account Response: ` + response);
        
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(`createAccount failed for create account page - Guest Create Account error: ` + err);
        yield put(createAccountCreators.createAccountFailure(err));
    }
}

export function* CreateAccountWatcher() {
    yield takeEvery(CREATEACCOUNT.GET_ACCOUNT_TYPES, getAccountTypes);
    yield takeEvery(CREATEACCOUNT.CREATE_ACCOUNT, createAccount);
}
