import { baseUrl, reportUrl, errorEmailNotification } from "../../envConfig";
import { GafOktaManager } from "../auth/gaf-okta-auth";
import { OKTA_CONFIG } from "../environments/environment";
import {
    API_ADMIN_SCOPES,
    API_SUPER_ADMIN_SCOPES,
    API_USER_SCOPES,
    GAF_EMPLOYEE,
} from "../environments/scopes";

var moment = require("moment");

import { postSendErrorReport } from "../utils/utils";
import { rejects } from "assert";

const checkScopes = (certification) => {
    let scope = [];
    switch (certification) {
        case "User":
            scope = GAF_EMPLOYEE;
            break;
        case "QM_ADMIN":
            scope = API_ADMIN_SCOPES;
            break;
        case "QM_SUPER_ADMIN":
            scope = API_SUPER_ADMIN_SCOPES;
            break;
        default:
            scope = API_USER_SCOPES;
            break;
    }
    return scope;
};

// To renew token after updating scopes
export const renewToken = (associatedGAFCertification, promise = {}) => {
    window["AppInsightTrace"].trackTrace("QM WebApp: Trigger Renew Token");
    OKTA_CONFIG.scopes = checkScopes(associatedGAFCertification);
    GafOktaManager.getRenewToken(promise);
};

export const selectSourceSubscriber = (sourceSelected) => {
    const dropDownSelectedVal = sourceSelected;
    let subscribername = "";
    let source = "";
    if (dropDownSelectedVal == "") {
        source = "";
        subscribername = "";
    } else if (
        dropDownSelectedVal == "GAF" ||
        dropDownSelectedVal == "GAF Guest"
    ) {
        source = dropDownSelectedVal;
        subscribername = "";
    } else {
        source = dropDownSelectedVal;
        subscribername = dropDownSelectedVal;
    }

    return { source, subscribername };
};

export const apiCallSendErrorReport = (subject, errorObj) => {
    let errorDesc;
    errorDesc = JSON.stringify(errorObj, null, 4);
    // console.log(errorDesc);
    const apiUrl = baseUrl + "/SendErrorReport";
    const userEmailEncoded = btoa(
        GafOktaManager.storage.info.profile
            ? GafOktaManager.storage.info.profile.email.toLowerCase()
            : "guest email address"
    );
    const encodedEmail = btoa(errorEmailNotification.toLowerCase());
    const errorDateTime = moment(new Date()).format("MM/DD/YYYY");

    const apiResponse = postSendErrorReport(
        apiUrl,
        encodedEmail,
        subject,
        errorDesc,
        errorDateTime,
        userEmailEncoded
    );
    apiResponse
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
};
