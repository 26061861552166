import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
// import * as TrackOrderActions from "../containers/TrackOrder/actionTypes";
import * as CANCELGUESTORDER from "../components/PaymentCancel/actionTypes";

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
export interface CancelGuestOrderState {
    cancelGuestOrderData: any;
    isCancellingOrder: boolean;
    isOrderCancelledSuccess: boolean;
    isOrderCancelledFailure: boolean;
    paymentCancelled: boolean;
}

export interface CancelGuestOrder {
    type: any;
    payload: any;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = CancelGuestOrder;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestAPI: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: CANCELGUESTORDER.CANCEL_GUEST_ORDER,
            payload: "test",
        });
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultProps: CancelGuestOrderState = {
    cancelGuestOrderData: {},
    isCancellingOrder: false,
    isOrderCancelledSuccess: false,
    isOrderCancelledFailure: false,
    paymentCancelled: false,
};

export const reducer: Reducer<CancelGuestOrderState> = (
    state: CancelGuestOrderState = defaultProps,
    incomingAction: Action
) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case CANCELGUESTORDER.CANCEL_GUEST_ORDER:
            return {
                ...state,
                cancelGuestOrderData: action.payload,
                isCancellingOrder: true,
            };
        case CANCELGUESTORDER.CANCEL_GUEST_ORDER_FAILURE:
            return {
                ...state,
                cancelGuestOrderData: action.payload,
                isCancellingOrder: false,
                isOrderCancelledFailure: true,
            };
        case CANCELGUESTORDER.CANCEL_GUEST_ORDER_SUCCESS:
            return {
                ...state,
                cancelGuestOrderData: action.payload,
                isCancellingOrder: false,
                isOrderCancelledSuccess: true,
            };
        case CANCELGUESTORDER.PAYMENT_CANCEL:
            return {
                ...state,
                paymentCancelled: action.payload,
            };
        default:
        // The following line guarantees that every action in the KnownAction union has been covered by a case above
        // const exhaustiveCheck: never = action;
    }

    return state || defaultProps;
};
