export const CUSTOMER_ORDER_DETAILS_RECORDS = "CUSTOMER_ORDER_DETAILS_RECORDS";
export type CUSTOMER_ORDER_DETAILS_RECORDS =
    typeof CUSTOMER_ORDER_DETAILS_RECORDS;

export const CUSTOMER_TRANSACTION_DETAILS_RECORDS =
    "CUSTOMER_TRANSACTION_DETAILS_RECORDS";
export type CUSTOMER_TRANSACTION_DETAILS_RECORDS =
    typeof CUSTOMER_TRANSACTION_DETAILS_RECORDS;

export const ADDPARENT_MODAL_EMAIL_ERROR = "ADDPARENT_MODAL_EMAIL_ERROR";
export type ADDPARENT_MODAL_EMAIL_ERROR = typeof ADDPARENT_MODAL_EMAIL_ERROR;

export const SINGLE_USER_PROFILE = "SINGLE_USER_PROFILE";
export type SINGLE_USER_PROFILE = typeof SINGLE_USER_PROFILE;

export const SPEICAL_PRICE_SELECTED = "SPEICAL_PRICE_SELECTED";
export type SPEICAL_PRICE_SELECTED = typeof SPEICAL_PRICE_SELECTED;

export const REJECT_REPORT_REASON = "REJECT_REPORT_REASON";
export type REJECT_REPORT_REASON = typeof REJECT_REPORT_REASON;

export const NAVIGATION_IN_PARENT_PAGE = "NAVIGATION_IN_PARENT_PAGE";
export type NAVIGATION_IN_PARENT_PAGE = typeof NAVIGATION_IN_PARENT_PAGE;

export const TRANSFER_BALANCE = "TRANSFER_BALANCE";
export type TRANSFER_BALANCE = typeof TRANSFER_BALANCE;

export const TRANSFER_BALANCE_SUCCESS = "TRANSFER_BALANCE_SUCCESS";
export type TRANSFER_BALANCE_SUCCESS = typeof TRANSFER_BALANCE_SUCCESS;

export const TRANSFER_BALANCE_FAILURE = "TRANSFER_BALANCE_FAILURE";
export type TRANSFER_BALANCE_FAILURE = typeof TRANSFER_BALANCE_FAILURE;

export const CLEAR_ERROR = "CLEAR_ERROR";
export type CLEAR_ERROR = typeof CLEAR_ERROR;
