import * as GUEST_USER_PAGE_ACTIONS from "./actionTypes";

export function getGuestUserProfileData(data) {
    return {
        payload: data,
        type: GUEST_USER_PAGE_ACTIONS.GUEST_USER_PROFILE_DATA,
    };
}
export function getGuestUserProfileDataSuccess(data) {
    return {
        payload: data,
        type: GUEST_USER_PAGE_ACTIONS.GUEST_USER_PROFILE_DATA_SUCCESS,
    };
}
export function getGuestUserProfileDataFailure(err) {
    return {
        payload: err,
        type: GUEST_USER_PAGE_ACTIONS.GUEST_USER_PROFILE_DATA_FAILURE,
    };
}

export function guestSearchUser(data) {
    return {
        type: GUEST_USER_PAGE_ACTIONS.GUEST_SEARCH_USER,
        payload: data,
    };
}

export function guestSearchUserSuccess(data) {
    return {
        type: GUEST_USER_PAGE_ACTIONS.GUEST_SEARCH_USER_SUCCESS,
        payload: data,
    };
}
export function guestSearchUserFailure(error) {
    return {
        type: GUEST_USER_PAGE_ACTIONS.GUEST_SEARCH_USER_FAILURE,
        payload: error,
    };
}
export function resetGuestSearchUser() {
    return {
        type: GUEST_USER_PAGE_ACTIONS.RESET_GUEST_SEARCH_USER,
    };
}
export function guestOrderDetails(data) {
    return {
        type: GUEST_USER_PAGE_ACTIONS.GUEST_ORDER_DETAILS,
        payload: data,
    };
}

export function GuestUpdateTAndC(data): any {
    return {
        type: GUEST_USER_PAGE_ACTIONS.GUEST_UPDATE_T_AND_C,
        payload: data,
    };
}

export function GuestUpdateTAndCSuccess(data): any {
    return {
        type: GUEST_USER_PAGE_ACTIONS.GUEST_UPDATE_T_AND_C_SUCCESS,
        payload: data,
    };
}

export function GuestUpdateTAndCFailure(err): any {
    return {
        type: GUEST_USER_PAGE_ACTIONS.GUEST_UPDATE_T_AND_C_FAILED,
        payload: err,
    };
}

export function TrackVendorActivity(data) : any {
    return {
        type: GUEST_USER_PAGE_ACTIONS.TRACK_ACTIVITY,
        payload: data,
    }
}

export function TrackVendorActivitySuccess(data) : any {
    return {
        type: GUEST_USER_PAGE_ACTIONS.TRACK_ACTIVITY_SUCCESS,
        payload: data,
    }
}

export function TrackVendorActivityFailure(err) : any {
    return {
        type: GUEST_USER_PAGE_ACTIONS.TRACK_ACTIVITY_FAILURE,
        payload: err,
    }
}

export function TrackSessionExpired(data) : any {
    return {
        type: GUEST_USER_PAGE_ACTIONS.TRACK_SESSION_EXPIRED,
        payload: data,
    }
}