import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import * as TrackOrderActions from "../containers/TrackOrder/actionTypes";

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
export interface TrackOrderState {
    TrackOrderData: any;
    CancelOrderData: any;
    DownloadData: any;
    isTrackOrderLoading: boolean,
    isCancelOrderLoading: boolean,
    isDownloading: boolean,
    isRequestFailed: boolean
}

export interface TrackOrder {
    type: any;
    payload: any;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = TrackOrder;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestAPI: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: TrackOrderActions.TRACK_ORDER,
            payload: "test",
        });
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultProps: TrackOrderState = {
    TrackOrderData: {},
    CancelOrderData: {},
    DownloadData: {},
    isTrackOrderLoading: false,
    isCancelOrderLoading: false,
    isDownloading: false,
    isRequestFailed: false,
};

export const reducer: Reducer<TrackOrderState> = (
    state: TrackOrderState = defaultProps,
    incomingAction: Action
) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case TrackOrderActions.TRACK_ORDER:
            return {
                ...state,
                TrackOrderData: action.payload,
                isTrackOrderLoading: true,
                isRequestFailed: false
            };
        case TrackOrderActions.TRACK_ORDER_FAILURE:
            return {
                ...state,
                TrackOrderData: action.payload,
                isTrackOrderLoading: false,
                isRequestFailed: true,
            };
        case TrackOrderActions.TRACK_ORDER_SUCCESS:
            return {
                ...state,
                TrackOrderData: action.payload,
                isTrackOrderLoading: false,
                isRequestFailed: false
            };
        case TrackOrderActions.CANCEL_ORDER:
            return {
                ...state,
                CancelOrderData: action.payload,
                isCancelOrderLoading: true,
                isRequestFailed: false,
            };
        case TrackOrderActions.CANCEL_ORDER_FAILURE:
            return {
                ...state,
                CancelOrderData: action.payload,
                isCancelOrderLoading: false,
                isRequestFailed: true,
            };
        case TrackOrderActions.CANCEL_ORDER_SUCCESS:
            return {
                ...state,
                CancelOrderData: action.payload,
                isCancelOrderLoading: false,
                isRequestFailed: false,
            };
        case TrackOrderActions.CLEAR_CANCEL_ORDER:
            return {
                ...state,
                CancelOrderData: {},
                isCancelOrderLoading: false,
                isRequestFailed: false,
            }
        case TrackOrderActions.DOWNLOAD_REPORT:
            return {
                ...state,
                DownloadData: action.payload,
                isDownloading: true,
                isRequestFailed: false,
            };
        case TrackOrderActions.DOWNLOAD_REPORT_SUCCESS:
            return {
                ...state,
                DownloadData: action.payload,
                isDownloading: false,
                isRequestFailed: false,
            };
        case TrackOrderActions.DOWNLOAD_REPORT_FAILURE:
            return {
                ...state,
                DownloadData: action.payload,
                isDownloading: false,
                isRequestFailed: true,
            };
        default:
        // The following line guarantees that every action in the KnownAction union has been covered by a case above
        // const exhaustiveCheck: never = action;
    }

    return state || defaultProps;
};
