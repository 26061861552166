export const SUBSCRIBERS_NAME = "SUBSCRIBERS_NAME";
export type SUBSCRIBERS_NAME = typeof SUBSCRIBERS_NAME;

export const SUBSCRIBERS_NAME_SUCCESS = "SUBSCRIBERS_NAME_SUCCESS";
export type SUBSCRIBERS_NAME_SUCCESS = typeof SUBSCRIBERS_NAME_SUCCESS;

export const SUBSCRIBERS_NAME_FAILURE = "SUBSCRIBERS_NAME_FAILURE";
export type SUBSCRIBERS_NAME_FAILURE = typeof SUBSCRIBERS_NAME_FAILURE;

export const SUBSCRIBER_DETAILS = "SUBSCRIBER_DETAILS";
export type SUBSCRIBER_DETAILS = typeof SUBSCRIBER_DETAILS;

// For Add/Edit Var page

export const ADD_NEW_VAR_BTN_CLICKED = "ADD_NEW_VAR_BTN_CLICKED";
export type ADD_NEW_VAR_BTN_CLICKED = typeof ADD_NEW_VAR_BTN_CLICKED;

export const BACK_TO_VAR_ADMN_BTN_CLICKED = "BACK_TO_VAR_ADMN_BTN_CLICKED";
export type BACK_TO_VAR_ADMN_BTN_CLICKED = typeof BACK_TO_VAR_ADMN_BTN_CLICKED;

export const POST_SUBSCIBER = "POST_SUBSCIBER";
export type POST_SUBSCIBER = typeof POST_SUBSCIBER;

export const POST_SUBSCIBER_SUCCESS = "POST_SUBSCIBER_SUCCESS";
export type POST_SUBSCIBER_SUCCESS = typeof POST_SUBSCIBER_SUCCESS;

export const POST_SUBSCIBER_FAILURE = "POST_SUBSCIBER_FAILURE";
export type POST_SUBSCIBER_FAILURE = typeof POST_SUBSCIBER_FAILURE;
