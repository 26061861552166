import { Guid } from 'guid-typescript';
import {vendorTrackingExpirationTime} from "../../envConfig";

export const initializeSession = () => {
    // Check if session id is available
    let sessionId = getSessionId();
    if (!sessionId || !isSessionValid()) { //   -->  session is expired, generate a new one
        // If session id is not available 
        sessionId = generateSessionId();
        setSessionId(sessionId);
    }
}

export const generateSessionId = () => {
    // Generate a new unique session id (GUID)
    const id = Guid.create().toString();
    return id;
}

export const getSessionId = () => {
    // Retrieve session id from local storage
    return sessionStorage.getItem('sessionId');
}

export const setSessionId = (sessionId) => {
    // Store session id in local storage with expiry of 30 minutes
    sessionStorage.setItem('sessionId', sessionId);
    sessionStorage.setItem('expiryTime', JSON.stringify(new Date().getTime() + vendorTrackingExpirationTime)); // 30 minutes in milliseconds 30 * 60 * 1000
}

export const updateSessionExpiry = () => {
    // Update session expiry time by 30 minutes
    sessionStorage.setItem('expiryTime', JSON.stringify(new Date().getTime() + vendorTrackingExpirationTime)); //30 * 60 * 1000
}

export const isSessionValid = () => {
    // Check if session is still valid
    const expiryTime = JSON.parse(sessionStorage.getItem('expiryTime'));
    return expiryTime && parseInt(expiryTime) > new Date().getTime();
}
