import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import * as MyOrdersPageActions from "../containers/MyOrders/actionTypes";

//import IProductsProps from '../models/HomePage/IProductSelection'
// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
export interface MyOrdersPageState {
    MyOrdersPageData: any;
    allDistributorList: any;
    isLoadingDistributors: boolean;
    isError: boolean;
    isSuccess: boolean;
    updateDistributorOrder: any;
    isUpdatingDistOrder: boolean;
    isUpdateOrderError: boolean;
    isUpdateOrderSuccess: boolean;
    downLoadFile: any;
    isDownloadingFile: boolean;
    isDownLoadSuccess: boolean;
    isDownLoadError: boolean;
}

export interface GetMyOrderRecords {
    type: any;
    payload: any;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = GetMyOrderRecords;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestAPI: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: MyOrdersPageActions.MY_ORDER_PAGE_RECORDS,
            payload: "test",
        });
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultProps: MyOrdersPageState = {
    MyOrdersPageData: {
        result: [],
        paging: {},
    },
    allDistributorList: {},
    isLoadingDistributors: false,
    isError: false,
    isSuccess: false,
    updateDistributorOrder: {},
    isUpdatingDistOrder: false,
    isUpdateOrderError: false,
    isUpdateOrderSuccess: false,
    downLoadFile: {},
    isDownloadingFile: false,
    isDownLoadSuccess: false,
    isDownLoadError: false,
};

export const reducer: Reducer<MyOrdersPageState> = (
    state: MyOrdersPageState = defaultProps,
    incomingAction: Action
) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case MyOrdersPageActions.MY_ORDER_PAGE_RECORDS:
            return {
                ...state,
                MyOrdersPageData: action.payload,
            };
        case MyOrdersPageActions.GET_DISTRIBUTOR_FOR_POSTALCODE:
            return {
                ...state,
                allDistributorList: action.payload,
                isLoadingDistributors: true,
                isError: false,
                isSuccess: false,
            };
        case MyOrdersPageActions.GET_DISTRIBUTOR_FOR_POSTALCODE_SUCCESS:
            return {
                ...state,
                allDistributorList: action.payload,
                isLoadingDistributors: false,
                isError: false,
                isSuccess: true,
            };
        case MyOrdersPageActions.GET_DISTRIBUTOR_FOR_POSTALCODE_FAILURE:
            return {
                ...state,
                allDistributorList: action.payload,
                isLoadingDistributors: false,
                isError: true,
                isSuccess: false,
            };
        case MyOrdersPageActions.UPDATE_ORDER_SERVICE:
            return {
                ...state,
                updateDistributorOrder: action.payload,
                isUpdatingDistOrder: true,
                isUpdateOrderError: false,
                isUpdateOrderSuccess: false,
            };
        case MyOrdersPageActions.UPDATE_ORDER_SERVICE_SUCCESS:
            return {
                ...state,
                updateDistributorOrder: action.payload,
                isUpdatingDistOrder: false,
                isUpdateOrderError: false,
                isUpdateOrderSuccess: true,
            };
        case MyOrdersPageActions.UPDATE_ORDER_SERVICE_FAILURE:
            return {
                ...state,
                updateDistributorOrder: action.payload,
                isUpdatingDistOrder: false,
                isUpdateOrderError: true,
                isUpdateOrderSuccess: false,
            };
        case MyOrdersPageActions.CLEAR_UPDATE_ORDER_SERVICE:
            return {
                ...state,
                updateDistributorOrder: {},
                isUpdatingDistOrder: false,
                isUpdateOrderError: false,
                isUpdateOrderSuccess: false,
            };
        case MyOrdersPageActions.DOWNLOAD_FILE:
            return {
                ...state,
                downLoadFile: action.payload,
                isDownloadingFile: true,
                isDownLoadSuccess: false,
                isDownLoadError: false,
            }
        case MyOrdersPageActions.DOWNLOAD_FILE_SUCCESS:
            return {
                ...state,
                downLoadFile: action.payload,
                isDownloadingFile: false,
                isDownLoadSuccess: true,
                isDownLoadError: false,
            }
        case MyOrdersPageActions.DOWNLOAD_FILE_FAILURE:
            return {
                ...state,
                downLoadFile: action.payload,
                isDownloadingFile: false,
                isDownLoadSuccess: false,
                isDownLoadError: true,
            }
        case MyOrdersPageActions.RESET_DOWNLOAD_FILE:
            return {
                ...state,
                downLoadFile: {},
                isDownloadingFile: false,
                isDownLoadSuccess: false,
                isDownLoadError: false,
            }
        default:
        // The following line guarantees that every action in the KnownAction union has been covered by a case above
        // const exhaustiveCheck: never = action;
    }

    return state || defaultProps;
};
