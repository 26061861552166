import * as React from 'react';
import { GafOktaManager } from "../../auth/gaf-okta-auth";
import { apiCallSendErrorReport } from "../../utils/ApiFunctions";

const Fallback = React.memo(() =>
    <div className="container mt-4">
        <h2 className="mt-2" style={{ 'fontSize': '25px' }}>Hmm...seems we are having some technical difficulties with your request.
        Please contact our support team for more information.</h2>

    </div>);

class ErrorBoundary extends React.Component<any, any>{
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: null
        }
    }

    static getDerivedStateFromError(error) {
        const path = window.location.href;
        const isGuestUser = path.includes('guest') === true || path.includes('track-order') === true || path.includes('create-act') === true;
        const user= isGuestUser === false ? GafOktaManager?.storage?.info?.profile?.email : 'GuestUser';
        window['AppInsightTrace'].trackException(`User ${user} - having technical issue on page - ${path}. Error: ${error}`);
        console.log(`in boundary---`, error)
        // Sending Error email for any error..
        if(error && !isGuestUser) {
            let errObj = {
                Page: path,
                userEmail: user,
                errorDescription: `Error Component for Techincal Error.`,
                err: JSON.stringify(error.stack),
            }
            apiCallSendErrorReport('Techincal Error', errObj);
        }
      
        return {
            hasError: true,
            error: error
        }
    }
    render() {
        return this.state.hasError ? <Fallback /> : this.props.children;
    }
}

export default ErrorBoundary;