var moment = require("moment");
import {BluePrintLookup} from "../../envConfig";
// To configure console.log dynamic
export const Log = (message, debug) => {
    if (debug) {
        console.log(message);
    }
};
export const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const validateEmail = (email) => {
    // const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;

    // Current expression - in Prod
    // const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;

    // This expression accept all the characters of any length.
    // const pattern = /^.+@[^\.].*\.[a-z]{2,}$/;

    // Checking for occurance of @
    const countAtTheRate = email.split("@");

    // MyRoof expression for email check
    // new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);

    // No special character after @, only multiple . allowed after @
    if (countAtTheRate.length == 2) {
        if (
            countAtTheRate[1].charAt(0) === "." ||
            countAtTheRate[1].charAt(0) === "_" ||
            countAtTheRate[1].charAt(0) === "-"
        ) {
            return false;
        } else {
            const pattern = /^[A-Z0-9._%+-~^]+@[A-Z0-9._-]+\.[A-Z]{2,}$/i;
            const result = pattern.test(email);
            return result;
        }
    } else {
        return false;
    }
};

export const validatePhoneNumber = (phone) => {
    const pattern1 = /^[0-9]{3}(-| )[0-9]{3}(-| )[0-9]{4}$/;
    const pattern2 = /^(\+1)?[0-9]{10}$/;
    const pattern3 = /^(\([0-9]{3}\)( )?|[0-9]{3}(-| ))[0-9]{3}(-| )[0-9]{4}$/;
    if (pattern1.test(phone) || pattern2.test(phone) || pattern3.test(phone)) {
        return true;
    }
    return false;
};

export const convertDate = (orderDate) => {
    if(orderDate) {
        let utcDate = moment.utc(orderDate).toDate();
        let orderDateConverted = moment(utcDate)
            .format("MM/DD/YY hh:mm A")
            .replace(" ", " at ");
    
        return orderDateConverted;
    } else {
        return "-";
    }
   
};

export const calculateTimeDifference = (orderDate) => {
    // Converting the orderDate to users time zone
    const dateOrdered = moment.utc(orderDate).toDate();

    // Current date and time
    const currentDate = new Date();
    
    // Calculating the difference
    const timeDifference = currentDate.getTime() - dateOrdered.getTime() ;

    // Convert the time difference to minutes
    const minutesDifference = Math.round(timeDifference / (1000 * 60));
    console.log(` ${minutesDifference} minutes`);
    return minutesDifference;
}

export const checkSpeicalCharacter = (searchText) => {
    // /[&\/\\#,+()$~%.'":*?<>{}]/g
    // new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
    const pattern = /[&\/\\+()~%'"^:*?<>{}]/g;
    const result = pattern.test(searchText);
    return result;
};

// This function will keep alphanumeric characters, spaces, dash and comma in a
// string and remove rest of all special characters
export const removeSpecialCharacter = (str) => {
    let result = "";
    result = str.replace(/[^a-zA-Z0-9\s\-,]/g, " ");
    //  str.replace(/[^\w\s\-\/,]/g, " ");
    console.log(result);
    return result;
};

export const currencyFormat = (value) =>
    new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    }).format(value);

// Regex to allow only numbers and optional two decimals.
export const numberInputKeyPress = (val, shouldAllowDecimal) => {
    // const pattern = /^\d*(\.)?(\d{0,2})?$/ // This will allow two deciaml point
    const pattern =
        shouldAllowDecimal === true ? /^\d*(\.)?(\d{0,2})?$/ : /^\d*?$/;
    const result = pattern.test(val);
    if (!result) {
        return false;
    }
    return true;
};

export const textTruncate = (str, length, ending) => {
    if (length == null) {
        length = 10;
    }
    if (ending == null) {
        ending = "...";
    }
    if (str.length > length) {
        return str.substring(0, length - ending.length) + ending;
    } else {
        return str;
    }
};

export const checkValidUser = (associatedGAFCertification, profileName) => {
    const certificationArray = ["QM_SUPER_ADMIN", "QM_ADMIN"];
    const profileNameArray = ["ADM", "SADM"];

    // Redirect user if they are not valid users with valid profileName
    if (
        certificationArray.indexOf(associatedGAFCertification) === -1 &&
        profileNameArray.indexOf(profileName) === -1
    ) {
        return false;
    }
    return true;
};

export const checkValidSuperAdminUser = (
    associatedGAFCertification,
    profileName
) => {
    if (
        associatedGAFCertification !== "QM_SUPER_ADMIN" &&
        profileName !== "SADM"
    ) {
        return false;
    }

    return true;
};

export const productCodeCreditObj = (value, amt) => {
    let fullName = "";
    let Amount = 0;
    switch (value) {
        case "SF":
            fullName = "Single Family";
            Amount = amt;
            break;
        case "MF":
            fullName = "Multi-family";
            Amount = amt;
            break;
        case "CM":
            fullName = "Commercial";
            Amount = amt;
            break;
    }
    return { fullName, Amount };
};

export const productCodeCreditObjGuest = (value, creditArray) => {
    let fullName = "";
    let creditVal = 0;
    switch (value) {
        case "SF":
            fullName = "Single Family";
            creditVal = creditArray[0].amount;
            break;
        case "MF":
            fullName = "Multi-family";
            creditVal = creditArray[1].amount;
            break;
        case "CM":
            fullName = "Commercial";
            creditVal = creditArray[2].amount;
            break;
    }
    return { fullName, creditVal };
};

export const getReportNames = (code) => {
    let reportName = "";
    switch (code) {
        case "RM":
        case "Roof Measurement":
            reportName = "Roof Measurment";
            break;
        // case "RC":
        //     reportName = "Roof Condition";
        //     break;    
        case "HI":
            reportName = "Historical Imagery";
            break;
        case "WHPI":
            reportName = "Weather History";
            break;
        case "BC":
            reportName = "Building Codes";
            break;
        case "QuickSite Report":
            reportName = "QuickSite Report";
            break;
        case "ER":
            reportName = "ScopeConnect Report";
            break;
        case "BP":
            reportName = "GAF Takeoff";
            break;
    }
    return reportName;
};

export const getDeliveredReportNames = (order) => {
    let reportNamesArray = [];
    const qmArray= ['SF', 'MF', 'CM']
    order.map((item) => {
        if (item.productTypeCode.includes("QM") === true || qmArray.includes(item.productTypeCode)=== true) {
            reportNamesArray.push(getReportNames("RM"));
        }
        if (item.productTypeCode.includes("QS") === true) {
            if (item.services.length > 0) {
                item.services.map((item) => {
                    if (item.fulfillmentStatus === "Success") {
                        if (item.serviceTypeCode === "HI") {
                            reportNamesArray.push(getReportNames("HI"));
                        }
                        // if (item.serviceTypeCode === "RC") {
                        //     reportNamesArray.push(getReportNames("RC"));
                        // }
                        if (item.serviceTypeCode === "WHPI") {
                            reportNamesArray.push(getReportNames("WHPI"));
                        }
                        if (item.serviceTypeCode === "BC") {
                            reportNamesArray.push(getReportNames("BC"));
                        }
                    }
                });
            } else {
                reportNamesArray.push(item.productDescription);
            }
        }
        if (item.productTypeCode.includes("CC") === true) {
            reportNamesArray.push(item.productDescription);
        }
        if(item.productTypeCode.includes("PRO") === true ) {
            reportNamesArray.push(item.productDescription);
        }
        if(item.productTypeCode.includes("BP") === true) {
            reportNamesArray.push(item.productDescription);
        }
    });

    return reportNamesArray;
};


export const getDeliveredReportNamesForSingleOrder = (order) => {
    let reportNamesArray = [];
    if (order.services.length > 0) {
        const rmReportsArray = order.services.filter(
            (item) =>
                item.serviceTypeCode === "RM" &&
                item.fulfillmentStatus === "Success"
        );
        if (rmReportsArray.length > 0) {
            reportNamesArray.push(getReportNames("RM"));
        }
        order.services.map((item) => {
            if (item.fulfillmentStatus === "Success") {
                // if (item.serviceTypeCode === "RC") {
                //     reportNamesArray.push(getReportNames("RC"));
                // }
                if (item.serviceTypeCode === "WHPI") {
                    reportNamesArray.push(getReportNames("WHPI"));
                }
                if (item.serviceTypeCode === "BC") {
                    reportNamesArray.push(getReportNames("BC"));
                }
                if (item.serviceTypeCode === "HI") {
                    reportNamesArray.push(getReportNames("HI"));
                }
            }
        });
        if (order.productTypeCode.includes("CC") === true) {
            reportNamesArray.push(order.productDescription);
        }
        if (order.productTypeCode.includes("BP") === true) {
            reportNamesArray.push(order.productDescription);
        }
    }
    return reportNamesArray;
}

export const getReportStatusDetails = (order) => {
    let reportStatusArray = [];
    if (order.services.length > 0) {
        const rmReportsArray = order.services.filter(
            (item) => item.serviceTypeCode === "RM"
        );
        if (rmReportsArray.length > 0) {
            reportStatusArray.push({
                reportName: getReportNames("RM"),
                reportStatus: rmReportsArray[0].fulfillmentStatus,
                errorCode: rmReportsArray[0].errorCode,
            });
        }
        order.services.map((item) => {
            if (item.serviceTypeCode === "HI") {
                reportStatusArray.push({
                    reportName: getReportNames("HI"),
                    reportStatus: item.fulfillmentStatus,
                    errorCode: item.errorCode,
                });
            }
            // if (item.serviceTypeCode === "RC") {
            //     reportStatusArray.push({
            //         reportName: getReportNames("RC"),
            //         reportStatus: item.fulfillmentStatus,
            //         errorCode: item.errorCode,
            //     });
            // }
            if (item.serviceTypeCode === "WHPI") {
                reportStatusArray.push({
                    reportName: getReportNames("WHPI"),
                    reportStatus: item.fulfillmentStatus,
                    errorCode: item.errorCode,
                });
            }
            if (item.serviceTypeCode === "BC") {
                reportStatusArray.push({
                    reportName: getReportNames("BC"),
                    reportStatus: item.fulfillmentStatus,
                    errorCode: item.errorCode,
                });
            }
            if (item.serviceTypeCode === "ER") {
                reportStatusArray.push({
                    reportName: getReportNames("ER"),
                    reportStatus: item.fulfillmentStatus,
                    errorCode: item.errorCode,
                });
            }
            if (item.serviceTypeCode === "BP") {
                reportStatusArray.push({
                    reportName: getReportNames("BP"),
                    reportStatus: item.fulfillmentStatus,
                    errorCode: item.errorCode,
                });
            }
        });
    }
    return reportStatusArray;
};

// Filtering Discount bundles as per ProductId and PropertyType
export const getFilteredDiscountBundles = (groupByBundleCode,selectedProductArray) => {
    let newfilteredData = [];
    const filteredData = groupByBundleCode.filter((bundleArray) => {
        return selectedProductArray.every((productItem) => {
          return bundleArray.some((bundleItem) => {
            return (
              bundleItem.productId === productItem.productId &&
              bundleItem.propertyType === productItem.propertyType
            );
          });
        });
      });
    newfilteredData =  filteredData.filter((item) =>(
        item.length === selectedProductArray.length
    ))
    return newfilteredData
}

// Grouping of Orders on GroupId and then sorting them in descending order
// Showing sorted order in QM/QS/SC sequence in each group.
export const taskGroupData = (data) => {
    // Grouping by GroupOrderId and then sorting them in descending order
    let groupByGroupId = data.result.reduce((result, order) => {
        const groupOrderID = order.groupOrderId;
        // Check if the groupOrderID key exists, if not, create an empty array
        if (!result[groupOrderID]) {
            result[groupOrderID] = [];
        }
        // Push the current order object into the array for the groupOrderID
        result[groupOrderID].push(order);
        return result;
    }, []);
    const arrayOfOrders = Object.values(groupByGroupId);
    arrayOfOrders.sort((a, b) => (b[0].groupOrderID > a[0].groupOrderID) ? 1 : -1);

    // Custom sorting function - To re-arrange order in sequence QM/SC/CC
    const sortOrderByReportType = ["QM", "QS", "CC"];

    (arrayOfOrders as any[]).forEach((subarray) => {
        subarray.sort((a, b) => sortOrderByReportType.indexOf(a.productTypeCode.substring(3, 5)) - sortOrderByReportType.indexOf(b.productTypeCode.substring(3, 5)));
    });
    console.log(`arrayOfOrders--`, arrayOfOrders)
    return arrayOfOrders
}

// Filtering ScopeConnect Questons as per lookup name
export const getFilterQuestionDetails = (data, lookupName) => {
    if (data) {
        const result = data.filter((item) => item.lookupName === lookupName);
        return result;
    }
}

export const propertyTypeFullName = (propertyType) => {
    let fullPropertyType;
    switch(propertyType){
        case "SF":
            fullPropertyType = "Single Family";
            break;
        case "MF":
            fullPropertyType = "Multi Family";
            break;
        case "CM":
            fullPropertyType = "Commercial";
            break;
        default:
            fullPropertyType = "Single Family";
            break;
    }
    return fullPropertyType;
}

export const fullReportName = (productCode) => {
    const qmArray = ['SF', 'MF', 'CM']

    let fullReportName = "";
    if (productCode.includes('QM') || qmArray.includes(productCode)) {
        fullReportName = "QuickMeasure Report";
    } else if (productCode.includes('QS')) {
        fullReportName = "QuickSite Report";
    } else if (productCode.includes('CC')) {
        fullReportName = "ScopeConnect Report";
    } else if(productCode.includes('PRO')) {
        fullReportName = "PRO.File Report";
    } else {
        fullReportName = "QuickMeasure Report";
    }
    return fullReportName;
}

// Returning URL for Success Orders
export const getItemURL = (itemArray, assetCode) => {
    let url = "";
    let itemFiltered = [];
    itemFiltered = itemArray.filter((item) => {
        if (item.assetCode === assetCode) {
            url = item.coverReference
        }
    })
    return url;
}
// Returning fileName for Download Reports
export const downloadReportFileName = (buttonType, newAdd) => {
    let fileName = "";
    switch (buttonType) {
        case 'fullReport':
            fileName = `Full Report - ${newAdd}.pdf`;
            break;
        case 'xml':
            fileName = `Report XML - ${newAdd}.xml`;
            break;
        case 'propertyOwner':
            fileName = `Property Owner Report - ${newAdd}.pdf`;
            break;
        case 'dxf':
            fileName = `DXF Report - ${newAdd}.dxf`;
            break;
        case 'quickSite':
            fileName = `QuickSite Report - ${newAdd}.pdf`;
            break;
        case 'scopeConnect':
            fileName = `ScopeConnect Report - ${newAdd}.pdf`;
            break;
        case 'scopeConnectXML':
            fileName = `ScopeConnect XML - ${newAdd}.xml`;
            break;
        case 'scopeConnectESX':
            fileName = `SC - ${newAdd}.esx`;
            break;
        case 'materialOrder':
            fileName = `Material Order PDF - ${newAdd}.pdf`;
            break;
        case 'purchaseOrderPdf':
            fileName = `Purchase Order PDF - ${newAdd}.pdf`;
            break;
        case 'BSR':
            fileName = `Roof Measurement Summary Report - ${newAdd}.pdf`;
            break;
        case 'BluePrintTakeOff':
            fileName = `Blueprint Takeoff Report - ${newAdd}.pdf`;
            break;
        default:
            break;
    }
    return fileName;
}

// FileExtension finder
export const getFileExtension = (fileName) => {
    const input = fileName;
    const period = input.lastIndexOf('.');
    const fileExtension = input.substring(period + 1);
    return fileExtension.toLowerCase();
}

// Filtering BluePrint Profile Products
export const filterBluePrintProduct = (profileProducts, propertyType) => {
    const bpProducts = profileProducts && profileProducts.filter((item) => item.code.includes("BP"));
    const fileterByPropertyTypeAndCountry = bpProducts ? bpProducts.filter((item) => (item.propertyType === propertyType && item.country === "USA")) : [];
    console.log(`fileterByPropertyTypeAndCountry---`, fileterByPropertyTypeAndCountry);
    return fileterByPropertyTypeAndCountry;
}

export const findBPProjectName = (order) => {
    const projectNameLookup = BluePrintLookup.filter((item) => item.LookupName === "BP_Project_Name");
    const additionalInformation = order.additionalInformation;
    const projectNameFilter = additionalInformation ? additionalInformation.filter((item) => item.questioncode === projectNameLookup[0].LookupValue) : [];
    const projectName = projectNameFilter.length > 0 ? projectNameFilter[0].answer : "";
    
    return projectName;
}

export const getUploadedFiles = (order) => {
    const additionalInformation = order.additionalInformation;
    const file1Lookup = BluePrintLookup.filter((item) => item.LookupName === "BP_Blueprint_File1");
    const file2Lookup = BluePrintLookup.filter((item) => item.LookupName === "BP_Blueprint_File2");
    const file3Lookup = BluePrintLookup.filter((item) => item.LookupName === "BP_Blueprint_File3");
    const file4Lookup = BluePrintLookup.filter((item) => item.LookupName === "BP_Blueprint_File4");
    const file5Lookup = BluePrintLookup.filter((item) => item.LookupName === "BP_Blueprint_File5");
    const lookupValues = [file1Lookup[0].LookupValue, file2Lookup[0].LookupValue, file3Lookup[0].LookupValue, file4Lookup[0].LookupValue, file5Lookup[0].LookupValue ];
    const filteredOrderData = additionalInformation.filter((item) => lookupValues.includes(item.questioncode));
    // console.log(`filteredData---`, filteredOrderData)
    return filteredOrderData;
}
// Converting base64 response to downloadable file
export const downloadFile = (fileName, fileContentType, base64Data) => {
    // Decode base64 data
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
  
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
  
    const byteArray = new Uint8Array(byteNumbers);
  
    // Creating a Blob from the byte array
    const blob = new Blob([byteArray], { type: fileContentType });
  
    // Create a link element, set its href to the Blob URL, and trigger a download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

export const handleCancelButtonState = (filteredProductArray, orderArray, productCode, stateKey: any) => {
    if (orderArray.length > 0) {
        const totalMinsAfterOrderPlaced = calculateTimeDifference(orderArray[0].orderRequestedOn);
        const currentProduct = filteredProductArray.find((product) => product.code.includes(productCode));
        const cancelLimit = currentProduct ? currentProduct.cancelLimit : null;
        const shouldShowCancelBtn = cancelLimit ? totalMinsAfterOrderPlaced <= cancelLimit : true;
        return shouldShowCancelBtn;
    }
};