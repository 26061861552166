import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import * as HomePageActions from "../containers/HomePage/actionTypes";

//import IProductsProps from '../models/HomePage/IProductSelection'
// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
export interface HomePageState {
    isHomePageLoading: boolean;
    reportPlaceOrder: any;
    isSendingPlaceOrderRequest: boolean;
    isPlaceOrderError: boolean;
    isPlaceOrderSuccess: boolean;
    location: any;
    address: any;
    successPromoCodeModal: boolean;
    userProfile: any;
    modalMessage: string;
    searchAddress: string;
    creditSelected: any;
    errorData: any;
    errorType: string;
    guestUser: boolean;
    shouldDisplayMarker: boolean;
    shouldDisableZoomBtns: boolean;
    totalMarkers: any;
    totalCreditsAsPerTotalPins: any;
    shouldDisableAdjustPinButton: boolean;
    shouldDisableRemovePinButton: boolean;
    adjustPinButtonClicked: boolean;
    removePinButtonClicked: boolean;
    addPinButtonClicked: boolean;
    isMapClicked: boolean;
    shouldDisableAddPinButton: boolean;
    totalMarkersWithPinLabel: any;
    disableSubmitOrder: boolean;
    setNotification: any;
    isSettingNotification: boolean;
    isSettingNotificationError: boolean;
    checkAreaCoverage: any;
    isSendingCheckCoverageRequest: boolean;
    isCheckAreaCoverageError: boolean;
    isCheckAreaCoverageSuccess: boolean;
    updateTAndC: any;
    isUpdatingTAndC: boolean;
    TandClinks: any;
    isLoadingTandClink: boolean;
    scopeConnectData: any;
    isLoadingScopeConnectData: boolean;
    isLoadingPilotProducts: boolean;
    PilotProducts: any;
    isLoadingPilotProductsState: boolean,
    PilotProductsStates: any,
    bluePrintFiles?: any;
    isUploadingBPFiles?: boolean;
    isbpFileUploadSuccess?: boolean,
    isbpFileUploadError?: boolean,
}

export interface HomePageDataSuccess {
    type: any;
    payload: any;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = HomePageDataSuccess;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestAPI: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: HomePageActions.GET_SITECORE_DATA_SUCCESS,
            payload: "test",
        });
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultProps: HomePageState = {
    modalMessage: "",
    location: {},
    address: "",
    successPromoCodeModal: false,
    userProfile: {
        // availableCredits: 0,
        availableBalance: 0,
        emailAddress: "",
        recipientEmailAddresses: "",
        parentEmailAddress: "",
        parentUserID: "",
        numberOfCredits: [],
        price: [],
        profileProducts: [],
        productPreferences: [],
        profileName: "",
        displayGAFCertification: "",
        associatedGAFCertification: "",
        userFirstName: "",
        userLastName: "",
        gafCustomerID: 0,
        activeNotification: {},
    },
    searchAddress: "",
    creditSelected: 0,
    errorType: "",
    errorData: {},
    isHomePageLoading: true,
    reportPlaceOrder: {},
    isSendingPlaceOrderRequest: false,
    isPlaceOrderError: false,
    isPlaceOrderSuccess: false,
    guestUser: true,
    shouldDisplayMarker: true,
    shouldDisableZoomBtns: false,
    totalMarkers: [],
    totalCreditsAsPerTotalPins: "$0.00",
    shouldDisableAdjustPinButton: false,
    shouldDisableRemovePinButton: false,
    adjustPinButtonClicked: false,
    removePinButtonClicked: false,
    addPinButtonClicked: false,
    isMapClicked: false,
    shouldDisableAddPinButton: false,
    totalMarkersWithPinLabel: [],
    disableSubmitOrder: false,
    setNotification: {},
    isSettingNotification: false,
    isSettingNotificationError: false,
    checkAreaCoverage: {},
    isSendingCheckCoverageRequest: false,
    isCheckAreaCoverageError: false,
    isCheckAreaCoverageSuccess: false,
    updateTAndC: {},
    isUpdatingTAndC: false,
    TandClinks: {},
    isLoadingTandClink: false,
    scopeConnectData: {},
    isLoadingScopeConnectData: false,
    isLoadingPilotProducts: false,
    PilotProducts: [],
    isLoadingPilotProductsState: false,
    PilotProductsStates: [],
    bluePrintFiles: {},
    isUploadingBPFiles: false,
    isbpFileUploadSuccess: false,
    isbpFileUploadError: false
};

export const reducer: Reducer<HomePageState> = (
    state: HomePageState = defaultProps,
    incomingAction: Action
) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case HomePageActions.GET_COORDINATE:
            return {
                ...state,
                location: action.payload,
            };
            break;
        case HomePageActions.SUCCESS_PROMOCODE_MODAL:
            return {
                ...state,
                successPromoCodeModal: action.payload.success,
                modalMessage: action.payload.message,
                isUserPageLoading: false,
            };
            break;

        case HomePageActions.GET_FULLADDRESS:
            return {
                ...state,
                address: action.payload,
            };
            break;
        case HomePageActions.USER_PROFILE_DATA:
            // console.log(action.payload, "redux");
            return {
                ...state,
                userProfile: action.payload,
                isLoadingUserProfile: false,
            };
            break;
        case HomePageActions.UPDATE_AVAILABLE_CREDIT:
            return {
                ...state,
                userProfile: {
                    ...state.userProfile,
                    // availableCredits: action.payload,
                    availableBalance: action.payload,
                },
            };
            break;
        case HomePageActions.SEARCH_ADDRESS_VALUE:
            return {
                ...state,
                searchAddress: action.payload,
                location: action.payload === "" ? {} : state.location,
            };

            break;
        case HomePageActions.GET_OPEN_CLOSE:
            return {
                ...state,
                openCloseStatus: action.payload.openCloseStatus,
                averageResponseTime: action.payload.averageResponseTime,
                siteAverageResponseTimeMFCommercial:
                    action.payload.siteAverageResponseTimeMFCommercial,
            };

            break;
        case HomePageActions.PLACE_ORDER:
            return {
                ...state,
                reportPlaceOrder: action.payload,
                isSendingPlaceOrderRequest: true,
                isPlaceOrderError: false,
                isPlaceOrderSuccess: false,
            };
        case HomePageActions.PLACE_ORDER_FAILED:
            return {
                ...state,
                reportPlaceOrder: action.payload,
                isSendingPlaceOrderRequest: false,
                isPlaceOrderError: true,
                isPlaceOrderSuccess: false,
            };
        case HomePageActions.PLACE_ORDER_SUCCESS:
            return {
                ...state,
                reportPlaceOrder: action.payload,
                isSendingPlaceOrderRequest: false,
                isPlaceOrderError: false,
                isPlaceOrderSuccess: true,
            };
        case HomePageActions.RESET_PLACE_ORDER:
            return {
                ...state,
                reportPlaceOrder: {},
                isSendingPlaceOrderRequest: false,
                isPlaceOrderError: false,
                isPlaceOrderSuccess: false,
            };
        case HomePageActions.GUEST_USER:
            return {
                ...state,
                guestUser: action.payload,
            };

            break;
        case HomePageActions.SHOULD_DISPLAY_MARKER:
            return {
                ...state,
                shouldDisplayMarker: action.payload,
            };
        case HomePageActions.SHOULD_DISABLE_ZOOM_BTN:
            return {
                ...state,
                shouldDisableZoomBtns: action.payload,
            };
        case HomePageActions.TOTAL_MARKER_PINS:
            return {
                ...state,
                totalMarkers: action.payload,
            };
        case HomePageActions.TOTAL_MARKERS_WITH_PINS_AND_LABEL:
            return {
                ...state,
                totalMarkersWithPinLabel: action.payload,
            };
        case HomePageActions.CREDITS_AS_PER_TOTAL_PINS:
            return {
                ...state,
                totalCreditsAsPerTotalPins: action.payload,
            };
        case HomePageActions.SHOULD_DISABLE_ADJUST_PIN_BTN:
            return {
                ...state,
                shouldDisableAdjustPinButton: action.payload,
            };
        case HomePageActions.SHOULD_DISABLE_REMOVE_PIN_BTN:
            return {
                ...state,
                shouldDisableRemovePinButton: action.payload,
            };
        case HomePageActions.ADJUST_PIN_BTN_CLICKED:
            return {
                ...state,
                adjustPinButtonClicked: action.payload,
            };
        case HomePageActions.REMOVE_PIN_BTN_CLICKED:
            return {
                ...state,
                removePinButtonClicked: action.payload,
            };
        case HomePageActions.ADD_PIN_BTN_CLICKED:
            return {
                ...state,
                addPinButtonClicked: action.payload,
            };
        case HomePageActions.MAP_CLICKED:
            return {
                ...state,
                isMapClicked: action.payload,
            };
        case HomePageActions.SHOULD_DISABLE_ADD_PIN_BTN:
            return {
                ...state,
                shouldDisableAddPinButton: action.payload,
            };
        case HomePageActions.SHOULD_DISABLE_SUBMIT_ORDER_BTN:
            return {
                ...state,
                disableSubmitOrder: action.payload,
            };
        case HomePageActions.SET_NOTIFICATION:
            return {
                ...state,
                setNotification: action.payload,
                isSettingNotification: true,
                isSettingNotificationError: false,
            };
        case HomePageActions.SET_NOTIFICATION_FAILED:
            return {
                ...state,
                setNotification: action.payload,
                isSettingNotification: false,
                isSettingNotificationError: true,
            };
        case HomePageActions.SET_NOTIFICATION_SUCCESS:
            return {
                ...state,
                setNotification: action.payload,
                isSettingNotification: false,
                isSettingNotificationError: false,
            };

        case HomePageActions.CHECK_COVERAGE:
            return {
                ...state,
                checkAreaCoverage: action.payload,
                isSendingCheckCoverageRequest: true,
                isCheckAreaCoverageError: false,
                isCheckAreaCoverageSuccess: false,
            };
        case HomePageActions.CHECK_COVERAGE_FAILED:
            return {
                ...state,
                checkAreaCoverage: action.payload,
                isCheckAreaCoverageError: true,
                isCheckAreaCoverageSuccess: false,
                isSendingCheckCoverageRequest: false,
            };
        case HomePageActions.CHECK_COVERAGE_SUCCESS:
            return {
                ...state,
                checkAreaCoverage: action.payload,
                isCheckAreaCoverageError: false,
                isCheckAreaCoverageSuccess: true,
                isSendingCheckCoverageRequest: false,
            };
        case HomePageActions.RESET_CHECK_COVERAGE:
            return {
                ...state,
                checkAreaCoverage: {},
                isSendingCheckCoverageRequest: false,
                isCheckAreaCoverageError: false,
                isCheckAreaCoverageSuccess: false,
            };

        case HomePageActions.GET_USER_PROFILE:
            return {
                ...state,
                userProfile: action.payload,
                isLoadingUserProfile: true,
            };
        case HomePageActions.GET_USER_PROFILE_FAILED:
            return {
                ...state,
                userProfile: action.payload,
                isLoadingUserProfile: false,
            };
        case HomePageActions.GET_USER_PROFILE_SUCCESS:
            return {
                ...state,
                userProfile: action.payload,
                isLoadingUserProfile: false,
            };
        case HomePageActions.UPDATE_T_AND_C:
            return {
                ...state,
                updateTAndC: action.payload,
                isUpdatingTAndC: true,
                // isSettingNotificationError: false,
            };
        case HomePageActions.UPDATE_T_AND_C_FAILED:
            return {
                ...state,
                updateTAndC: action.payload,
                isUpdatingTAndC: false,
                // isSettingNotificationError: true,
            };
        case HomePageActions.UPDATE_T_AND_C_SUCCESS:
            return {
                ...state,
                updateTAndC: action.payload,
                isUpdatingTAndC: false,
                // isSettingNotificationError: false,
            };
        case HomePageActions.GET_T_AND_C_LOOKUP:
            return {
                ...state,
                TandClinks: {},
                isLoadingTandClink: true,
            };
        case HomePageActions.GET_T_AND_C_LOOKUP_FAILED:
            return {
                ...state,
                TandClinks: action.payload,
                isLoadingTandClink: false,
            };
        case HomePageActions.GET_T_AND_C_LOOKUP_SUCCESS:
            return {
                ...state,
                TandClinks: action.payload,
                isLoadingTandClink: false,
            };
        case HomePageActions.GET_SCOPE_CONNECT_QUESTIONS:
            return {
                ...state,
                scopeConnectData: {},
                isLoadingScopeConnectData: true,
            };
        case HomePageActions.GET_SCOPE_CONNECT_QUESTIONS_SUCCESS:
            return {
                ...state,
                scopeConnectData: action.payload,
                isLoadingScopeConnectData: false,
            };
        case HomePageActions.GET_SCOPE_CONNECT_QUESTIONS_FAILED:
            return {
                ...state,
                scopeConnectData: action.payload,
                isLoadingScopeConnectData: false,
            };
        case HomePageActions.GET_PILOT_PRODUCTS:
            return {
                ...state,
                PilotProducts: [],
                isLoadingPilotProducts: true,
            };
        case HomePageActions.GET_PILOT_PRODUCTS_FAILED:
            return {
                ...state,
                PilotProducts: action.payload,
                isLoadingPilotProducts: false,
            };
        case HomePageActions.GET_PILOT_PRODUCTS_SUCCESS:
            return {
                ...state,
                PilotProducts: action.payload,
                isLoadingPilotProducts: false,
            };
        case HomePageActions.GET_PILOT_PRODUCTS_STATES:
            return {
                ...state,
                PilotProductsStates: [],
                isLoadingPilotProductsState: true,
            };
        case HomePageActions.GET_PILOT_PRODUCTS_FAILED_STATES:
            return {
                ...state,
                PilotProductsStates: action.payload,
                isLoadingPilotProductsState: false,
            };
        case HomePageActions.GET_PILOT_PRODUCTS_SUCCESS_STATES:
            return {
                ...state,
                PilotProductsStates: action.payload,
                isLoadingPilotProductsState: false,
            };
        case HomePageActions.BLUE_PRINT_FILE_UPLOAD:
            return {
                ...state,
                bluePrintFiles: action.payload,
                isUploadingBPFiles: true,
                isbpFileUploadSuccess: false,
                isbpFileUploadError: false
            };
        case HomePageActions.BLUE_PRINT_FILE_UPLOAD_SUCCESS:
            return {
                ...state,
                bluePrintFiles: action.payload,
                isUploadingBPFiles: false,
                isbpFileUploadSuccess: true,
                isbpFileUploadError: false
            };
        case HomePageActions.BLUE_PRINT_FILE_UPLOAD_FAILED:
            return {
                ...state,
                bluePrintFiles: action.payload,
                isUploadingBPFiles: false,
                isbpFileUploadSuccess: false,
                isbpFileUploadError: true,
            };
        case HomePageActions.RESEST_BLUE_PRINT_FILE_UPLOAD:
            return {
                ...state,
                bluePrintFiles: {},
                isUploadingBPFiles: false,
                isbpFileUploadSuccess: false,
                isbpFileUploadError: false,
            };
        default:
        // The following line guarantees that every action in the KnownAction union has been covered by a case above
        // const exhaustiveCheck: never = action;
        // return state;
    }

    return state || defaultProps;
};
