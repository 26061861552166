import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import * as PromoCodePageActions from "../containers/concierge/Promocodes/actionTypes";

//import IProductsProps from '../models/HomePage/IProductSelection'
// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
export interface PromoCodePageState {
    maxPromoCode: any;
    isLoadingMaxAmt: boolean;
    isError: boolean;
}

export interface PromoCodeRecords {
    type: any;
    payload: any;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = PromoCodeRecords;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestAPI: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: PromoCodePageActions.GET_MAX_PROMOCODE_AMT,
            payload: "",
        });
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultProps: PromoCodePageState = {
    maxPromoCode: {},
    isLoadingMaxAmt: false,
    isError: false,
};

export const reducer: Reducer<PromoCodePageState> = (
    state: PromoCodePageState = defaultProps,
    incomingAction: Action
) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case PromoCodePageActions.GET_MAX_PROMOCODE_AMT:
            return {
                ...state,
                maxPromoCode: {},
                isLoadingMaxAmt: true,
                isError: false,
            };
        case PromoCodePageActions.GET_MAX_PROMOCODE_AMT_FAILURE:
            return {
                ...state,
                maxPromoCode: action.payload,
                isLoadingMaxAmt: false,
                isError: true,
            };
        case PromoCodePageActions.GET_MAX_PROMOCODE_AMT_SUCCESS:
            return {
                ...state,
                maxPromoCode: action.payload,
                isLoadingMaxAmt: false,
                isError: false,
            };

        default:
    }

    return state || defaultProps;
};
