import { put, takeEvery, call } from "redux-saga/effects";

import * as trackOrderCreators from "./actionCreater";
import * as TRACKORDER from "./actionTypes";

import { baseUrl } from "../../../envConfig";
import { postRequest, getDownLoadReportCustomers } from "../../utils/utils";

export function* trackOrder(action) {
    const endpointUrl = baseUrl + "/guest/trackorder";

    try {
        const response = yield postRequest(endpointUrl, JSON.stringify(action.payload));
        window["AppInsightTrace"].trackTrace(`track order post request for track order page - Track Order Payload: ` + action.payload);

        if (response) {
            yield put(trackOrderCreators.trackOrderSuccess(response));
            window["AppInsightTrace"].trackTrace(`track order post success for track order page - Track Order Response: ` + response);

        }
    } catch (err) {
        yield put(trackOrderCreators.trackOrderFailure(err));
        window["AppInsightTrace"].trackTrace(`track order post failed for track order page - Track Order err: ` + err);

    }
}

export function* cancelOrder(action) {
    const endpointUrl = baseUrl + "/guest/cancelorder";

    try {
        const response = yield postRequest(endpointUrl, JSON.stringify(action.payload));
        window["AppInsightTrace"].trackTrace(`cancel order post request for track order page - Cancel Order Payload: ` + action.payload);

        if (response) {
            response["Cancelled"] = true;
            yield put(trackOrderCreators.cancelOrderSuccess(response));
            window["AppInsightTrace"].trackTrace(`cancel order post success for track order page - Cancel Order Response: ` + response);

        }
    } catch (err) {
        yield put(trackOrderCreators.cancelOrderFailure(err));
        window["AppInsightTrace"].trackTrace(`cancel order post failed for track order page - Cancel Order err: ` + err);

    }
}

export function* downloadReport(action) {
    console.log(action, "download report in saga ");
    const endpointUrl = baseUrl + "/guest/download/" + action.payload.fileName + "?Captcha=" + JSON.parse(sessionStorage.getItem("Captcha"));
    try {
        const response = yield getDownLoadReportCustomers(endpointUrl);
        window["AppInsightTrace"].trackTrace(`download report get request for track order page - Download URL: ` + endpointUrl);

        if (response) {
            yield put(trackOrderCreators.downloadReportSuccess(response));
            window["AppInsightTrace"].trackTrace(`download get success for track order page - Download Response: ` + response);

        }
    } catch (err) {
        yield put(trackOrderCreators.downloadReportFailure(err));
        window["AppInsightTrace"].trackTrace(`download get failed for track order page - Download Err: ` + err);

    }
}


export function* TrackOrderWatcher() {
    yield takeEvery(TRACKORDER.TRACK_ORDER, trackOrder);
    yield takeEvery(TRACKORDER.CANCEL_ORDER, cancelOrder);
    yield takeEvery(TRACKORDER.DOWNLOAD_REPORT, downloadReport);
}
