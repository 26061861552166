import { put, takeLatest, call } from "redux-saga/effects";

import * as LoaderCreateors from "./actionCreater";
import * as LoaderActions from "./actionTypes";

export function* turnOff() {
    try {
        yield put(LoaderCreateors.turnOff());
    } catch (error) {}
}
export function* turnOn() {
    try {
        yield put(LoaderCreateors.turnOn());
    } catch (error) {}
}
export function* LoaderWatcher() {
    yield [
        takeLatest(LoaderActions.LOADING_OFF, turnOff),
        takeLatest(LoaderActions.LOADING_ON, turnOn)
    ];
}
