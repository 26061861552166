import * as SERVICE_ACTIONS from "./actionTypes";

export function selectedService(data): any {
    return {
        type: SERVICE_ACTIONS.SELECTED_SERVICE,
        payload: data,
    };
}

export function isAddNewService(data): any {
    return {
        type: SERVICE_ACTIONS.ADD_NEW_SERVICE,
        payload: data,
    };
}

export function getAllServices(data): any {
    return {
        type: SERVICE_ACTIONS.GET_SERVICES,
        payload: data,
    };
}

export function getAllServicesSuccess(data): any {
    return {
        type: SERVICE_ACTIONS.GET_SERVICES_SUCCESS,
        payload: data,
    };
}

export function getAllServicesFailure(error): any {
    return {
        type: SERVICE_ACTIONS.GET_SERVICES_FAILURE,
        payload: error,
    };
}

export function addEditServices(data): any {
    return {
        type: SERVICE_ACTIONS.ADD_EDIT_SERVICES,
        payload: data,
    };
}

export function addEditServicesSuccess(data): any {
    return {
        type: SERVICE_ACTIONS.ADD_EDIT_SERVICES_SUCCESS,
        payload: data,
    };
}

export function addEditServicesFailure(error): any {
    return {
        type: SERVICE_ACTIONS.ADD_EDIT_SERVICES_FAILURE,
        payload: error,
    };
}
