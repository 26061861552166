import * as VAR_ACTIONTYPES from "./actionTypes";

export function getSubScribersNames(data): any {
    return {
        payload: data,
        type: VAR_ACTIONTYPES.SUBSCRIBERS_NAME,
    };
}

export function getSubScribersNamesSuccess(data): any {
    // console.log(data, "Action creatoroooooooooooo Successsssssssss");
    return {
        payload: data,
        type: VAR_ACTIONTYPES.SUBSCRIBERS_NAME_SUCCESS,
    };
}

export function getSubScribersNamesFailure(error): any {
    // console.log(error, "Action creatoroooooooooooo");
    return {
        payload: error,
        type: VAR_ACTIONTYPES.SUBSCRIBERS_NAME_FAILURE,
    };
}

export function getSubScriberDetails(data): any {
    return {
        payload: data,
        type: VAR_ACTIONTYPES.SUBSCRIBER_DETAILS,
    };
}

// For Add/Edit Var Page.

export function addVarBtnClick(data): any {
    return {
        payload: data,
        type: VAR_ACTIONTYPES.ADD_NEW_VAR_BTN_CLICKED,
    };
}
export function backToVarAdmnBtnClick(data): any {
    return {
        payload: data,
        type: VAR_ACTIONTYPES.BACK_TO_VAR_ADMN_BTN_CLICKED,
    };
}

export function postSubscriber(data): any {
    return {
        payload: data,
        type: VAR_ACTIONTYPES.POST_SUBSCIBER,
    };
}

export function postSubscriberSuccess(data): any {
    return {
        payload: data,
        type: VAR_ACTIONTYPES.POST_SUBSCIBER_SUCCESS,
    };
}

export function postSubscriberFailure(err): any {
    return {
        payload: err,
        type: VAR_ACTIONTYPES.POST_SUBSCIBER_FAILURE,
    };
}
