import * as CANCELGUESTORDER from "./actionTypes";

export function cancelGuestOrder(data): any {
    return {
        type: CANCELGUESTORDER.CANCEL_GUEST_ORDER,
        payload: data,
    };
}

export function cancelGuestOrderSuccess(data) {
    return {
        type: CANCELGUESTORDER.CANCEL_GUEST_ORDER_SUCCESS,
        payload: data,
    };
}

export function cancelGuestOrderFailure(error) {
    return {
        type: CANCELGUESTORDER.CANCEL_GUEST_ORDER_FAILURE,
        payload: error,
    };
}

export function paymentCancel(data) {
    return {
        type: CANCELGUESTORDER.PAYMENT_CANCEL,
        payload: data,
    };
}
