import * as React from "react";
import { Modal } from "react-bootstrap";

import RadioButton from "../../FormControls/RadioButton";
import CheckBox from "../../FormControls/Checkbox";

import "./style.scss";

const ReplenishBalanceModal = (props) => {
    const { defaultAmtRadio, customAmtError, txtCustomAmt, customAmtErrMsg, shouldDisableBtn,
        chkSaveAsDefaultReplenishment, isAutoReplenishmentEnabled } = props;

    const radioLookupValues = props.radioLookupValues;
    const leftColRadioAmt = radioLookupValues && radioLookupValues.filter((value, index) => !(index % 2));
    const rightColRadioAmt = radioLookupValues && radioLookupValues.filter((value, index) => (index % 2));
    return (
        <Modal
            className="replenish-balance-modal"
            show={props.show}
            onHide={() => props.close()}
            animation={false}
            data-keyboard="false"
            backdrop="static"
        >
            <Modal.Header
                className="redeemCode-modal-header border-bottom-0 pb-0"
                closeButton
            >
                <Modal.Title>
                    <h6>Add to Balance?</h6>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body className="redeemCode-modal-body pt-0">
                <div className="container">
                    <p className="ParagraphAndLabel">
                        Select an amount below to replenish your balance.
                        {!isAutoReplenishmentEnabled && (
                            <>&nbsp;
                                You also have the option to save an amount as your default replenishment amount.
                            </>
                        )}
                    </p>
                    <form name="relpenish-balalnce-radio">
                        <div className="radio-div ">

                            <div className="row">
                                <div className="col-6">
                                    {leftColRadioAmt.map((item) => (
                                        <RadioButton labelName={`$${item}`}
                                            value={String(item)}
                                            radioName="defaultAmtRadio"
                                            onChange={(e) => props.handleReplenishRadioChange(e)}
                                            selectedValue={defaultAmtRadio}
                                            radioFor={`defaultAmt${item}`}
                                            key={item}
                                        />
                                    ))}
                                </div>
                                <div className="col-6">
                                    {rightColRadioAmt.map((item) => (
                                        <RadioButton labelName={item === 'Custom' ? item : `$${item}`}
                                            value={item === 'Custom' ? item.toLowerCase() : String(item)}
                                            radioName="defaultAmtRadio"
                                            onChange={(e) => props.handleReplenishRadioChange(e)}
                                            selectedValue={defaultAmtRadio}
                                            radioFor={`defaultAmt${item}`}
                                            key={item}
                                        />
                                    ))}
                                    {props.shouldShowCustomTxtAmt && (
                                        <div>
                                            <div className={customAmtError === true ? 'input-group red-border' : "input-group"}>
                                                <span className="input-group-append">$</span>
                                                <input type="text"
                                                    defaultValue={txtCustomAmt}
                                                    name='txtCustomAmt'
                                                    maxLength={6}
                                                    onChange={(e) => props.txtInputChange(e)}
                                                    placeholder="00"
                                                    autoFocus
                                                    onBlur={(e) => props.onCustomAmtTxtBlur(e)}
                                                />

                                            </div>
                                            {customAmtError &&
                                                <p className='ErrorMessage pricingError'>{customAmtErrMsg}</p>
                                            }
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>
                    </form>
                    {!isAutoReplenishmentEnabled && (
                        <div className="checkbox-container">
                            <CheckBox
                                labelName="Save as default replenish amount"
                                value={1}
                                checkBoxName="chkSaveAsDefaultReplenishment"
                                // tabIndex="12"
                                onChange={(e) => props.handleCheckBoxChange(e)}
                                selectedValue={chkSaveAsDefaultReplenishment}
                                checkBoxFor="Default Replenishment"
                                className=""
                                disabled=""
                            />
                        </div>
                    )}

                    <div className="btn-div">
                        <button className="gaf-btn-secondary cancel-btn" onClick={() => props.close()}>
                            Cancel
                        </button>
                        <button
                            className="gaf-btn-secondary yes-btn"
                            disabled={shouldDisableBtn || customAmtError}
                            onClick={() => props.replenishClick()}>
                            Continue
                        </button>
                    </div>
                </div>
            </Modal.Body>

        </Modal>

    );
}

export default ReplenishBalanceModal;

