const CacheAmdinData = [
    {
        item: 1,
        key: "AAAA",
    },
    {
        item: 2,
        key: "BBBBB",
    },
    {
        item: 3,
        key: "CCCCC",
    },
];

export default CacheAmdinData;
