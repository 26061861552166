import * as React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";

import SignInAs from "../SignInAs";

const ResponseBar = (props) => {
    const { averageResponseTime, siteAverageResponseTimeMFCommercial, openCloseStatus, guestUser } = props;
    const openCloseClass = openCloseStatus === "Open" ? "open" : "redFonts";
    const isOpen = openCloseStatus === "Open" ? true : false;

    return (
        <Row className="text-center respond-bar">
            <div className="container-fluid px-4">
                <div className={`response-detail ${guestUser === true && 'guest-response-detail'}`}>
                    <p className="m-0 d-none d-md-block Disclaimer">
                        <span className="redFonts font-weight-bold">GAF QuickMeasure: </span>
                        {isOpen && (
                            <>
                                <span>Average response times:&nbsp;</span>
                                <span>Single-family:&nbsp;</span>
                                <span className="redFonts font-weight-bold">
                                    {averageResponseTime} min.
                                </span>
                                <span>
                                    {" "}| Multi-family/Commercial:&nbsp;
                                </span>
                                <span className="redFonts font-weight-bold">
                                    {siteAverageResponseTimeMFCommercial} min
                                </span>
                                <span>.</span>
                            </>
                        )}
                        {openCloseStatus !== undefined && (
                            <>
                                <span>{" "}We are currently </span>
                                <span className={`${openCloseClass} font-weight-bold`}>
                                    {openCloseStatus}
                                </span>
                                .
                            </>
                        )}
                    </p>
                    {/* The following <p> tag will only display in small screen */}
                    <div className="m-0 d-md-none d-sm-block justify-content-center Disclaimer">
                        {isOpen && (
                            <>
                                <p className="avergareTime-first-line">
                                    <span>Average response times:&nbsp;</span>
                                    <span>Single-family:&nbsp;</span>
                                    <span className="redFonts font-weight-bold">
                                        {averageResponseTime}
                                        &nbsp;min.
                                    </span>
                                </p>
                                <p className="mf-cm-response-time">
                                    <span>
                                        Multi-family/Commercial:&nbsp;
                                    </span>
                                    <span className="redFonts font-weight-bold">
                                        {siteAverageResponseTimeMFCommercial} min
                                    </span>
                                    <span>.</span>
                                </p>
                            </>)}
                        {openCloseStatus !== undefined && (
                            <p className={`${isOpen === false && 'mt-2'} mb-0`}>
                                <span>We are currently </span>
                                <span className={`${openCloseClass} font-weight-bold`}>
                                    {openCloseStatus}
                                </span>.
                            </p>
                        )}

                    </div>

                    <div className="d-none d-md-block sign-in-outer-div">
                        <SignInAs guestUser={guestUser} />
                    </div>
                </div>
            </div>
        </Row>
    );
};

const mapStateToProps = (state) => {
    const { averageResponseTime, siteAverageResponseTimeMFCommercial } = state.HomePage;
    const { openCloseStatus } = state.HomePage;
    return { averageResponseTime, openCloseStatus, siteAverageResponseTimeMFCommercial };
};

export default connect(mapStateToProps)(ResponseBar);
