import { put, takeLatest, takeEvery, call } from "redux-saga/effects";
import { baseUrl } from "../../../envConfig";
import { doPostRequest, doGetRequest, getRequest, DownloadPostRequest } from "../../utils/utils";
import * as myOrderPageActionCreator from "./actionCreater";
import * as MY_ORDER_PAGE_ACTIONS from "./actionTypes";

export function* getDistributorForPostalCode(action) {
    const endpointUrl =baseUrl + "/Orders/GetDistributorsForPostalCode";
    try {
        const response = yield doPostRequest(
            endpointUrl,
            JSON.stringify(action.payload)
        );
        window["AppInsightTrace"].trackTrace(
            `MyOrderPage- Get distributor for postal code payload: ` +
                JSON.stringify(action.payload)
        );
        if (response) {
            window["AppInsightTrace"].trackTrace(
                `MyOrderPage- Get distributor for postal code- successs: ` +
                    JSON.stringify(response)
            );

            yield put(
                myOrderPageActionCreator.getDistributorForPostalCodeSuccess(response)
            );
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `MyOrderPage- Get distributor for postal code Failed : ` +
                JSON.stringify(err)
        );
        yield put(myOrderPageActionCreator.getDistributorForPostalCodeFailure(err));
    }
}

export function* updateDistributorOrder(action) {
    const endpointUrl =baseUrl + "/Orders/updateOrderService";
    try {
        const response = yield doPostRequest(
            endpointUrl,
            JSON.stringify(action.payload)
        );
        window["AppInsightTrace"].trackTrace(
            `MyOrderPage- Update Order Service payload: ` +
                JSON.stringify(action.payload)
        );
        if (response) {
            window["AppInsightTrace"].trackTrace(
                `MyOrderPage- Update Order Service- successs: ` +
                    JSON.stringify(response)
            );

            yield put(
                myOrderPageActionCreator.updateOrderServiceSuccess(response)
            );
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `MyOrderPage- Update Order Service Failed : ` +
                JSON.stringify(err)
        );
        yield put(myOrderPageActionCreator.updateOrderServiceFailure(err));
    }
}

export function* downloadUploadedFile(action) {
    try {
        const endpointUrl = baseUrl + `/Orders/DownloadFile`;
        const response = yield DownloadPostRequest(endpointUrl, JSON.stringify(action.payload),  { responseType: 'blob' });
        window["AppInsightTrace"].trackTrace(`BluePrint uploaded file download - request paylod - ${JSON.stringify(action.payload)}`);
        if (response) {
            window["AppInsightTrace"].trackTrace(`BluePrint uploaded file download: Success: `);
            yield put(myOrderPageActionCreator.downLoadFileSuccess(response));
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(`BluePrint uploaded file download :` + JSON.stringify(err));
        yield put(myOrderPageActionCreator.downLoadFileFailure(err));
    }
}

export function* MyOrderesPageWatcher() {
    yield takeEvery(MY_ORDER_PAGE_ACTIONS.GET_DISTRIBUTOR_FOR_POSTALCODE, getDistributorForPostalCode);
    yield takeEvery(MY_ORDER_PAGE_ACTIONS.UPDATE_ORDER_SERVICE, updateDistributorOrder);
    yield takeEvery(MY_ORDER_PAGE_ACTIONS.DOWNLOAD_FILE, downloadUploadedFile);
    }
