import * as RESUBMIT_ACTIONS from "./actionTypes";

export function getReProcessOrders(data): any {
    return {
        type: RESUBMIT_ACTIONS.GET_RE_PROCESS_ORDERS,
        payload: data,
    };
}

export function getReProcessOrdersSuccess(data): any {
    return {
        type: RESUBMIT_ACTIONS.GET_RE_PROCESS_ORDERS_SUCCESS,
        payload: data,
    };
}

export function getReProcessOrdersFailure(error): any {
    return {
        type: RESUBMIT_ACTIONS.GET_RE_PROCESS_ORDERS_FAILURE,
        payload: error,
    };
}

export function reProcessOrders(data): any {
    return {
        type: RESUBMIT_ACTIONS.RE_PROCESS_ORDERS,
        payload: data,
    };
}

export function reProcessOrdersSuccess(data): any {
    return {
        type: RESUBMIT_ACTIONS.RE_PROCESS_ORDERS_SUCCESS,
        payload: data,
    };
}

export function reProcessOrdersFailure(error): any {
    return {
        type: RESUBMIT_ACTIONS.RE_PROCESS_ORDERS_FAILURE,
        payload: error,
    };
}
