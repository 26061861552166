import * as React from "react";
// import "./style.scss";

type AvailableBalances = {
    availableBalance: number;
};

export class AvailableBalance extends React.Component<AvailableBalances> {
    render() {
        return (
            <React.Fragment>
                <span className="d-none d-md-inline available-balance ParagraphBold">
                    Available Balance : ${this.props.availableBalance}        </span>
            </React.Fragment>
        );
    }
}
