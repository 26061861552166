//GET ACCOUNT TYPES
export const GET_ACCOUNT_TYPES = "GET_ACCOUNT_TYPES";

export const GET_ACCOUNT_TYPES_SUCCESS = "GET_ACCOUNT_TYPES_SUCCESS";

export const GET_ACCOUNT_TYPES_FAILURE = "GET_ACCOUNT_TYPES_FAILURE";

export type GET_ACCOUNT_TYPES = typeof GET_ACCOUNT_TYPES;

export type GET_ACCOUNT_TYPES_SUCCESS = typeof GET_ACCOUNT_TYPES_SUCCESS;

export type GET_ACCOUNT_TYPES_FAILURE = typeof GET_ACCOUNT_TYPES_FAILURE;



//CREATE_ACCOUNT ACTIONS
export const CREATE_ACCOUNT = "CREATE_ACCOUNT";

export const CREATE_ACCOUNT_SUCCESS = "CREATE_ACCOUNT_SUCCESS";

export const CREATE_ACCOUNT_FAILURE = "CREATE_ACCOUNT_FAILURE";

export type CREATE_ACCOUNT = typeof CREATE_ACCOUNT;

export type CREATE_ACCOUNT_SUCCESS = typeof CREATE_ACCOUNT_SUCCESS;

export type CREATE_ACCOUNT_FAILURE = typeof CREATE_ACCOUNT_FAILURE;

