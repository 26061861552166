export const GUEST_USER_PROFILE_DATA = "GUEST_USER_PROFILE_DATA";
export type GUEST_USER_PROFILE_DATA = "GUEST_USER_PROFILE_DATA";

export const GUEST_USER_PROFILE_DATA_SUCCESS =
    "GUEST_USER_PROFILE_DATA_SUCCESS";
export type GUEST_USER_PROFILE_DATA_SUCCESS = "GUEST_USER_PROFILE_DATA_SUCCESS";

export const GUEST_USER_PROFILE_DATA_FAILURE =
    "GUEST_USER_PROFILE_DATA_FAILURE";
export type GUEST_USER_PROFILE_DATA_FAILURE = "GUEST_USER_PROFILE_DATA_FAILURE";

export const GUEST_SEARCH_USER = "GUEST_SEARCH_USER";
export type GUEST_SEARCH_USER = "GUEST_SEARCH_USER";

export const GUEST_SEARCH_USER_SUCCESS = "GUEST_SEARCH_USER_SUCCESS";
export type GUEST_SEARCH_USER_SUCCESS = "GUEST_SEARCH_USER_SUCCESS";

export const GUEST_SEARCH_USER_FAILURE = "GUEST_SEARCH_USER_FAILURE";
export type GUEST_SEARCH_USER_FAILURE = "GUEST_SEARCH_USER_FAILURE";

export const RESET_GUEST_SEARCH_USER = "RESET_GUEST_SEARCH_USER";
export type RESET_GUEST_SEARCH_USER = "RESET_GUEST_SEARCH_USER";

export const GUEST_ORDER_DETAILS = "GUEST_ORDER_DETAILS";
export type GUEST_ORDER_DETAILS = "GUEST_ORDER_DETAILS";

// export const GUEST_CREATE_ACCOUNT_SUCCESS = "GUEST_CREATE_ACCOUNT_SUCCESS";
// export type GUEST_CREATE_ACCOUNT_SUCCESS = "GUEST_CREATE_ACCOUNT_SUCCESS";

export const GUEST_UPDATE_T_AND_C = "GUEST_UPDATE_T_AND_C";
export type GUEST_UPDATE_T_AND_C = "GUEST_UPDATE_T_AND_C";

export const GUEST_UPDATE_T_AND_C_SUCCESS = "GUEST_UPDATE_T_AND_C_SUCCESS";
export type GUEST_UPDATE_T_AND_C_SUCCESS = "GUEST_UPDATE_T_AND_C_SUCCESS";

export const GUEST_UPDATE_T_AND_C_FAILED = "GUEST_UPDATE_T_AND_C_FAILED";
export type GUEST_UPDATE_T_AND_C_FAILED = "GUEST_UPDATE_T_AND_C_FAILED";

export const TRACK_ACTIVITY = "TRACK_ACTIVITY";
export type TRACK_ACTIVITY = "TRACK_ACTIVITY";

export const TRACK_ACTIVITY_SUCCESS = "TRACK_ACTIVITY_SUCCESS";
export type TRACK_ACTIVITY_SUCCESS = "TRACK_ACTIVITY_SUCCESS";

export const TRACK_ACTIVITY_FAILURE = "TRACK_ACTIVITY_FAILURE";
export type TRACK_ACTIVITY_FAILURE = "TRACK_ACTIVITY_FAILURE";

export const TRACK_SESSION_EXPIRED = "TRACK_SESSION_EXPIRED";
export type TRACK_SESSION_EXPIRED = "TRACK_SESSION_EXPIRED";
