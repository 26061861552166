import {
    ApplicationInsights,
    SeverityLevel,
} from "@microsoft/applicationinsights-web";
import AppTracking from "./index";

import {
    instrumentationKey,
    isAppInsightEnabled,
    isTrackTraceEnabled,
} from "../envConfig";

class AppInsight {
    id: any;
    appInsights: any;
    webAppName: any;
    constructor() {
        this.id = AppTracking.id;
        this.webAppName = "QuickMeasure-WebApp";
        if (isAppInsightEnabled) {
            this.appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: instrumentationKey,
                    disableFetchTracking: false,
                },
            });
            this.appInsights.loadAppInsights();
            var telemetryInitializer = (envelope) => {
                // console.log(this.id.value);
                envelope.tags.push({ "ai.operation.id": this.id });

                envelope.data["ApplicationName"] = this.webAppName;
                // envelope.data["ApplicationName"] = "QuickMeasure-WebApp";
            };
            this.appInsights.addTelemetryInitializer(telemetryInitializer);
        }
    }
    updateOperationId = (id?: any) => {
        AppTracking.updateTrackingId(id);
        this.id = AppTracking.id;
    };
    trackPageView = () => {
        if (isAppInsightEnabled) {
            this.updateOperationId();
            this.appInsights.trackPageView();
        }
    };
    trackTrace = (msg: string) => {
        if (isAppInsightEnabled && isTrackTraceEnabled) {
            this.appInsights.trackTrace({
                message: `${this.webAppName}: ${msg}`,
                severityLevel: SeverityLevel.Information,
            });
        }
    };
    trackException = (error: any) => {
        if (isAppInsightEnabled && isTrackTraceEnabled) {

            if (!Error.prototype.isPrototypeOf(error)) {
                this.appInsights.trackException({
                    error: `${this.webAppName}: ${new Error(
                        JSON.stringify(error)
                    )}`,
                    severityLevel: SeverityLevel.Error,
                });
            } else
                this.appInsights.trackException({
                    error: `${this.webAppName}: ${error}`,
                    // error,
                    severityLevel: SeverityLevel.Error,
                });
        }
    };
    trackEvent = (msg: string) => {
        if (isAppInsightEnabled && isTrackTraceEnabled) {
            this.appInsights.trackEvent({ name: `${this.webAppName}: ${msg}` });
        }
    };
}

window["AppInsightTrace"] = new AppInsight();
