import { put, takeEvery, call } from "redux-saga/effects";

import * as cancelGuestOrderCreators from "./actionCreater";
import * as CANCELGUESTORDER from "./actionTypes";

import { baseUrl } from "../../../envConfig";
import { postRequest } from "../../utils/utils";

//TODO: NEED TO INTEGRATE WITH CANCELORDER IN TRACKORDER
export function* cancelGuestOrder(action) {
    const endpointUrl = baseUrl + "/Guest/cancelorder";
    console.log(action, "cancel order saga");
    try {
        const response = yield postRequest(endpointUrl, JSON.stringify(action.payload));
        console.log(action, "cancel order saga response success");
        if (response) {
            yield put(cancelGuestOrderCreators.cancelGuestOrderSuccess(response));
        }
    } catch (err) {
        yield put(cancelGuestOrderCreators.cancelGuestOrderFailure(err));
    }
}




export function* CancelGuestOrderWatcher() {
    yield takeEvery(CANCELGUESTORDER.CANCEL_GUEST_ORDER, cancelGuestOrder);
}
