import * as React from "react";
import { lazy } from "react";

import { GafOktaManager } from "../../auth/gaf-okta-auth";
// import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { withRouter, Link, Redirect } from "react-router-dom";

import Loader from "../Loader";

import { isUserGuestUser, getUserProfileData, getOpenClose, } from "../../containers/HomePage/actionCreater";
import { TrackVendorActivity } from "../../containers/GuestUser/actionCreater";

import { turnOn, turnOff } from "../Loader/actionCreater";

import { baseUrl, cdnPath, enableVendorTracking } from "../../../envConfig";
import { postUserProfile, postSiteStatus } from "../../utils/utils";
import { apiCallSendErrorReport } from "../../utils/ApiFunctions";
import { Log } from "../../utils/validation";

const ErrorModal = lazy(() => import( /*webpackChunkName:"ErrorModal" */"../ErrorModal"));

export class HomeLoggedIn extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            showErrorModal: false,
            errorCode: '',
            authenticated: null,
            error: null,
            browserPath: "/home-page",
            isLoading: false,
            trackedPageForLogin: false,
        };
    }

    componentDidMount() {

        const authenticated = GafOktaManager.isAuthenticated;

        if (authenticated) {
            this.setState({ authenticated });

            // Guest User value setting to false on successful login.
            this.props.isUserGuestUser(false);

            // Call for SiteStatus
            this.apiCallSiteStatus();

            const userEmail = GafOktaManager.storage.info.profile?.email;
            const userFirstName = GafOktaManager.storage.info.profile.firstName !== undefined ? GafOktaManager.storage.info.profile.firstName : "FirstName";
            const userLastName = GafOktaManager.storage.info.profile.lastName !== undefined ? GafOktaManager.storage.info.profile.lastName : "LastName";
            let encodeEmail = userEmail && btoa(userEmail.toLowerCase());
            userEmail && this.getUserProfile(encodeEmail, userFirstName, userLastName, userEmail);

        }
    }

    componentDidUpdate = () => {
        // const emailAddress = this.props.userProfile.emailAddress
        const emailAddress = GafOktaManager.storage.info.profile?.email;
        const userProfile = this.props.userProfile;
        const associatedGAFCertification = sessionStorage.getItem('associatedGAFCertification');
        if (!this.state.trackedPageForLogin && associatedGAFCertification && userProfile.associatedGAFCertification !== "") {
            
            // Vendor Tracking - User loggin into the site
            if (sessionStorage.getItem('pathName') === '/thd' && enableVendorTracking) {
                const activityCode = 'SITE_LOGIN';
                const metaData = emailAddress;
                this.callVendorTrackPageActivity(activityCode, metaData);
            }
            this.setState({
                trackedPageForLogin: true,
            })
        }
    }

    apiCallSiteStatus = () => {
        const { isDebug } = this.props;
        const utcDate = new Date().toISOString()
        const apiUrl = baseUrl + "/SiteStatus";
        const apiResponse = postSiteStatus(apiUrl, utcDate);
        this.props.turnOn();

        apiResponse.then(res => {
            window['AppInsightTrace'].trackTrace(`API call in Home-logged-in component for SiteStatus, Success : True`);
            window['AppInsightTrace'].trackTrace(`Site Status response in Home-logged-in component :${JSON.stringify(res)}`);

            this.props.turnOff();

            // Storing in redux open-close
            this.props.getOpenClose({
                openCloseStatus: res.siteStatus,
                averageResponseTime: res.siteAverageResponseTime,
                siteAverageResponseTimeMFCommercial: res.siteAverageResponseTimeMFCommercial
            });
        }).catch(err => {
            this.props.turnOff();

            window['AppInsightTrace'].trackTrace(`Site Status Api exception: ${err}`);
            let errObj = {
                apiUrl: apiUrl,
                utcDate: utcDate,
                statusCode: err.status,
                message: err.message,
                err: err.stack,
                errorDescription: `Error in fetching SiteStatus api in Home-logged-in component.`
            }
            this.setState({
                errorCode: 'genericError',
                showErrorModal: true
            });
            Log(err, isDebug);
            apiCallSendErrorReport('HomeLoggedIn Component', errObj);
            window['AppInsightTrace'].trackTrace(`API call in Home-logged-in  component for SiteStatus, Success : False,${err}`);

        });
    }


    getUserProfile = (userEmail, userFirstName, userLastName, loggedInEmail) => {

        let extendedProfile = true;

        const profileUrl = baseUrl + `/user/userprofile`;
        this.props.turnOn();
        this.setState({
            isLoading: true,
        });

        window['AppInsightTrace'].trackTrace(`Before making API call in Home-logged-in component to get userprofile for email : ${loggedInEmail}`);


        postUserProfile(profileUrl, userEmail, userFirstName, userLastName, extendedProfile).then((res) => {

            window['AppInsightTrace'].trackTrace(`API call in Home-logged-in component to get userprofile for email : ${loggedInEmail}, Success: True`);
            window['AppInsightTrace'].trackTrace(`User Profile response: ${JSON.stringify(res)}`);


            let profilePrices = [];
            res.result[0].profile.profileProducts.forEach((profile) => {
                profilePrices.push(profile.price);
            });



            const userProfileData = {
                // availableCredits: res.result[0].availableCredits,
                availableBalance: res.result[0].availableBalance,
                emailAddress: res.result[0].emailAddress,
                recipientEmailAddresses: res.result[0].recipientEmailAddresses,
                userId: res.result[0].userId,
                parentUserID: res.result[0].parentUserID,
                parentEmailAddress: res.result[0].parentEmailAddress,
                price: profilePrices,
                profileProducts: res.result[0].profile.profileProducts,
                productPreferences: res.result[0].productPreferences,
                profileProductDiscounts: res.result[0].profile.profileProductDiscounts,
                displayGAFCertification:
                    res.result[0].profile.displayGAFCertification,
                profileType: res.result[0].profile.profileType,
                profileName: res.result[0].profile.profileName,
                associatedGAFCertification:
                    res.result[0].profile.associatedGAFCertification,
                companyName: res.result[0].gafCustomerName,
                userFirstName: res.result[0].firstName,
                userLastName: res.result[0].lastName,
                gafCustomerID: res.result[0].gafCustomerID,
                activeNotification: res.result[0].activeNotification,
                generalTAndCAccepted: res.result[0].generalTAndCAccepted,
                tAndAccepted: res.result[0].tAndAccepted,
                userPhone: res.result[0].phone,
            };

            window.sessionStorage.setItem('profileName', res.result[0].profile.profileName);
            window.sessionStorage.setItem('associatedGAFCertification', res.result[0].profile.associatedGAFCertification);

            // sending userProfile data to redux
            this.props.getUserProfileData(userProfileData);
            this.props.turnOff();
            this.setState({
                isLoading: false,
            });

        }).catch(err => {
            this.props.turnOff();
            this.setState({
                isLoading: false,
            });
            window['AppInsightTrace'].trackTrace(`User Profile Exception: ${err}`);

            this.setState({
                errorCode: 'genericError',
                showErrorModal: true
            });
            window['AppInsightTrace'].trackTrace(`API call in Home-logged-in component to get userprofile for email : ${loggedInEmail}, Success: false, error: ${err}`);
            let errObj = {
                apiUrl: profileUrl,
                statusCode: err.status,
                userEmail: GafOktaManager.storage.info.profile?.email,
                userFirstName: userFirstName,
                userLastName: userLastName,
                extendedProfile: extendedProfile,
                message: err.message,
                err: err.stack,
                errorDescription: `Error in fetching user profile.`
            }
            apiCallSendErrorReport('Home-logged-in', errObj);
        });

    };
    hideErrorModal = () => {
        this.setState({ showErrorModal: false, errorCode: null });
    };

    // Vendor TrackPage API call 
    callVendorTrackPageActivity = (activityCode, metaData) => {
        const sessionId = sessionStorage.getItem('sessionId');
        const pathName = sessionStorage.getItem('pathName'); 
        const { userProfile } = this.props;
        const refererUrl = window.document.referrer;
       
        const requestPayload = {
            url: window.location.pathname,
            refererUrl: refererUrl,
            activityCode: activityCode,
            sessionId: sessionId,
            ipAddress: null,
            userId: userProfile.userId,
            originatingSource: pathName ? pathName.slice(1) : '',
            metaData: metaData === null ? null : metaData,
        }
        this.props.TrackVendorActivity(requestPayload)
    }
    render() {

        if (this.state.authenticated === null) {
            return null;
        }
        if (!GafOktaManager.isAuthenticated) {
            return <Redirect to="/homenotloggedin"></Redirect>;
        } else {
            return (
                <>
                    {this.state.isLoading && <Loader />}

                    {this.state.showErrorModal && (
                        <ErrorModal
                            errorCode={this.state.errorCode}
                            close={() => this.hideErrorModal()}
                            show={this.state.showErrorModal}

                        />
                    )}
                    {!this.state.isLoading && (
                        <Redirect to="/home-page"></Redirect>

                    )}
                </>
            );
        }

    }
}

export function mapStateToProps(state: any) {
    const { browserPath } = state.PathHistory;
    const userProfile = state.HomePage.userProfile;
    return {
        browserPath, userProfile
    };
}

export function mapDispatchToProps(dispatch) {
    return {
        isUserGuestUser: bindActionCreators(isUserGuestUser, dispatch),
        getUserProfileData: bindActionCreators(getUserProfileData, dispatch),
        getOpenClose: bindActionCreators(getOpenClose, dispatch),
        turnOff: bindActionCreators(turnOff, dispatch),
        turnOn: bindActionCreators(turnOn, dispatch),
        TrackVendorActivity: bindActionCreators(TrackVendorActivity, dispatch),
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(HomeLoggedIn) as React.ComponentType<any>
);
