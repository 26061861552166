import { put, takeLatest, call, takeEvery } from "redux-saga/effects";
import { baseUrl } from "../../../../envConfig";
import { getRequest, postRequest } from "../../../utils/utilsScubscribers";

import * as varActionCreator from "./actionCreater";
import * as VAR_ACTIONTYPES from "./actionTypes";

export function* getSubScriberNames(action) {
    // console.log(action, `get subscriber name in saga`);
    const apiUrl = baseUrl + "/subscribers";
    try {
        const response = yield getRequest(apiUrl, null);
        if (response) {
            // console.log(response);
            yield put(varActionCreator.getSubScribersNamesSuccess(response));
        }
    } catch (error) {
        console.log(error);
        yield put(varActionCreator.getSubScribersNamesFailure(error));
    }
}

export function* getSubScriberDetails(action) {
    // console.log(action, `get subscriber Details in saga`);
    // const subscriberName = action.payload.subscriberName;
    // console.log(`Subscriber : `, subscriberName);
    // const apiUrl = baseUrl + `/${subscriberName}`;
    // console.log(apiUrl);
    // try {
    //     const response = yield getRequest(apiUrl, null);
    //     if (response) {
    //         // console.log(response);
    //         yield put(varActionCreator.getSubScriberDetailsSuccess(response));
    //     }
    // } catch (error) {
    //     console.log(error);
    //     yield put(varActionCreator.getSubScriberDetailsFailure(error));
    // }
}

export function* postSubscriber(action) {
    // console.log(action, `post Subscriber --saga`);
    const apiUrl = baseUrl + "/subscriber";
    try {
        const response = yield postRequest(
            apiUrl,
            JSON.stringify(action.payload)
        );
        if (response) {
            // console.log(response);
            yield put(varActionCreator.postSubscriberSuccess(response));
        }
    } catch (error) {
        // console.log(error);
        yield put(varActionCreator.postSubscriberFailure(error));
    }
}

export function* VarAdminPageWatcher() {
    yield takeEvery(VAR_ACTIONTYPES.SUBSCRIBERS_NAME, getSubScriberNames);
    yield takeEvery(VAR_ACTIONTYPES.SUBSCRIBER_DETAILS, getSubScriberDetails);
    yield takeEvery(VAR_ACTIONTYPES.POST_SUBSCIBER, postSubscriber);
}
