import { GafOktaSettings } from "@gaf/typescript-authentication-okta";
import { GafAuthenticationMode } from "@gaf/typescript-authentication-general";
// import { API_CUSTOM_SCOPES } from "./scopes";
import { API_USER_SCOPES } from "./scopes"



// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false
};

import {
    clientId,
    issuer,
    loginUrl,
    postLogoutRedirectUri,
    redirectUri,
} from "../../envConfig";

export const OKTA_CONFIG = Object.create({
    clientId,
    issuer,
    accessTokenThreshold: 15000,
    loginUrl,
    postLogoutRedirectUri,
    redirectUri,
    idp: undefined,

    whitelistDomains: [""],

    scopes: API_USER_SCOPES,
    mode: GafAuthenticationMode.Parent,
    pkce: true,
    tokenManager: {
        storage: "sessionStorage",
        secure: true,
        autoRenew: true
    },
    responseType: "token"

}) as GafOktaSettings;
