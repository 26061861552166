import * as ACCOUNTACTIONS from "./actionTypes";

export function getAccountTypes(data): any {
    return {
        type: ACCOUNTACTIONS.GET_ACCOUNT_TYPES,
        payload: data,
    };
}

export function getAccountTypesSuccess(data) {
    return {
        type: ACCOUNTACTIONS.GET_ACCOUNT_TYPES_SUCCESS,
        payload: data,
    };
}

export function getAccountTypesFailure(error) {
    return {
        type: ACCOUNTACTIONS.GET_ACCOUNT_TYPES_FAILURE,
        payload: error,
    };
}

export function createAccount(data): any {
    return {
        type: ACCOUNTACTIONS.CREATE_ACCOUNT,
        payload: data,
    };
}

export function createAccountSuccess(data, actData) {
    return {
        type: ACCOUNTACTIONS.CREATE_ACCOUNT_SUCCESS,
        payload: data,
        actData: actData,
    };
}

export function createAccountFailure(error) {
    return {
        type: ACCOUNTACTIONS.CREATE_ACCOUNT_FAILURE,
        payload: error,
    };
}
