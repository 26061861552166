export const CUSTOMERS_ROOF_REPORTS = "CUSTOMERS_ROOF_REPORTS";
export type CUSTOMERS_ROOF_REPORTS = typeof CUSTOMERS_ROOF_REPORTS;

export const ROOF_REPORTS_SEARCH_TEXT = "ROOF_REPORTS_SEARCH_TEXT";
export type ROOF_REPORTS_SEARCH_TEXT = typeof ROOF_REPORTS_SEARCH_TEXT;

export const ROOF_REPORTS_EMAIL_ADDRESS = "ROOF_REPORTS_EMAIL_ADDRESS";
export type ROOF_REPORTS_EMAIL_ADDRESS = typeof ROOF_REPORTS_EMAIL_ADDRESS;

export const ROOF_REPORTS_EMAIL_CLICKED = "ROOF_REPORTS_EMAIL_CLICKED";
export type ROOF_REPORTS_EMAIL_CLICKED = typeof ROOF_REPORTS_EMAIL_CLICKED;

export const REFUND_REPORT = "REFUND_REPORT";
export type REFUND_REPORT = typeof REFUND_REPORT;

export const REFUND_REPORT_SUCCESS = "REFUND_REPORT_SUCCESS";
export type REFUND_REPORT_SUCCESS = typeof REFUND_REPORT_SUCCESS;

export const REFUND_REPORT_FAILED = "REFUND_REPORT_FAILED";
export type REFUND_REPORT_FAILED = typeof REFUND_REPORT_FAILED;

export const RESET_REFUND_REPORT = "RESET_REFUND_REPORT";
export type RESET_REFUND_REPORT = typeof RESET_REFUND_REPORT;

export const RESEND_EMAIL = "RESEND_EMAIL";
export type RESEND_EMAIL = typeof RESEND_EMAIL;

export const RESEND_EMAIL_SUCCESS = "RESEND_EMAIL_SUCCESS";
export type RESEND_EMAIL_SUCCESS = typeof RESEND_EMAIL_SUCCESS;

export const RESEND_EMAIL_FAILED = "RESEND_EMAIL_FAILED";
export type RESEND_EMAIL_FAILED = typeof RESEND_EMAIL_FAILED;

export const RESET_RESEND_EMAIL = "RESET_RESEND_EMAIL";
export type RESET_RESEND_EMAIL = typeof RESET_RESEND_EMAIL;

export const TRANSFER_ORDER = "TRANSFER_ORDER";
export type TRANSFER_ORDER = typeof TRANSFER_ORDER;

export const TRANSFER_ORDER_SUCCESS = "TRANSFER_ORDER_SUCCESS";
export type TRANSFER_ORDER_SUCCESS = typeof TRANSFER_ORDER_SUCCESS;

export const TRANSFER_ORDER_FAILED = "TRANSFER_ORDER_FAILED";
export type TRANSFER_ORDER_FAILED = typeof TRANSFER_ORDER_FAILED;

export const RESET_TRANSFER_ORDER = "RESET_TRANSFER_ORDER";
export type RESET_TRANSFER_ORDER = typeof RESET_TRANSFER_ORDER;

export const SEARCH_USER_PROFILE = "SEARCH_USER_PROFILE";
export type SEARCH_USER_PROFILE = typeof SEARCH_USER_PROFILE;

export const SEARCH_USER_PROFILE_SUCCESS = "SEARCH_USER_PROFILE_SUCCESS";
export type SEARCH_USER_PROFILE_SUCCESS = typeof SEARCH_USER_PROFILE_SUCCESS;

export const SEARCH_USER_PROFILE_FAILED = "SEARCH_USER_PROFILE_FAILED";
export type SEARCH_USER_PROFILE_FAILED = typeof SEARCH_USER_PROFILE_FAILED;

export const RESET_SEARCH_USER_PROFILE = "RESET_SEARCH_USER_PROFILE";
export type RESET_SEARCH_USER_PROFILE = typeof RESET_SEARCH_USER_PROFILE;


