import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { Link, } from "react-router-dom";
import * as TagManager from 'react-gtm-module';


import "./slideDrawer.scss";
import PromoCodeModal from "../PromoCodeModal";
import GuestPromoCodeModal from "../GuestPromoCodeModal";

import { cdnPath, oktaLogoutUrl, postLogoutRedirectUri, gafLoginArray, sampleReport } from "../../../envConfig";
import { GafOktaManager } from "../../auth/gaf-okta-auth";

const menuCloseImg = cdnPath + "/images/Ham-menu-close@3x.png";

export default class SideDrawer extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            redeemModal: false,
            guestPromoCodeModal: false
        };
    }

    hideReedemModal = () => {
        this.setState({ redeemModal: false });
    };

    hideGuestPromoCodeModal = () => {
        this.setState({ guestPromoCodeModal: false });
    };

    reportClick = (e) => {
        e.preventDefault();
        window.open(sampleReport)

        // ----------------------------------
        // GTM settings
        const tagManagerArgs = {
            dataLayer: {
                event: 'sampleReportDownload',
            },
            dataLayerName: 'PageDataLayer'
        }
        TagManager.dataLayer(tagManagerArgs);
        // -------------------------------------
    }

    redeemModalClick = () => {
        this.setState({
            redeemModal: true,
        });
        // ------------------------------
        // GTM settings
        const tagManagerArgs = {
            dataLayer: {
                event: 'clickHamMenuItem',
                hamMenuItem: 'Redeem Code',
            },
            dataLayerName: 'PageDataLayer'
        }
        TagManager.dataLayer(tagManagerArgs);
        // -------------------------------------
    }
    guestRedeemModalClick = () => {
        this.setState({
            guestPromoCodeModal: true
        });
        // ------------------------------
        // GTM settings
        const tagManagerArgs = {
            dataLayer: {
                event: 'clickHamMenuItem',
                hamMenuItem: 'Redeem Code',
            },
            dataLayerName: 'PageDataLayer'
        }
        TagManager.dataLayer(tagManagerArgs);
        // -------------------------------------
    }
    logOutBtnClick = (logoutUrl) => {
        if (sessionStorage.getItem('welcomeModal')) {
            sessionStorage.removeItem('welcomeModal');
        }
        if (sessionStorage.getItem('isVendorSessionExpired')) {
            sessionStorage.removeItem('isVendorSessionExpired')
        }
        if (sessionStorage.getItem('siteCreateActTracked')) {
            sessionStorage.removeItem('siteCreateActTracked')
        }
        if (sessionStorage.getItem('siteAccessTracked')) {
            sessionStorage.removeItem('siteAccessTracked');
        }
        if (sessionStorage.getItem('siteGuestPageAccessTracked')) {
            sessionStorage.removeItem('siteGuestPageAccessTracked');
        }
        GafOktaManager.logout(logoutUrl)
    }
    loginBtnClick = () => {
        // ------------------------------
        // GTM settings
        const tagManagerArgs = {
            dataLayer: {
                event: 'clickHamMenuItem',
                hamMenuItem: 'Login'
            },
            dataLayerName: 'PageDataLayer'
        }
        TagManager.dataLayer(tagManagerArgs);
        // -------------------------------------

        GafOktaManager.login()
    }
    menuItemClick = (pageName) => {
        // ------------------------------
        // GTM settings
        const tagManagerArgs = {
            dataLayer: {
                event: 'clickHamMenuItem',
                hamMenuItem: pageName,
            },
            dataLayerName: 'PageDataLayer'
        }
        TagManager.dataLayer(tagManagerArgs);
        // -------------------------------------
        // Removing sessionStorage values for CoverageMap
        sessionStorage.removeItem('btnName');
        sessionStorage.removeItem('searchLocation');
        sessionStorage.removeItem('lat');
        sessionStorage.removeItem('lng');
        sessionStorage.removeItem('coverageFileName');
        sessionStorage.removeItem('backFromScopeConnect');
        
        // Removing address value from AutoComplete
        this.props.clearSearchAddress("");

    }

    render() {
        const { guestUser } = this.props;
        const userEmail = GafOktaManager.storage.info.profile !== undefined && GafOktaManager.storage.info.profile.email;
        const userEmailSplit = userEmail && userEmail.split('@');
        const userEmailDomain = userEmailSplit && userEmailSplit[userEmailSplit.length - 1]
        const logoutUrl = gafLoginArray.includes(userEmailDomain) === true ? oktaLogoutUrl : null;
        const pathNameUrl = sessionStorage.getItem('pathName') === '/thd' ? '/thd' : '/';
        let drawerClasses = "side-drawer";
        if (this.props.show) {
            drawerClasses = "side-drawer open";
        }
        return (
            <>

                <div className={drawerClasses} onClick={this.props.close}>
                    <nav className="NavigationItems">
                        <div className={`navigationTitlebg ${guestUser && 'guestNavigationTitleng'}`}  >
                            <Row>
                                <Col className="align-right pr-0">
                                    <button
                                        type="button"
                                        className="close"
                                        onClick={this.props.close}
                                    >

                                        <img src={menuCloseImg} alt="close image" />
                                    </button>
                                </Col>
                            </Row>
                            {guestUser !== true &&
                                <Row>
                                    <Col>
                                        <span className="sing-in">

                                            <p className="ParagraphBold mb-0 text-break">
                                                {this.props.emailAddress}
                                            </p>
                                            <p className="ParagraphAndLabel text-capitalize">
                                                {this.props.displayGAFCertification}
                                            </p>
                                        </span>
                                    </Col>
                                </Row>
                            }
                        </div>

                        <div onClick={this.props.close} className="ParagraphAndLabel menu-items">

                            <Link className={`dropdown-item ${guestUser === true && 'pt-0'}`} to={guestUser == true ? pathNameUrl : `/home-page`}
                                onClick={() => this.menuItemClick('Place order')}
                            >
                                Place Order
                            </Link>
                            {guestUser === true &&
                                <a
                                    className="dropdown-item"
                                    href="/track-order"
                                    onClick={() => this.menuItemClick('Track order')}
                                >
                                    Track Order
                                </a>
                            }
                            <a
                                className="dropdown-item"
                                onClick={() => { guestUser === true ? this.guestRedeemModalClick() : this.redeemModalClick() }}
                            >
                                Redeem Code
                            </a>
                            {/* MyOrders and MyReceipts - only for logged in users */}

                            {guestUser !== true ?
                                <>
                                    <Link className="dropdown-item" to={`/my-orders`} onClick={() => this.menuItemClick('My Orders')}>
                                        My Orders
                                    </Link>
                                    <Link className="dropdown-item" to={`/my-receipts`} onClick={() => this.menuItemClick('My Receipts')}>
                                        My Receipts
                                    </Link>
                                    <Link className="dropdown-item" to={`/my-preferences`} onClick={() => this.menuItemClick('My Preferences')}>
                                        My Preferences
                                    </Link>
                                </>
                                : ""}
                            <Link
                                className="dropdown-item"
                                to={`/coverage-map`}
                                onClick={() => this.menuItemClick('Coverage Map')}
                            >
                                Coverage Map
                            </Link>
                            <a
                                className="dropdown-item"
                                href={'#'}
                                target="_blank"
                                onClick={(e) => this.reportClick(e)}
                            >
                                Sample Report
                            </a>
                            <Link className="dropdown-item" to="/faqs" onClick={() => this.menuItemClick('Support & FAQs')}>
                                Support &amp; FAQs
                            </Link>
                            {
                                (this.props.associatedGAFCertification === "Subscriber") &&
                                <Link
                                    className="dropdown-item"
                                    to="/support-requests">
                                    Request Support
                                </Link>
                            }
                            {/* CreateAccount is only for Guest user */}
                            {guestUser === true &&
                                <div className='create-act-btn-div'>
                                    <a
                                        className={`MiniButtonCopy dropdown-item transparent-btn create-act-btn }`}
                                        href="/create-account"
                                        onClick={() => this.menuItemClick('Create Account')}
                                    >
                                        Create Account
                                    </a>
                                </div>
                            }
                            {/* Following menu options only for Admins and Super Admins */}
                            {this.props.profileName === "ADM" &&
                                this.props.associatedGAFCertification ===
                                "QM_ADMIN" && (
                                    <React.Fragment>
                                        <Link
                                            className="dropdown-item"
                                            to="/support-requests">
                                            Support &amp; Requests
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            to={`/concierge/customers`}
                                        >
                                            Customers
                                        </Link>

                                        <Link
                                            className="dropdown-item"
                                            to={`/concierge/roofreports`}
                                        >
                                            Roof Reports
                                        </Link>
                                    </React.Fragment>

                                )}
                            {this.props.profileName === "SADM" &&
                                this.props.associatedGAFCertification ===
                                "QM_SUPER_ADMIN" && (
                                    <React.Fragment>
                                        <Link
                                            className="dropdown-item"
                                            to="/support-requests">
                                            Request Support
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            to={`/concierge/customers`}
                                        >
                                            Customers
                                        </Link>

                                        <Link
                                            className="dropdown-item"
                                            to={`/concierge/roofreports`}
                                        >
                                            Roof Reports
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            to={`/concierge/promocodes`}
                                        >
                                            Generate Promo Codes
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            to={`/concierge/subscriberAdministration`}
                                        >
                                            Subscriber Administration
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            to={`/concierge/operationalReports`}
                                        >
                                            Operational Reports
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            to={`/concierge/application-admin`}
                                        >
                                            Application Admin
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            to={`/concierge/services`}
                                        >
                                            Maintain Services
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            to={`/concierge/serviceBundles`}
                                        >
                                            Maintain Service Bundles
                                        </Link>
                                        <Link
                                            className="dropdown-item"
                                            to={`/concierge/reSubmit`}
                                        >
                                            Resubmit
                                        </Link>
                                        {/* <Link
                                            className="dropdown-item"
                                            to={`/concierge/cacheAdministration`}
                                        >
                                            Cache Administration
                                        </Link> */}
                                    </React.Fragment>
                                )}

                            {guestUser === true
                                ? <button
                                    className={`MiniButtonCopy logout-btn ${guestUser && 'login-btn-menu'}`}
                                    onClick={() => this.loginBtnClick()}
                                >
                                    Login
                                </button>
                                : <button
                                    className="MiniButtonCopy logout-btn"
                                    onClick={() => this.logOutBtnClick(logoutUrl)}
                                >
                                    Logout
                                </button>
                            }
                        </div>
                    </nav>
                </div>
                {
                    this.state.redeemModal && (
                        <PromoCodeModal
                            close={() => this.hideReedemModal()}
                            show={this.state.redeemModal}
                        />
                    )
                }
                {
                    this.state.guestPromoCodeModal && (
                        <GuestPromoCodeModal
                            close={() => this.hideGuestPromoCodeModal()}
                            show={this.state.guestPromoCodeModal}
                            loginBtnClick={() => { GafOktaManager.login() }}
                            createActBtnClick={() => { window.location.href = "/create-account" }}
                        />
                    )
                }
            </>
        );
    }
}
