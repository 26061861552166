var routes = require('./routes');

module.exports = {
    cdnPath: "https://qmcdn.gaf.com",
    cdnBuildPath: 'https://qmcdn.gaf.com/',
    siteUrl: 'https://quickmeasure.gaf.com',
    reportUrl: 'https://quickmeasureapi.gaf.com/api/v1.0/roofmeasure/download',
    baseUrl: 'https://quickmeasureapi.gaf.com/api/v1.0',
    oktaLogoutUrl: 'https://standardindustries.okta-emea.com/login/signout?fromURI=https://quickmeasure.gaf.com',
    oktaErrorLogoutUrl: 'https://ssoext.gaf.com/login/signout?fromURI=https://standardindustries.okta-emea.com/login/signout',
    gafLoginArray: ['gaf.com', 'gaf.energy'],
    googleMapUrl: 'https://maps.googleapis.com/maps/api/js?libraries=places&callback=Function.prototype&key=',
    googleMapKey: 'AIzaSyBf2H-GgJBkTsyGh67xPnBmov8WekbC4eU',
    googleTrackingId: 'GTM-5PNLDTL',
    newGoogleTrackingId: 'GTM-PNM2PFB',
    instrumentationKey: 'c56a495a-41a6-44bb-9732-de51d3c3106c',
    isAppInsightEnabled: true,
    isTrackTraceEnabled: true,
    appIdleTimeOut: 1000 * 60 * 57,
    termsAndConditionUrl: 'https://www.gaf.com/en-us/about-us/privacy-legal/terms-of-use',
    privacyPolicyUrl: 'https://www.gaf.com/en-us/our-company/privacy-and-legal/privacy-policy',
    rewardsURL: 'https://rewards.gaf.com/signup?directfrom=quickmeasurelp',
    gafEnergyPrivacyPolicyUrl: 'https://www.gaf.energy/privacy-policy/',
    pdReceiptUrl: 'https://pay.gaf.com',
    sampleReport: "https://www.gaf.com/en-us/document-library/documents/documents/formsdocuments/reportsdocuments/QuickMeasure_Roof_Report_Sample.pdf",
    errorEmailNotification: 'qmintnotifications@gaf.com',
    companyInformationPageUrl: 'https://ccz.gaf.com',
    guestUserEmail: "guest@gaf.com",
    routes: routes,
    maxPinAllowed: 30,
    replenishMinCustomAmt: 1,
    replenishMaxCustomAmt: 20000,
    replenishMinBalance: 25,
    managePaymentPageUrl: 'https://pay.gaf.com/managepaymentmethods/QuickMeasure',
    shouldLoadProBundle: false,
    defaultBundleForCoverageMap: 'QM',
    reSubmitFromDate: '2023-01-05',
    quickCartURL: 'https://quickcart.com',
    maxRedeemCodeAttempt: 3,
    vendorTrackingExpirationTime: 30 * 60 * 1000,  //30 minutes in milliseconds
    enableVendorTracking: true,
    bluePrintMaxFileSize: 100 * 1024 * 1024, // 104,857,600 bytes = 100MB
    bpFileExtensionArray: ["pdf"],
    
    // For okta
    clientId: '0oaclwmauH1TXBDzU4x6',
    issuer: 'https://ssoext.gaf.com/oauth2/ausclyogeZBNESNcI4x6',
    loginUrl: "https://quickmeasure.gaf.com",
    postLogoutRedirectUri: "https://www.gaf.com/en-us/quickmeasure",
    redirectUri: "https://quickmeasure.gaf.com/callback",
    Report3DUrl: "https://eave.us/report?taskId=",

    // Recaptcha
    sitekey: '6LdxluYZAAAAAPf8rgPg9gx1fki2iVlv0RZCRS-b',
    // BluePrint Lookup
    BluePrintLookup: [
    {
        LookupName: "BP_Blueprint_File1",
        LongName: "Location of Blueprint File 1",
        ShortName: "Location of Blueprint File 1",
        LookupValue: "Q101",
        SortOrder: 1,
    },
    {
        LookupName: "BP_Blueprint_File2",
        LongName: "Location of Blueprint File 2",
        ShortName: "Location of Blueprint File 2",
        LookupValue: "Q102",
        SortOrder: 2,
    },
    {
        LookupName: "BP_Blueprint_File3",
        LongName: "Location of Blueprint File 3",
        ShortName: "Location of Blueprint File 3",
        LookupValue: "Q103",
        SortOrder: 3,
    },
    {
        LookupName: "BP_Blueprint_File4",
        LongName: "Location of Blueprint File 4",
        ShortName: "Location of Blueprint File 4",
        LookupValue: "Q104",
        SortOrder: 4,
    },
    {
        LookupName: "BP_Blueprint_File5",
        LongName: "Location of Blueprint File 5",
        ShortName: "Location of Blueprint File 5",
        LookupValue: "Q105",
        SortOrder: 5,
    },
    {
        LookupName: "BP_Project_Name",
        LongName: "Name/address of the project",
        ShortName: "Name/address of the project",
        LookupValue: "Q106",
        SortOrder: 6,
    },
    {
        LookupName: "BP_UOM",
        LongName: "What unit of measurement would you like on the blue print",
        ShortName: "Unit of Measurement for BluePrint",
        LookupValue: "Q107",
        SortOrder: 7,
    }
]


}
