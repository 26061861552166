import { put, takeEvery, call } from "redux-saga/effects";

import * as sendRequestCreators from "./actionCreater";
import * as SENDREQUEST from "./actionTypes";

import { baseUrl } from "../../../envConfig";
import { postRequest } from "../../utils/utils";


export function* sendRequest(action) {
    const endpointUrl = baseUrl + "/SupportRequest";//guest/createaccount
    try {
        const response = yield postRequest(endpointUrl, JSON.stringify(action.payload));
        window["AppInsightTrace"].trackTrace(`support request post request for support request page - Support Request Payload: ` + action.payload);

        if (response) {
            yield put(sendRequestCreators.sendRequestSuccess(response));
            window["AppInsightTrace"].trackTrace(`support request send success for support request page - Support Request Response: ` + response);

        }
    } catch (err) {
        yield put(sendRequestCreators.sendRequestFailure(err));
        window["AppInsightTrace"].trackTrace(`support request send failed for support request page - Support Request error: ` + err);
    }
}


export function*SendRequestWatcher() {
    yield takeEvery(SENDREQUEST.SEND_REQUEST, sendRequest);
}
