import {
    createStore,
    applyMiddleware,
    compose,
    combineReducers,
    StoreEnhancer,
    Store,
    StoreEnhancerStoreCreator,
    ReducersMapObject,
} from "redux";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import { connectRouter, routerMiddleware } from "connected-react-router";
// import analytics from "../src/GTM/middleware";
import * as StoreModule from "./reducers";
import { ApplicationState, reducers } from "./reducers";
import { History } from "history";
import rootWatchers from "./rootSaga";
const sagaMiddleware = createSagaMiddleware();
export default function configureStore(
    history: History,
    initialState?: ApplicationState
) {
    // Build middleware. These are functions that can process the actions before they reach the store.
    const windowIfDefined =
        typeof window === "undefined" ? null : (window as any);
    // If devTools is installed, connect to it
    const devToolsExtension =
        windowIfDefined &&
        (windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__ as () => StoreEnhancer);
    const createStoreWithMiddleware = compose<StoreEnhancerStoreCreator<any>>(
        applyMiddleware(thunk, routerMiddleware(history)),
        applyMiddleware(sagaMiddleware),
        devToolsExtension
            ? devToolsExtension()
            : <S>(next: StoreEnhancerStoreCreator<S>) => next
    )(createStore);

    // Combine all reducers and instantiate the app-wide store instance
    const allReducers = buildRootReducer(reducers);
    const store = createStoreWithMiddleware(allReducers, initialState) as Store<
        ApplicationState
    >;
    sagaMiddleware.run(rootWatchers);
    // Enable Webpack hot module replacement for reducers
    if (module.hot) {
        module.hot.accept("./reducers", () => {
            const nextRootReducer = require<typeof StoreModule>("./reducers");
            store.replaceReducer(buildRootReducer(nextRootReducer.reducers));
        });
    }

    return store;
}
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

function buildRootReducer(allReducers: ReducersMapObject<ApplicationState>) {
    return combineReducers<ApplicationState>(
        Object.assign({}, allReducers, { router: connectRouter(history) })
    );
}
