import { GafOktaManager } from "../auth/gaf-okta-auth";
import AppTracking from "../../AppTracking";

const TIMEOUT = 120000; // 120000 ms = 2 mins

const headers = (additionalHeaders = {}, type?: any) => {
    const { accessToken, idToken } = GafOktaManager.storage.info;

    const headersObj = {
        "Content-Type": "application/json; charset=UTF-8",

        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${accessToken}`,
        Authorization1: `${idToken}`,
        TrackingId: `${AppTracking.id}`,
        ...additionalHeaders,
    } as any;

    return headersObj;
};
/**
 * @description function to handle the time out error
 * @param  {Promise} promise - promise
 * @param  {number} timeout - millseconds
 * @param  {string} error - errorcode
 * @return
 */
function timeoutPromise(promise, timeout, error) {
    return new Promise((resolve, reject) => {
        const clearTimeOut = setTimeout(() => {
            const err = { status: error, message: "Request TimeOut" };
            reject(err);
        }, timeout);
        promise.then(
            (data) => {
                clearTimeout(clearTimeOut);
                resolve(data);
            },
            (data) => {
                clearTimeout(clearTimeOut);
                reject(data);
            }
        );
    });
}

export const statusCheck = (data) => {
    const { status } = data;
    if (status === 204) {
        const res = {};
        const resData = { status, ...res };
        window["AppInsightTrace"].trackTrace("API status code: " + status);

        return Promise.resolve(resData);
    } else if (status === 401) {
        const res = { message: "Your authorization expired." };
        const resData = { status, ...res };
        window["AppInsightTrace"].trackException("API status code: " + status);
        window["AppInsightTrace"].trackTrace(
            "For Promise reject API status code: " + status
        );

        return Promise.reject(resData);
    } else if (status === 403) {
        const res = { message: "You are not authorized for this page." };
        const resData = { status, ...res };
        window["AppInsightTrace"].trackException("API status code: " + status);
        window["AppInsightTrace"].trackTrace(
            "For Promise reject API status code: " + status
        );

        return Promise.reject(resData);
    } else {
        return data
            .json()
            .then((res) => {
                const resData = { status, ...res };
                if (data.status >= 200 && data.status < 300) {
                    window["AppInsightTrace"].trackTrace(
                        "API status code: " + status
                    );
                    return Promise.resolve(resData);
                }
                window["AppInsightTrace"].trackException(
                    "API status code: " + status
                );
                window["AppInsightTrace"].trackTrace(
                    "For Promise reject API status code: " + status
                );
                return Promise.reject(resData);
            })
            .catch((error) => {
                let resData = { status, ...error };
                console.log(error);
                if (error) {
                    resData = { status, ...error };
                    if (status >= 500) {
                        let res = { message: "Can not fetch data from api." };
                        let resData = { status, ...res };
                        window["AppInsightTrace"].trackException(error);
                        return Promise.reject(resData);
                    }
                    return Promise.reject(resData);
                }
                return Promise.reject(resData);
            });
    }
};

// Common GET Request
export const getRequest = (url, data) => {

    return timeoutPromise(
        fetch(url, {
            method: "GET",
            headers: headers(),
            body: data,
        }),
        TIMEOUT,
        504
    ).then(statusCheck);
};


// Common POST Request
export const postRequest = (url, data) => {

    return timeoutPromise(
        fetch(url, {
            method: "POST",
            headers: headers(),
            body: data,
        }),
        TIMEOUT,
        504
    ).then(statusCheck);
};

// ------VAR Subscriber---------

// GET request to get selected VAR act details.
export const getSourceDetails = (url) => {
    window["AppInsightTrace"].trackTrace(
        `API call to get Subscriber Names : Get request - no payload`
    );
    return timeoutPromise(
        fetch(url, {
            method: "GET",
            headers: headers(),

        }),
        TIMEOUT,
        504
    ).then(statusCheck);
}

// Post Request for Subscriber 
export const postSubsciber = (url, name, status, emailAddress, suppressAccountValidation, suppressCreditsCheck, suppressEmailNotification, code,
    billFreq, returnGAFUserPricing, enable3D, enableFullReport,
    enablePropertyOwnerReport, enableXML) => {
    window["AppInsightTrace"].trackTrace(
        `API call for Post request for Subscirbers, payload:body=
        {
            "name":${name},
            "status":${status},
            "emailAddress":${emailAddress},
            "suppressAccountValidation":${suppressAccountValidation},
            "suppressCreditsCheck":${suppressCreditsCheck},
            "suppressEmailNotification":${suppressEmailNotification},
            "code":${code},
            "billingFrequency":${billFreq},
            "returnGAFUserPricing":${returnGAFUserPricing},
            "enable3D":${enable3D},
            "enableFullReport":${enableFullReport},
            "enablePropertyOwnerReport":${enablePropertyOwnerReport},
            "enableXML":${enableXML},
        }`
    );
    return timeoutPromise(
        fetch(url, {
            method: "POST",
            headers: headers(),
            body: JSON.stringify({
                "name": name,
                "status": status,
                "emailAddress": emailAddress,
                "suppressAccountValidation": suppressAccountValidation,
                "suppressCreditsCheck": suppressCreditsCheck,
                "suppressEmailNotification": suppressEmailNotification,
                "code": code,
                "billingFrequency": billFreq,
                "returnGAFUserPricing": returnGAFUserPricing,
                "enable3D": enable3D,
                "enableFullReport": enableFullReport,
                "enablePropertyOwnerReport": enablePropertyOwnerReport,
                "enableXML": enableXML,
            })
        }),
        TIMEOUT,
        504
    ).then(statusCheck);
};
// Post Request for Subscriber Product
export const postSubsciberProduct = (url, name, productCode, emailAddress, price) => {
    window["AppInsightTrace"].trackTrace(
        `API call for Post request for Subscirbers Product, payload:body=
        {
            "subscriberName": ${name},
            "productCode":${productCode},
            "emailAddress": ${emailAddress},
            "priec":${price}
        }`
    );
    return timeoutPromise(
        fetch(url, {
            method: "POST",
            headers: headers(),
            body: JSON.stringify({
                "subscriberName": name,
                "productCode": productCode,
                "emailAddress": emailAddress,
                "price": price
            })
        }),
        TIMEOUT,
        504
    ).then(statusCheck);
};
// Post Request for Subscriber Config
export const postSubscriberConfig = (url, name, key, value, status, emailAddress) => {
    window["AppInsightTrace"].trackTrace(
        `API call for Post request for Subscirbers Config, payload:body=
        {
            "name": ${name},
            "key":${key},
            "value":${value},
            "status":${status},
            "emailAddress": ${emailAddress},
            
        }`
    );
    return timeoutPromise(
        fetch(url, {
            method: "POST",
            headers: headers(),
            body: JSON.stringify({
                "name": name,
                "key": key,
                "value": value,
                "status": status,
                "emailAddress": emailAddress,

            })
        }),
        TIMEOUT,
        504
    ).then(statusCheck);
};
