import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import * as MyReceiptsPageActions from "../containers/MyReceipts/actionTypes";

//import IProductsProps from '../models/HomePage/IProductSelection'
// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
export interface MyReceiptsPageState {
    MyReceiptsPageData: any;
}

export interface GetMyReceiptsRecords {
    type: any;
    payload: any;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = GetMyReceiptsRecords;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestAPI: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: MyReceiptsPageActions.MY_RECEIPTS_PAGE_RECORDS,
            payload: "",
        });
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultProps: MyReceiptsPageState = {
    MyReceiptsPageData: {
        result: [],
        paging: {},
    },
};

export const reducer: Reducer<MyReceiptsPageState> = (
    state: MyReceiptsPageState = defaultProps,
    incomingAction: Action
) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case MyReceiptsPageActions.MY_RECEIPTS_PAGE_RECORDS:
            return {
                ...state,
                MyReceiptsPageData: action.payload,
            };
            break;
        default:
        // The following line guarantees that every action in the KnownAction union has been covered by a case above
        // const exhaustiveCheck: never = action;
    }

    return state || defaultProps;
};
