import * as React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { GafOktaManager } from "../../../auth/gaf-okta-auth";

import HeroBanner from "../../../components/HeroBanner";
import ErrorModal from "../../../components/ErrorModal";
import Loader from "../../../components/Loader";


import { renewToken } from "../../../utils/ApiFunctions";
import { checkValidSuperAdminUser } from "../../../utils/validation";

import { hasRenewTokenData } from "../Customers/actionCreater";

import { getSuceessPromoCodeModal } from "../../HomePage/actionCreater";
import CacheAmdinData from "./data.js";
import "./style.scss";

class CacheAdministration extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {
        if (!GafOktaManager.isAuthenticated) {
            GafOktaManager.login();
        } else {
            const associatedGAFCertification = this.props.userProfile.associatedGAFCertification ? this.props.userProfile.associatedGAFCertification : sessionStorage.getItem('associatedGAFCertification');
            const profileName = this.props.userProfile.profileName ? this.props.userProfile.profileName : sessionStorage.getItem('profileName');
            if (!this.props.hasRenewToken) {
                new Promise((resolve, reject) => {
                    renewToken(associatedGAFCertification, { resolve, reject })
                }).then(() => {
                    this.props.hasRenewTokenData(true);
                    this.callForUnAuthorizedPage(associatedGAFCertification, profileName);
                });
            } else {
                this.callForUnAuthorizedPage(associatedGAFCertification, profileName);
            }
        }
    }


    callForUnAuthorizedPage = (associatedGAFCertification, profileName) => {
        const validUser = checkValidSuperAdminUser(associatedGAFCertification, profileName);

        // redirect to unauthorized page for unauthorized users
        if (!validUser) {
            window["AppInsightTrace"].trackTrace(`Not valid user for Super Admin pages redirect to not authorized page.`);
            this.props.history.push("/concierge/not-authorized");
        } else {
            window["AppInsightTrace"].trackTrace(`User validated for Super Admin page - Cache Administration.`);

            //Loading orders details
            // this.getOrdersList();
        }
    };

    removeClick = (key) => {
        alert(`Cache for Key: ${key} will be refreshing soon`)
    }
    render() {
        return (
            <>
                {/* {loading && (
                <Loader />
            )} */}
                <div className='container cacheAdmin-wrapper'>
                    <HeroBanner pageTitle='Cache Administration' />
                    <div>
                        <table className="table table-striped cache-admin-table">
                            <thead>
                                <tr>
                                    <th className='w-75'>
                                        Key
                                    </th>
                                    <th className='w-25'>

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {CacheAmdinData && CacheAmdinData.map((item) => (
                                    <tr key={item.id}>
                                        <td className='w-75'>{item.key}</td>
                                        <td className='w-25'>
                                            <button className="remove-btn" onClick={() => this.removeClick(item.key)}>
                                                Remove
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>

                        </table>
                    </div>
                </div>
            </>
        )
    }

}

export function mapStateToProps(state) {
    return {
        hasRenewToken: state.Customers.hasRenewToken,
        userProfile: state.HomePage.userProfile,
        ReSubmitData: state.Resubmit,
    }
}
export function mapDispatchToProps(dispatch) {
    return {
        hasRenewTokenData: bindActionCreators(hasRenewTokenData, dispatch),
        getSuceessPromoCodeModal: bindActionCreators(getSuceessPromoCodeModal, dispatch),

    };
}

export default withRouter(
    connect(mapStateToProps,
        mapDispatchToProps
    )(CacheAdministration) as React.ComponentType<any>);
