import * as React from "react";
import ErrorModal from "../ErrorModal";
import { appIdleTimeOut } from "../../../envConfig";

export class Idle extends React.Component<any, any> {
    events: any;
    logoutTimeout: any;
    constructor(props: any) {
        super(props);
        this.events = [
            "mousemove",
            "mousedown",
            "click",
            "scroll",
            "keypress"
        ];
        this.logout = this.logout.bind(this);
        this.state = { showErrorModal: false, errorCode: "" };
        this.resetTimeout = this.resetTimeout.bind(this);
    }
    componentDidMount() {
        // window.addEventListener('beforeunload', this.handleLeavePage);
        this.destroy();
        for (var i in this.events) {
            window.addEventListener(this.events[i], this.resetTimeout);
        }
        this.setTimeout();

    }
    componentWillUnmount() {
        this.destroy();
        // window.removeEventListener('beforeunload', this.handleLeavePage);

    }

    // handleLeavePage() {
    //     // Not removing items in keysToStore Array
    //     const keysToStore = ["selectedAct", "isAddNewActBtnClicked"]
    //     const sessionStorageKeysArray = Object.keys(sessionStorage);
    //     sessionStorageKeysArray.map((key) => {
    //         if (keysToStore.includes(key) == false) {
    //             sessionStorage.removeItem(key)
    //         }
    //     })
    // }
    clearTimeout() {
        if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
    }
    setTimeout() {
        this.setState({
            showErrorModal: false,
            errorCode: ''
        })
        this.logoutTimeout = setTimeout(this.logout, appIdleTimeOut);
    }

    resetTimeout() {
        this.setState({
            showErrorModal: false,
            errorCode: ''
        })
        this.clearTimeout();
        this.setTimeout();
    }

    logout() {
        this.setState({
            showErrorModal: true,
            errorCode: 'sessionTimeOut'
        })
        this.destroy();
    }

    destroy() {
        for (var i in this.events) {
            window.removeEventListener(this.events[i], this.resetTimeout);
        }
        this.clearTimeout();
    }

    hideErrorModal = () => {
        this.setState({ showErrorModal: false, errorCode: '' });
    };

    render() {
        return (
            this.state.showErrorModal ?
                (
                    <ErrorModal
                        errorCode={this.state.errorCode}
                        close={() => this.hideErrorModal()}
                        show={this.state.showErrorModal}
                    />
                ) :
                <React.Fragment />

        )
    }
}
