import * as TRACKORDER from "./actionTypes";

export function trackOrder(data): any {
  return {
    type: TRACKORDER.TRACK_ORDER,
    payload: data,
  };
}

export function trackOrderSuccess(data) {
  return {
    type: TRACKORDER.TRACK_ORDER_SUCCESS,
    payload: data,
  };
}

export function trackOrderFailure(error) {
  return {
    type: TRACKORDER.TRACK_ORDER_FAILURE,
    payload: error,
  };
}




export function cancelOrder(data): any {
  return {
    type: TRACKORDER.CANCEL_ORDER,
    payload: data,
  };
}

export function cancelOrderSuccess(data) {
  console.log(data, "cancel order success action creator");
  return {
    type: TRACKORDER.CANCEL_ORDER_SUCCESS,
    payload: data,
  };
}

export function cancelOrderFailure(error) {
  return {
    type: TRACKORDER.CANCEL_ORDER_FAILURE,
    payload: error,
  };
}

export function clearCancelOrder() {
  return {
    type: TRACKORDER.CLEAR_CANCEL_ORDER
  }
}




export function downloadReport(data): any {
  return {
    type: TRACKORDER.DOWNLOAD_REPORT,
    payload: data,
  };
}

export function downloadReportSuccess(data) {
  console.log(data, "download report success action creator");
  return {
    type: TRACKORDER.DOWNLOAD_REPORT_SUCCESS,
    payload: data,
  };
}

export function downloadReportFailure(error) {
  return {
    type: TRACKORDER.DOWNLOAD_REPORT_FAILURE,
    payload: error,
  };
}

