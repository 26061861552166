import * as SENDREQUEST from "./actionTypes";


export function sendRequest(data): any {
  return {
    type: SENDREQUEST.SEND_REQUEST,
    payload: data,
  };
}

export function sendRequestSuccess(data) {
  return {
    type: SENDREQUEST.SEND_REQUEST_SUCCESS,
    payload: data,
  };
}

export function sendRequestFailure(error) {
  return {
    type: SENDREQUEST.SEND_REQUEST_FAILURE,
    payload: error,
  };
}



