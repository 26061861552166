import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { GafOktaManager } from "../../auth/gaf-okta-auth";
import { Redirect } from "react-router-dom";
const queryString = require("query-string");

import { HomeLoggedIn } from "../../components/home-logged-in";
import { HomeNotLoggedIn } from "../../components/home-not-logged-in";

export class Home extends React.Component<any, any> {
    state = {
        authenticated: null,
        error: null,
        redirectTo: false
    };

    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        const pathName = window.location.pathname;
        
        if (pathName === "/guest-home-page" || pathName === "/thd") {
            this.setState({
                redirectTo: false
            })
        } else {
            this.setState({
                redirectTo: true
            }, () => {
                GafOktaManager.login();
            })
        }
        if (GafOktaManager.isAuthenticated) {
            this.setState({
                redirectTo: true
            })
        }
        if (window.localStorage) {
            if (!localStorage.getItem("firstLoad")) {
                localStorage["firstLoad"] = true;

                // commented below line because of reloading issue
                window.location.reload();
            } else {
                localStorage.removeItem("firstLoad")
            };

        }
        const authenticated = GafOktaManager.isAuthenticated;

        this.setState({ authenticated });

    }

    render() {

        if (this.state.redirectTo) {
            return (
                <React.Fragment>
                    <div className="container">
                        <HomeLoggedIn />
                    </div>
                </React.Fragment>
            );
        } else {
            return (
                <Redirect to="/guest-home-page"></Redirect>
            );
        }

    }
}

export function mapDispatchToProps(dispatch) {
    return {
    };
}
export function mapStateToProps(state: any) {
    return state;
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Home) as React.ComponentType<any>);

