export const MY_ORDER_PAGE_RECORDS = "MY_ORDER_PAGE_RECORDS";
export type MY_ORDER_PAGE_RECORDS = typeof MY_ORDER_PAGE_RECORDS;

export const GET_DISTRIBUTOR_FOR_POSTALCODE = "GET_DISTRIBUTOR_FOR_POSTALCODE";
export type GET_DISTRIBUTOR_FOR_POSTALCODE = typeof GET_DISTRIBUTOR_FOR_POSTALCODE;

export const GET_DISTRIBUTOR_FOR_POSTALCODE_SUCCESS = "GET_DISTRIBUTOR_FOR_POSTALCODE_SUCCESS";
export type GET_DISTRIBUTOR_FOR_POSTALCODE_SUCCESS = typeof GET_DISTRIBUTOR_FOR_POSTALCODE_SUCCESS;

export const GET_DISTRIBUTOR_FOR_POSTALCODE_FAILURE = "GET_DISTRIBUTOR_FOR_POSTALCODE_FAILURE";
export type GET_DISTRIBUTOR_FOR_POSTALCODE_FAILURE = typeof GET_DISTRIBUTOR_FOR_POSTALCODE_FAILURE;

export const UPDATE_ORDER_SERVICE = "UPDATE_ORDER_SERVICE";
export type UPDATE_ORDER_SERVICE = typeof UPDATE_ORDER_SERVICE;

export const UPDATE_ORDER_SERVICE_SUCCESS = "UPDATE_ORDER_SERVICE_SUCCESS";
export type UPDATE_ORDER_SERVICE_SUCCESS = typeof UPDATE_ORDER_SERVICE_SUCCESS;

export const UPDATE_ORDER_SERVICE_FAILURE = "UPDATE_ORDER_SERVICE_FAILURE";
export type UPDATE_ORDER_SERVICE_FAILURE = typeof UPDATE_ORDER_SERVICE_FAILURE;

export const CLEAR_UPDATE_ORDER_SERVICE = "CLEAR_UPDATE_ORDER_SERVICE";
export type CLEAR_UPDATE_ORDER_SERVICE  = typeof CLEAR_UPDATE_ORDER_SERVICE;

export const DOWNLOAD_FILE = "DOWNLOAD_FILE";
export type DOWNLOAD_FILE = typeof DOWNLOAD_FILE;

export const DOWNLOAD_FILE_SUCCESS = "DOWNLOAD_FILE_SUCCESS";
export type DOWNLOAD_FILE_SUCCESS = typeof DOWNLOAD_FILE_SUCCESS;

export const DOWNLOAD_FILE_FAILURE = "DOWNLOAD_FILE_FAILURE";
export type DOWNLOAD_FILE_FAILURE = typeof DOWNLOAD_FILE_FAILURE;

export const RESET_DOWNLOAD_FILE = "RESET_DOWNLOAD_FILE";
export type RESET_DOWNLOAD_FILE = typeof RESET_DOWNLOAD_FILE;

