import * as React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { paymentCancel } from "../../components/PaymentCancel/actionCreater";

export class PDLocal extends React.Component<any, any> {
    buttonClick = () => {
        this.props.paymentCancel(true);
        this.props.history.push('/paymentcancel?ref=?ref=be842b63-8e65-4cad-8b95-61a76f1274f4')
        // setTimeout(() => {
        //     this.props.history.push("/paymentsuccess?ref=c5865a64-f61e-426e-83a1-10271eeb402b");

        // }, 3000)
    }
    render() {


        return (
            <div className="container">
                <h2>Local PD </h2>
                <button onClick={() => this.buttonClick()}>Back</button>
            </div>

        )
    }
}

export function mapDispatchToProps(dispatch) {
    return {

        paymentCancel: bindActionCreators(paymentCancel, dispatch)
    };
}
const mapStateToProps = (state) => {

    return {

    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(PDLocal) as React.ComponentType<any>
);
