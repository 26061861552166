
export const SEND_REQUEST = "SEND_REQUEST";

export const SEND_REQUEST_SUCCESS = "SEND_REQUEST_SUCCESS";

export const SEND_REQUEST_FAILURE = "SEND_REQUEST_FAILURE";

export type SEND_REQUEST = typeof SEND_REQUEST;

export type SEND_REQUEST_SUCCESS = typeof SEND_REQUEST_SUCCESS;

export type SEND_REQUEST_FAILURE = typeof SEND_REQUEST_FAILURE;

