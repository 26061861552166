import { put, takeLatest, call } from "redux-saga/effects";

import * as myReceiptsPageActionCreator from "./actionCreater";
import * as MY_RECEIPTS_PAGE_ACTIONS from "./actionTypes";

export function* getMyReceipts() {
    try {
        const response = {};
        yield put(
            myReceiptsPageActionCreator.getMyReceiptsPageRecords(response)
        );
    } catch (error) {}
}

export function* MyReceiptsPageWatcher() {
    yield [
        takeLatest(
            MY_RECEIPTS_PAGE_ACTIONS.MY_RECEIPTS_PAGE_RECORDS,
            getMyReceipts
        ),
    ];
}
