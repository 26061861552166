import * as APPLICATION_ADMIN_ACTIONS from "./actionTypes";

export function getActTypes(data): any {
    return {
        type: APPLICATION_ADMIN_ACTIONS.GET_ACT_TYPES,
        payload: data,
    };
}

export function getActTypesSuccess(data): any {
    return {
        type: APPLICATION_ADMIN_ACTIONS.GET_ACT_TYPES_SUCCESS,
        payload: data,
    };
}

export function getActTypesFailure(error): any {
    return {
        type: APPLICATION_ADMIN_ACTIONS.GET_ACT_TYPES_FAILURE,
        payload: error,
    };
}

export function getSelectedAct(data): any {
    return {
        type: APPLICATION_ADMIN_ACTIONS.SELECTED_ACT,
        payload: data,
    };
}

export function saveActTypes(data): any {
    return {
        type: APPLICATION_ADMIN_ACTIONS.SAVE_ACT_TYPES,
        payload: data,
    };
}

export function saveActTypesSuccess(data): any {
    return {
        type: APPLICATION_ADMIN_ACTIONS.SAVE_ACT_TYPES_SUCCESS,
        payload: data,
    };
}

export function saveActTypesFailure(error): any {
    return {
        type: APPLICATION_ADMIN_ACTIONS.SAVE_ACT_TYPES_FAILURE,
        payload: error,
    };
}

export function addNewActBtnClicked(data): any {
    return {
        type: APPLICATION_ADMIN_ACTIONS.ADD_NEW_ACT,
        payload: data,
    };
}
