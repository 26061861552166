import * as React from "react";
import { useEffect } from "react"

import { Modal } from "react-bootstrap";
import { cdnPath, postLogoutRedirectUri, oktaLogoutUrl, oktaErrorLogoutUrl, gafLoginArray, rewardsURL } from "../../../envConfig";
import { GafOktaManager } from "../../auth/gaf-okta-auth";
import { currencyFormat } from "../../utils/validation";

const roundErrorImg = cdnPath + "/images/roundErrorSymbol@3x.png";

const noCloseBtnErrorCodesArray = ['sessionTimeOut', 'oktaProviderError']


const ErrorModal = (props) => {

    const homePageErrorCodeArray = ["00", "01", "05"];
    const buyCreditsErrorCodeArray = ["00", "01", "02", "03", "04", "10"];
    const userEmail = GafOktaManager.storage.info.profile !== undefined && GafOktaManager.storage.info.profile.email;
    const userEmailSplit = userEmail && userEmail.split('@');
    const userEmailDomain = userEmailSplit && userEmailSplit[userEmailSplit.length - 1];

    // Setting values for logout based on user logged in - Internal user or External user
    // For Internal users has userEmailDomain - [gaf.com,gaf.energy]
    const logoutUrl = gafLoginArray.includes(userEmailDomain) === true ? oktaLogoutUrl : null;
    const oktaProviderErrorUrl = gafLoginArray.includes(userEmailDomain) === true ? oktaErrorLogoutUrl : postLogoutRedirectUri;

    // Display logout screen only if sessionTimeout erroCode or OktaProvider Error code is passed for errorModal
    useEffect(() => {
        if (props.errorCode == "sessionTimeOut") {
            setTimeout(() => { GafOktaManager.logout(logoutUrl) }, 3000);
        }
        if (props.errorCode == "oktaProviderError") {
            setTimeout(() => { window.location.href = oktaProviderErrorUrl; }, 3000)
        }

    }, []);

    const oktaErrorCloseBtnClick = () => {
        if (props.errorCode == "oktaProviderError") {
            window.location.href = oktaProviderErrorUrl
        }

    }

    const generateCodeClick = () => {
        props.generateCodeClick();
        props.close();
    };

    const refundClick = () => {
        props.refundClick();
        props.close();
    };
    const emailPromocodeGenerateClick = () => {
        props.emailGenerateCodeClick();
        props.close();
    };
    const yesClickContractorError = () => {
        window.open(rewardsURL, '_self');
    }
    return (
        <React.Fragment>
            <Modal
                className={`redeemCode-modal  
                 ${props.refundModal ||
                        props.csvPromoCodesModal ||
                        props.emailPromoCodesModal
                        ? "refund-modal"
                        : "error-modal"
                    } 
                    ${(props.errorCode == "sessionTimeOut" || props.errorCode == "oktaProviderError") ? "TimeOutModal" : ""} ${props.errorCode == "guestPaymentError" ? 'guestPaymentErrorModal' : ""}
             `}
                show={props.show}
                onHide={() => props.close()}
                animation={false}
                data-keyboard="false"
                backdrop="static"
            >
                {(noCloseBtnErrorCodesArray.includes(props.errorCode) == false) && (
                    <Modal.Header className="border-bottom-0 pb-0" closeButton>
                        <Modal.Title>
                            {((props.errorCode === "genericError") || (props.isEmailSupressedForSubscriber && props.errorCode === '04')) && (
                                <div>
                                    <img
                                        className="round-error-img"
                                        src={roundErrorImg}
                                        alt="round-error-image"
                                    />
                                    <h6 className="Error">Error</h6>
                                </div>
                            )}
                            {/* createActRole_Contractor */}
                            {props.errorCode === "createActRole_Contractor" && (
                                <div>
                                    <img
                                        className="round-error-img"
                                        src={roundErrorImg}
                                        alt="round-error-image"
                                    />
                                    <h6 className="Error">Confirmation</h6>
                                </div>
                            )}
                            {(
                                (props.homepageError && homePageErrorCodeArray.includes(props.errorCode))
                                || (props.guestHomepageError && props.errorCode === "05")
                                || props.errorCode === "Area not covered") && (
                                    <div>
                                        <img
                                            className="round-error-img"
                                            src={roundErrorImg}
                                            alt="round-error-image"
                                        />
                                        <h6 className="Error">Error</h6>
                                    </div>
                                )}
                            {(props.errorCode === "paymentError" ||
                                props.errorCode === "paymentCancel" || props.errorCode === "guestPaymentError") && (
                                    <div>
                                        <img
                                            className="round-error-img"
                                            src={roundErrorImg}
                                            alt="round-error-image"
                                        />
                                        <h6 className="Error">Error</h6>
                                    </div>
                                )}

                            {props.buyBalanceError &&
                                (buyCreditsErrorCodeArray.includes(props.errorCode) ||
                                    props.errorCode === null) && (
                                    <div>
                                        <img
                                            className="round-error-img"
                                            src={roundErrorImg}
                                            alt="round-error-image"
                                        />
                                        <h6 className="Error">Error</h6>
                                    </div>
                                )}
                            {(props.errorCode === "exportTypeError" || props.errorCode === 'exportDateError') && (
                                <div>
                                    <img
                                        className="round-error-img"
                                        src={roundErrorImg}
                                        alt="round-error-image"
                                    />
                                    <h6 className="Error">Error</h6>
                                </div>
                            )}

                            {props.errorCode === "promoCodesError1" && (
                                <div>
                                    <img
                                        className="round-error-img"
                                        src={roundErrorImg}
                                        alt="round-error-image"
                                    />
                                    <h6 className="Error">Error</h6>
                                </div>
                            )}
                            {(props.errorCode === "promoCodesError02" || props.errorCode === "promoCodesError06" || props.errorCode === "promoCodesError07") && (
                                <div>
                                    <img
                                        className="round-error-img"
                                        src={roundErrorImg}
                                        alt="round-error-image"
                                    />
                                    <h6 className="Error">Error</h6>
                                </div>
                            )}
                            {props.errorCode === "promoCodesCSV" && (
                                <div>
                                    <h6 className="Error"></h6>
                                </div>
                            )}
                            {props.errorCode === "duplicateSourceCode" && (
                                <div>
                                    <img
                                        className="round-error-img"
                                        src={roundErrorImg}
                                        alt="round-error-image"
                                    />
                                    <h6 className="Error">Error</h6>
                                </div>
                            )}
                            {props.emailPromoCodesModal &&
                                props.errorCode === "emailPromoCodes" && (
                                    <div>
                                        <h6 className="Error">
                                            Are you sure you want to create promo
                                            code(s)?
                                        </h6>
                                    </div>
                                )}
                            {/* For Refund Modal */}
                            {props.refundModal === true && (
                                <div>
                                    <h6 className="Error refund-heading">
                                        Refund payment
                                    </h6>
                                </div>
                            )}
                        </Modal.Title>
                    </Modal.Header>
                )}
                <Modal.Body className="redeemCode-modal-body py-0">
                    {props.errorCode === "genericError" && (
                        <p className="ParagraphAndLabel">
                            Hmm...seems we are having some technical
                            difficulties with your request. Please contact our
                            support team for more information.
                        </p>
                    )}
                    {props.errorCode === "oktaProviderError" && (
                        <>
                            <h6 className="Error mt-0 mb-2">Login Error.</h6>
                            <p className="ParagraphAndLabel">
                                We are having some issues with login....Please close your browser and login again.
                            </p>
                            <p className="ParagraphAndLabel">
                                {props.displayError}
                            </p>
                            <button
                                type="button"
                                className="ButtonCopy gaf-btn-secondary continue-btn"
                                onClick={() => { oktaErrorCloseBtnClick() }}
                            >
                                Close
                            </button>

                        </>
                    )}
                    {props.errorCode === "exportTypeError" && (
                        <p className="ParagraphAndLabel">
                            Not a valid type for export report.
                        </p>
                    )}
                    {props.errorCode === "exportDateError" && (
                        <p className="ParagraphAndLabel">
                            Please enter vaild date range.
                        </p>
                    )}
                    {/* HomePage Errors for PlaceOrder */}
                    {props.homepageError && props.errorCode === "00" && (
                        <p className="ParagraphAndLabel">
                            Unable to load userprofile for email address
                            {props.searchEmail}
                        </p>
                    )}
                    {props.homepageError && props.errorCode === "01" && (
                        <p className="ParagraphAndLabel">
                            Hmm...seems like you need to replenish your balance in order to purchase
                            this roof report. Please select the Add to Balance button to replenish account balance.
                        </p>
                    )}
                    {((props.homepageError && props.errorCode === "05") ||
                        props.errorCode === "Area not covered") && (
                            <p className="ParagraphAndLabel">
                                Unfortunately, we don't cover the property you
                                requested but we are regularly adding coverage so
                                please be sure to check out the <a className="coverage-link" href="/coverage-map">coverage map</a> often!
                            </p>
                        )}
                    {/* For Guest User HomePage */}
                    {(props.guestHomepageError && props.errorCode === "05")
                        && (
                            <p className="ParagraphAndLabel">
                                Unfortunately, we don't cover the property you
                                requested but we are regularly adding coverage so
                                please be sure to check out the <a className="coverage-link" href="/coverage-map">coverage map</a> often!
                            </p>
                        )}
                    {/* For BuyrCredits */}
                    {props.buyBalanceError && props.errorCode === "04" && (
                        <p className="ParagraphAndLabel">
                            Requested Number of Credits and Amount is not
                            permissible for your profile.
                        </p>
                    )}
                    {props.buyBalanceError && props.errorCode === "03" && (
                        <p className="ParagraphAndLabel">
                            Invalid Amount or Number of Credits
                        </p>
                    )}
                    {props.buyBalanceError && props.errorCode === "02" && (
                        <p className="ParagraphAndLabel">
                            Unable to load profile data for user!
                        </p>
                    )}
                    {props.buyBalanceError && props.errorCode === "01" && (
                        <p className="ParagraphAndLabel">
                            Your account is associated with Parent Account, only
                            parent account can add funds.
                        </p>
                    )}
                    {props.buyBalanceError && props.errorCode === "00" && (
                        <p className="ParagraphAndLabel">
                            Please enter valid email address to initiate payment
                            transaction
                        </p>
                    )}

                    {props.buyBalanceError && props.errorCode === "10" && (
                        <p className="ParagraphAndLabel">
                            Unable to Initiate handshake with Payment Director.
                        </p>
                    )}
                    {/* For Payment */}
                    {((props.errorCode === "paymentError") || (props.buyBalanceError && props.errorCode === null)) && (
                        <>
                            <p className="ParagraphAndLabel">
                                We are sorry, but due to technical difficulties,
                                you are not able to replenish your balance at this
                                time.
                            </p>
                            <p className="ParagraphAndLabel">
                                Please try again later. If the problem persists,
                                please contact Customer Support at 512-964-5711.
                            </p>
                        </>
                    )}
                    {props.errorCode === "paymentCancel" && (
                        <p className="ParagraphAndLabel">
                            Payment cancelled!!!
                        </p>
                    )}
                    {props.errorCode === "guestPaymentError" && (
                        <>
                            <p className="ParagraphAndLabel">
                                We are sorry, but we are currently unable to accept your payment.
                            </p>
                            <p className="ParagraphAndLabel">Please try back in a few minutes.</p>
                            <p className="ParagraphAndLabel gaf-support-link">
                                If the problem persists, please contact
                                <a href={`mailto:gafquickmeasuresupport@gaf.com`}> gafquickmeasuresupport@gaf.com</a>
                            </p>
                        </>
                    )}
                    {/* For PromoCodes */}
                    {props.errorCode === "promoCodesError1" && (
                        <p className="ParagraphAndLabel">
                            You are required to either check export to CSV or
                            enter an email address in order to generate promo
                            codes.
                        </p>
                    )}
                    {props.errorCode === "promoCodesCSV" && (
                        <div className="promoCodes-csv">
                            <h6 className="Error">
                                Are you sure you want to create promo code(s)?
                            </h6>
                            <p className="ParagraphAndLabel mb-0">
                                By clicking "Generate Code", you will be
                                downloading a CSV file of codes for use on GAF
                                Business Services.
                            </p>
                            <button
                                // href="#"
                                type="button"
                                // generate-code-btn
                                className="ButtonCopy gaf-btn-secondary refund-btn"
                                onClick={() => generateCodeClick()}
                            >
                                Generate Code(s)
                            </button>
                        </div>
                    )}
                    {props.errorCode === "promoCodesError02" && (
                        <p className="ParagraphAndLabel">
                            {/* The email you entered is not valid */}
                            {/* Response fail from api */}
                            User {props.userId} not available in QM Database";
                        </p>
                    )}
                    {props.errorCode === "promoCodesError06" && (
                        <p className="ParagraphAndLabel">
                            Products can not be used with Amount value.
                        </p>
                    )}
                    {props.errorCode === "promoCodesError07" && (
                        <p className="ParagraphAndLabel">
                            Amount can not be used when selecting products.
                        </p>
                    )}
                    {props.emailPromoCodesModal &&
                        props.errorCode === "emailPromoCodes" && (
                            <div>
                                <p className="ParagraphAndLabel mb-0">
                                    By clicking "Generate Code", you will send
                                    an email to the user with the list of promo
                                    code(s) for their use on GAF Business Services.
                                </p>
                                <button
                                    type="button"
                                    className="ButtonCopy gaf-btn-secondary refund-btn"
                                    onClick={() =>
                                        emailPromocodeGenerateClick()
                                    }
                                >
                                    Generate code(s)
                                </button>
                            </div>
                        )}
                    {/* For Refund Modal */}
                    {props.refundModal === true && (
                        <div>
                            <p className="ParagraphAndLabel mb-0">
                                By continuing you will be refunding this
                                customer {currencyFormat(props.amount)} USD.
                            </p>
                            <button
                                // href="#"
                                type="button"
                                className="ButtonCopy gaf-btn-secondary refund-btn"
                                onClick={() => refundClick()}
                            >
                                Refund
                            </button>
                        </div>
                    )}
                    {/* TimeOut Modal */}
                    {props.errorCode === "sessionTimeOut" && (
                        <div className="">
                            <h6 className="Error mt-0 mb-2">Your session has expired.</h6>
                            <p className="ParagraphAndLabel">
                                For security reasons we are about to log you out
                                due to one hour of inactivity. You may login
                                again by clicking the button below.
                            </p>
                            <button
                                type="button"
                                className="ButtonCopy gaf-btn-secondary continue-btn"
                                onClick={() => {
                                    GafOktaManager.logout(logoutUrl);
                                    // GafOktaManager.logout();
                                }}
                            >
                                Continue
                            </button>
                        </div>
                    )}
                    {/* For Duplicate Source Code - Add/Edit Subscriber Page */}
                    {props.errorCode === "duplicateSourceCode" && (
                        <>
                            <p className="ParagraphAndLabel">
                                The Subscriber Account Source code must be unique.
                            </p>
                            <p className="ParagraphAndLabel">
                                Please enter a unique Account Source Code.
                            </p>
                        </>
                    )}
                    {/* Create Act Warning for Role- Cotractor */}
                    {/* createActRole_Contractor */}
                    {props.errorCode === "createActRole_Contractor" && (
                        <>
                            <p className="ParagraphAndLabel mt-2 font-weight-bold">
                                Roofing Contractor?  <button className="contractor-error-btn" onClick={() => yesClickContractorError()}> Yes</button>
                                <button className="contractor-error-btn" onClick={() => props.close()}>No</button>
                            </p>
                            <p className="ParagraphAndLabel mb-1">
                                Sign up as a Roofing Contractor so we can create an account for you.
                            </p>
                            <p className="ParagraphAndLabel"> This account will give you access to more than just QuickMeasure! </p>
                        </>
                    )}
                    {/* ResendEmail - Subscriber not enabled to send email */}
                    {(props.isEmailSupressedForSubscriber && props.errorCode === '04') && (
                        <p className="ParagraphAndLabel">
                            This order belongs to a subscriber that has not enabled emails.
                        </p>
                    )}
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default ErrorModal;
