import * as React from "react";
import { Modal, } from "react-bootstrap";
import * as TagManager from 'react-gtm-module';

const GuestPromoCodeModal = (props) => {
    const loginBtnClick = () => {
        // ----------------------------------
        // GTM settings
        const tagManagerArgs = {
            dataLayer: {
                event: 'clickRedeemCodeLogin',
            },
            dataLayerName: 'PageDataLayer'
        }
        TagManager.dataLayer(tagManagerArgs);
        // -------------------------------------

        props.loginBtnClick();
    }
    const createActBtnClick = () => {

        // ----------------------------------
        // GTM settings
        const tagManagerArgs = {
            dataLayer: {
                event: 'clickRedeemCodeCreateAct',
            },
            dataLayerName: 'PageDataLayer'
        }
        TagManager.dataLayer(tagManagerArgs);
        // -------------------------------------

        props.createActBtnClick();
    }
    return (
        <React.Fragment>
            <Modal
                className="guest-promocode-modal"
                show={props.show}
                onHide={() => props.close()}
                animation={false}
                data-keyboard="false"
                backdrop="static"
            >
                <Modal.Header
                    className="border-bottom-0"
                    closeButton
                >

                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <h3 className="H3White">Please login to redeem a promo code </h3>
                        <p className="ParagraphAndLabel">You must be a registered GAF user in order to redeem a promo code.</p>
                        <p className="ParagraphAndLabel">Click the red button below to login or the white button to create an account and redeem your code.</p>
                        <div className="btn-div">
                            <button className="MiniButtonCopy gaf-btn-secondary"
                                onClick={loginBtnClick}
                            >
                                Login
                            </button>
                            <button className="MiniButtonCopy gaf-btn-secondary create-act-btn"
                                onClick={createActBtnClick}
                            >
                                Create Account
                                </button>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        </React.Fragment>
    )
}

export default GuestPromoCodeModal;