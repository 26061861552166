import * as React from "react";
import "./style.scss";

export const RadioButton = (props) => {

    const { labelName, radioName, value, selectedValue, onChange, radioFor, disabled, isError } = props;
    const className = props.className === undefined ? "" : props.className;
    return (
        <div>
            <label htmlFor={radioFor} className={`radio ${className}`} >
                <input type="radio" name={radioName}
                    id={radioFor}
                    className="radio-input"
                    value={value}
                    disabled={disabled && disabled}
                    checked={selectedValue === value}
                    onChange={onChange} />
                <div className={`${isError ? 'custom-radio red-border' : 'custom-radio'}`}></div>
                {labelName}
            </label>
        </div>
    )
}
export default RadioButton;