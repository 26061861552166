import { put, takeLatest, call } from "redux-saga/effects";

import * as historyActionCreator from "./actionCreater";
import * as historyActions from "./actionType";

export function* getBrowserPath() {
    try {
        const response = {};
        yield put(historyActionCreator.locationChange(response));
        window["AppInsightTrace"].trackTrace("Saving browser path");
    } catch (error) {}
}

export function* historyWatcher() {
    yield [takeLatest(historyActions.LOCATION_CHANGE, getBrowserPath)];
}
