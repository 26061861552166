import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import * as CustomerRoofReportsPageActions from "../containers/concierge/Roofreports/actionTypes";

//import IProductsProps from '../models/HomePage/IProductSelection'
// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
export interface CustomersRoofReportsPageState {
    roofReports: any;
    roofReportSearchText: any;
    roofReportRequesterEmail: any;
    requesterEmailClick: boolean;
    refundReport: any;
    isRefundingReport: boolean;
    refundReportSuccess: boolean;
    refundReportFailed: boolean;
    resendEmail: any;
    isResendingEmail: boolean;
    isResendEmailSuccess: boolean;
    isResendEmailFailed: boolean;
    transferOrder: any;
    isTransferingOrder: boolean;
    isTransferOrderSuccess: boolean;
    isTransferOrderFailed: boolean;
    searchProfileData: any;
    isSearchingProfile: boolean;
    isSearchProfileFailed: boolean;
    isSearchProfileSuccess: boolean;
}

export interface GetCurtomersRoofReports {
    type: any;
    payload: any;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = GetCurtomersRoofReports;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestAPI: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: CustomerRoofReportsPageActions.CUSTOMERS_ROOF_REPORTS,
            payload: "test",
        });
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultProps: CustomersRoofReportsPageState = {
    roofReports: {
        result: [],
        paging: {},
    },
    roofReportSearchText: "",
    roofReportRequesterEmail: "",
    requesterEmailClick: false,
    refundReport: {},
    isRefundingReport: false,
    refundReportSuccess: false,
    refundReportFailed: false,
    resendEmail: {},
    isResendingEmail: false,
    isResendEmailSuccess: false,
    isResendEmailFailed: false,
    transferOrder: {},
    isTransferingOrder: false,
    isTransferOrderSuccess: false,
    isTransferOrderFailed: false,
    searchProfileData: {},
    isSearchingProfile: false,
    isSearchProfileFailed: false,
    isSearchProfileSuccess: false,
};

export const reducer: Reducer<CustomersRoofReportsPageState> = (
    state: CustomersRoofReportsPageState = defaultProps,
    incomingAction: Action
) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case CustomerRoofReportsPageActions.CUSTOMERS_ROOF_REPORTS:
            return {
                ...state,
                roofReports: action.payload,
            };
            break;
        case CustomerRoofReportsPageActions.ROOF_REPORTS_SEARCH_TEXT:
            return {
                ...state,
                roofReportSearchText: action.payload,
            };
            break;
        case CustomerRoofReportsPageActions.ROOF_REPORTS_EMAIL_ADDRESS:
            return {
                ...state,
                roofReportRequesterEmail: action.payload,
            };
            break;
        case CustomerRoofReportsPageActions.ROOF_REPORTS_EMAIL_CLICKED:
            return {
                ...state,
                requesterEmailClick: action.payload,
            };
            break;
        case CustomerRoofReportsPageActions.REFUND_REPORT:
            return {
                ...state,
                refundReport: action.payload,
                isRefundingReport: true,
                refundReportSuccess: false,
                refundReportFailed: false,
            }
        case CustomerRoofReportsPageActions.REFUND_REPORT_SUCCESS:
            return {
                ...state,
                refundReport: action.payload,
                isRefundingReport: false,
                refundReportSuccess: true,
                refundReportFailed: false,
            }
        case CustomerRoofReportsPageActions.REFUND_REPORT_FAILED:
            return {
                ...state,
                refundReport: action.payload,
                isRefundingReport: false,
                refundReportSuccess: false,
                refundReportFailed: true,
            }
        case CustomerRoofReportsPageActions.RESET_REFUND_REPORT:
            return {
                ...state,
                refundReport: {},
                isRefundingReport: false,
                refundReportSuccess: false,
                refundReportFailed: false,
            }
        case CustomerRoofReportsPageActions.RESEND_EMAIL:
            return{
                ...state,
                resendEmail: action.payload,
                isResendingEmail: true,
                isResendEmailSuccess: false,
                isResendEmailFailed: false,
            }
        case CustomerRoofReportsPageActions.RESEND_EMAIL_SUCCESS:
            return{
                ...state,
                resendEmail: action.payload,
                isResendingEmail: false,
                isResendEmailSuccess: true,
                isResendEmailFailed: false,
            }
        case CustomerRoofReportsPageActions.RESEND_EMAIL_FAILED:
            return{
                ...state,
                resendEmail: action.payload,
                isResendingEmail: false,
                isResendEmailSuccess: false,
                isResendEmailFailed: true,
            }
        case CustomerRoofReportsPageActions.RESET_RESEND_EMAIL:
            return{
                ...state,
                resendEmail: {},
                isResendingEmail: false,
                isResendEmailSuccess: false,
                isResendEmailFailed: false,
            }
        case CustomerRoofReportsPageActions.TRANSFER_ORDER:
            return{
                ...state,
                transferOrder: action.payload,
                isTransferingOrder: true,
                isTransferOrderSuccess: false,
                isTransferOrderFailed: false,
            }
        case CustomerRoofReportsPageActions.TRANSFER_ORDER_SUCCESS:
            return{
                ...state,
                transferOrder: action.payload,
                isTransferingOrder: false,
                isTransferOrderSuccess: true,
                isTransferOrderFailed: false,
            }
        case CustomerRoofReportsPageActions.TRANSFER_ORDER_FAILED:
            return{
                ...state,
                transferOrder: action.payload,
                isTransferingOrder: false,
                isTransferOrderSuccess: false,
                isTransferOrderFailed: true,
            }
        case CustomerRoofReportsPageActions.RESET_TRANSFER_ORDER:
            return{
                ...state,
                transferOrder: {},
                isTransferingOrder: false,
                isTransferOrderSuccess: false,
                isTransferOrderFailed: false,
            }
        case CustomerRoofReportsPageActions.SEARCH_USER_PROFILE:
            return {
                ...state,
                searchProfileData: action.payload,
                isSearchingProfile: true,
            }
        case CustomerRoofReportsPageActions.SEARCH_USER_PROFILE_SUCCESS:
            return {
                ...state,
                searchProfileData: action.payload,
                isSearchingProfile: false,
                isSearchProfileSuccess: true,
                isSearchProfileFailed: false,
            }
        case CustomerRoofReportsPageActions.SEARCH_USER_PROFILE_FAILED:
            return {
                ...state,
                searchProfileData: action.payload,
                isSearchingProfile: false,
                isSearchProfileFailed: true,
                isSearchProfileSuccess: false,
            }
        case CustomerRoofReportsPageActions.RESET_SEARCH_USER_PROFILE:
            return {
                ...state,
                searchProfileData: {},
                isSearchingProfile: false,
                isSearchProfileFailed: false,
                isSearchProfileSuccess: false,
            }
        default:
        // The following line guarantees that every action in the KnownAction union has been covered by a case above
        // const exhaustiveCheck: never = action;
    }

    return state || defaultProps;
};
