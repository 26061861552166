import * as React from "react";

interface Props {
    pageTitle: string;
}

const HeroBanner = ({ pageTitle }: Props) => {
    return (
        <section className="container hero-banner">
            <h1 className="H1White mb-0">{pageTitle}</h1>
        </section>
    );
};

export default HeroBanner;