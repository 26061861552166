import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import * as MyPrefPageActions from "../containers/MyPreferences/actionTypes";

//import IProductsProps from '../models/HomePage/IProductSelection'
// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
export interface MyPreferencePageState {
    shouldDisableSavePrefBtn: boolean;
    stateList: any;
    isGettingStateList: boolean;
    isSavingPreferences: boolean;
    isError: boolean;
    isSavePrefError: boolean;
    savePref: any;
    getPref: any;
    isGettingPref: boolean;
    isGettingAddressError: boolean;
    isGettingAddress: boolean;
    availableAddresses: any;
    getReplenishment: any;
    isGettingReplenishment: boolean;
    isGetReplenishmentError: boolean;
    isGetReplenishmentSuccess: boolean;
    saveReplenishment: any;
    isSavingReplenishment: boolean;
    isSaveReplenishmentError: boolean;
    paymentPreferenceList: any;
    isLoadingPaymentPreferences: boolean;
    isPaymentPrefError: boolean;
    isPaymentPrefSuccess: boolean;
    replenishRadioAmts: any;
    isGettingRadioAmts: boolean;
    replenishRadioLookupError: boolean;
    getProductPreferences: any;
    isGetttingProductPreferences: boolean;
    isGetProductPrefError: boolean;
    isGetProductPrefSuccess: boolean;
    saveProductPreferences: {};
    savingProductPreferences: boolean;
    isSavingProductPrefError: boolean;
    isSavingProductPrefSuccess: boolean;
}

export interface GetMyPreferenceRecords {
    type: any;
    payload: any;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = GetMyPreferenceRecords;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestAPI: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: MyPrefPageActions.SHOULD_DISABLE_SAVE_PREF_BTN,
            payload: "",
        });
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultProps: MyPreferencePageState = {
    shouldDisableSavePrefBtn: true,
    stateList: [],
    isGettingStateList: false,
    isError: false,
    isSavePrefError: false,
    isSavingPreferences: false,
    savePref: {},
    getPref: {},
    isGettingPref: false,
    isGettingAddressError: false,
    isGettingAddress: false,
    availableAddresses: [],
    getReplenishment: {},
    isGettingReplenishment: false,
    isGetReplenishmentError: false,
    isGetReplenishmentSuccess: false,
    saveReplenishment: {},
    isSavingReplenishment: false,
    isSaveReplenishmentError: false,
    paymentPreferenceList: {},
    isLoadingPaymentPreferences: false,
    isPaymentPrefError: false,
    isPaymentPrefSuccess: false,
    replenishRadioAmts: {},
    isGettingRadioAmts: false,
    replenishRadioLookupError: false,
    getProductPreferences: {},
    isGetttingProductPreferences: false,
    isGetProductPrefError: false,
    isGetProductPrefSuccess: false,
    saveProductPreferences: {},
    savingProductPreferences: false,
    isSavingProductPrefError: false,
    isSavingProductPrefSuccess: false,
};

export const reducer: Reducer<MyPreferencePageState> = (
    state: MyPreferencePageState = defaultProps,
    incomingAction: Action
) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case MyPrefPageActions.SHOULD_DISABLE_SAVE_PREF_BTN:
            return {
                ...state,
                shouldDisableSavePrefBtn: action.payload,
            };

        case MyPrefPageActions.GET_STATE_LIST:
            return {
                ...state,
                stateList: action.payload,
                isGettingStateList: true,
                isError: false,
            };
        case MyPrefPageActions.GET_STATE_LIST_FAILURE:
            return {
                ...state,
                stateList: action.payload,
                isGettingStateList: false,
                isError: true,
            };
        case MyPrefPageActions.GET_STATE_LIST_SUCCESS:
            return {
                ...state,
                stateList: action.payload,
                isGettingStateList: false,
                isError: false,
            };
        case MyPrefPageActions.SAVE_PREFERENCE:
            return {
                ...state,
                savePref: action.payload,
                isSavingPreferences: true,
                isSavePrefError: false,
            };
        case MyPrefPageActions.SAVE_PREFERENCE_FAILURE:
            return {
                ...state,
                savePref: action.payload,
                isSavingPreferences: false,
                isSavePrefError: true,
            };
        case MyPrefPageActions.SAVE_PREFERENCE_SUCCESS:
            return {
                ...state,
                savePref: action.payload,
                isSavingPreferences: false,
                isSavePrefError: false,
            };
        case MyPrefPageActions.GET_PREFERENCE:
            return {
                ...state,
                getPref: null,
                isGettingPref: true,
                isError: false,
            };
        case MyPrefPageActions.GET_PREFERENCE_FAILURE:
            return {
                ...state,
                getPref: action.payload,
                isGettingPref: false,
                isError: true,
            };
        case MyPrefPageActions.GET_PREFERENCE_SUCCESS:
            return {
                ...state,
                getPref: action.payload,
                isGettingPref: false,
                isError: false,
            };
        case MyPrefPageActions.GET_AVAILABLE_ADDRESSES:
            return {
                ...state,
                availableAddresses: action.payload,
                isGettingAddress: true,
                isGettingAddressError: false,
            };
        case MyPrefPageActions.GET_AVAILABLE_ADDRESSES_FAILURE:
            return {
                ...state,
                availableAddresses: action.payload,
                isGettingAddress: false,
                isGettingAddressError: true,
            };
        case MyPrefPageActions.GET_AVAILABLE_ADDRESSES_SUCCESS:
            return {
                ...state,
                availableAddresses: action.payload,
                isGettingAddress: false,
                isGettingAddressError: false,
            };
        case MyPrefPageActions.GET_REPLENISHMENT:
            return {
                ...state,
                getReplenishment: {},
                isGettingReplenishment: true,
                isGetReplenishmentError: false,
                isGetReplenishmentSuccess: false,
            };
        case MyPrefPageActions.GET_REPLENISHMENT_FAILURE:
            return {
                ...state,
                getReplenishment: action.payload,
                isGettingReplenishment: false,
                isGetReplenishmentError: true,
                isGetReplenishmentSuccess: false,
            };
        case MyPrefPageActions.GET_REPLENISHMENT_SUCCESS:
            return {
                ...state,
                getReplenishment: action.payload,
                isGettingReplenishment: false,
                isGetReplenishmentSuccess: true,
                isGetReplenishmentError: false,
            };
        case MyPrefPageActions.RESET_REPLENISHMENT:
            return {
                ...state,
                getReplenishment: {},
                isGettingReplenishment: false,
                isGetReplenishmentSuccess: false,
                isGetReplenishmentError: false,
            };
        case MyPrefPageActions.SAVE_REPLENISHMENT:
            return {
                ...state,
                saveReplenishment: action.payload,
                isSavingReplenishment: true,
                isSaveReplenishmentError: false,
            };
        case MyPrefPageActions.SAVE_REPLENISHMENT_FAILURE:
            return {
                ...state,
                saveReplenishment: action.payload,
                isSavingReplenishment: false,
                isSaveReplenishmentError: true,
            };
        case MyPrefPageActions.SAVE_REPLENISHMENT_SUCCESS:
            return {
                ...state,
                saveReplenishment: action.payload,
                isSavingReplenishment: false,
                isSaveReplenishmentError: false,
                getReplenishment: {},
            };
        case MyPrefPageActions.GET_PAYMENT_PREFERENCE_TYPES:
            return {
                ...state,
                paymentPreferenceList: {},
                isLoadingPaymentPreferences: true,
                isPaymentPrefSuccess: false,
                isPaymentPrefError: false,
            };
        case MyPrefPageActions.GET_PAYMENT_PREFERENCE_TYPES_FAILURE:
            return {
                ...state,
                paymentPreferenceList: action.payload,
                isLoadingPaymentPreferences: false,
                isPaymentPrefError: true,
                isPaymentPrefSuccess: false,
            };
        case MyPrefPageActions.GET_PAYMENT_PREFERENCE_TYPES_SUCCESS:
            return {
                ...state,
                paymentPreferenceList: action.payload,
                isLoadingPaymentPreferences: false,
                isPaymentPrefSuccess: true,
                isPaymentPrefError: false,
            };
        case MyPrefPageActions.GET_REPLENISHMENT_RADIO_AMOUNTS:
            return {
                ...state,
                replenishRadioAmts: {},
                isGettingRadioAmts: true,
                replenishRadioLookupError: false,
            };
        case MyPrefPageActions.GET_REPLENISHMENT_RADIO_AMOUNTS_FAILURE:
            return {
                ...state,
                replenishRadioAmts: action.payload,
                isGettingRadioAmts: false,
                replenishRadioLookupError: true,
            };
        case MyPrefPageActions.GET_REPLENISHMENT_RADIO_AMOUNTS_SUCCESS:
            return {
                ...state,
                replenishRadioAmts: action.payload,
                isGettingRadioAmts: false,
                replenishRadioLookupError: false,
            };
        case MyPrefPageActions.GET_PRODUCT_PREFERENCES:
            return {
                ...state,
                getProductPreferences: {},
                isGetttingProductPreferences: true,
                isGetProductPrefError: false,
                isGetProductPrefSuccess: false,
            };
        case MyPrefPageActions.GET_PRODUCT_PREFERENCE_SUCCESS:
            return {
                ...state,
                getProductPreferences: action.payload,
                isGetttingProductPreferences: false,
                isGetProductPrefError: false,
                isGetProductPrefSuccess: true,
            };
        case MyPrefPageActions.GET_PRODUCT_PREFERENCE_FAILURE:
            return {
                ...state,
                getProductPreferences: action.payload,
                isGetttingProductPreferences: false,
                isGetProductPrefError: true,
                isGetProductPrefSuccess: false,
            };
        case MyPrefPageActions.RESET_PRODUCT_PREFERENCES:
            return {
                ...state,
                getProductPreferences: {},
                isGetttingProductPreferences: false,
                isGetProductPrefError: false,
                isGetProductPrefSuccess: false,
                saveProductPreferences: {},
                savingProductPreferences: false,
                isSavingProductPrefError: false,
                isSavingProductPrefSuccess: false,
            };
        case MyPrefPageActions.SAVE_PRODUCT_PREFERENCES:
            return {
                ...state,
                saveProductPreferences: action.payload,
                savingProductPreferences: true,
                isSavingProductPrefError: false,
                isSavingProductPrefSuccess: false,
            };
        case MyPrefPageActions.SAVE_PRODUCT_PREFERENCE_SUCCESS:
            return {
                ...state,
                saveProductPreferences: action.payload,
                savingProductPreferences: false,
                isSavingProductPrefError: false,
                isSavingProductPrefSuccess: true,
            };
        case MyPrefPageActions.SAVE_PRODUCT_PREFERENCE_FAILURE:
            return {
                ...state,
                saveProductPreferences: action.payload,
                savingProductPreferences: false,
                isSavingProductPrefError: true,
                isSavingProductPrefSuccess: false,
            };
        default:
    }

    return state || defaultProps;
};
