import * as React from "react";
import { connect } from "react-redux";
import {  withRouter } from "react-router-dom";
import {  termsAndConditionUrl, privacyPolicyUrl } from "../../../envConfig";

import Credits from "../Header/Credits";

// Cdnpath to import images and report
// const Pdf = cdnPath + "/report.pdf";

interface FooterGroup {
    isCollapseOpen: boolean;
}
interface FooterState {

    fixedBlock: any;

}

export class Footer extends React.Component<any, FooterState> {
    private defaultFooterState: FooterState = {
        fixedBlock: 'unset',
    };
    constructor(props) {
        super(props);

        this.state = this.defaultFooterState;
    }

    componentDidMount() {
        this.setFloatingButton.bind(this);
        window.scrollTo(0, 0);
        window.addEventListener("scroll", this.handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    // Function to determine the footer position to set the Replenish Button footer sticky on scroll
    setFloatingButton = () => {
        const footerField = document.getElementById('footer-block');
        const footerFieldOffset = footerField.getBoundingClientRect();
        if (footerFieldOffset.top < window.innerHeight && footerFieldOffset.bottom >= 0) {
            this.setState({
                fixedBlock: 'unset'
            })
        } else {
            this.setState({
                fixedBlock: 'fixed'
            });
        }
    }
    // Funciton to keep Replenish Button footer sticky on scroll
    handleScroll = () => {
        const footerTag = document.getElementById('footer-block');
        const footerPosition = footerTag.getBoundingClientRect();
        if (footerPosition.top < window.innerHeight && footerPosition.bottom >= 0) {
            this.setState({
                fixedBlock: 'unset'
            })
        } else {
            if (window.pageYOffset > footerPosition.top) {
                this.setState({
                    fixedBlock: 'unset'
                })
            } else {
                this.setState({
                    fixedBlock: 'fixed'
                })
            }
        }
    }


    onScroll = () => {
        window.scrollTo(0, 0);
    };
    render() {
        const { guestUser } = this.props;
        const copyRightYear = new Date().getFullYear();
        // To remove the margin top of buyCredit fix footer in concierge pages
        const pathName = window.location.pathname;
        let marginTop = '8px';
        if (
            pathName === "/concierge/customers" ||
            pathName === "/concierge/customer-details" ||
            pathName === "/concierge/promocodes" ||
            pathName === "/concierge/roofreports" ||
            pathName === "/concierge/operationalReports"
        ) {
            marginTop = '0px';
        } else {
            // marginTop = '8px';
            marginTop = '0px';
        }

        return (
            <React.Fragment>
                {guestUser !== true &&
                    <div
                        className="fixedBlock d-block bg-light p-2 d-md-none border d-flex justify-content-center"
                        style={{ position: this.state.fixedBlock, marginTop: marginTop }}
                    >
                        <Credits />
                    </div>
                }

                <footer id="footer-block">

                    {/* {guestUser !== true ?
                        <div className="copy-right DislcaimerWhite2">
                            © Copyright 2020 GAF
                        </div>
                        : */}
                    <div className={`${guestUser !== true ? 'gafUserFooter' : 'copy-right-bar'}`}>
                        <div className="row">
                            <div className={`${guestUser !== true ? "col-12" : "col-lg-6 col-12"} DislcaimerWhite2 gafUserLinks `}>
                                <div className=" copy-right-guest">
                                    <p className="mb-0">© Copyright {copyRightYear} GAF</p>
                                </div>
                                <div>
                                    <ul>
                                        <li className="" >
                                            <a
                                                href={termsAndConditionUrl}
                                                target="_blank"
                                                rel="noopener"
                                            >
                                                Terms of Use
                                            </a>
                                        </li>
                                        <li className="" >
                                            <a
                                                href={privacyPolicyUrl}
                                                target="_blank"
                                                rel="noopener"
                                            >

                                                Privacy Policy
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {guestUser !== true ? "" :
                                <div className="col-lg-6 col-12 DislcaimerWhite2 cookie-div">
                                    <ul>

                                        <li className="optanon-toggle-display" >Cookie Preferences</li>
                                        <li>
                                            <a href="https://www.gaf.com/en-us/dsar" target="_blank">Access or Delete My Personal Infromation</a>
                                        </li>
                                        <li>
                                            <a href="https://www.gaf.com/en-us/dsar" target="_blank">Do not sell my personal information</a>
                                        </li>

                                    </ul>
                                </div>
                            }

                        </div>
                    </div>
                </footer>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    const { guestUser } = state.HomePage;
    return { guestUser };
};


export default withRouter(
    connect(mapStateToProps)(Footer) as React.ComponentType<any>);


// export default withRouter(
//     connect(mapStateToProps, mapDispatchToProps)(Header) as React.ComponentType<
//         any
//     >
// );
