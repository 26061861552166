import { Guid } from 'guid-typescript';

class TrackingId{
    id: Guid
    constructor(){
        this.id = Guid.create();
    }
    updateTrackingId = (id?: Guid) => {
        if (id) {
            this.id = id;
        } else this.id = Guid.create();
    }
}

const AppTracking = new TrackingId();

export default AppTracking; 