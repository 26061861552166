import * as PROMO_CODE_ACTIONS from "./actionTypes";

export function getMaxPromoCodeAmt(data): any {
    return {
        type: PROMO_CODE_ACTIONS.GET_MAX_PROMOCODE_AMT,
        payload: data,
    };
}

export function getMaxPromoCodeAmtSuccess(data): any {
    return {
        type: PROMO_CODE_ACTIONS.GET_MAX_PROMOCODE_AMT_SUCCESS,
        payload: data,
    };
}

export function getMaxPromoCodeAmtFailure(err): any {
    return {
        type: PROMO_CODE_ACTIONS.GET_MAX_PROMOCODE_AMT_FAILURE,
        payload: err,
    };
}
