import { string } from "prop-types";
import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import * as SERVICE_BUNDLE_ACTION from "../containers/concierge/ServiceBundles/actionTypes";

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
export interface ServiceBundleState {
    SelectedServiceBundle: any;
    isAddNewServiceBundleClicked: boolean;
    products: any;
    isGettingProducts: boolean;
    saveBundle: any;
    isSavingBundle: boolean;
    isError: boolean;
    saveProductServices: any;
    isSavingProductServices: boolean;
}

export interface ServiceBundleAdmin {
    actData: any;
    type: any;
    payload: any;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = ServiceBundleAdmin;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestAPI: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            actData: "",
            type: SERVICE_BUNDLE_ACTION.SELECTED_BUNDLE,
            payload: "",
        });
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultProps: ServiceBundleState = {
    SelectedServiceBundle: {},
    isAddNewServiceBundleClicked: false,
    products: [],
    isGettingProducts: false,
    saveBundle: [],
    isSavingBundle: false,
    isError: false,
    saveProductServices: {},
    isSavingProductServices: false,
};

export const reducer: Reducer<ServiceBundleState> = (
    state: ServiceBundleState = defaultProps,
    incomingAction: Action
) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case SERVICE_BUNDLE_ACTION.SELECTED_BUNDLE:
            return {
                ...state,
                SelectedServiceBundle: action.payload,
            };
        case SERVICE_BUNDLE_ACTION.ADD_NEW_BUNDLE:
            return {
                ...state,
                isAddNewServiceBundleClicked: action.payload,
            };
        case SERVICE_BUNDLE_ACTION.GET_PRODUCTS:
            return {
                ...state,
                products: action.payload,
                isGettingProducts: true,
                isError: false,
            };
        case SERVICE_BUNDLE_ACTION.GET_PRODUCTS_FAILURE:
            return {
                ...state,
                products: action.payload,
                isGettingProducts: false,
                isError: true,
            };
        case SERVICE_BUNDLE_ACTION.GET_PRODUCTS_SUCCESS:
            return {
                ...state,
                products: action.payload,
                isGettingProducts: false,
                isError: false,
            };
        case SERVICE_BUNDLE_ACTION.ADD_EDIT_BUNDLE:
            return {
                ...state,
                saveBundle: action.payload,
                isSavingBundle: true,
                isError: false,
            };
        case SERVICE_BUNDLE_ACTION.ADD_EDIT_BUNDLE_FAILURE:
            return {
                ...state,
                saveBundle: action.payload,
                isSavingBundle: false,
                isError: true,
            };
        case SERVICE_BUNDLE_ACTION.ADD_EDIT_BUNDLE_SUCCESS:
            return {
                ...state,
                saveBundle: action.payload,
                isSavingBundle: false,
                isError: false,
            };
        case SERVICE_BUNDLE_ACTION.ADD_UPDATE_PRODUCT_SERVICES:
            return {
                ...state,
                saveProductServices: action.payload,
                isSavingProductServices: true,
                isError: false,
            };
        case SERVICE_BUNDLE_ACTION.ADD_UPDATE_PRODUCT_SERVICES_FAILURE:
            return {
                ...state,
                saveProductServices: action.payload,
                isSavingProductServices: false,
                isError: true,
            };
        case SERVICE_BUNDLE_ACTION.ADD_UPDATE_PRODUCT_SERVICES_SUCCESS:
            return {
                ...state,
                saveProductServices: action.payload,
                isSavingProductServices: false,
                isError: false,
            };
        // case SERVICE_BUNDLE_ACTION.RESET_ADD_EDIT_BUNDLE:
        //     return {
        //         ...state,
        //         saveBundle: [],
        //         isSavingBundle: false,
        //         isError: false,
        //     };
        default:
        // The following line guarantees that every action in the KnownAction union has been covered by a case above
        // const exhaustiveCheck: never = action;
    }

    return state || defaultProps;
};
