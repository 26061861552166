export const SELECTED_BUNDLE = "SELECTED_BUNDLE";
export type SELECTED_BUNDLE = typeof SELECTED_BUNDLE;

export const ADD_NEW_BUNDLE = "ADD_NEW_BUNDLE";
export type ADD_NEW_BUNDLE = typeof ADD_NEW_BUNDLE;

export const GET_PRODUCTS = "GET_PRODUCTS";
export type GET_PRODUCTS = typeof GET_PRODUCTS;

export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export type GET_PRODUCTS_SUCCESS = typeof GET_PRODUCTS_SUCCESS;

export const GET_PRODUCTS_FAILURE = "GET_PRODUCTS_FAILURE";
export type GET_PRODUCTS_FAILURE = typeof GET_PRODUCTS_FAILURE;

export const ADD_EDIT_BUNDLE = "ADD_EDIT_BUNDLE";
export type ADD_EDIT_BUNDLE = typeof ADD_EDIT_BUNDLE;

export const ADD_EDIT_BUNDLE_SUCCESS = "ADD_EDIT_BUNDLE_SUCCESS";
export type ADD_EDIT_BUNDLE_SUCCESS = typeof ADD_EDIT_BUNDLE_SUCCESS;

export const ADD_EDIT_BUNDLE_FAILURE = "ADD_EDIT_BUNDLE_FAILURE";
export type ADD_EDIT_BUNDLE_FAILURE = typeof ADD_EDIT_BUNDLE_FAILURE;


export const ADD_UPDATE_PRODUCT_SERVICES = "ADD_UPDATE_PRODUCT_SERVICES";
export type ADD_UPDATE_PRODUCT_SERVICES = typeof ADD_UPDATE_PRODUCT_SERVICES;

export const ADD_UPDATE_PRODUCT_SERVICES_SUCCESS = "ADD_UPDATE_PRODUCT_SERVICES_SUCCESS";
export type ADD_UPDATE_PRODUCT_SERVICES_SUCCESS = typeof ADD_UPDATE_PRODUCT_SERVICES_SUCCESS;

export const ADD_UPDATE_PRODUCT_SERVICES_FAILURE = "ADD_UPDATE_PRODUCT_SERVICES_FAILURE";
export type ADD_UPDATE_PRODUCT_SERVICES_FAILURE = typeof ADD_UPDATE_PRODUCT_SERVICES_FAILURE;

