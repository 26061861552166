import * as MY_PREF_ACTIONS from "./actionTypes";

export function shouldDsiableSaveBtn(data): any {
    return {
        type: MY_PREF_ACTIONS.SHOULD_DISABLE_SAVE_PREF_BTN,
        payload: data,
    };
}

export function getStateListFromLookUp(data): any {
    return {
        type: MY_PREF_ACTIONS.GET_STATE_LIST,
        payload: data,
    };
}

export function getStateListFromLookUpSuccess(data): any {
    return {
        type: MY_PREF_ACTIONS.GET_STATE_LIST_SUCCESS,
        payload: data,
    };
}

export function getStateListFromLookUpFailure(err): any {
    return {
        type: MY_PREF_ACTIONS.GET_STATE_LIST_FAILURE,
        payload: err,
    };
}

export function savePreferences(data): any {
    // console.log(data);
    return {
        type: MY_PREF_ACTIONS.SAVE_PREFERENCE,
        payload: data,
    };
}

export function savePreferencesSuccess(data): any {
    return {
        type: MY_PREF_ACTIONS.SAVE_PREFERENCE_SUCCESS,
        payload: data,
    };
}

export function savePreferencesFailure(err): any {
    return {
        type: MY_PREF_ACTIONS.SAVE_PREFERENCE_FAILURE,
        payload: err,
    };
}

export function getPreferences(data): any {
    // console.log(data);
    return {
        type: MY_PREF_ACTIONS.GET_PREFERENCE,
        payload: data,
    };
}

export function getPreferencesSuccess(data): any {
    return {
        type: MY_PREF_ACTIONS.GET_PREFERENCE_SUCCESS,
        payload: data,
    };
}

export function getPreferencesFailure(err): any {
    return {
        type: MY_PREF_ACTIONS.GET_PREFERENCE_FAILURE,
        payload: err,
    };
}

export function getAvailableAddresses(data): any {
    // console.log(data);
    return {
        type: MY_PREF_ACTIONS.GET_AVAILABLE_ADDRESSES,
        payload: data,
    };
}

export function getAvailableAddressesSuccess(data): any {
    return {
        type: MY_PREF_ACTIONS.GET_AVAILABLE_ADDRESSES_SUCCESS,
        payload: data,
    };
}

export function getAvailableAddressesFailure(err): any {
    return {
        type: MY_PREF_ACTIONS.GET_AVAILABLE_ADDRESSES_FAILURE,
        payload: err,
    };
}
// Repelnish Tab
export function getReplenishmentData(data): any {
    return {
        type: MY_PREF_ACTIONS.GET_REPLENISHMENT,
        payload: data,
    };
}

export function getReplenishmentSuccess(data): any {
    return {
        type: MY_PREF_ACTIONS.GET_REPLENISHMENT_SUCCESS,
        payload: data,
    };
}

export function getReplenishmentFailure(err): any {
    return {
        type: MY_PREF_ACTIONS.GET_REPLENISHMENT_FAILURE,
        payload: err,
    };
}

export function resetReplenishmentData(data): any {
    return {
        type: MY_PREF_ACTIONS.RESET_REPLENISHMENT,
        payload: data,
    };
}

export function saveReplenishmentData(data): any {
    return {
        type: MY_PREF_ACTIONS.SAVE_REPLENISHMENT,
        payload: data,
    };
}

export function saveReplenishmentDataSuccess(data): any {
    return {
        type: MY_PREF_ACTIONS.SAVE_REPLENISHMENT_SUCCESS,
        payload: data,
    };
}

export function saveReplenishmentDataFailure(err): any {
    return {
        type: MY_PREF_ACTIONS.SAVE_REPLENISHMENT_FAILURE,
        payload: err,
    };
}

export function getPaymentPreferences(data): any {
    return {
        type: MY_PREF_ACTIONS.GET_PAYMENT_PREFERENCE_TYPES,
        // payload: data,
    };
}

export function getPaymentPreferencesSuccess(data): any {
    return {
        type: MY_PREF_ACTIONS.GET_PAYMENT_PREFERENCE_TYPES_SUCCESS,
        payload: data,
    };
}

export function getPaymentPreferencesFailure(err): any {
    return {
        type: MY_PREF_ACTIONS.GET_PAYMENT_PREFERENCE_TYPES_FAILURE,
        payload: err,
    };
}

export function getReplenishRadioAmountsLookup(data): any {
    return {
        type: MY_PREF_ACTIONS.GET_REPLENISHMENT_RADIO_AMOUNTS,
    };
}

export function getReplenishRadioAmountsLookupSuccess(data): any {
    return {
        type: MY_PREF_ACTIONS.GET_REPLENISHMENT_RADIO_AMOUNTS_SUCCESS,
        payload: data,
    };
}

export function getReplenishRadioAmountsLookupFailure(err): any {
    return {
        type: MY_PREF_ACTIONS.GET_REPLENISHMENT_RADIO_AMOUNTS_FAILURE,
        payload: err,
    };
}
// Services Tab
export function getProductPreferences(data): any {
    // console.log(data);
    return {
        type: MY_PREF_ACTIONS.GET_PRODUCT_PREFERENCES,
        payload: data,
    };
}

export function getProductPreferencesSuccess(data): any {
    return {
        type: MY_PREF_ACTIONS.GET_PRODUCT_PREFERENCE_SUCCESS,
        payload: data,
    };
}

export function getProductPreferencesFailure(err): any {
    return {
        type: MY_PREF_ACTIONS.GET_PRODUCT_PREFERENCE_FAILURE,
        payload: err,
    };
}

export function resetProductPreferences() {
    return {
        type: MY_PREF_ACTIONS.RESET_PRODUCT_PREFERENCES,
    };
}

export function saveProductPreferences(data) {
    return {
        type: MY_PREF_ACTIONS.SAVE_PRODUCT_PREFERENCES,
        payload: data,
    };
}

export function saveProductPreferencesSuccess(data) {
    return {
        type: MY_PREF_ACTIONS.SAVE_PRODUCT_PREFERENCE_SUCCESS,
        payload: data,
    };
}

export function saveProductPreferencesFailure(err) {
    return {
        type: MY_PREF_ACTIONS.SAVE_PRODUCT_PREFERENCE_FAILURE,
        payload: err,
    };
}
