import * as React from "react";
import { GafOktaManager } from "../../auth/gaf-okta-auth";
import { Redirect } from "react-router-dom";
import ErrorModal from "../ErrorModal";

export class Callback extends React.Component {
    state = {
        authenticated: null,
        error: null,
        showErrorModal: false,
        errorCode: ""
    };

    constructor(props: any) {
        super(props);
    }

    componentDidMount() {

        GafOktaManager.handleAuthentication()
            .then((success) => {
                const user = GafOktaManager.storage.info.profile.email;
                window["AppInsightTrace"].trackTrace(`User ${user} authenticated - logged in.`);
                this.setState({ authenticated: true });
            })
            .catch((err) => {
                window["AppInsightTrace"].trackTrace(`Login fail - User  not authenticated.`);
                window["AppInsightTrace"].trackTrace(`Login fail for User , Error: ${err}.`);

                this.setState({ authenticated: false, error: err.toString() })
                this.setState({
                    showErrorModal: true,
                    errorCode: 'oktaProviderError'
                })
            });
    }
    hideErrorModal = () => {
        this.setState({ showErrorModal: false, errorCode: '' });
    }
    render() {
        if (this.state.authenticated === null) {
            return null; //<h1>this state authentication session is null</h1>;
        }

        if (this.state.authenticated) {
            return (
                <>
                    callback page
                    <Redirect to="/homeloggedin"></Redirect>
                </>
            );
        } else {
            return (
                // <h1>this state authentication session is {this.state.error}</h1>
                this.state.showErrorModal ?
                    (
                        <ErrorModal
                            errorCode={this.state.errorCode}
                            close={() => this.hideErrorModal()}
                            show={this.state.showErrorModal}
                            displayError={this.state.error}
                        />
                    ) :
                    <React.Fragment />
            );
        }
    }
}
