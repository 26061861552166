export const GET_ACT_TYPES = "GET_ACT_TYPES";
export type GET_ACT_TYPES = typeof GET_ACT_TYPES;

export const GET_ACT_TYPES_SUCCESS = "GET_ACT_TYPES_SUCCESS";
export type GET_ACT_TYPES_SUCCESS = typeof GET_ACT_TYPES_SUCCESS;

export const GET_ACT_TYPES_FAILURE = "GET_ACT_TYPES_FAILURE";
export type GET_ACT_TYPES_FAILURE = typeof GET_ACT_TYPES_FAILURE;

export const SELECTED_ACT = "SELECTED_ACT";
export type SELECTED_ACT = typeof SELECTED_ACT;

export const SAVE_ACT_TYPES = "SAVE_ACT_TYPES";
export type SAVE_ACT_TYPES = typeof SAVE_ACT_TYPES;

export const SAVE_ACT_TYPES_SUCCESS = "SAVE_ACT_TYPES_SUCCESS";
export type SAVE_ACT_TYPES_SUCCESS = typeof SAVE_ACT_TYPES_SUCCESS;

export const SAVE_ACT_TYPES_FAILURE = "SAVE_ACT_TYPES_FAILURE";
export type SAVE_ACT_TYPES_FAILURE = typeof SAVE_ACT_TYPES_FAILURE;

export const ADD_NEW_ACT = "ADD_NEW_ACT";
export type ADD_NEW_ACT = typeof ADD_NEW_ACT;
