module.exports = {
    logInPage: {
        name: 'LogIn Page',
        url: '/',
    },
    guestUser: {
        name: 'GuestUser HomePage',
        url: '/guest-home-page'
    },
    homePage: {
        name: 'HomePage',
        url: '/home-page',
    },
    trackOrder: {
        name: 'TrackOrder',
        url: '/track-order',
    },
    supportRequests: {
        name: 'SupportRequests',
        url: '/support-requests',
    },
    createAccount: {
        name: 'CreateAccount',
        url: '/create-account',
    },
    myOrderPage: {
        name: 'My orders',
        url: '/my-orders',
    },
    report3D: {
        name: 'Report 3D',
        url: '/report3D',
    },
    report3D_Contractor: {
        name: 'Report 3D Contractor',
        url: '/report3D/contractor',
    },
    report3D_PropertyOwner: {
        name: 'Report 3D PropertyOwner',
        url: '/report3D/propertyowner',
    },
    myReceiptPage: {
        name: 'My Receipts',
        url: '/my-receipts',
    },
    myPreferences: {
        name: 'My Preferences',
        url: '/my-preferences',
    },
    faqs: {
        name: 'FAQs',
        url: '/faqs',
    },
    coverageMap: {
        name: 'Coverage Map',
        url: '/coverage-map',
    },
    paymentSuccess: {
        name: 'Payment Success',
        url: '/paymentsuccess'
    },
    paymentError: {
        name: 'Payment Error',
        url: '/paymenterror'
    },
    paymentCancel: {
        name: 'Payment Cancel',
        url: '/paymentcancel'
    },
    siteMaintenance: {
        name: 'Site Maintenance',
        url: '/site-maintenance',
    },
    cutOverPage: {
        name: 'CutOver Page',
        url: '/cutover',
    },
    pageNotFound: {
        name: 'Page Not Found',
        url: '/page-not-found'
    },
    downLoadReport: {
        name: 'Download Report',
        url: '/roofmeasure/download'
    },
    createAct: {
        name: 'Create Account',
        url: '/create-account',
    },


    // For Concierge
    notAuthorized: {
        name: 'Not Authorized',
        url: '/concierge/not-authorized'
    },
    customers: {
        name: 'Customers',
        url: '/concierge/customers',
    },
    customerDetails: {
        name: 'Customer Details',
        url: '/concierge/customer-details',
    },
    promocodes: {
        name: 'PromoCodes',
        url: '/concierge/promocodes',
    },
    roofreports: {
        name: 'Roof Reports',
        url: '/concierge/roofreports',
    },
    subscriberAdministration: {
        name: 'VAR Administration',
        url: '/concierge/subscriberAdministration',
    },
    addEditSubscriber: {
        name: 'Add/Edit VAR',
        url: '/concierge/addEditSubscriber',
    },
    reports: {
        name: 'Reports',
        url: '/concierge/operationalReports',
    },
    applicationAdmin: {
        name: 'Application Admin',
        url: '/concierge/application-admin'
    },
    accountTypeAdministration: {
        name: 'Account Type Administration',
        url: '/concierge/accountTypeAdministration'
    },
    addEditAccountType: {
        name: 'Add Edit AccountType',
        url: '/concierge/addEditAccountType'
    },
    services: {
        name: 'Services',
        url: '/concierge/services',
    },
    addEditService: {
        name: 'Add Edit Service',
        url: '/concierge/addEditService'
    },
    serviceBundles: {
        name: 'Service Bundles',
        url: '/concierge/serviceBundles'
    },
    addEditServiceBundles: {
        name: 'Add Edit Service Bundles',
        url: '/concierge/addEditServiceBundles'

    },
    reSubmit: {
        name: 'Resubmit',
        url: '/concierge/reSubmit'
    },
    cacheAdministration: {
        name: 'Cache Administration',
        url: '/concierge/cacheAdministration'
    },
    // Vendor order pages
    vendorHomePage: {
        name: 'Vendor HomePage',
        url: '/thd',
    }
};
