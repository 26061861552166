export const GET_CUSTOMERS_SEARCH_RECORDS = "GET_CUSTOMERS_SEARCH_RECORDS";
export type GET_CUSTOMERS_SEARCH_RECORDS = typeof GET_CUSTOMERS_SEARCH_RECORDS;

export const SEND_DATA_TO_CUSTOMER_DETAIL_PAGE =
    "SEND_DATA_TO_CUSTOMER_DETAIL_PAGE";
export type SEND_DATA_TO_CUSTOMER_DETAIL_PAGE = typeof SEND_DATA_TO_CUSTOMER_DETAIL_PAGE;

export const SEARCH_TEXT = "SEARCH_TEXT";
export type SEARCH_TEXT = typeof SEARCH_TEXT;

export const PAGE_NO = "PAGE_NO";
export type PAGE_NO = typeof PAGE_NO;

export const HAS_RENEW_TOKEN = "HAS_RENEW_TOKEN";
export type HAS_RENEW_TOKEN = typeof HAS_RENEW_TOKEN;

export const IS_USER_PROFILE_REFRESHED = "IS_USER_PROFILE_REFRESHED";
export type IS_USER_PROFILE_REFRESHED = typeof IS_USER_PROFILE_REFRESHED;