export const GET_RE_PROCESS_ORDERS = "GET_RE_PROCESS_ORDERS";
export type GET_RE_PROCESS_ORDERS = typeof GET_RE_PROCESS_ORDERS;

export const GET_RE_PROCESS_ORDERS_SUCCESS = "GET_RE_PROCESS_ORDERS_SUCCESS";
export type GET_RE_PROCESS_ORDERS_SUCCESS = typeof GET_RE_PROCESS_ORDERS_SUCCESS;

export const GET_RE_PROCESS_ORDERS_FAILURE = "GET_RE_PROCESS_ORDERS_FAILURE";
export type GET_RE_PROCESS_ORDERS_FAILURE = typeof GET_RE_PROCESS_ORDERS_FAILURE;

export const RE_PROCESS_ORDERS = "RE_PROCESS_ORDERS";
export type RE_PROCESS_ORDERS = typeof RE_PROCESS_ORDERS;

export const RE_PROCESS_ORDERS_SUCCESS = "RE_PROCESS_ORDERS_SUCCESS";
export type RE_PROCESS_ORDERS_SUCCESS = typeof RE_PROCESS_ORDERS_SUCCESS;

export const RE_PROCESS_ORDERS_FAILURE = "RE_PROCESS_ORDERS_FAILURE";
export type RE_PROCESS_ORDERS_FAILURE = typeof RE_PROCESS_ORDERS_FAILURE;
