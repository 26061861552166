import { put, takeLatest, takeEvery, call } from "redux-saga/effects";

import { baseUrl } from "../../../envConfig";
import {
    doGetRequest,
    doPostRequest,
    postRequest,
    getRequest, postBPFileUpload, postGuestBPFileUploadRequest
} from "../../utils/utils";

import * as homeaPageActionCreators from "./actionCreater";
import * as HOMEPAGEACTIONS from "./actionTypes";
import { GafOktaManager } from "../../auth/gaf-okta-auth";

export function* placeOrder(action) {
    const userEmail = atob(action.payload.emailAddress);
    const endpointUrl = baseUrl + "/orders/placeorder";
    try {
        const response = yield doPostRequest(
            endpointUrl,
            JSON.stringify(action.payload)
        );
        window["AppInsightTrace"].trackTrace(
            `Order Report page - Place Order request Payload for user ${userEmail}: ` +
                JSON.stringify(action.payload)
        );

        if (response) {
            window["AppInsightTrace"].trackTrace(
                `Order Report page - Place Order request for user ${userEmail} - successs: ` +
                    JSON.stringify(response)
            );

            yield put(homeaPageActionCreators.placeOrderSuccess(response));
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `Order Report page - Place Order request Failed for user ${userEmail}: ` +
                JSON.stringify(err)
        );
        yield put(homeaPageActionCreators.placeOrderFailure(err));
    }
}
const mapUserProfile = (res) => {
    let profilePrices = [];
    res.result[0].profile.profileProducts.forEach((profile) => {
        profilePrices.push(profile.price);
    });

    return {
        // availableCredits: res.result[0].availableCredits,
        availableBalance: res.result[0].availableBalance,
        emailAddress: res.result[0].emailAddress,
        recipientEmailAddresses: res.result[0].recipientEmailAddresses,
        parentUserID: res.result[0].parentUserID,
        parentEmailAddress: res.result[0].parentEmailAddress,
        price: profilePrices,
        profileProducts: res.result[0].profile.profileProducts,
        productPreferences: res.result[0].productPreferences,
        profileProductDiscounts: res.result[0].profile.profileProductDiscounts,
        displayGAFCertification: res.result[0].profile.displayGAFCertification,
        profileType: res.result[0].profile.profileType,
        profileName: res.result[0].profile.profileName,
        associatedGAFCertification:
            res.result[0].profile.associatedGAFCertification,
        companyName: res.result[0].gafCustomerName,
        userFirstName: res.result[0].firstName,
        userLastName: res.result[0].lastName,
        gafCustomerID: res.result[0].gafCustomerID,
        activeNotification: res.result[0].activeNotification,
        generalTAndCAccepted: res.result[0].generalTAndCAccepted,
        tAndAccepted: res.result[0].tAndAccepted,
    };
    // return userProfileData;
};

export function* getUserProfileInfo(action) {
    const endpointUrl = baseUrl + "/user/userprofile";
    try {
        const response = yield doPostRequest(
            endpointUrl,
            JSON.stringify(action.payload)
        );
        window["AppInsightTrace"].trackTrace(
            `User Profile - Payload: ` + JSON.stringify(action.payload)
        );

        if (response) {
            window["AppInsightTrace"].trackTrace(
                `User Profile - successs: ` + JSON.stringify(response)
            );

            yield put(
                homeaPageActionCreators.getUserProfileInfoSuccess(
                    mapUserProfile(response)
                )
            );
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `User Profile Failed: ` + JSON.stringify(err)
        );
        yield put(homeaPageActionCreators.getUserProfileInfoFailure(err));
    }
}

export function* setNotification(action) {
    const endpointUrl = baseUrl + "/user/SetNotificaitonLog";
    try {
        const response = yield doPostRequest(
            endpointUrl,
            JSON.stringify(action.payload)
        );
        window["AppInsightTrace"].trackTrace(
            `Set Notification- Cover Page Reminder - Payload: ` +
                JSON.stringify(action.payload)
        );

        if (response) {
            window["AppInsightTrace"].trackTrace(
                `Set Notification- Cover Page Reminder - successs: ` +
                    JSON.stringify(response)
            );

            yield put(
                homeaPageActionCreators.setNotificationsSuccess(response)
            );
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `Set Notification- Cover Page Reminder Failed: ` +
                JSON.stringify(err)
        );
        yield put(homeaPageActionCreators.setNotificationsFailure(err));
    }
}

export function* checkCoverage(action) {
    const endpointUrl = baseUrl + "/coverage/check";
    try {
        const response = yield doPostRequest(
            endpointUrl,
            JSON.stringify(action.payload)
        );
        window["AppInsightTrace"].trackTrace(
            `Check Coverage Payload: ` + JSON.stringify(action.payload)
        );

        if (response) {
            window["AppInsightTrace"].trackTrace(
                `Check Coverage - successs: ` + JSON.stringify(response)
            );

            yield put(homeaPageActionCreators.checkCoverageSuccess(response));
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `Check Coverage Failed: ` + JSON.stringify(err)
        );
        yield put(homeaPageActionCreators.checkCoverageFailure(err));
    }
}

export function* checkCoverageGuest(action) {
    const endpointUrl = baseUrl + "/Guest/coverage/check";
    try {
        const response = yield postRequest(
            endpointUrl,
            JSON.stringify(action.payload)
        );

        window["AppInsightTrace"].trackTrace(
            `Guest Check Coverage Payload: ` + JSON.stringify(action.payload)
        );

        if (response) {
            window["AppInsightTrace"].trackTrace(
                `Guest Check Coverage - successs: ` + JSON.stringify(response)
            );

            yield put(homeaPageActionCreators.checkCoverageSuccess(response));
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `Guest Check Coverage Failed: ` + JSON.stringify(err)
        );
        yield put(homeaPageActionCreators.checkCoverageFailure(err));
    }
}

export function* setTermsAndCondition(action) {
    const endpointUrl = baseUrl + "/user/acceptTermsAndConditions";
    try {
        const response = yield doPostRequest(
            endpointUrl,
            JSON.stringify(action.payload)
        );
        window["AppInsightTrace"].trackTrace(
            `Update Terms and Condition Payload: ` +
                JSON.stringify(action.payload)
        );

        if (response) {
            window["AppInsightTrace"].trackTrace(
                `Update Terms and Condition - successs: ` +
                    JSON.stringify(response)
            );

            yield put(homeaPageActionCreators.updateTAndCSuccess(response));
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `Update Terms and Condition Failed: ` + JSON.stringify(err)
        );
        yield put(homeaPageActionCreators.updateTAndCFailure(err));
    }
}

export function* getTandCLookupData() {
    const currentUrl = window.location.href;
    let isGuest = currentUrl && (currentUrl.includes("guest") === true || currentUrl.includes("create-account") === true);
    
    try {
        let response;
        if (!isGuest) {
            const endpointUrl =
                baseUrl + `/lookup?lookupName=Legal_Terms_And_Conditions`;
            response = yield doGetRequest(endpointUrl, null);
        } else {
            // For Guest User
            const endpointUrl = baseUrl + `/Guest/GetTermsnConditions`;
            response = yield getRequest(endpointUrl, null);
        }
        window["AppInsightTrace"].trackTrace(
            `Lookup for Terms and Condition links - Place order Page: `
        );
        if (response) {
            window["AppInsightTrace"].trackTrace(
                `Lookup for Terms and Condition links - Place order Page: Success: ` +
                    JSON.stringify(response)
            );
            yield put(
                homeaPageActionCreators.getTandCllookupDataSuccess(response)
            );
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `Lookup for Terms and Condition links - Place order Page:Failed: ` +
                JSON.stringify(err)
        );

        yield put(homeaPageActionCreators.getTandCllookupDataFailure(err));
    }
}

export function* getScopeConnectQuestions() {
    const currentUrl = window.location.href;
    let isGuest = currentUrl && (currentUrl.includes("guest") === true || currentUrl.includes("create-account") === true);
    
    try {
        let response;
        if (!isGuest) {
            const endpointUrl =
                baseUrl + `/scopeconnectquestions`;
            response = yield doGetRequest(endpointUrl, null);
        } else {
            // For Guest User
            const endpointUrl = baseUrl + `/Guest/scopeconnectquestions`;
            response = yield getRequest(endpointUrl, null);
        }
        window["AppInsightTrace"].trackTrace(
            `GET request for Scope connect questions: `
        );
        if (response) {
            window["AppInsightTrace"].trackTrace(
                `GET request for Scope connect questions:: Success: ` +
                    JSON.stringify(response)
            );
            yield put(
                homeaPageActionCreators.getScopeConnectQuestionsSuccess(response)
            );
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `GET request for Scope connect questions: Failed: ` +
                JSON.stringify(err)
        );

        yield put(homeaPageActionCreators.getScopeConnectQuestionsFailure(err));
    }
}


export function* getPilotProductsLookupData() {
    const currentUrl = window.location.href;
    let isGuest = currentUrl && (currentUrl.includes("guest") === true || currentUrl.includes("create-account") === true);
    
    try {
        let response;
        if (!isGuest) {
            const endpointUrl =
                baseUrl + `/lookup?lookupName=PILOT_PRODUCTS`;
            response = yield doGetRequest(endpointUrl, null);
        } else {
            // For Guest User
            const endpointUrl = baseUrl + `/Guest/GetPilotProducts`;
            response = yield getRequest(endpointUrl, null);
        }
        window["AppInsightTrace"].trackTrace(
            `Lookup for Pilot Products List - Place order Page: `
        );
        if (response) {
            window["AppInsightTrace"].trackTrace(
                `Lookup for Pilot Products List - Place order Page: Success: ` +
                    JSON.stringify(response)
            );
            yield put(
                homeaPageActionCreators.getPilotProductsSuccess(response)
            );
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `Lookup forPilot Products List - Place order Page:Failed: ` +
                JSON.stringify(err)
        );

        yield put(homeaPageActionCreators.getPilotProductsFailure(err));
    }
}



export function* getPilotProductsStateData() {
    const currentUrl = window.location.href;
    let isGuest = currentUrl && (currentUrl.includes("guest") === true || currentUrl.includes("create-account") === true);
    
    try {
        let response;
        if (!isGuest) {
            const endpointUrl =
                baseUrl + `/lookup?lookupName=PILOT_PRODUCTS_STATES`;
            response = yield doGetRequest(endpointUrl, null);
        } else {
            // For Guest User
            const endpointUrl = baseUrl + `/Guest/GetPilotProductStates`;
            response = yield getRequest(endpointUrl, null);
        }
        window["AppInsightTrace"].trackTrace(
            `Lookup for Pilot Products State List - Place order Page: `
        );
        if (response) {
            window["AppInsightTrace"].trackTrace(
                `Lookup for Pilot Products States List - Place order Page: Success: ` +
                    JSON.stringify(response)
            );
            yield put(
                homeaPageActionCreators.getPilotProductsStatesSuccess(response)
            );
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `Lookup forPilot Products State List - Place order Page:Failed: ` +
                JSON.stringify(err)
        );

        yield put(homeaPageActionCreators.getPilotProductsStatesFailure(err));
    }
}


export function* uploadBluePrintFiles(action) {
    const currentUrl = window.location.href;
    let isGuest = currentUrl && (currentUrl.includes("guest") === true);
    let endpointUrl;
    const {file} = action.payload;
    try {
        let response;
        if (!isGuest) {
            endpointUrl = baseUrl +  "/Orders/BPFileupload" ;
            response = yield postBPFileUpload(endpointUrl,file);
        } else {
            endpointUrl =  baseUrl + "/Guest/BPFileupload";
            response = yield postGuestBPFileUploadRequest(endpointUrl, file);
        }
        if (response) {
            window["AppInsightTrace"].trackTrace(`BluePrint File Upload - successs: ` + JSON.stringify(response));
            yield put(homeaPageActionCreators.bluePrintFileUploadSuccess(response));
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(`BluePrint File Upload Failed: ` + JSON.stringify(err));
        yield put(homeaPageActionCreators.bluePrintFileUploadFailure(err));
    }
}



export function* HomePageWatcher() {
    yield takeEvery(HOMEPAGEACTIONS.PLACE_ORDER, placeOrder);
    yield takeEvery(HOMEPAGEACTIONS.GET_USER_PROFILE, getUserProfileInfo);
    yield takeEvery(HOMEPAGEACTIONS.SET_NOTIFICATION, setNotification);
    yield takeEvery(HOMEPAGEACTIONS.CHECK_COVERAGE, checkCoverage);
    yield takeEvery(HOMEPAGEACTIONS.CHECK_COVERAGE_GUEST, checkCoverageGuest);
    yield takeEvery(HOMEPAGEACTIONS.UPDATE_T_AND_C, setTermsAndCondition);
    yield takeEvery(HOMEPAGEACTIONS.GET_T_AND_C_LOOKUP, getTandCLookupData);
    yield takeEvery(HOMEPAGEACTIONS.GET_SCOPE_CONNECT_QUESTIONS, getScopeConnectQuestions);
    yield takeEvery(HOMEPAGEACTIONS.GET_PILOT_PRODUCTS, getPilotProductsLookupData);
    yield takeEvery(HOMEPAGEACTIONS.GET_PILOT_PRODUCTS_STATES, getPilotProductsStateData);
    yield takeEvery(HOMEPAGEACTIONS.BLUE_PRINT_FILE_UPLOAD, uploadBluePrintFiles);
}
