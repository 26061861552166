import { string } from "prop-types";
import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import * as CreateAccountActions from "../containers/CreateAccount/actionTypes";

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
export interface CreateAccountState {
    AccountTypes: any;
    AccountInfo: any;
    isGettingAccountTypes: boolean;
    isCreatingAccount: boolean;
    isRequestFailed: boolean;
    AccountData: any;
}

export interface CreateAccount {
    actData: any;
    type: any;
    payload: any;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = CreateAccount;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestAPI: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            actData: "test",
            type: CreateAccountActions.GET_ACCOUNT_TYPES,
            payload: "test",
        });
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultProps: CreateAccountState = {
    AccountTypes: {},
    AccountInfo: {},
    isGettingAccountTypes: false,
    isCreatingAccount: false,
    isRequestFailed: false,
    AccountData: {},
};

export const reducer: Reducer<CreateAccountState> = (
    state: CreateAccountState = defaultProps,
    incomingAction: Action
) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case CreateAccountActions.GET_ACCOUNT_TYPES:
            return {
                ...state,
                AccountTypes: action.payload,
                isGettingAccountTypes: true,
            };
        case CreateAccountActions.GET_ACCOUNT_TYPES_FAILURE:
            return {
                ...state,
                AccountTypes: action.payload,
                isGettingAccountTypes: false,
            };
        case CreateAccountActions.GET_ACCOUNT_TYPES_SUCCESS:
            return {
                ...state,
                AccountTypes: action.payload,
                isGettingAccountTypes: false,
            };
        case CreateAccountActions.CREATE_ACCOUNT:
            return {
                ...state,
                AccountInfo: action.payload,
                isCreatingAccount: true,
                isRequestFailed: false,
            };
        case CreateAccountActions.CREATE_ACCOUNT_FAILURE:
            return {
                ...state,
                AccountInfo: action.payload,
                isCreatingAccount: false,
                isRequestFailed: true,
            };
        case CreateAccountActions.CREATE_ACCOUNT_SUCCESS:
            return {
                ...state,
                AccountInfo: action.payload,
                AccountData: action.actData,
                isCreatingAccount: false,
                isRequestFailed: false,
            };
        default:
        // The following line guarantees that every action in the KnownAction union has been covered by a case above
        // const exhaustiveCheck: never = action;
    }

    return state || defaultProps;
};
