import * as MY_ORDER_ACTIONS from "./actionTypes";

export function getMyOrderPageRecords(data): any {
    return {
        payload: data,
        type: MY_ORDER_ACTIONS.MY_ORDER_PAGE_RECORDS,
    };
}

export function getDistributorForPostalCode(data): any {
    return {
        payload: data,
        type: MY_ORDER_ACTIONS.GET_DISTRIBUTOR_FOR_POSTALCODE,
    }
}

export function getDistributorForPostalCodeSuccess(data): any {
    return {
        payload: data,
        type: MY_ORDER_ACTIONS.GET_DISTRIBUTOR_FOR_POSTALCODE_SUCCESS,
    }
}

export function getDistributorForPostalCodeFailure(data): any {
    return {
        payload: data,
        type: MY_ORDER_ACTIONS.GET_DISTRIBUTOR_FOR_POSTALCODE_FAILURE,
    }
}

export function updateOrderService(data): any {
    return {
        payload: data,
        type: MY_ORDER_ACTIONS.UPDATE_ORDER_SERVICE,
    }
}

export function updateOrderServiceSuccess(data): any {
    return {
        payload: data,
        type: MY_ORDER_ACTIONS.UPDATE_ORDER_SERVICE_SUCCESS,
    }
}

export function updateOrderServiceFailure(data): any {
    return {
        payload: data,
        type: MY_ORDER_ACTIONS.UPDATE_ORDER_SERVICE_FAILURE,
    }
}

export function clearUpdateOrderService() {
    return{
        type: MY_ORDER_ACTIONS.CLEAR_UPDATE_ORDER_SERVICE
    }
}

export function downLoadFile(data): any {
    return {
        payload: data,
        type: MY_ORDER_ACTIONS.DOWNLOAD_FILE
    }
}

export function downLoadFileSuccess(data): any {
    return {
        payload: data,
        type: MY_ORDER_ACTIONS.DOWNLOAD_FILE_SUCCESS,
    }
}

export function downLoadFileFailure(data): any {
    return {
        payload: data,
        type: MY_ORDER_ACTIONS.DOWNLOAD_FILE_FAILURE,
    }
}

export function resetDownLoadFile(): any {
    return {
        type: MY_ORDER_ACTIONS.RESET_DOWNLOAD_FILE
    }
}