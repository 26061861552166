//cancel order types
export const CANCEL_GUEST_ORDER = "CANCEL_GUEST_ORDER";

export const CANCEL_GUEST_ORDER_SUCCESS = "CANCEL_GUEST_ORDER_SUCCESS";

export const CANCEL_GUEST_ORDER_FAILURE = "CANCEL_GUEST_ORDER_FAILURE";

export type CANCEL_GUEST_ORDER = typeof CANCEL_GUEST_ORDER;

export type CANCEL_GUEST_ORDER_SUCCESS = typeof CANCEL_GUEST_ORDER_SUCCESS;

export type CANCEL_GUEST_ORDER_FAILURE = typeof CANCEL_GUEST_ORDER_FAILURE;

export const PAYMENT_CANCEL = "PAYMENT_CANCEL";

export type PAYMENT_CANCEL = typeof PAYMENT_CANCEL;
