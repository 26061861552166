import * as React from "react";
import "./loader.css";
class Loader extends React.PureComponent {
    classNames: string;
    constructor(props: any) {
        super(props);
        this.classNames = "";
    }

    /** @description React Life cycle method
     * it will invoke, when component is mounted.
     */
    componentDidMount() {
        const { className } = document.body;
        this.classNames = className;
        document.body.className = `${this.classNames} modal-open`;
    }

    /** @description React Life cycle method
     * it will invoke, when component is unmounted.
     */
    componentWillUnmount() {
        document.body.className = this.classNames;
    }

    render() {
        return (
            <div className="loader">
                <div className="loader-inner">
                    <div className="logo"></div>
                    <div className="logo-svg">
                        <svg
                            width="110px"
                            height="110px"
                            viewBox="0 0 110 110"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g
                                id="Symbols"
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                            >
                                <g
                                    id="Header"
                                    transform="translate(-132.000000, -1.000000)"
                                    fillRule="nonzero"
                                >
                                    <g id="gaf_logo_88x80">
                                        <g transform="translate(132.000000, 1.000000)">
                                            <rect
                                                id="Rectangle-path"
                                                fill="#D72027"
                                                x="0"
                                                y="0"
                                                width="98.9159892"
                                                height="98.9159892"
                                            ></rect>
                                            <path
                                                d="M96.2059621,41.5489344 L96.2059621,33.9930829 L69.4274621,33.9930829 L69.4274621,65.5570432 L57.5398102,34.7242944 L46.2778243,34.7242944 L35.516371,65.4351746 L35.516371,48.4954431 L20.2501233,48.4954431 L20.2501233,55.4419517 L25.2554504,55.4419517 C25.2554504,58.2449289 21.0009224,59.5854832 18.623392,59.5854832 C16.2458617,59.5854832 11.4908009,59.0980089 11.4908009,49.4703916 C11.4908009,39.8427744 18.1228593,39.964643 18.1228593,39.964643 C23.7538523,39.964643 24.5046514,44.3519116 24.5046514,44.3519116 L34.6404388,44.3519116 C32.8885743,34.9680315 25.1303173,32.7743972 18.7485252,32.5306601 C12.3667332,32.2869229 1.35501355,36.3085858 1.35501355,49.4703916 C1.35501355,62.6321975 8.8630042,67.6288089 16.8715276,67.7506775 C17.1217939,67.7506775 17.3720603,67.7506775 17.6223266,67.7506775 C18.623392,67.7506775 19.2490579,67.6288089 19.999857,67.5069404 C25.5057168,66.5319918 28.0083803,62.8759346 28.0083803,62.8759346 L28.0083803,67.3850718 L44.2756934,67.3850718 L45.7772915,63.1196718 L57.9152098,63.1196718 L59.1665415,67.2632032 L80.0637822,67.2632032 L80.0637822,55.4419517 L92.8273663,55.4419517 L92.8273663,48.0079688 L80.1889153,48.0079688 L80.1889153,41.1833287 L96.2059621,41.1833287 L96.2059621,41.5489344 Z M47.9045556,56.782506 L52.0339504,43.4988316 L56.0382121,56.782506 L47.9045556,56.782506 Z"
                                                id="Shape"
                                                fill="#FFFFFF"
                                            ></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
        );
    }
}
// fill="#E40514"

export default Loader;
