import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import * as VarAdminActions from "../containers/concierge/SubscriberAdmin/actionTypes";

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
export interface VarAdminState {
    Sources: any;
    isGettingSources: boolean;
    SourceDetails: any;
    isGettingSourceDetails: boolean;
    sourceDetailshasError: boolean;
    addNewVarBtnClicked: boolean;
    saveSubscriber: any;
    isGettingSubscriberSaved: boolean;
    backToVarAdmnBtnClicked: boolean;
}

export interface VarAdmin {
    type: any;
    payload: any;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = VarAdmin;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestAPI: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: VarAdminActions.SUBSCRIBERS_NAME,
            payload: "test",
        });
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultProps: VarAdminState = {
    Sources: {},
    isGettingSources: false,
    SourceDetails: {},
    isGettingSourceDetails: false,
    sourceDetailshasError: false,
    addNewVarBtnClicked: false,
    saveSubscriber: {},
    isGettingSubscriberSaved: false,
    backToVarAdmnBtnClicked: false,
};

export const reducer: Reducer<VarAdminState> = (
    state: VarAdminState = defaultProps,
    incomingAction: Action
) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case VarAdminActions.SUBSCRIBERS_NAME:
            return {
                ...state,
                Sources: action.payload,
                isGettingSources: true,
            };
        case VarAdminActions.SUBSCRIBERS_NAME_FAILURE:
            return {
                ...state,
                Sources: action.payload,
                isGettingSources: false,
            };
        case VarAdminActions.SUBSCRIBERS_NAME_SUCCESS:
            return {
                ...state,
                Sources: action.payload,
                isGettingSources: false,
            };

        case VarAdminActions.SUBSCRIBER_DETAILS:
            return {
                ...state,
                SourceDetails: action.payload,
                isGettingSourceDetails: true,
            };

        case VarAdminActions.ADD_NEW_VAR_BTN_CLICKED:
            return {
                ...state,
                addNewVarBtnClicked: action.payload,
            };
        case VarAdminActions.BACK_TO_VAR_ADMN_BTN_CLICKED:
            return {
                ...state,
                backToVarAdmnBtnClicked: action.payload,
            };
        case VarAdminActions.POST_SUBSCIBER:
            return {
                ...state,
                saveSubscriber: action.payload,
                isGettingSubscriberSaved: true,
            };
        case VarAdminActions.POST_SUBSCIBER_SUCCESS:
            return {
                ...state,
                saveSubscriber: action.payload,
                isGettingSubscriberSaved: false,
            };
        case VarAdminActions.POST_SUBSCIBER_FAILURE:
            return {
                ...state,
                saveSubscriber: action.payload,
                isGettingSubscriberSaved: false,
            };
        default:
        // The following line guarantees that every action in the KnownAction union has been covered by a case above
        // const exhaustiveCheck: never = action;
    }

    return state || defaultProps;
};
