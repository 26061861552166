import { AnyMxRecord } from "dns";
import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import * as GuestUserPageActions from "../containers/GuestUser/actionTypes";

//import IProductsProps from '../models/HomePage/IProductSelection'
// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
export interface GuestHomePageState {
    guestUserProfile: any;
    searchUser: any;
    isSearchingUser: boolean;
    isSearchUserProfileSuccess: boolean;
    isSearchUSerProfileFailure: boolean;
    orderDetails: any;
    isGuestUserProfileLoading: any;
    guestUpdateTandC: any;
    isUpdatingGuestTAndC: boolean;
    isGuestUserProfileSuccess?: boolean;
    trackActivity?: any;
    isVendorSessionExpired?: boolean;
}

export interface GuestPageDataSuccess {
    type: any;
    payload: any;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = GuestPageDataSuccess;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestAPI: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: GuestUserPageActions.GUEST_USER_PROFILE_DATA,
            payload: "test",
        });
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultProps: GuestHomePageState = {
    guestUserProfile: {
        profilePrices: [],
        emailAddress: "",
        profileProducts: [],
        productPreferences: [],
    },
    searchUser: {},
    orderDetails: {},
    isGuestUserProfileLoading: false,
    guestUpdateTandC: {},
    isUpdatingGuestTAndC: false,
    isGuestUserProfileSuccess: false,
    isSearchingUser: false,
    isSearchUserProfileSuccess: false,
    isSearchUSerProfileFailure: false,
    trackActivity: {},
    isVendorSessionExpired: false,
};

export const reducer: Reducer<GuestHomePageState> = (
    state: GuestHomePageState = defaultProps,
    incomingAction: Action
) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case GuestUserPageActions.GUEST_USER_PROFILE_DATA:
            return {
                ...state,
                guestUserProfile: action.payload,
                isGuestUserProfileLoading: false,
                isGuestUserProfileSuccess: false,
            };
        case GuestUserPageActions.GUEST_USER_PROFILE_DATA_SUCCESS:
            return {
                ...state,
                guestUserProfile: action.payload,
                isGuestUserProfileLoading: false,
                isGuestUserProfileSuccess: true,
            };
        case GuestUserPageActions.GUEST_USER_PROFILE_DATA_FAILURE:
            return {
                ...state,
                guestUserProfile: action.payload,
                isGuestUserProfileLoading: false,
                isGuestUserProfileSuccess: false,
            };
        case GuestUserPageActions.GUEST_SEARCH_USER:
            return {
                ...state,
                searchUser: action.payload,
                isSearchingUser: true,
                isSearchUserProfileSuccess: false,
                isSearchUSerProfileFailure: false,
            };
        case GuestUserPageActions.GUEST_SEARCH_USER_SUCCESS:
            return {
                ...state,
                searchUser: action.payload,
                isSearchingUser: false,
                isSearchUserProfileSuccess: true,
                isSearchUSerProfileFailure: false,
            };
        case GuestUserPageActions.GUEST_SEARCH_USER_FAILURE:
            return {
                ...state,
                searchUser: action.payload,
                isSearchingUser: false,
                isSearchUserProfileSuccess: false,
                isSearchUSerProfileFailure: true,
            };
        case GuestUserPageActions.RESET_GUEST_SEARCH_USER:
            return {
                ...state,
                searchUser: {},
                isSearchingUser: false,
                isSearchUserProfileSuccess: false,
                isSearchUSerProfileFailure: false,
            };
        case GuestUserPageActions.GUEST_ORDER_DETAILS:
            return {
                ...state,
                primaryEmail: action.payload.primaryEmail,
                orderId: action.payload.orderId,
                orderIds: action.payload.orderIds
            };
        case GuestUserPageActions.GUEST_UPDATE_T_AND_C:
            return {
                ...state,
                guestUpdateTandC: action.payload,
                isUpdatingGuestTAndC: true,
            };
        case GuestUserPageActions.GUEST_UPDATE_T_AND_C_FAILED:
            return {
                ...state,
                guestUpdateTandC: action.payload,
                isUpdatingGuestTAndC: false,
            };
        case GuestUserPageActions.GUEST_UPDATE_T_AND_C_SUCCESS:
            return {
                ...state,
                guestUpdateTandC: action.payload,
                isUpdatingGuestTAndC: false,
            };
        case GuestUserPageActions.TRACK_ACTIVITY:
            return {
                ...state,
                trackActivity: action.payload
            };
        case GuestUserPageActions.TRACK_ACTIVITY_SUCCESS:
            return {
                ...state,
                trackActivity: action.payload
            };
        case GuestUserPageActions.TRACK_ACTIVITY_FAILURE:
            return {
                ...state,
                trackActivity: action.payload
            }
        case GuestUserPageActions.TRACK_SESSION_EXPIRED:
            return {
                ...state,
                isVendorSessionExpired: action.payload
            }
        default:
        // The following line guarantees that every action in the KnownAction union has been covered by a case above
        // const exhaustiveCheck: never = action;
    }

    return state || defaultProps;
};
