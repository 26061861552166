import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import * as SupportRequestActions from "../containers/SupportRequests/actionTypes";

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
export interface SupportRequestState {
    RequestInfo: any;
    isSendingRequest: boolean,
    isRequestSentSuccess: boolean,
    isRequestFailed: boolean,
}

export interface CreateAccount {
    type: any;
    payload: any;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = CreateAccount;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestAPI: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: SupportRequestActions.SEND_REQUEST,
            payload: "test",
        });
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultProps: SupportRequestState = {
    RequestInfo: {},
    isSendingRequest: false,
    isRequestSentSuccess: false,
    isRequestFailed: false
};

export const reducer: Reducer<SupportRequestState> = (
    state: SupportRequestState = defaultProps,
    incomingAction: Action
) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case SupportRequestActions.SEND_REQUEST:
            return {
                ...state,
                RequestInfo: action.payload,
                isSendingRequest: true,
                isRequestSentSuccess: false,
                isRequestFailed: false
            };
        case SupportRequestActions.SEND_REQUEST_SUCCESS:
            return {
                ...state,
                RequestInfo: action.payload,
                isRequestSentSuccess: true,
                isSendingRequest: false,
                isRequestFailed: false
            };
        case SupportRequestActions.SEND_REQUEST_FAILURE:
            return {
                ...state,
                RequestInfo: action.payload,
                isRequestSentSuccess: false,
                isSendingRequest: false,
                isRequestFailed: true
            };
        default:
        // The following line guarantees that every action in the KnownAction union has been covered by a case above
        // const exhaustiveCheck: never = action;
    }

    return state || defaultProps;
};
