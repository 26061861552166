import { put, takeLatest, call } from "redux-saga/effects";

import * as getCustomerRecords from "./actionCreater";
import * as CUSTOMER_PAGE_ACTIONS from "./actionTypes";

export function* getCustomers() {
    try {
        const response = {};
        yield put(getCustomerRecords.getCustomersRecords(response));
    } catch (error) {}
}

export function* CustomersPageWatcher() {
    yield [
        takeLatest(
            CUSTOMER_PAGE_ACTIONS.GET_CUSTOMERS_SEARCH_RECORDS,
            getCustomers
        ),
    ];
}
