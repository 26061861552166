import * as GET_CUSTOMER_DETAILS from "./actionTypes";

export function getCustomerOrderDetailsRecords(data): any {
    return {
        payload: data,
        type: GET_CUSTOMER_DETAILS.CUSTOMER_ORDER_DETAILS_RECORDS,
    };
}
export function getCustomerTransactionRecords(data): any {
    return {
        payload: data,
        type: GET_CUSTOMER_DETAILS.CUSTOMER_TRANSACTION_DETAILS_RECORDS,
    };
}

export function addParentEmailError(data): any {
    return {
        payload: data,
        type: GET_CUSTOMER_DETAILS.ADDPARENT_MODAL_EMAIL_ERROR,
    };
}

export function singleUserProfile(data): any {
    return {
        payload: data,
        type: GET_CUSTOMER_DETAILS.SINGLE_USER_PROFILE,
    };
}

export function getSpecialPriceSelected(data): any {
    return {
        payload: data,
        type: GET_CUSTOMER_DETAILS.SPEICAL_PRICE_SELECTED,
    };
}

export function getRejectReportResaon(data): any {
    return {
        payload: data,
        type: GET_CUSTOMER_DETAILS.REJECT_REPORT_REASON,
    };
}

export function navigationInParentPagefunction(data): any {
    return {
        payload: data,
        type: GET_CUSTOMER_DETAILS.NAVIGATION_IN_PARENT_PAGE,
    };
}

export function transferBalance(data): any {
    return {
        type: GET_CUSTOMER_DETAILS.TRANSFER_BALANCE,
        payload: data,
    };
}

export function transferCreditSuccess(data): any {
    return {
        type: GET_CUSTOMER_DETAILS.TRANSFER_BALANCE_SUCCESS,
        payload: data,
    };
}

export function transferCreditFailure(err): any {
    return {
        type: GET_CUSTOMER_DETAILS.TRANSFER_BALANCE_FAILURE,
        payload: err,
    };
}

export function clearErrors() {
    return {
        type: GET_CUSTOMER_DETAILS.CLEAR_ERROR,
    };
}