import * as React from "react";
import { cdnPath } from "../../../envConfig";

const closeBtnImg = cdnPath + "/images/mask@3x.png";
const TickMarkImg = cdnPath + "/images/check@2x.png";

export default class SuccessPromoCodeModal extends React.Component<any, any> {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <React.Fragment>
                <div className="container success-promocode-modal">
                    <div className="d-flex position-relative ">
                        <img
                            src={TickMarkImg}
                            alt="Tick-Mark Logo"
                            className="checkmark-img"
                        />
                        <p className="ParagraphWhite mb-0  d-inline-block">
                            {this.props.message}
                        </p>

                        <button
                            className="close-btn"
                            onClick={() =>
                                this.props.closeSuccessPromoCodeModal()
                            }
                        >
                            <img
                                src={closeBtnImg}
                                alt="close-button"
                                className="clsoe-btn-img"
                            />
                        </button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
