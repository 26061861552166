import { call, all } from "redux-saga/effects";

import { HomePageWatcher } from "./containers/HomePage/saga";
import { MyOrderesPageWatcher } from "./containers/MyOrders/saga";
import { MyReceiptsPageWatcher } from "./containers/MyReceipts/saga";
import { GuestUserPageWatcher } from "./containers/GuestUser/saga";
import { LoaderWatcher } from "./components/Loader/saga";
import { CustomersPageWatcher } from "./containers/concierge/Customers/saga";
import { CustomerDetailPageWatcher } from "./containers/concierge/CustomerDetails/saga";
import { CustomersRoofReportPageWatcher } from "./containers/concierge/Roofreports/saga";
import { PromoCodePageWatcher } from "./containers/concierge/Promocodes/saga";
import { historyWatcher } from "../src/components/History/saga";
import { HeaderWatcher } from "./components/Header/saga";
import { TrackOrderWatcher } from "./containers/TrackOrder/saga";
import { CreateAccountWatcher } from "./containers/CreateAccount/saga";
import { CancelGuestOrderWatcher } from "./components/PaymentCancel/saga";
import { SendRequestWatcher } from "./containers/SupportRequests/saga";
import { VarAdminPageWatcher } from "./containers/concierge/SubscriberAdmin/saga";
import { ApplicationAdminWatcher } from "./containers/concierge/ApplicationAdmin/saga";
import { MyPreferenceWatcher } from "./containers/MyPreferences/saga";
import { ServiceWatcher } from "./containers/concierge/Services/saga";
import { ServiceBundleWatcher } from "./containers/concierge/ServiceBundles/saga";
import { ResubmitWatcher } from "./containers/concierge/Resubmit/saga";

export default function* rootWatchers() {
  yield all([
    call(HomePageWatcher),
    call(HeaderWatcher),
    call(LoaderWatcher),
    call(MyOrderesPageWatcher),
    call(MyReceiptsPageWatcher),
    call(GuestUserPageWatcher),
    call(CustomersPageWatcher),
    call(CustomerDetailPageWatcher),
    call(CustomersRoofReportPageWatcher),
    call(PromoCodePageWatcher),
    call(historyWatcher),
    call(TrackOrderWatcher),
    call(CreateAccountWatcher),
    call(CancelGuestOrderWatcher),
    call(SendRequestWatcher),
    call(VarAdminPageWatcher),
    call(ApplicationAdminWatcher),
    call(MyPreferenceWatcher),
    call(ServiceWatcher),
    call(ServiceBundleWatcher),
    call(ResubmitWatcher),
  ]);
}
