import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import * as TagManager from "react-gtm-module";

import ErrorModal from "../ErrorModal";
import ReplenishBalanceModal from "../Modals/ReplenishBalanceModal";
import Loader from "../Loader";


// action creator imports
import { turnOn, turnOff } from "../Loader/actionCreater";
import * as HeaderActionCreator from "../Header/actionCreater";
import * as MyPreferenceActionCreator from "../../containers/MyPreferences/actionCreater";

import { replenishMaxCustomAmt } from "../../../envConfig";

class Credits extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {

            errorCode: "",
            showErrorModal: false,
            buyBalanceError: false,
            showReplenishBalanceModal: false,
            defaultAmtRadio: '1000',
            customAmtError: false,
            customAmtErrMsg: "",
            shouldShowCustomTxtAmt: false,
            txtCustomAmt: 25,
            chkSaveAsDefaultReplenishment: 0,
            isRedirectedToPD: false,
            isReplenishBtnClicked: false,
            isErrorDisplayForPaymentRequest: false,
            isGetReplenishmentApiCalled: false,
            isReplenishmentDataStored: false,
            isReplensihDataOnUI: false,
            originalDefaultAmt: 0,
            defaultAmtArray: [100, 500, 1000, 2500, 5000],
            isOrigianlDefaultAmtCustomAmt: false,
            lookupRadioAmtsCalled: false,
            isRadioLookupSaved: false,
            radioLookupValues: [],
            isAutoReplenishmentEnabled: false,
        };
    }

    componentDidUpdate = (prevProps) => {
        const Header = this.props.Header;
        const MyPreferences = this.props.MyPreferences;

        // Get Repelinishment Api call for Repelnish Balance
        if (!this.state.isGetReplenishmentApiCalled && this.state.showReplenishBalanceModal) {
            const emailAddress = btoa(this.props.userProfile.emailAddress);
            const requestObj = {
                emailAddress: emailAddress,
                requestType: 0 // For Replenish Balalnce button this always 0, defaultRepenishment=0
            }
            this.props.getReplenishmentData(requestObj);
            this.setState({
                isGetReplenishmentApiCalled: true,
                isReplenishmentDataStored: false,
            })
        }

        // Storing Replenishment data to State level
        if (!this.state.isReplenishmentDataStored && this.state.isGetReplenishmentApiCalled && !MyPreferences.isGettingReplenishment && MyPreferences.isGetReplenishmentSuccess
            && MyPreferences.getReplenishment.success) {
            this.setState({
                isReplenishmentDataStored: true,
                getReplenishmentData: MyPreferences.getReplenishment,
                isReplensihDataOnUI: false,
            });
        }

        // Displying Replenishment data on UI
        if (!this.state.isReplensihDataOnUI && this.state.isReplenishmentDataStored &&
            this.state.isRadioLookupSaved && !MyPreferences.isGettingReplenishment && MyPreferences.isGetReplenishmentSuccess) {
            this.PlaceValueOnUIForReplenish();
            this.setState({
                isReplensihDataOnUI: true,
            });
        }

        // Lookup - Radio Amounts for Replenishment - API call
        if (!this.state.lookupRadioAmtsCalled && this.state.showReplenishBalanceModal) {
            this.props.lookupRadioAmts();
            this.setState({
                lookupRadioAmtsCalled: true,
            })
        }

        // Storing the lookup radio values
        if (!this.state.isRadioLookupSaved && !MyPreferences.isGettingRadioAmts && this.state.lookupRadioAmtsCalled
            && Object.keys(MyPreferences.replenishRadioAmts).length !== 0) {
            const replenishRadioAmts = MyPreferences.replenishRadioAmts.lookups
            const amtArray = replenishRadioAmts && replenishRadioAmts.map((amt) => amt.lookupValue);
            this.setState({
                isRadioLookupSaved: true,
                radioLookupValues: amtArray || []
            })
        }

        // Generic error for Payment Request api call erros
        if (!this.state.isErrorDisplayForPaymentRequest && this.state.isReplenishBtnClicked && !Header.isSendingPaymentRequest && !Header.paymentRequest.success) {
            const errorCode = Header.paymentRequest.errorCode;
            this.setState({
                isErrorDisplayForPaymentRequest: true,
                showErrorModal: true,
                errorCode: errorCode,
                buyBalanceError: true,
            });
        }

        // Redirecting to Payment Director
        if (!this.state.isRedirectedToPD && this.state.isReplenishBtnClicked && Header.isPaymentRequestSuccess && Header.paymentRequest.success) {
            const pdUrl = Header.paymentRequest.paymentRedirectURL;
            const emailAddress = this.props.userProfile.emailAddress;
            this.setState({
                isRedirectedToPD: true,
            });
            window["AppInsightTrace"].trackTrace(`RepelnishButton Click  - Redirecting to PD for user ${emailAddress}`);
            window.location.href = pdUrl;
        }
    }
    // Placing values on UI for Replinshment 
    PlaceValueOnUIForReplenish = () => {
        const getReplenishmentData = this.state.getReplenishmentData;
        const defaultAmtArray = this.state.radioLookupValues;
        const isCustomAmt = defaultAmtArray.includes(String(getReplenishmentData.defaultReplenishmentAmount)) === false ? true : false;
        const currentDefaultAmt = getReplenishmentData.defaultReplenishmentAmount;

        this.setState({
            isReplensihDataOnUI: true,
            // chkSaveAsDefaultReplenishment: currentDefaultAmt !== null ? 1 : 0,
            defaultAmtRadio: isCustomAmt === true ? "custom" : String(getReplenishmentData.defaultReplenishmentAmount),
            txtCustomAmt: isCustomAmt === true ? getReplenishmentData.defaultReplenishmentAmount : 25,
            shouldShowCustomTxtAmt: isCustomAmt === true ? true : false,
            originalDefaultAmt: currentDefaultAmt,
            isOrigianlDefaultAmtCustomAmt: isCustomAmt,
            isAutoReplenishmentEnabled: getReplenishmentData.isAutoReplenishmentEnabled === 1 ? true : false,
        })
    }
    hideErrorModal = () => {
        this.setState({ showErrorModal: false, errorCode: null });
    };

    hdieReplenishBalanceModal = () => {
        const originalDefaultAmt = this.state.originalDefaultAmt;
        const defaultAmt = this.state.defaultAmtRadio === 'custom' ? parseInt(this.state.txtCustomAmt) : parseInt(this.state.defaultAmtRadio);
        const isOrigianlDefaultAmtCustomAmt = this.state.isOrigianlDefaultAmtCustomAmt;

        // Reseting the defaultAmt to original defaultAmt if user close the modal by clicking Cancel
        if (this.state.chkSaveAsDefaultReplenishment === 1 && originalDefaultAmt !== defaultAmt) {
            this.setState({
                defaultAmtRadio: isOrigianlDefaultAmtCustomAmt == true ? "custom" : String(originalDefaultAmt),
                txtCustomAmt: isOrigianlDefaultAmtCustomAmt === true ? originalDefaultAmt : 25,
            }, () => this.saveReplenish())
        }
        this.setState({
            showReplenishBalanceModal: false
        });
    }


    onReplnishBalanceClick = () => {
        const parentUserID = this.props.userProfile.parentUserID;
        // Showing ErrorModal when Parent is associated with act
        if (parentUserID) {
            this.setState({
                showErrorModal: true,
                errorCode: "01", // ErrorCode when parent is associated with act
                buyBalanceError: true,
            })
        } else {
            this.setState({
                showReplenishBalanceModal: true,
                isGetReplenishmentApiCalled: false,
                chkSaveAsDefaultReplenishment: 0,
            })
        }

    }


    // For Replenishment Radios of amounts
    handleReplenishRadioChange = (e) => {
        // e.stopPropagation();
        this.setState({
            [e.target.name]: e.target.value,
            chkSaveAsDefaultReplenishment: 0,
        });
        // Displaying text box on Custom radio click
        if (e.target.value === 'custom') {
            this.setState({
                shouldShowCustomTxtAmt: true,
            });
        } else {
            this.setState({
                shouldShowCustomTxtAmt: false,
                // txtCustomAmt: 25,
            });
        }
        if (this.state.customAmtError) {
            this.setState({
                customAmtError: false,
                customAmtErrMsg: "",
            })
        }
    }

    // Regex to allow only numbers and optional two decimals.
    priceInputKeyPress = (val) => {
        // const pattern = /^\d*(\.)?(\d{0,2})?$/ // This will allow two deciaml point
        const pattern = /^\d*?$/
        const result = pattern.test(val);
        if (!result) {
            return false
        }
        return true
    }

    replenishmentTxtInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
        if (e.target.name == 'txtCustomAmt') {
            const result = this.priceInputKeyPress(e.target.value);
            if (!result) {
                this.setState({
                    customAmtError: true,
                    customAmtErrMsg: "Invalid value",
                });
            } else {
                this.setState({
                    customAmtError: false,
                    customAmtErrMsg: "",
                });
            }

        }
    }

    // Saving Replenishment if Save Default Amt checkbox is checked 
    handleCheckBoxChange = (e) => {
        if (!e.target.checked) {
            this.setState({
                [e.target.name]: 0,
                customAmtError: false,
                customAmtErrMsg: '',
            })
        } else {
            // Not Saving replenishment when custom amt is 0
            if (this.state.defaultAmtRadio === 'custom' && parseInt(this.state.txtCustomAmt) === 0) {
                this.setState({
                    customAmtError: true,
                    customAmtErrMsg: "Invalid value.",
                    [e.target.name]: 1,
                })
            } else {
                this.setState({
                    [e.target.name]: 1,
                }, () => {
                    // Saving Replenish
                    this.saveReplenish();
                });
            }
        }

    }

    // Payment Request
    replenishClick = () => {
        const emailAddress = this.props.userProfile.emailAddress;
        const defaultAmt = this.state.defaultAmtRadio === 'custom' ? parseInt(this.state.txtCustomAmt) : parseInt(this.state.defaultAmtRadio);

        // Saving Replenishment if Save Default Amt checkbox is checked and selected amt is different then original amt.
        // if (this.state.chkSaveAsDefaultReplenishment === 1 && defaultAmt !== this.state.originalDefaultAmt) {
        //     this.saveReplenish();
        // }

        // Payment Request api call
        window['AppInsightTrace'].trackEvent(`Credits component - Replenish Button clciked  - User ${emailAddress}  `);
        if (!defaultAmt) {
            this.setState({
                customAmtError: true,
                customAmtErrMsg: `Invalid value.`,
            })
        } else {
            const requestObj = {
                emailAddress: btoa(emailAddress),
                amount: defaultAmt,
                numberOfOrders: 1,
            }
            // console.log(requestObj)
            this.props.paymentRequest(requestObj);
            window["AppInsightTrace"].trackTrace(`Credits component - Payment Request - Payload for user ${emailAddress}: ` + JSON.stringify(requestObj));
            this.setState({
                isReplenishBtnClicked: true,
                buyBalanceError: false,
                isErrorDisplayForPaymentRequest: false,
                errorCode: null,
                showReplenishBalanceModal: false,
                isRedirectedToPD: false,
            });
        }



        // -------------Needs to create tags in TagMananager---------------------
        // GTM settings
        const tagManagerArgs = {
            dataLayer: {
                event: 'clickBuyCredits',
                costs: defaultAmt
            },
            dataLayerName: 'PageDataLayer'
        }
        TagManager.dataLayer(tagManagerArgs);
        // -------------------------------------

    }

    // SavePreferences for Replenishment
    saveReplenish = () => {
        const emailAddress = this.props.userProfile.emailAddress;
        const defaultAmt = this.state.defaultAmtRadio === 'custom' ? parseInt(this.state.txtCustomAmt) : parseInt(this.state.defaultAmtRadio);
        const requestPayload = {
            emailAddress: emailAddress,
            isAutoReplenishmentEnabled: 0,
            defaultReplenishmentAmount: defaultAmt,
            minimumBalanceAmount: 0,
            paymentPreferences: "",
            requestType: 0
        }
        this.props.saveReplenishmentData(requestPayload);
        this.setState({
            isSaveReplenishBtnClicked: true,
        });

        window['AppInsightTrace'].trackEvent(`Replenish Balance- User ${emailAddress} 
        - Save Replenishment API requestPayload : ${JSON.stringify(requestPayload)} `);
    }

    onCustomAmtTxtBlur = (e) => {
        const txtVal = parseInt(e.target.value);
        if (txtVal > replenishMaxCustomAmt) {
            this.setState({
                customAmtError: true,
                customAmtErrMsg: `Amount cannot be greater than ${replenishMaxCustomAmt}.`,
            })
        }
    }

    render() {
        const loading = this.props.Header.isSendingPaymentRequest || this.props.MyPreferences.isSavingReplenishment || this.props.MyPreferences.isGettingRadioAmts;
        const { showReplenishBalanceModal, defaultAmtRadio } = this.state;
        const shouldDisableRelenishBtns = this.state.defaultAmtRadio === 'custom' && (parseInt(this.state.txtCustomAmt) === 0 || this.state.txtCustomAmt === "") ? true : false;
        return (
            <React.Fragment>
                <button
                    className="ButtonCopy gaf-btn-secondary replenish-balance-button"
                    onClick={() => {
                        this.onReplnishBalanceClick();
                    }}
                >
                    Add to Balance
                </button>

                {this.state.showErrorModal && (
                    <ErrorModal
                        buyBalanceError={this.state.buyBalanceError}
                        errorCode={this.state.errorCode}
                        close={() => this.hideErrorModal()}
                        show={this.state.showErrorModal}
                    />
                )}
                {(this.state.showReplenishBalanceModal && this.state.radioLookupValues.length > 0) && (
                    <ReplenishBalanceModal
                        show={showReplenishBalanceModal}
                        close={() => this.hdieReplenishBalanceModal()}
                        defaultAmtRadio={defaultAmtRadio}
                        handleReplenishRadioChange={(e) => this.handleReplenishRadioChange(e)}
                        txtInputChange={(e) => this.replenishmentTxtInputChange(e)}
                        customAmtErrMsg={this.state.customAmtErrMsg}
                        customAmtError={this.state.customAmtError}
                        txtCustomAmt={this.state.txtCustomAmt}
                        shouldShowCustomTxtAmt={this.state.shouldShowCustomTxtAmt}
                        shouldDisableBtn={shouldDisableRelenishBtns}
                        chkSaveAsDefaultReplenishment={this.state.chkSaveAsDefaultReplenishment}
                        handleCheckBoxChange={(e) => this.handleCheckBoxChange(e)}
                        replenishClick={() => this.replenishClick()}
                        radioLookupValues={this.state.radioLookupValues}
                        onCustomAmtTxtBlur={(e) => this.onCustomAmtTxtBlur(e)}
                        isAutoReplenishmentEnabled={this.state.isAutoReplenishmentEnabled}
                    />
                )}
                {loading && (
                    <Loader />
                )}

            </React.Fragment>
        );
    }
}

export function mapDispatchToProps(dispatch) {
    return {
        turnOff: bindActionCreators(turnOff, dispatch),
        turnOn: bindActionCreators(turnOn, dispatch),
        paymentRequest: bindActionCreators(HeaderActionCreator.initiatePaymentRequest, dispatch),
        getReplenishmentData: bindActionCreators(MyPreferenceActionCreator.getReplenishmentData, dispatch),
        saveReplenishmentData: bindActionCreators(MyPreferenceActionCreator.saveReplenishmentData, dispatch),
        lookupRadioAmts: bindActionCreators(MyPreferenceActionCreator.getReplenishRadioAmountsLookup, dispatch)
    };
}
const mapStateToProps = (state) => {
    const { loading } = state.Loader;
    const { isDebug } = state.Header;
    const Header = state.Header;
    const userProfile = state.HomePage.userProfile;
    const MyPreferences = state.MyPreferences;

    return { loading, isDebug, Header, userProfile, MyPreferences };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Credits));
