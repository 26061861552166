export const SELECTED_SERVICE = "SELECTED_SERVICE";
export type SELECTED_SERVICE = typeof SELECTED_SERVICE;

export const ADD_NEW_SERVICE = "ADD_NEW_SERVICE";
export type ADD_NEW_SERVICE = typeof ADD_NEW_SERVICE;

export const GET_SERVICES = "GET_SERVICES";
export type GET_SERVICES = typeof GET_SERVICES;

export const GET_SERVICES_SUCCESS = "GET_SERVICES_SUCCESS";
export type GET_SERVICES_SUCCESS = typeof GET_SERVICES_SUCCESS;

export const GET_SERVICES_FAILURE = "GET_SERVICES_FAILURE";
export type GET_SERVICES_FAILURE = typeof GET_SERVICES_FAILURE;

export const ADD_EDIT_SERVICES = "ADD_EDIT_SERVICES";
export type ADD_EDIT_SERVICES = typeof ADD_EDIT_SERVICES;

export const ADD_EDIT_SERVICES_SUCCESS = "ADD_EDIT_SERVICES_SUCCESS";
export type ADD_EDIT_SERVICES_SUCCESS = typeof ADD_EDIT_SERVICES_SUCCESS;

export const ADD_EDIT_SERVICES_FAILURE = "ADD_EDIT_SERVICES_FAILURE";
export type ADD_EDIT_SERVICES_FAILURE = typeof ADD_EDIT_SERVICES_FAILURE;
