import { put, takeEvery, call } from "redux-saga/effects";

import * as ServiceBundleActionCreator from "./actionCreater";
import * as SERVICE_BUNDLE_ACTIONS from "./actionTypes";

import { baseUrl } from "../../../../envConfig";
import { doGetRequest, doPostRequest } from "../../../utils/utils";

export function* getAllProducts() {
    const endpointUrl = baseUrl + "/products";
    try {
        const response = yield doGetRequest(endpointUrl, null);
        window["AppInsightTrace"].trackTrace(
            `GET Products - request: `
        );
        if (response) {
            window["AppInsightTrace"].trackTrace(
                `GET Products - success: ` +
                    JSON.stringify(response)
            );
            yield put(ServiceBundleActionCreator.getProductsSuccess(response));
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `GET Products - Failed: ` +
                JSON.stringify(err)
        );

        yield put(ServiceBundleActionCreator.getProductsFailure(err));
    }
}

export function* addEditBundle(action) {
    const endpointUrl = baseUrl + "/addupdateproduct";

    try {
        const response = yield doPostRequest(
            endpointUrl,
            JSON.stringify(action.payload)
        );
        window["AppInsightTrace"].trackTrace(
            `Add/Edit Service Bundle - POST request - Payload: ` +
                JSON.stringify(action.payload)
        );

        if (response) {
            window["AppInsightTrace"].trackTrace(
                `Add/Edit Service Bundle - POST request success: ` +
                    JSON.stringify(response)
            );

            yield put(
                ServiceBundleActionCreator.addEditBundleSuccess(response)
            );
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `Add/Edit Service Bundle - post request Failed: ` +
                JSON.stringify(err)
        );
        yield put(ServiceBundleActionCreator.addEditBundleFailure(err));
    }
}

export function* addUpdateProductsService(action) {
    const endpointUrl = baseUrl + "/addupdateproductservice";

    try {
        const response = yield doPostRequest(
            endpointUrl,
            JSON.stringify(action.payload)
        );
        window["AppInsightTrace"].trackTrace(
            `Add/Edit Products Service - POST request - Payload: ` +
                JSON.stringify(action.payload)
        );

        if (response) {
            window["AppInsightTrace"].trackTrace(
                `Add/Edit Products Service - POST request success: ` +
                    JSON.stringify(response)
            );

            yield put(
                ServiceBundleActionCreator.addUpdateProductsServiceSuccess(
                    response
                )
            );
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `Add/Edit Products Service - POST request Failed: ` +
                JSON.stringify(err)
        );
        yield put(
            ServiceBundleActionCreator.addUpdateProductsServiceFailure(err)
        );
    }
}

export function* ServiceBundleWatcher() {
    yield takeEvery(SERVICE_BUNDLE_ACTIONS.GET_PRODUCTS, getAllProducts);
    yield takeEvery(SERVICE_BUNDLE_ACTIONS.ADD_EDIT_BUNDLE, addEditBundle);
    yield takeEvery(
        SERVICE_BUNDLE_ACTIONS.ADD_UPDATE_PRODUCT_SERVICES,
        addUpdateProductsService
    );
}
