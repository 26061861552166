import { put, takeEvery, call } from "redux-saga/effects";

import * as PromocodeActionCreator from "./actionCreater";
import * as PROMO_CODE_ACTIONS from "./actionTypes";

import { baseUrl } from "../../../../envConfig";
import { doGetRequest, doPostRequest } from "../../../utils/utils";

export function* getMaxPromoCodeAmt() {
    const endpointUrl = baseUrl + `/lookup?lookupName=MaxPromoCodeAmount`;
    try {
        const response = yield doGetRequest(endpointUrl, null);
        window["AppInsightTrace"].trackTrace(
            `GET Max Promocode Amount- Generate Promocode Page: `
        );
        if (response) {
            window["AppInsightTrace"].trackTrace(
                `GET Max Promocode Amount Success- Generate Promocode Page: ` +
                    JSON.stringify(response)
            );
            yield put(
                PromocodeActionCreator.getMaxPromoCodeAmtSuccess(response)
            );
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `GET Max Promocode Amount Failure- Generate Promocode Page: ` +
                JSON.stringify(err)
        );

        yield put(PromocodeActionCreator.getMaxPromoCodeAmtFailure(err));
    }
}

export function* PromoCodePageWatcher() {
    yield takeEvery(
        PROMO_CODE_ACTIONS.GET_MAX_PROMOCODE_AMT,
        getMaxPromoCodeAmt
    );
}
