export const GET_SITECORE_DATA_SUCCESS = "GET_SITECORE_DATA_SUCCESS";
export type GET_SITECORE_DATA_SUCCESS = typeof GET_SITECORE_DATA_SUCCESS;

export const GET_COORDINATE = "GET_COORDINATE";
export type GET_COORDINATE = "GET_COORDINATE";

export const GET_FULLADDRESS = "GET_FULLADDRESS";
export type GET_FULLADDRESS = "GET_FULLADDRESS";

export const SUCCESS_PROMOCODE_MODAL = "SUCCESS_PROMOCODE_MODAL";
export type SUCCESS_PROMOCODE_MODAL = "SUCCESS_PROMOCODE_MODAL";

export const USER_PROFILE_DATA = "USER_PROFILE_DATA";
export type USER_PROFILE_DATA = "USER_PROFILE_DATA";

export const UPDATE_AVAILABLE_CREDIT = "UPDATE_AVAILABLE_CREDIT";
export type UPDATE_AVAILABLE_CREDIT = "UPDATE_AVAILABLE_CREDIT";

export const SEARCH_ADDRESS_VALUE = "SEARCH_ADDRESS_VALUE";
export type SEARCH_ADDRESS_VALUE = "SEARCH_ADDRESS_VALUE";

export const GET_OPEN_CLOSE = "GET_OPEN_CLOSE";
export type GET_OPEN_CLOSE = "GET_OPEN_CLOSE";

export const PLACE_ORDER = "PLACE_ORDER";
export type PLACE_ORDER = "PLACE_ORDER";

export const PLACE_ORDER_SUCCESS = "PLACE_ORDER_SUCCESS";
export type PLACE_ORDER_SUCCESS = "PLACE_ORDER_SUCCESS";

export const PLACE_ORDER_FAILED = "PLACE_ORDER_FAILED";
export type PLACE_ORDER_FAILED = "PLACE_ORDER_FAILED";

export const RESET_PLACE_ORDER = "RESET_PLACE_ORDER";
export type RESET_PLACE_ORDER = "RESET_PLACE_ORDER";

export const GTM_ACTION = "GTM_ACTION";
export type GTM_ACTION = "GTM_ACTION";

export const GUEST_USER = "GUEST_USER";
export type GUEST_USER = "GUEST_USER";

export const SHOULD_DISPLAY_MARKER = "SHOULD_DISPLAY_MARKER";
export type SHOULD_DISPLAY_MARKER = "SHOULD_DISPLAY_MARKER";

export const SHOULD_DISABLE_ZOOM_BTN = "SHOULD_DISABLE_ZOOM_BTN";
export type SHOULD_DISABLE_ZOOM_BTN = "SHOULD_DISABLE_ZOOM_BTN";

export const TOTAL_MARKER_PINS = "TOTAL_MARKER_PINS";
export type TOTAL_MARKER_PINS = "TOTAL_MARKER_PINS";

export const TOTAL_MARKERS_WITH_PINS_AND_LABEL =
    "TOTAL_MARKERS_WITH_PINS_AND_LABEL";
export type TOTAL_MARKERS_WITH_PINS_AND_LABEL =
    "TOTAL_MARKERS_WITH_PINS_AND_LABEL";

export const CREDITS_AS_PER_TOTAL_PINS = "CREDITS_AS_PER_TOTAL_PINS";
export type CREDITS_AS_PER_TOTAL_PINS = "CREDITS_AS_PER_TOTAL_PINS";

export const SHOULD_DISABLE_ADJUST_PIN_BTN = "SHOULD_DISABLE_ADJUST_PIN_BTN";
export type SHOULD_DISABLE_ADJUST_PIN_BTN = "SHOULD_DISABLE_ADJUST_PIN_BTN";

export const SHOULD_DISABLE_REMOVE_PIN_BTN = "SHOULD_DISABLE_REMOVE_PIN_BTN";
export type SHOULD_DISABLE_REMOVE_PIN_BTN = "SHOULD_DISABLE_REMOVE_PIN_BTN";

export const ADJUST_PIN_BTN_CLICKED = "ADJUST_PIN_BTN_CLICKED";
export type ADJUST_PIN_BTN_CLICKED = "ADJUST_PIN_BTN_CLICKED";

export const REMOVE_PIN_BTN_CLICKED = "REMOVE_PIN_BTN_CLICKED";
export type REMOVE_PIN_BTN_CLICKED = "REMOVE_PIN_BTN_CLICKED";

export const ADD_PIN_BTN_CLICKED = "ADD_PIN_BTN_CLICKED";
export type ADD_PIN_BTN_CLICKED = "ADD_PIN_BTN_CLICKED";

export const MAP_CLICKED = "MAP_CLICKED";
export type MAP_CLICKED = "MAP_CLICKED";

export const SHOULD_DISABLE_ADD_PIN_BTN = "SHOULD_DISABLE_ADD_PIN_BTN";
export type SHOULD_DISABLE_ADD_PIN_BTN = "SHOULD_DISABLE_ADD_PIN_BTN";

export const SHOULD_DISABLE_SUBMIT_ORDER_BTN =
    "SHOULD_DISABLE_SUBMIT_ORDER_BTN";
export type SHOULD_DISABLE_SUBMIT_ORDER_BTN = "SHOULD_DISABLE_SUBMIT_ORDER_BTN";

export const SET_NOTIFICATION = "SET_NOTIFICATION";
export type SET_NOTIFICATION = "SET_NOTIFICATION";

export const SET_NOTIFICATION_SUCCESS = "SET_NOTIFICATION_SUCCESS";
export type SET_NOTIFICATION_SUCCESS = "SET_NOTIFICATION_SUCCESS";

export const SET_NOTIFICATION_FAILED = "SET_NOTIFICATION_FAILED";
export type SET_NOTIFICATION_FAILED = "SET_NOTIFICATION_FAILED";

export const CHECK_COVERAGE = "CHECK_COVERAGE";
export type CHECK_COVERAGE = "CHECK_COVERAGE";

export const CHECK_COVERAGE_GUEST = "CHECK_COVERAGE_GUEST";
export type CHECK_COVERAGE_GUEST = "CHECK_COVERAGE_GUEST";

export const CHECK_COVERAGE_SUCCESS = "CHECK_COVERAGE_SUCCESS";
export type CHECK_COVERAGE_SUCCESS = "CHECK_COVERAGE_SUCCESS";

export const CHECK_COVERAGE_FAILED = "CHECK_COVERAGE_FAILED";
export type CHECK_COVERAGE_FAILED = "CHECK_COVERAGE_FAILED";

export const RESET_CHECK_COVERAGE = "RESET_CHECK_COVERAGE";
export type RESET_CHECK_COVERAGE = "RESET_CHECK_COVERAGE";

export const GET_USER_PROFILE = "GET_USER_PROFILE";
export type GET_USER_PROFILE = "GET_USER_PROFILE";

export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export type GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";

export const GET_USER_PROFILE_FAILED = "GET_USER_PROFILE_FAILED";
export type GET_USER_PROFILE_FAILED = "GET_USER_PROFILE_FAILED";

export const UPDATE_T_AND_C = "UPDATE_T_AND_C";
export type UPDATE_T_AND_C = "UPDATE_T_AND_C";

export const UPDATE_T_AND_C_SUCCESS = "UPDATE_T_AND_C_SUCCESS";
export type UPDATE_T_AND_C_SUCCESS = "UPDATE_T_AND_C_SUCCESS";

export const UPDATE_T_AND_C_FAILED = "UPDATE_T_AND_C_FAILED";
export type UPDATE_T_AND_C_FAILED = "UPDATE_T_AND_C_FAILED";

export const GET_T_AND_C_LOOKUP = "GET_T_AND_C_LOOKUP";
export type GET_T_AND_C_LOOKUP = "GET_T_AND_C_LOOKUP";

export const GET_T_AND_C_LOOKUP_SUCCESS = "GET_T_AND_C_LOOKUP_SUCCESS";
export type GET_T_AND_C_LOOKUP_SUCCESS = "GET_T_AND_C_LOOKUP_SUCCESS";

export const GET_T_AND_C_LOOKUP_FAILED = "GET_T_AND_C_LOOKUP_FAILED";
export type GET_T_AND_C_LOOKUP_FAILED = "GET_T_AND_C_LOOKUP_FAILED";

export const GET_SCOPE_CONNECT_QUESTIONS = "GET_SCOPE_CONNECT_QUESTIONS";
export type GET_SCOPE_CONNECT_QUESTIONS = "GET_SCOPE_CONNECT_QUESTIONS";

export const GET_SCOPE_CONNECT_QUESTIONS_SUCCESS = "GET_SCOPE_CONNECT_QUESTIONS_SUCCESS";
export type GET_SCOPE_CONNECT_QUESTIONS_SUCCESS = "GET_SCOPE_CONNECT_QUESTIONS_SUCCESS";

export const GET_SCOPE_CONNECT_QUESTIONS_FAILED = "GET_SCOPE_CONNECT_QUESTIONS_FAILED";
export type GET_SCOPE_CONNECT_QUESTIONS_FAILED = "GET_SCOPE_CONNECT_QUESTIONS_FAILED";

export const GET_PILOT_PRODUCTS = "GET_PILOT_PRODUCTS";
export type GET_PILOT_PRODUCTS = "GET_PILOT_PRODUCTS";

export const GET_PILOT_PRODUCTS_SUCCESS = "GET_PILOT_PRODUCTS_SUCCESS";
export type GET_PILOT_PRODUCTS_SUCCESS = "GET_PILOT_PRODUCTS_SUCCESS";

export const GET_PILOT_PRODUCTS_FAILED = "GET_PILOT_PRODUCTS_FAILED";
export type GET_PILOT_PRODUCTS_FAILED = "GET_PILOT_PRODUCTS_FAILED";

export const GET_PILOT_PRODUCTS_STATES = "GET_PILOT_PRODUCTS_STATES";
export type GET_PILOT_PRODUCTS_STATES = "GET_PILOT_PRODUCTS_STATES";

export const GET_PILOT_PRODUCTS_SUCCESS_STATES = "GET_PILOT_PRODUCTS_SUCCESS_STATES";
export type GET_PILOT_PRODUCTS_SUCCESS_STATES = "GET_PILOT_PRODUCTS_SUCCESS_STATES";

export const GET_PILOT_PRODUCTS_FAILED_STATES = "GET_PILOT_PRODUCTS_FAILED_STATES";
export type GET_PILOT_PRODUCTS_FAILED_STATES = "GET_PILOT_PRODUCTS_FAILED_STATES";

export const BLUE_PRINT_FILE_UPLOAD = "BLUE_PRINT_FILE_UPLOAD";
export type BLUE_PRINT_FILE_UPLOAD = "BLUE_PRINT_FILE_UPLOAD";

export const BLUE_PRINT_FILE_UPLOAD_SUCCESS = "BLUE_PRINT_FILE_UPLOAD_SUCCESS";
export type BLUE_PRINT_FILE_UPLOAD_SUCCESS = "BLUE_PRINT_FILE_UPLOAD_SUCCESS";

export const BLUE_PRINT_FILE_UPLOAD_FAILED = "BLUE_PRINT_FILE_UPLOAD_FAILED";
export type BLUE_PRINT_FILE_UPLOAD_FAILED = "BLUE_PRINT_FILE_UPLOAD_FAILED";

export const RESEST_BLUE_PRINT_FILE_UPLOAD = "RESEST_BLUE_PRINT_FILE_UPLOAD";
export type RESEST_BLUE_PRINT_FILE_UPLOAD = "RESEST_BLUE_PRINT_FILE_UPLOAD";

