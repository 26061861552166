import { put, takeEvery } from "redux-saga/effects";

import { baseUrl } from "../../../../envConfig";
import {doPostRequest} from "../../../utils/utils";

import * as RoofReportActionCreators from "./actionCreater";
import * as ROOF_REPORT_PAGE_ACTIONS from "./actionTypes";

export function* refundRoofReport(action) {
    const endpointUrl = baseUrl + "/Orders/refundorder";
    try {
        const response = yield doPostRequest(
            endpointUrl,
            JSON.stringify(action.payload)
        );
        window["AppInsightTrace"].trackTrace(
            `Refund Report Payload: ` +
                JSON.stringify(action.payload)
        );

        if (response) {
            window["AppInsightTrace"].trackTrace(
                `Refund Report - successs: ` +
                    JSON.stringify(response)
            );

            yield put(RoofReportActionCreators.refundReportSuccess(response));
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `Refund Report Failed: ` + JSON.stringify(err)
        );
        yield put(RoofReportActionCreators.refundReportFailed(err));
    }
}


export function* resendEmail(action) {
    const endpointUrl = baseUrl + "/Orders/resendEmail";
    try {
        const response = yield doPostRequest(
            endpointUrl,
            JSON.stringify(action.payload)
        );
        window["AppInsightTrace"].trackTrace(
            `Resend Email Payload: ` +
                JSON.stringify(action.payload)
        );

        if (response) {
            window["AppInsightTrace"].trackTrace(
                `Resend Email - successs: ` +
                    JSON.stringify(response)
            );

            yield put(RoofReportActionCreators.resendEmailSuccess(response));
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `Resend Email Failed: ` + JSON.stringify(err)
        );
        yield put(RoofReportActionCreators.resendEmailFailed(err));
    }
}

export function* transferOrder(action) {
    const endpointUrl = baseUrl + "/Orders/transferorder";
    try {
        const response = yield doPostRequest(
            endpointUrl,
            JSON.stringify(action.payload)
        );
        window["AppInsightTrace"].trackTrace(
            `Transfer Order Payload: ` +
                JSON.stringify(action.payload)
        );

        if (response) {
            window["AppInsightTrace"].trackTrace(
                `Transfer Order - successs: ` +
                    JSON.stringify(response)
            );

            yield put(RoofReportActionCreators.transferOrderSuccess(response));
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `Transfer Order Failed: ` + JSON.stringify(err)
        );
        yield put(RoofReportActionCreators.transferOrderFailed(err));
    }
}


export function* searchProfile(action) {
    const endpointUrl = baseUrl + "/SearchUserProfile";
    try {
        const response = yield doPostRequest(
            endpointUrl,
            JSON.stringify(action.payload)
        );
        window["AppInsightTrace"].trackTrace(
            `Search Profile Payload: ` +
                JSON.stringify(action.payload)
        );

        if (response) {
            window["AppInsightTrace"].trackTrace(
                `Search Profile - successs: ` +
                    JSON.stringify(response)
            );

            yield put(RoofReportActionCreators.searchProfileSuccess(response));
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `Search Profile Failed: ` + JSON.stringify(err)
        );
        yield put(RoofReportActionCreators.searchProfileFailed(err));
    }
}
export function* CustomersRoofReportPageWatcher() {
    yield takeEvery(ROOF_REPORT_PAGE_ACTIONS.REFUND_REPORT, refundRoofReport);
    yield takeEvery(ROOF_REPORT_PAGE_ACTIONS.RESEND_EMAIL, resendEmail);
    yield takeEvery(ROOF_REPORT_PAGE_ACTIONS.TRANSFER_ORDER, transferOrder);
    yield takeEvery(ROOF_REPORT_PAGE_ACTIONS.SEARCH_USER_PROFILE, searchProfile);
}
