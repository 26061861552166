import { string } from "prop-types";
import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import * as RESUBMIT_ACTIONS from "../containers/concierge/Resubmit/actionTypes";

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
export interface ResubmitState {
    outBoundOrders: any;
    isLoadingOutBoundOrders: boolean;
    isError: boolean;
    reProcessOrder: any;
    isProcessingOrders: boolean;
}

export interface ResubmitPage {
    actData: any;
    type: any;
    payload: any;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = ResubmitPage;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestAPI: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            actData: "",
            type: RESUBMIT_ACTIONS.GET_RE_PROCESS_ORDERS,
            payload: "",
        });
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultProps: ResubmitState = {
    outBoundOrders: {},
    isLoadingOutBoundOrders: false,
    isError: false,
    reProcessOrder: {},
    isProcessingOrders: false,
};

export const reducer: Reducer<ResubmitState> = (
    state: ResubmitState = defaultProps,
    incomingAction: Action
) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case RESUBMIT_ACTIONS.GET_RE_PROCESS_ORDERS:
            return {
                ...state,
                outBoundOrders: action.payload,
                isLoadingOutBoundOrders: true,
                isError: false,
            };
        case RESUBMIT_ACTIONS.GET_RE_PROCESS_ORDERS_FAILURE:
            return {
                ...state,
                outBoundOrders: action.payload,
                isLoadingOutBoundOrders: false,
                isError: true,
            };
        case RESUBMIT_ACTIONS.GET_RE_PROCESS_ORDERS_SUCCESS:
            return {
                ...state,
                outBoundOrders: action.payload,
                isLoadingOutBoundOrders: false,
                isError: false,
            };
        case RESUBMIT_ACTIONS.RE_PROCESS_ORDERS:
            return {
                ...state,
                reProcessOrder: action.payload,
                isProcessingOrders: true,
                isError: false,
            };
        case RESUBMIT_ACTIONS.RE_PROCESS_ORDERS_FAILURE:
            return {
                ...state,
                reProcessOrder: action.payload,
                isProcessingOrders: false,
                isError: true,
            };
        case RESUBMIT_ACTIONS.RE_PROCESS_ORDERS_SUCCESS:
            return {
                ...state,
                reProcessOrder: action.payload,
                isProcessingOrders: false,
                isError: false,
            };

        default:
        // The following line guarantees that every action in the KnownAction union has been covered by a case above
        // const exhaustiveCheck: never = action;
    }

    return state || defaultProps;
};
