import * as Home from "./HomePage";
import * as MyOrders from "./MyOrdersPage";
import * as MyReceipts from "./MyReceiptsPage";
import * as MyPreferences from "./MyPreferencePage";
import * as GuestHomePage from "./GuestHomePage";

import * as Loader from "./Loader";
import * as Customers from "./CustomersPage";
import * as CustomerDetails from "./CustomerDetailsPage";
import * as CustomersRoofReports from "./CustomersRoofReportPage";
import * as PromoCode from "./PromoCodePage";
import * as PathHistory from "./HistoryReducer";
import * as Header from "./Header";
import * as TrackOrder from "./TrackOrder";
import * as CreateAccount from "./CreateAccount";
import * as CancelGuestOrder from "./CancelOrder";
import * as SupportRequest from "./SupportRequest";
import * as VarAdmin from "./SubscriberAdmin";
import * as ApplicationAdmin from "./ApplicaitonAdmin";
import * as ServicePage from "./Service";
import * as ServiceBundle from "./ServiceBundle";
import * as Resubmit from "./ResubmitPage";

import { reducer as formReducer } from "redux-form";

// The top-level state object
export interface ApplicationState {
    HomePage: Home.HomePageState;
    MyOrders: MyOrders.MyOrdersPageState;
    MyReceipts: MyReceipts.MyReceiptsPageState;
    MyPreferences: MyPreferences.MyPreferencePageState;
    GuestHomePage: GuestHomePage.GuestHomePageState;
    Loader: Loader.LoaderState;
    Customers: Customers.CustomersPageState;
    CustomerDetails: CustomerDetails.CustomerDetailsPageState;
    CustomersRoofReports: CustomersRoofReports.CustomersRoofReportsPageState;
    PathHistory: PathHistory.HistoryState;
    Header: Header.HeaderState;
    TrackOrder: TrackOrder.TrackOrderState;
    CreateAccount: CreateAccount.CreateAccountState;
    CancelGuestOrder: CancelGuestOrder.CancelGuestOrderState;
    SupportRequest: SupportRequest.SupportRequestState;
    VarAdmin: VarAdmin.VarAdminState;
    ApplicationAdmin: ApplicationAdmin.ApplicationAdminState;
    ServiceAdmin: ServicePage.ServiceState;
    ServiceBundleAdmin: ServiceBundle.ServiceBundleState;
    PromoCode: PromoCode.PromoCodePageState;
    Resubmit: Resubmit.ResubmitState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    Header: Header.reducer,
    HomePage: Home.reducer,
    MyOrders: MyOrders.reducer,
    MyReceipts: MyReceipts.reducer,
    MyPreferences: MyPreferences.reducer,
    GuestHomePage: GuestHomePage.reducer,
    Loader: Loader.reducer,
    Customers: Customers.reducer,
    CustomerDetails: CustomerDetails.reducer,
    CustomersRoofReports: CustomersRoofReports.reducer,
    PathHistory: PathHistory.reducer,
    TrackOrder: TrackOrder.reducer,
    CreateAccount: CreateAccount.reducer,
    CancelGuestOrder: CancelGuestOrder.reducer,
    SupportRequest: SupportRequest.reducer,
    VarAdmin: VarAdmin.reducer,
    ApplicationAdmin: ApplicationAdmin.reducer,
    ServiceAdmin: ServicePage.reducer,
    ServiceBundleAdmin: ServiceBundle.reducer,
    PromoCode: PromoCode.reducer,
    Resubmit: Resubmit.reducer,
    form: formReducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (
        dispatch: (action: TAction) => void,
        getState: () => ApplicationState
    ): void;
}
