export const CHECK_DEBUG = "CHECK_DEBUG";
export type CHECK_DEBUG = "CHECK_DEBUG";

export const INITIATE_PAYMENT_REQUEST = "INITIATE_PAYMENT_REQUEST";
export type INITIATE_PAYMENT_REQUEST = typeof INITIATE_PAYMENT_REQUEST;

export const INITIATE_PAYMENT_REQUEST_SUCCESS = "INITIATE_PAYMENT_REQUEST_SUCCESS";
export type INITIATE_PAYMENT_REQUEST_SUCCESS = typeof INITIATE_PAYMENT_REQUEST_SUCCESS;

export const INITIATE_PAYMENT_REQUEST_FAILURE = "INITIATE_PAYMENT_REQUEST_FAILURE";
export type INITIATE_PAYMENT_REQUEST_FAILURE = typeof INITIATE_PAYMENT_REQUEST_FAILURE;


export const LOOKUP_CONTACT_DETAILS = "LOOKUP_CONTACT_DETAILS";
export type LOOKUP_CONTACT_DETAILS = typeof LOOKUP_CONTACT_DETAILS;

export const GUEST_LOOKUP_CONTACT_DETAILS = "GUEST_LOOKUP_CONTACT_DETAILS";
export type GUEST_LOOKUP_CONTACT_DETAILS = typeof GUEST_LOOKUP_CONTACT_DETAILS;

export const LOOKUP_CONTACT_DETAILS_SUCCESS = "LOOKUP_CONTACT_DETAILS_SUCCESS";
export type LOOKUP_CONTACT_DETAILS_SUCCESS = typeof LOOKUP_CONTACT_DETAILS_SUCCESS;

export const LOOKUP_CONTACT_DETAILS_FAILURE = "LOOKUP_CONTACT_DETAILS_FAILURE";
export type LOOKUP_CONTACT_DETAILS_FAILURE = typeof LOOKUP_CONTACT_DETAILS_FAILURE;

export const LOOKUP_ZENDESK_VAL = "LOOKUP_ZENDESK_VAL";
export type LOOKUP_ZENDESK_VAL = typeof LOOKUP_ZENDESK_VAL;

export const GUEST_LOOKUP_ZENDESK_VAL = "GUEST_LOOKUP_ZENDESK_VAL";
export type GUEST_LOOKUP_ZENDESK_VAL = typeof GUEST_LOOKUP_ZENDESK_VAL;

export const LOOKUP_ZENDESK_VAL_SUCCESS = "LOOKUP_ZENDESK_VAL_SUCCESS";
export type LOOKUP_ZENDESK_VAL_SUCCESS = typeof LOOKUP_ZENDESK_VAL_SUCCESS;

export const LOOKUP_ZENDESK_VAL_FAILURE = "LOOKUP_ZENDESK_VAL_FAILURE";
export type LOOKUP_ZENDESK_VAL_FAILURE = typeof LOOKUP_ZENDESK_VAL_FAILURE;