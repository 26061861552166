//TRACK_ORDER ACTIONS
export const TRACK_ORDER = "TRACK_ORDER";

export const TRACK_ORDER_SUCCESS = "TRACK_ORDER_SUCCESS";

export const TRACK_ORDER_FAILURE = "TRACK_ORDER_FAILURE";

export type TRACK_ORDER = typeof TRACK_ORDER;

export type TRACK_ORDER_SUCCESS = typeof TRACK_ORDER_SUCCESS;

export type TRACK_ORDER_FAILURE = typeof TRACK_ORDER_FAILURE;

//CANCEL_ORDER ACTIONS
export const CANCEL_ORDER = "CANCEL_ORDER";

export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS";

export const CANCEL_ORDER_FAILURE = "CANCEL_ORDER_FAILURE";

export type CANCEL_ORDER = typeof CANCEL_ORDER;

export type CANCEL_ORDER_SUCCESS = typeof CANCEL_ORDER_SUCCESS;

export type CANCEL_ORDER_FAILURE = typeof CANCEL_ORDER_FAILURE;

export const CLEAR_CANCEL_ORDER = "CLEAR_CANCEL_ORDER";

export type CLEAR_CANCEL_ORDER = typeof CLEAR_CANCEL_ORDER;

//DOWNLOAD_FULL_REPORT ACTIONS
export const DOWNLOAD_REPORT = "DOWNLOAD_FULL_REPORT";

export const DOWNLOAD_REPORT_SUCCESS = "DOWNLOAD_REPORT_SUCCESS";

export const DOWNLOAD_REPORT_FAILURE = "DOWNLOAD_REPORT_FAILURE";

export type DOWNLOAD_REPORT = typeof DOWNLOAD_REPORT;

export type DOWNLOAD_REPORT_SUCCESS = typeof DOWNLOAD_REPORT_SUCCESS;

export type DOWNLOAD_REPORT_FAILURE = typeof DOWNLOAD_REPORT_FAILURE;


// //DOWNLOAD_OWNER_REPORT ACTIONS
// export const DOWNLOAD_OWNER_REPORT = "DOWNLOAD_OWNER_REPORT";

// export const DOWNLOAD_OWNER_REPORT_SUCCESS = "DOWNLOAD_OWNER_REPORT_SUCCESS";

// export const DOWNLOAD_OWNER_REPORT_FAILURE = "DOWNLOAD_OWNER_REPORT_FAILURE";

// export type DOWNLOAD_OWNER_REPORT = typeof DOWNLOAD_OWNER_REPORT;

// export type DOWNLOAD_OWNER_REPORT_SUCCESS = typeof DOWNLOAD_OWNER_REPORT_SUCCESS;

// export type DOWNLOAD_OWNER_REPORT_FAILURE = typeof DOWNLOAD_OWNER_REPORT_FAILURE;

// //DOWNLOAD_XML_REPORT ACTIONS
// export const DOWNLOAD_XML_REPORT = "DOWNLOAD_XML_REPORT";

// export const DOWNLOAD_XML_REPORT_SUCCESS = "DOWNLOAD_XML_REPORT_SUCCESS";

// export const DOWNLOAD_XML_REPORT_FAILURE = "DOWNLOAD_XML_REPORT_FAILURE";

// export type DOWNLOAD_XML_REPORT = typeof DOWNLOAD_XML_REPORT;

// export type DOWNLOAD_XML_REPORT_SUCCESS = typeof DOWNLOAD_XML_REPORT_SUCCESS;

// export type DOWNLOAD_XML_REPORT_FAILURE = typeof DOWNLOAD_XML_REPORT_FAILURE;