import { put, takeEvery, call } from "redux-saga/effects";

import * as MyPreferenceActionCreator from "./actionCreater";
import * as MY_PREFERENCE_ACTIONS from "./actionTypes";

import { baseUrl } from "../../../envConfig";
import { doGetRequest, doPostRequest } from "../../utils/utils";

export function* getStateList(action) {
    const endpointUrl = baseUrl + `/lookup?lookupName=` + action.payload;
    try {
        const response = yield doGetRequest(endpointUrl, null);
        window["AppInsightTrace"].trackTrace(
            `GET State List - request for My Preference Page: `
        );
        if (response) {
            window["AppInsightTrace"].trackTrace(
                `GET State List - success for My Preference Page: ` +
                    JSON.stringify(response)
            );
            yield put(
                MyPreferenceActionCreator.getStateListFromLookUpSuccess(
                    response
                )
            );
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `GET Act Type - Failed for MyPreference Page: ` +
                JSON.stringify(err)
        );

        yield put(MyPreferenceActionCreator.getStateListFromLookUpFailure(err));
    }
}

export function* savePreferences(action) {
    const endpointUrl = baseUrl + "/SavePreferences";
    try {
        const response = yield doPostRequest(
            endpointUrl,
            JSON.stringify(action.payload)
        );
        window["AppInsightTrace"].trackTrace(
            `Save Preferences - POST request for My Preference Page - Payload: ` +
                JSON.stringify(action.payload)
        );

        if (response) {
            window["AppInsightTrace"].trackTrace(
                `Save Preferences - POST request success for My Preference Page : ` +
                    JSON.stringify(response)
            );

            yield put(
                MyPreferenceActionCreator.savePreferencesSuccess(response)
            );
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `Save Preferene - post request Failed for My Preference Page : ` +
                JSON.stringify(err)
        );
        yield put(MyPreferenceActionCreator.savePreferencesFailure(err));
    }
}

export function* getPreferences(action) {
    const endpointUrl =
        baseUrl + "/GetPreferences?emailAddress=" + action.payload;
    try {
        const response = yield doGetRequest(endpointUrl, null);
        window["AppInsightTrace"].trackTrace(
            `GET Preferences - request for My Preference Page: `
        );
        if (response) {
            // console.log(response);
            window["AppInsightTrace"].trackTrace(
                `GET Preferences - success for My Preference Page: ` +
                    JSON.stringify(response)
            );
            yield put(
                MyPreferenceActionCreator.getPreferencesSuccess(response)
            );
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `GET Preferences - Failed for My Preferences Page: ` +
                JSON.stringify(err)
        );

        yield put(MyPreferenceActionCreator.getPreferencesFailure(err));
    }
}

export function* getAvailableAddresses(action) {
    const endpointUrl =
        baseUrl + "/GetAvailableAddress?emailaddress=" + action.payload;
    try {
        const response = yield doGetRequest(endpointUrl, null);
        window["AppInsightTrace"].trackTrace(
            `GET Available Addresses - request for My Preference Page: `
        );
        if (response) {
            window["AppInsightTrace"].trackTrace(
                `GET Available Addresses - success for My Preference Page: ` +
                    JSON.stringify(response)
            );
            yield put(
                MyPreferenceActionCreator.getAvailableAddressesSuccess(response)
            );
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `GET Available Addresses - Failed for My Preferences Page: ` +
                JSON.stringify(err)
        );

        yield put(MyPreferenceActionCreator.getAvailableAddressesFailure(err));
    }
}

export function* getReplenishment(action) {
    const endpointUrl = baseUrl + "/user/getreplenishmentpreferences";
    try {
        const response = yield doPostRequest(
            endpointUrl,
            JSON.stringify(action.payload)
        );
        window["AppInsightTrace"].trackTrace(
            `Get Replenishment - POST request for My Preference Page - Payload: ` +
                JSON.stringify(action.payload)
        );

        if (response) {
            window["AppInsightTrace"].trackTrace(
                `Get Replenishment - POST request success for My Preference Page : ` +
                    JSON.stringify(response)
            );

            yield put(
                MyPreferenceActionCreator.getReplenishmentSuccess(response)
            );
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `Get Replenishment - post request Failed for My Preference Page : ` +
                JSON.stringify(err)
        );
        yield put(MyPreferenceActionCreator.getReplenishmentFailure(err));
    }
}

export function* saveReplenishment(action) {
    const endpointUrl = baseUrl + "/user/savereplenishmentpreferences";
    try {
        const response = yield doPostRequest(
            endpointUrl,
            JSON.stringify(action.payload)
        );
        window["AppInsightTrace"].trackTrace(
            `Save Replenishment - POST request for My Preference Page - Payload: ` +
                JSON.stringify(action.payload)
        );

        if (response) {
            window["AppInsightTrace"].trackTrace(
                `Save Replenishment - POST request success for My Preference Page : ` +
                    JSON.stringify(response)
            );

            yield put(
                MyPreferenceActionCreator.saveReplenishmentDataSuccess(response)
            );
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `Save Replenishment - post request Failed for My Preference Page : ` +
                JSON.stringify(err)
        );
        yield put(MyPreferenceActionCreator.saveReplenishmentDataFailure(err));
    }
}

export function* getPaymentPreferencesList(action) {
    const endpointUrl = baseUrl + `/lookup?lookupName=PaymentPreferenceType`;
    try {
        const response = yield doGetRequest(endpointUrl, null);
        window["AppInsightTrace"].trackTrace(
            `Lookup for Payment Preference List - request for My Preference Page.`
        );
        if (response) {
            window["AppInsightTrace"].trackTrace(
                `Lookup for Payment Preference List - success for My Preference Page: ` +
                    JSON.stringify(response)
            );
            yield put(
                MyPreferenceActionCreator.getPaymentPreferencesSuccess(response)
            );
        }
    } catch (err) {
        console.log(err);
        window["AppInsightTrace"].trackTrace(
            `Lookup for Payment Preference List - Failed for MyPreference Page: ` +
                JSON.stringify(err)
        );

        yield put(MyPreferenceActionCreator.getPaymentPreferencesFailure(err));
    }
}

export function* getRadioAmts() {
    const endpointUrl = baseUrl + `/lookup?lookupName=ReplenishAmount`;
    try {
        const response = yield doGetRequest(endpointUrl, null);
        window["AppInsightTrace"].trackTrace(
            `Lookup call to get Replenishment amounts radio values. `
        );
        if (response) {
            window["AppInsightTrace"].trackTrace(
                `Lookup call to get Replenishment amounts radio values: Success` +
                    JSON.stringify(response)
            );
            yield put(
                MyPreferenceActionCreator.getReplenishRadioAmountsLookupSuccess(
                    response
                )
            );
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `Lookup call to get Replenishment amounts radio values: Error : ` +
                JSON.stringify(err)
        );

        yield put(
            MyPreferenceActionCreator.getReplenishRadioAmountsLookupFailure(err)
        );
    }
}

export function* getProductPreferences(action) {
    const endpointUrl = baseUrl + "/user/getproductpreferences";
    try {
        const response = yield doPostRequest(
            endpointUrl,
            JSON.stringify(action.payload)
        );
        window["AppInsightTrace"].trackTrace(
            `Get Product Preferences - My Preference Page, Service tab - Payload: ` +
                JSON.stringify(action.payload)
        );

        if (response) {
            window["AppInsightTrace"].trackTrace(
                `Get Product Preferences - My Preference Page, Service tab - Success : ` +
                    JSON.stringify(response)
            );

            yield put(
                MyPreferenceActionCreator.getProductPreferencesSuccess(response)
            );
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `Get Product Preferences - My Preference Page, Service tab Failed : ` +
                JSON.stringify(err)
        );
        yield put(MyPreferenceActionCreator.getProductPreferencesFailure(err));
    }
}

export function* saveProductPreferences(action) {
    const endpointUrl = baseUrl + "/user/saveproductpreferences";
    try {
        const response = yield doPostRequest(
            endpointUrl,
            JSON.stringify(action.payload)
        );
        window["AppInsightTrace"].trackTrace(
            `Save Product Preferences - My Preference Page, Service tab - Payload: ` +
                JSON.stringify(action.payload)
        );

        if (response) {
            window["AppInsightTrace"].trackTrace(
                `Save Product Preferences - My Preference Page, Service tab - Success : ` +
                    JSON.stringify(response)
            );

            yield put(
                MyPreferenceActionCreator.saveProductPreferencesSuccess(
                    response
                )
            );
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `Save Product Preferences - My Preference Page, Service tab Failed : ` +
                JSON.stringify(err)
        );
        yield put(MyPreferenceActionCreator.saveProductPreferencesFailure(err));
    }
}

export function* MyPreferenceWatcher() {
    yield takeEvery(MY_PREFERENCE_ACTIONS.GET_STATE_LIST, getStateList);
    yield takeEvery(MY_PREFERENCE_ACTIONS.SAVE_PREFERENCE, savePreferences);
    yield takeEvery(MY_PREFERENCE_ACTIONS.GET_PREFERENCE, getPreferences);
    yield takeEvery(
        MY_PREFERENCE_ACTIONS.GET_AVAILABLE_ADDRESSES,
        getAvailableAddresses
    );
    yield takeEvery(MY_PREFERENCE_ACTIONS.GET_REPLENISHMENT, getReplenishment);
    yield takeEvery(
        MY_PREFERENCE_ACTIONS.SAVE_REPLENISHMENT,
        saveReplenishment
    );
    yield takeEvery(
        MY_PREFERENCE_ACTIONS.GET_PAYMENT_PREFERENCE_TYPES,
        getPaymentPreferencesList
    );
    yield takeEvery(
        MY_PREFERENCE_ACTIONS.GET_REPLENISHMENT_RADIO_AMOUNTS,
        getRadioAmts
    );
    yield takeEvery(
        MY_PREFERENCE_ACTIONS.GET_PRODUCT_PREFERENCES,
        getProductPreferences
    );
    yield takeEvery(
        MY_PREFERENCE_ACTIONS.SAVE_PRODUCT_PREFERENCES,
        saveProductPreferences
    );
}
