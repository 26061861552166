import * as LOADER from "./actionTypes";

export function turnOn(): any {
    return {
        type: LOADER.LOADING_ON
    };
}

export function turnOff(): any {
    return {
        type: LOADER.LOADING_OFF
    };
}
