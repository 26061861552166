import * as React from "react";
import { connect } from "react-redux";

const SignInAs = (props) => {
    const { guestUser } = props;
    return (
        <span className="sing-in-as">{
            guestUser == true ? "" :
                (<>
                    <span className="Disclaimer">{props.emailAddress}</span>
                    <span className="d-lg-inline-block d-none">&nbsp;-&nbsp; </span>
                    <span className="certification DisclaimerBold text-capitalize">
                        {props.displayGAFCertification}
                    </span>
                </>
                )
        }
        </span>
    );
};

const mapStateToProps = (state) => {
    const {
        emailAddress,
        displayGAFCertification,
    } = state.HomePage.userProfile;

    return { emailAddress, displayGAFCertification };
};

export default connect(mapStateToProps)(SignInAs);
