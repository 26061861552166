import * as HEADER_ACTION_TYPES from "./actionTypes";

export function getDebugFromQueryString(data): any {
    return {
        payload: data,
        type: HEADER_ACTION_TYPES.CHECK_DEBUG,
    };
}

export function initiatePaymentRequest(data): any {
    return {
        type: HEADER_ACTION_TYPES.INITIATE_PAYMENT_REQUEST,
        payload: data,
    };
}

export function initiatePaymentRequestSuccess(data): any {
    return {
        type: HEADER_ACTION_TYPES.INITIATE_PAYMENT_REQUEST_SUCCESS,
        payload: data,
    };
}

export function initiatePaymentRequestFailure(err): any {
    return {
        type: HEADER_ACTION_TYPES.INITIATE_PAYMENT_REQUEST_FAILURE,
        payload: err,
    };
}

export function lookupContactDetails(data): any {
    return {
        type: HEADER_ACTION_TYPES.LOOKUP_CONTACT_DETAILS,
        payload: data,
    };
}

export function guestLookupContactDetails(data): any {
    return {
        type: HEADER_ACTION_TYPES.GUEST_LOOKUP_CONTACT_DETAILS,
        payload: data,
    };
}

export function lookupContactDetailsSuccess(data): any {
    return {
        type: HEADER_ACTION_TYPES.LOOKUP_CONTACT_DETAILS_SUCCESS,
        payload: data,
    };
}

export function lookupContactDetailsFailure(err): any {
    return {
        type: HEADER_ACTION_TYPES.LOOKUP_CONTACT_DETAILS_FAILURE,
        payload: err,
    };
}

export function lookupZenDeskVal(data): any {
    return {
        type: HEADER_ACTION_TYPES.LOOKUP_ZENDESK_VAL,
        payload: data,
    };
}

export function guestLookupZenDeskVal(data): any {
    return {
        type: HEADER_ACTION_TYPES.GUEST_LOOKUP_ZENDESK_VAL,
        payload: data,
    };
}

export function lookupZenDeskValSuccess(data): any {
    return {
        type: HEADER_ACTION_TYPES.LOOKUP_ZENDESK_VAL_SUCCESS,
        payload: data,
    };
}

export function lookupZenDeskValFailure(err): any {
    return {
        type: HEADER_ACTION_TYPES.LOOKUP_ZENDESK_VAL_FAILURE,
        payload: err,
    };
}
