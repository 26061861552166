import * as React from "react";
import "./style.scss";

export const CheckBox = (props) => {

    const { labelName, checkBoxName, value, selectedValue, onChange, checkBoxFor, disabled, checked } = props;
    const className = props.className === undefined ? "" : props.className;
    
    return (
        <div>
            <label htmlFor={checkBoxFor} className={`checkBox ${className}`} >
                <input type="checkbox" name={checkBoxName}
                    id={checkBoxFor}
                    className="checkbox-input"
                    value={value}
                    defaultChecked={checked}
                    disabled={disabled && disabled}
                    checked={selectedValue === value}
                    onChange={onChange}
                />
                <div className={disabled === true ? `disabled-checkbox custom-checkBox` : "custom-checkBox"}
                ></div>
                {labelName}
            </label>
        </div>
    )
}
export default CheckBox;