export const SHOULD_DISABLE_SAVE_PREF_BTN = "SHOULD_DISABLE_SAVE_PREF_BTN";
export type SHOULD_DISABLE_SAVE_PREF_BTN = typeof SHOULD_DISABLE_SAVE_PREF_BTN;

export const GET_STATE_LIST = "GET_STATE_LIST";
export type GET_STATE_LIST = typeof GET_STATE_LIST;

export const GET_STATE_LIST_SUCCESS = "GET_STATE_LIST_SUCCESS";
export type GET_STATE_LIST_SUCCESS = typeof GET_STATE_LIST_SUCCESS;

export const GET_STATE_LIST_FAILURE = "GET_STATE_LIST_FAILURE";
export type GET_STATE_LIST_FAILURE = typeof GET_STATE_LIST_FAILURE;

export const SAVE_PREFERENCE = "SAVE_PREFERENCE";
export type SAVE_PREFERENCE = typeof SAVE_PREFERENCE;

export const SAVE_PREFERENCE_SUCCESS = "SAVE_PREFERENCE_SUCCESS";
export type SAVE_PREFERENCE_SUCCESS = typeof SAVE_PREFERENCE_SUCCESS;

export const SAVE_PREFERENCE_FAILURE = "SAVE_PREFERENCE_FAILURE";
export type SAVE_PREFERENCE_FAILURE = typeof SAVE_PREFERENCE_FAILURE;

export const GET_PREFERENCE = "GET_PREFERENCE";
export type GET_PREFERENCE = typeof GET_PREFERENCE;

export const GET_PREFERENCE_SUCCESS = "GET_PREFERENCE_SUCCESS";
export type GET_PREFERENCE_SUCCESS = typeof GET_PREFERENCE_SUCCESS;

export const GET_PREFERENCE_FAILURE = "GET_PREFERENCE_FAILURE";
export type GET_PREFERENCE_FAILURE = typeof GET_PREFERENCE_FAILURE;

export const GET_AVAILABLE_ADDRESSES = "GET_AVAILABLE_ADDRESSES";
export type GET_AVAILABLE_ADDRESSES = typeof GET_AVAILABLE_ADDRESSES;

export const GET_AVAILABLE_ADDRESSES_SUCCESS = "GET_AVAILABLE_ADDRESSES_SUCCESS";
export type GET_AVAILABLE_ADDRESSES_SUCCESS = typeof GET_AVAILABLE_ADDRESSES_SUCCESS;

export const GET_AVAILABLE_ADDRESSES_FAILURE = "GET_AVAILABLE_ADDRESSES_FAILURE";
export type GET_AVAILABLE_ADDRESSES_FAILURE = typeof GET_AVAILABLE_ADDRESSES_FAILURE;

// Replenish tab actions ---
export const GET_REPLENISHMENT = "GET_REPLENISHMENT";
export type GET_REPLENISHMENT = typeof GET_REPLENISHMENT;

export const GET_REPLENISHMENT_SUCCESS = "GET_REPLENISHMENT_SUCCESS";
export type GET_REPLENISHMENT_SUCCESS = typeof GET_REPLENISHMENT_SUCCESS;

export const GET_REPLENISHMENT_FAILURE = "GET_REPLENISHMENT_FAILURE";
export type GET_REPLENISHMENT_FAILURE = typeof GET_REPLENISHMENT_FAILURE;

export const RESET_REPLENISHMENT = "RESET_REPLENISHMENT";
export type RESET_REPLENISHMENT = typeof RESET_REPLENISHMENT;

export const SAVE_REPLENISHMENT = "SAVE_REPLENISHMENT";
export type SAVE_REPLENISHMENT = typeof SAVE_REPLENISHMENT;

export const SAVE_REPLENISHMENT_SUCCESS = "SAVE_REPLENISHMENT_SUCCESS";
export type SAVE_REPLENISHMENT_SUCCESS = typeof SAVE_REPLENISHMENT_SUCCESS;

export const SAVE_REPLENISHMENT_FAILURE = "SAVE_REPLENISHMENT_FAILURE";
export type SAVE_REPLENISHMENT_FAILURE = typeof SAVE_REPLENISHMENT_FAILURE;


export const GET_PAYMENT_PREFERENCE_TYPES = "GET_PAYMENT_PREFERENCE_TYPES";
export type GET_PAYMENT_PREFERENCE_TYPES = typeof GET_PAYMENT_PREFERENCE_TYPES;

export const GET_PAYMENT_PREFERENCE_TYPES_SUCCESS = "GET_PAYMENT_PREFERENCE_TYPES_SUCCESS";
export type GET_PAYMENT_PREFERENCE_TYPES_SUCCESS = typeof GET_PAYMENT_PREFERENCE_TYPES_SUCCESS;

export const GET_PAYMENT_PREFERENCE_TYPES_FAILURE = "GET_PAYMENT_PREFERENCE_TYPES_FAILURE";
export type GET_PAYMENT_PREFERENCE_TYPES_FAILURE = typeof GET_PAYMENT_PREFERENCE_TYPES_FAILURE;

export const GET_REPLENISHMENT_RADIO_AMOUNTS = "GET_REPLENISHMENT_RADIO_AMOUNTS";
export type GET_REPLENISHMENT_RADIO_AMOUNTS = typeof GET_REPLENISHMENT_RADIO_AMOUNTS;

export const GET_REPLENISHMENT_RADIO_AMOUNTS_SUCCESS = "GET_REPLENISHMENT_RADIO_AMOUNTS_SUCCESS";
export type GET_REPLENISHMENT_RADIO_AMOUNTS_SUCCESS = typeof GET_REPLENISHMENT_RADIO_AMOUNTS_SUCCESS;

export const GET_REPLENISHMENT_RADIO_AMOUNTS_FAILURE = "GET_REPLENISHMENT_RADIO_AMOUNTS_FAILURE";
export type GET_REPLENISHMENT_RADIO_AMOUNTS_FAILURE = typeof GET_REPLENISHMENT_RADIO_AMOUNTS_FAILURE;

export const GET_PRODUCT_PREFERENCES = "GET_PRODUCT_PREFERENCES";
export type GET_PRODUCT_PREFERENCES = typeof GET_PRODUCT_PREFERENCES;

export const GET_PRODUCT_PREFERENCE_SUCCESS = "GET_PRODUCT_PREFERENCE_SUCCESS";
export type GET_PRODUCT_PREFERENCEE_SUCCESS = typeof GET_PRODUCT_PREFERENCE_SUCCESS;

export const GET_PRODUCT_PREFERENCE_FAILURE = "GET_PRODUCT_PREFERENCE_FAILURE";
export type GET_PRODUCT_PREFERENCE_FAILURE = typeof GET_PRODUCT_PREFERENCE_FAILURE;

export const RESET_PRODUCT_PREFERENCES = "RESET_PRODUCT_PREFERENCES";
export type RESET_PRODUCT_PREFERENCES = typeof RESET_PRODUCT_PREFERENCES;

export const SAVE_PRODUCT_PREFERENCES = "SAVE_PRODUCT_PREFERENCES";
export type SAVE_PRODUCT_PREFERENCES = typeof SAVE_PRODUCT_PREFERENCES;

export const SAVE_PRODUCT_PREFERENCE_SUCCESS = "SAVE_PRODUCT_PREFERENCE_SUCCESS";
export type SAVE_PRODUCT_PREFERENCEE_SUCCESS = typeof SAVE_PRODUCT_PREFERENCE_SUCCESS;

export const SAVE_PRODUCT_PREFERENCE_FAILURE = "SAVE_PRODUCT_PREFERENCE_FAILURE";
export type SAVE_PRODUCT_PREFERENCE_FAILURE = typeof SAVE_PRODUCT_PREFERENCE_FAILURE;
