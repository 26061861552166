export const API_CUSTOM_SCOPES = [
    // 'openid',
    // 'email',
    // 'token',
    'GetUserProfile',
];

export const GAF_EMPLOYEE = [

    'SearchCustomers',

    // All Users
    'CheckCoverage',
    'IsServiceOpen',
    'SiteStatus',
    'SendErrorReport',
    'SearchOrders',
    'PlaceOrder',
    'InitiatePayment',
    'UpdatePayment',
    'IsValidPromoCode',
    'RedeemPromoCode',
    'SearchReceipts',
    'DownloadRoofReport',
    'GetUserProfile',
    'SaveUserProfile',
    'GetLookup',
    'User:SavePreferences',
    'User:GetPreferences',
    'User:GetAvailableAddresses',
    'User:SetNotificationLog',
    'User:GetReplenishmentPreferences',
    'User:SaveReplenishmentPreferences',
    'User:GetProductPreferences',
    'User:SaveProductPreferences',
    'User:AcceptTermsAndConditions',
    'Track',
    'Guest:AcceptTermsAndConditions',
    // 'Guest:Track',
    'GetDistributorsForPostalCode',
    'UpdateOrderService',
    'DownloadFile',
    'BPFileupload',
]
export const API_SUPER_ADMIN_SCOPES = [

    'openid',
    'email',
    'profile',

    // All Users
    'CheckCoverage',
    'IsServiceOpen',
    'SiteStatus',
    'SendErrorReport',
    'SearchOrders',
    'PlaceOrder',
    'InitiatePayment',
    'UpdatePayment',
    'IsValidPromoCode',
    'RedeemPromoCode',
    'SearchReceipts',
    'DownloadRoofReport',
    'GetUserProfile',
    'SaveUserProfile',
    'GetLookup',
    'User:SavePreferences',
    'User:GetPreferences',
    'User:GetAvailableAddresses',
    'User:SetNotificationLog',
    'User:GetReplenishmentPreferences',
    'User:SaveReplenishmentPreferences',
    'User:GetProductPreferences',
    'User:SaveProductPreferences',
    'User:AcceptTermsAndConditions',
    'Track',
    'Guest:AcceptTermsAndConditions',
    // 'Guest:Track',
    'GetDistributorsForPostalCode',
    'UpdateOrderService',
    'DownloadFile',
    'BPFileupload',

    // -------------------
    // Admin
    // -------------------
    'SearchCustomers',
    'SearchPromoCodes',
    'UpdateParent',
    'SearchAllOrders',
    'GetProfiles',
    'UpdateUserForSpecialPrice',
    'Refund',
    'Reject',
    'Config',

    // -------------------
    // Super Admin
    // -------------------
    'AddSupportRequest',
    'RoofMeasureCreate',
    'RoofMeasureProcess',
    'SynchronizeUser',
    'GeneratePromoCode',
    'GetPromoCodesForBatch',
    'PlaceOrderForVendor',
    'RefreshProfileCache',
    'Subscriber:GetSubscriberDetails',
    'AddUpdateSubscriber',
    'AddUpdateSubscriberConfiguration',
    'AddUpdateSubscriberProduct',
    'GetLookup',
    'AddUpdateLookup',
    'CustomerSupport:TransferBalance',
    'Products:GetProducts',
    'Products:AddUpdateProduct',
    'Products:AddUpdateProductServices',
    'Services:GetServices',
    'Services:AddUpdateService',
    'CustomerSupport:GetReprocessOutBoundOrders',
    'CustomerSupport:ReprocessOutBoundOrders',
    'Orders:RefundOrder',
    'Orders:ResendEmail',
    'Orders:TransferOrder',
    'SearchUserProfile',
];
export const API_ADMIN_SCOPES = [
    'openid',
    'email',
    'profile',

    // All Users
    'CheckCoverage',
    'IsServiceOpen',
    'SiteStatus',
    'SendErrorReport',
    'SearchOrders',
    'PlaceOrder',
    'InitiatePayment',
    'UpdatePayment',
    'IsValidPromoCode',
    'RedeemPromoCode',
    'SearchReceipts',
    'DownloadRoofReport',
    'GetUserProfile',
    'SaveUserProfile',
    'GetLookup',
    'User:SavePreferences',
    'User:GetPreferences',
    'User:GetAvailableAddresses',
    'User:SetNotificationLog',
    'User:GetReplenishmentPreferences',
    'User:SaveReplenishmentPreferences',
    'User:GetProductPreferences',
    'User:SaveProductPreferences',
    'User:AcceptTermsAndConditions',
    'Track',
    'Guest:AcceptTermsAndConditions',
    // 'Guest:Track',
    'GetDistributorsForPostalCode',
    'UpdateOrderService',
    'DownloadFile',
    'BPFileupload',
    // -------------------
    // Admin
    // -------------------
    'AddSupportRequest',
    'SearchCustomers',
    'SearchPromoCodes',
    'UpdateParent',
    'SearchAllOrders',
    'GetProfiles',
    'UpdateUserForSpecialPrice',
    'Refund',
    'Reject',
    'Config',

];
export const API_USER_SCOPES = [

    'openid',
    'email',
    'profile',

    // All Users
    'CheckCoverage',
    'IsServiceOpen',
    'SiteStatus',
    'SendErrorReport',
    'SearchOrders',
    'PlaceOrder',
    'InitiatePayment',
    'UpdatePayment',
    'IsValidPromoCode',
    'RedeemPromoCode',
    'SearchReceipts',
    'DownloadRoofReport',
    'GetUserProfile',
    'SaveUserProfile',
    'GetLookup',
    'User:SavePreferences',
    'User:GetPreferences',
    'User:GetAvailableAddresses',
    'User:SetNotificationLog',
    'User:GetReplenishmentPreferences',
    'User:SaveReplenishmentPreferences',
    'User:GetProductPreferences',
    'User:SaveProductPreferences',
    'User:AcceptTermsAndConditions',
    'Track',
    'Guest:AcceptTermsAndConditions',
    // 'Guest:Track',
    'GetDistributorsForPostalCode',
    'UpdateOrderService',
    'DownloadFile',
    'BPFileupload',
]