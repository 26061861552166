import * as SERVICE_BUNDLE_ACTIONS from "./actionTypes";

export function selectedServiceBundle(data): any {
    return {
        type: SERVICE_BUNDLE_ACTIONS.SELECTED_BUNDLE,
        payload: data,
    };
}

export function isAddNewServiceBundle(data): any {
    return {
        type: SERVICE_BUNDLE_ACTIONS.ADD_NEW_BUNDLE,
        payload: data,
    };
}

export function getProducts(data): any {
    return {
        type: SERVICE_BUNDLE_ACTIONS.GET_PRODUCTS,
        payload: data,
    };
}

export function getProductsSuccess(data): any {
    return {
        type: SERVICE_BUNDLE_ACTIONS.GET_PRODUCTS_SUCCESS,
        payload: data,
    };
}

export function getProductsFailure(error): any {
    return {
        type: SERVICE_BUNDLE_ACTIONS.GET_PRODUCTS_FAILURE,
        payload: error,
    };
}

export function addEditBundle(data): any {
    return {
        type: SERVICE_BUNDLE_ACTIONS.ADD_EDIT_BUNDLE,
        payload: data,
    };
}

export function addEditBundleSuccess(data): any {
    return {
        type: SERVICE_BUNDLE_ACTIONS.ADD_EDIT_BUNDLE_SUCCESS,
        payload: data,
    };
}

export function addEditBundleFailure(error): any {
    return {
        type: SERVICE_BUNDLE_ACTIONS.ADD_EDIT_BUNDLE_FAILURE,
        payload: error,
    };
}

export function addUpdateProductsService(data): any {
    return {
        type: SERVICE_BUNDLE_ACTIONS.ADD_UPDATE_PRODUCT_SERVICES,
        payload: data,
    };
}

export function addUpdateProductsServiceSuccess(data): any {
    return {
        type: SERVICE_BUNDLE_ACTIONS.ADD_UPDATE_PRODUCT_SERVICES_SUCCESS,
        payload: data,
    };
}

export function addUpdateProductsServiceFailure(error): any {
    return {
        type: SERVICE_BUNDLE_ACTIONS.ADD_UPDATE_PRODUCT_SERVICES_FAILURE,
        payload: error,
    };
}

// export function resetAddEditBundle(): any {
//     return {
//         type: SERVICE_BUNDLE_ACTIONS.RESET_ADD_EDIT_BUNDLE,
//     };
// }
