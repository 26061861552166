import * as React from "react";
import { GafOktaManager } from "../../auth/gaf-okta-auth";
import { Redirect } from "react-router-dom";

export class HomeNotLoggedIn extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            authenticated: null,
            error: null,
        };
    }

    componentDidMount() {
        // debugger;

        const authenticated = GafOktaManager.isAuthenticated;
        this.setState({ authenticated }, () => {
            if (!this.state.authenticated) {
                GafOktaManager.login();
            }
        });

    }

    render() {

        if (!GafOktaManager.isAuthenticated) {
            return null
            // return <Redirect to="/guest-home-page"></Redirect>
        } else {
            return <Redirect to="/homeloggedin"></Redirect>;
        }

    }
}
