import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import * as LoaderActions from "../components/Loader/actionTypes";

//import IProductsProps from '../models/HomePage/IProductSelection'
// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
export interface LoaderState {
    loading: boolean;
}

export interface GetLoaderData {
    type: string;
    // payload: any;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = GetLoaderData;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultProps: LoaderState = {
    loading: false
};

export const reducer: Reducer<LoaderState> = (
    state: LoaderState = defaultProps,
    incomingAction: Action
) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case LoaderActions.LOADING_ON:
            return {
                ...state,
                loading: true
            };
            break;
        case LoaderActions.LOADING_OFF:
            return {
                ...state,
                loading: false
            };
            break;
        default:
        // The following line guarantees that every action in the KnownAction union has been covered by a case above
        // const exhaustiveCheck: never = action;
    }

    return state || defaultProps;
};
