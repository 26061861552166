import { put, takeLatest, takeEvery, call } from "redux-saga/effects";

import * as HeaderCreateors from "./actionCreater";
import * as HEADER_ACTIONS from "./actionTypes";

import { baseUrl } from "../../../envConfig";
import { doGetRequest, doPostRequest, getRequest } from "../../utils/utils";

export function* initiatePaymentRequest(action) {
    const endpointUrl = baseUrl + `/payments/initiate`;
    try {
        const response = yield doPostRequest(
            endpointUrl,
            JSON.stringify(action.payload)
        );
        window["AppInsightTrace"].trackTrace(
            `Initiate Payment Request - Payload: ` +
                JSON.stringify(action.payload)
        );

        if (response) {
            window["AppInsightTrace"].trackTrace(
                `Initiate Payment Request - POST request success: ` +
                    JSON.stringify(response)
            );

            yield put(HeaderCreateors.initiatePaymentRequestSuccess(response));
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `Initiate Payment Request - POST request Failed: ` + err
        );
        yield put(HeaderCreateors.initiatePaymentRequestFailure(err));
    }
}
export function* lookupContactDetails(action) {
    const endpointUrl = baseUrl + `/lookup?lookupName=ContactConfiguration`;
    try {
        const response = yield doGetRequest(endpointUrl, null);
        window["AppInsightTrace"].trackTrace(`Lookup request for Contact details`);
        if (response) {
            window["AppInsightTrace"].trackTrace(
                `Lookup request for Contact details success: ` +
                    JSON.stringify(response)
            );

            yield put(HeaderCreateors.lookupContactDetailsSuccess(response));
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(`Lookup request for Contact details Failed: ` + err);
        yield put(HeaderCreateors.lookupContactDetailsFailure(err));
    }
}

// Lookup Contact Details for Guest
export function* guestContactDetails() {
    const endpointUrl = baseUrl + `/guest/ContactConfiguration`;
    try {
        const response = yield getRequest(endpointUrl, null);
        if (response) {
            yield put(HeaderCreateors.lookupContactDetailsSuccess(response));
        }
    } catch (err) {
        yield put(HeaderCreateors.lookupContactDetailsFailure(err));
    }
}

export function* lookupZenDeskFeature(action) {
    const endpointUrl = baseUrl + `/lookup?lookupName=isZenDeskChatEnabled`;
    try {
        const response = yield doGetRequest(endpointUrl, null);
        window["AppInsightTrace"].trackTrace(`Lookup request for ZenDesk Feature`);
        if (response) {
            window["AppInsightTrace"].trackTrace(
                `Lookup request for ZenDesk Feature success: ` +
                    JSON.stringify(response)
            );

            yield put(HeaderCreateors.lookupZenDeskValSuccess(response));
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(`Lookup request for ZenDesk Feature Failed: ` + err);
        yield put(HeaderCreateors.lookupZenDeskValFailure(err));
    }
}

// Lookup Contact Details for Guest
export function* guestlookupZenDeskFeature() {
    const endpointUrl = baseUrl + `/guest/GetZenDeskChatConfig`;
    try {
        const response = yield getRequest(endpointUrl, null);
        if (response) {
            yield put(HeaderCreateors.lookupZenDeskValSuccess(response));
        }
    } catch (err) {
        yield put(HeaderCreateors.lookupZenDeskValFailure(err));
    }
}


export function* HeaderWatcher() {
    yield takeEvery(HEADER_ACTIONS.INITIATE_PAYMENT_REQUEST, initiatePaymentRequest);
    yield takeEvery(HEADER_ACTIONS.LOOKUP_CONTACT_DETAILS, lookupContactDetails);
    yield takeEvery(HEADER_ACTIONS.GUEST_LOOKUP_CONTACT_DETAILS,guestContactDetails);
    yield takeEvery(HEADER_ACTIONS.LOOKUP_ZENDESK_VAL, lookupZenDeskFeature);
    yield takeEvery(HEADER_ACTIONS.GUEST_LOOKUP_ZENDESK_VAL,guestlookupZenDeskFeature);
}
