import * as React from "react";
import { Navbar, Nav, Row, Col } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { GafOktaManager } from "../../auth/gaf-okta-auth";
import * as TagManager from "react-gtm-module";

import Recaptcha from "react-google-invisible-recaptcha";

const queryString = require("query-string");



import { AvailableBalance } from "./AvailableBalance";
import ResponseBar from "../ResponseBar";
import SuccessPromoCodeModal from "../../components/SuccessPromoCodeModal";
import Credits from "./Credits";
import ErrorModal from "../ErrorModal";
import SideDrawer from "../SideDrawer";
import Backdrop from "../Backdrop";
import Loader from "../Loader";

import { Idle } from "./idle";

import { baseUrl, cdnPath, sitekey, guestUserEmail, enableVendorTracking } from "../../../envConfig";
import { postUserProfile, postSiteStatus } from "../../utils/utils";
import { postGuestSiteStatus } from "../../utils/utilsGuest";

import { apiCallSendErrorReport } from "../../utils/ApiFunctions";
import { Log } from "../../utils/validation";
import { initializeSession, updateSessionExpiry, isSessionValid } from "../../utils/TrackingFunctions";

import { getDebugFromQueryString, lookupZenDeskVal, guestLookupZenDeskVal} from "./actionCreater";
import { getUserProfileData, getOpenClose, getSuceessPromoCodeModal, isUserGuestUser, clearSearchAddress
        } from "../../containers/HomePage/actionCreater";
import { turnOn, turnOff } from "../Loader/actionCreater";
import { getGuestUserProfileData, TrackSessionExpired } from "../../containers/GuestUser/actionCreater";
import { bind } from "lodash";



// png files
const hamburgerMenuIcon = cdnPath + "/images/hamburger@3x.png";
// const logo = cdnPath + "/images/GAFPropertyProFile.svg";
const businessServicesLogo =  cdnPath + "/images/BusinessServices.svg";

export class Header extends React.Component<any, any> {
    recaptcha: any;

    constructor(props) {
        super(props);
        this.state = {
            authenticated: null,
            showModal: false,
            redeemModal: false,
            errorCode: '',
            showErrorModal: false,
            successPromoCodeModal: this.props.successPromoCodeModal,
            drawerOpen: false,
            associatedGAFCertification: "",
            loadSiteStatus: false,
            shouldGetGuestUserProfile: false,
            isGuestUserProfileLoaded: false,
            isLoading: false,
            isZenDeskValRetrived: false,
            isZenDeskValRetrivedForGuest: false,
            shouldGenerateCaptchaForZenDesk: false,
            isRequestSentForZenDesk: false,
            isZenDeskScriptAtteched: false,
            isSiteStatusCallFinished: false,
            inactivityTimerId: null, // Initialize inactivity timer ID
        };
    }

    drawerToggleClickHandler = () => {
        this.setState({
            drawerOpen: !this.state.drawerOpen
        }, () => {
            if (this.state.drawerOpen) {
                // GTM settings
                const tagManagerArgs = {
                    dataLayer: {
                        event: 'menuOpen'
                    },
                    dataLayerName: 'PageDataLayer'
                }
                TagManager.dataLayer(tagManagerArgs);
            } else {
                // GTM settings
                const tagManagerArgs = {
                    dataLayer: {
                        event: 'menuClose'
                    },
                    dataLayerName: 'PageDataLayer'
                }
                TagManager.dataLayer(tagManagerArgs);
            }
        });

    }
    backdropClickHandler = () => {
        this.setState({
            drawerOpen: false
        }, () => {
            // GTM settings
            const tagManagerArgs = {
                dataLayer: {
                    event: 'menuClose'
                },
                dataLayerName: 'PageDataLayer'
            }
            TagManager.dataLayer(tagManagerArgs);
        })
    }
    componentDidMount = () => {
        // To get the value of debug from query string
        const debugVal = queryString.parse(this.props.history.location.search);

        // Converting string 'true' value to boolean and storing in redux 
        const isDebug =
            debugVal.debug !== undefined &&
            debugVal.debug.toLowerCase() === "true";
        this.props.getDebugFromQueryString(isDebug);
     
        if (GafOktaManager.isAuthenticated) {

            // Guuest User value setting to false on successful login.
            this.props.isUserGuestUser(false);

            // To get the SiteStatus
            this.apiCallSiteStatus();

            // Okta
            const userEmail = GafOktaManager.storage.info.profile?.email;
            const userFirstName = GafOktaManager.storage.info.profile.firstName !== undefined ? GafOktaManager.storage.info.profile.firstName : "FirstName";
            const userLastName = GafOktaManager.storage.info.profile.lastName !== undefined ? GafOktaManager.storage.info.profile.lastName : "LastName";
            let encodeEmail = userEmail && btoa(userEmail.toLowerCase());


            // Storing data in redux for user profile
            userEmail && this.getUserProfile(encodeEmail, userFirstName, userLastName, userEmail);
        }

        if (sessionStorage.getItem('pathName') === '/thd' && enableVendorTracking) {
            // On page mount initialize session for Tracking
            initializeSession();

            // Add event listeners for user activity
            window.addEventListener('mousemove', this.handleUserActivity);
            window.addEventListener('keypress', this.handleUserActivity);
            window.addEventListener('click', this.handleUserActivity);

            this.resetInactivityTimer();
        }
    };

    componentDidUpdate = () => {
        const GuestHomePage = this.props.GuestHomePage;
        const isGuestUserProfileSuccess = GuestHomePage.isGuestUserProfileSuccess;

        // Loading userProfile for guest
        if (!this.state.isGuestUserProfileLoaded && this.props.guestUser && this.props.openCloseStatus !== undefined && this.recaptcha !== null) {
            this.setState({
                isGuestUserProfileLoaded: true,
                shouldGetGuestUserProfile: true
            }, () => this.recaptcha.execute())
        }

        // Retriving val for ZenDesk lookup - Attaching script based on this val -- Fpr logged in user
        if(!this.state.isZenDeskValRetrived && this.props.emailAddress !== "" ) {
            this.props.lookupZenDeskVal()
            this.setState({
                isZenDeskValRetrived: true,
            })
        }
        // Generating Captcha for ZenDesk lookup for Guest
        if(!this.state.isZenDeskValRetrivedForGuest && this.props.guestUser && this.state.isGuestUserProfileLoaded && isGuestUserProfileSuccess ) {
            this.setState({
                isZenDeskValRetrivedForGuest: true,
                shouldGenerateCaptchaForZenDesk: true,
                isRequestSentForZenDesk: false,
            },() => this.recaptcha.execute())
        }

        // Attaching script for ZenDesk
        if(!this.state.isZenDeskScriptAtteched && ((this.state.isZenDeskValRetrived || this.state.isRequestSentForZenDesk) && !this.props.isLoadingZenDeskVal) ) {
            const lookUpArray = this.props.zenDeskVal.lookups;
            const isZenDeskChatEnabled = lookUpArray && lookUpArray.length > 0 ? lookUpArray[0].lookupValue : 'N';

            if(isZenDeskChatEnabled === 'Y') {
            // <!-- Start of pushpin Zendesk Widget script -->
                const script = document.createElement("script");
                script.src = "https://static.zdassets.com/ekr/snippet.js?key=632d0ea2-1b40-4101-9404-f89ad73aeb7d";
                script.id = "ze-snippet";
                script.async = true;
                document.body.appendChild(script);
            // <!-- End of pushpin Zendesk Widget script -->
            }
           
            this.setState({
                isZenDeskScriptAtteched: true
            })
        }

    }

    componentWillUnmount() { 
          // Remove event listeners when component unmounts
          window.removeEventListener('mousemove', this.handleUserActivity);
          window.removeEventListener('keypress', this.handleUserActivity);
          window.removeEventListener('click', this.handleUserActivity);
          clearInterval(this.state.inactivityTimerId);

          if (sessionStorage.getItem('isVendorSessionExpired')) {
            sessionStorage.removeItem('isVendorSessionExpired')
          }
          if (sessionStorage.getItem('siteCreateActTracked')) {
            sessionStorage.removeItem('siteCreateActTracked')
          }
          if (sessionStorage.getItem('siteAccessTracked')) {
            sessionStorage.removeItem('siteAccessTracked');
          }
          if (sessionStorage.getItem('siteGuestPageAccessTracked')) {
            sessionStorage.removeItem('siteGuestPageAccessTracked');
          }
          if (sessionStorage.getItem('pathName') && sessionStorage.getItem('pathName') === "/thd") {
            sessionStorage.setItem('pathName', '/thd');
          }
    
    }

    resetInactivityTimer = () => {
        clearInterval(this.state.inactivityTimerId);
        this.setState({
            inactivityTimerId: setInterval(this.checkSessionExpiry, 1000 * 30) // Check every 30 second
        }); 
    };

    handleUserActivity = () => {
        if (!isSessionValid()) {
            initializeSession(); // generate new sessionIf if session expired on user activity
            this.props.TrackSessionExpired(false);
        } else {
            updateSessionExpiry(); // Extend session expiry on user activity
        }
    };

    checkSessionExpiry = () => {
        if (!isSessionValid()) {
            console.log('Session expired');
            this.props.TrackSessionExpired(true);
        }
    };

    apiCallSiteStatus = () => {
        const { isDebug } = this.props;
        const apiUrl = baseUrl + "/SiteStatus";
        const utcDate = new Date().toISOString()

        const apiResponse = postSiteStatus(apiUrl, utcDate);
        this.props.turnOn();

        apiResponse.then(res => {
            window['AppInsightTrace'].trackTrace(`Site Status response :${JSON.stringify(res)}`);
            window['AppInsightTrace'].trackTrace(`API call in Header component for SiteStatus, Success : True`);
            this.props.turnOff();

            // Storing in redux open-close
            this.props.getOpenClose({
                openCloseStatus: res.siteStatus,
                averageResponseTime: res.siteAverageResponseTime,
                siteAverageResponseTimeMFCommercial: res.siteAverageResponseTimeMFCommercial
            });
        }).catch(err => {
            this.props.turnOff();
            window['AppInsightTrace'].trackTrace(`Site Status Api exception: ${err}`);
            let errObj = {
                apiUrl: apiUrl,
                utcDate: utcDate,
                statusCode: err.status,
                message: err.message,
                err: err.stack,
                errorDescription: `Error in fetching SiteStatus api.`
            }
            this.setState({
                errorCode: 'genericError',
                showErrorModal: true
            });
            Log(err, isDebug);
            apiCallSendErrorReport('Header Component', errObj);
            window['AppInsightTrace'].trackTrace(`API call in Header component for SiteStatus, Success : False,${err}`);

        });
    }

    getUserProfile = (userEmail, userFirstName, userLastName, loggedInEmail) => {

        let extendedProfile = true;

        const profileUrl = baseUrl + `/user/userprofile`;
        this.props.turnOn();
        this.setState({
            isLoading: true,
        })
        window['AppInsightTrace'].trackTrace(`Before making API call in Header component to get userprofile for email : ${loggedInEmail}`);


        postUserProfile(profileUrl, userEmail, userFirstName, userLastName, extendedProfile).then((res) => {
            // Setting Loading off in redux
            window['AppInsightTrace'].trackTrace(`API call in Header component to get userprofile for email : ${loggedInEmail}, Success: True`);
            window['AppInsightTrace'].trackTrace(`User Profile response: ${JSON.stringify(res)}`);

            let profilePrices = [];
            res.result[0].profile.profileProducts.forEach((profile) => {
                profilePrices.push(profile.price);
            });

            const userProfileData = {
                // availableCredits: res.result[0].availableCredits,
                availableBalance: res.result[0].availableBalance,
                emailAddress: res.result[0].emailAddress,
                recipientEmailAddresses: res.result[0].recipientEmailAddresses,
                userId: res.result[0].userId,
                parentUserID: res.result[0].parentUserID,
                parentEmailAddress: res.result[0].parentEmailAddress,
                price: profilePrices,
                profileProducts: res.result[0].profile.profileProducts,
                productPreferences: res.result[0].productPreferences,
                profileProductDiscounts: res.result[0].profile.profileProductDiscounts,
                displayGAFCertification:
                    res.result[0].profile.displayGAFCertification,
                profileType: res.result[0].profile.profileType,
                profileName: res.result[0].profile.profileName,
                associatedGAFCertification:
                    res.result[0].profile.associatedGAFCertification,
                companyName: res.result[0].gafCustomerName,
                userFirstName: res.result[0].firstName,
                userLastName: res.result[0].lastName,
                gafCustomerID: res.result[0].gafCustomerID,
                activeNotification: res.result[0].activeNotification,
                generalTAndCAccepted: res.result[0].generalTAndCAccepted,
                tAndAccepted: res.result[0].tAndAccepted,
                userPhone: res.result[0].phone,
            };
            window.sessionStorage.setItem('profileName', res.result[0].profile.profileName);
            window.sessionStorage.setItem('associatedGAFCertification', res.result[0].profile.associatedGAFCertification);

            window.sessionStorage.setItem('profilePrices', JSON.stringify(profilePrices));
            // sending userProfile data to redux
            this.props.getUserProfileData(userProfileData);
            this.props.turnOff();

            this.setState({
                isLoading: false,
            })


        }).catch(err => {
            this.props.turnOff();
            this.setState({
                isLoading: false,
            })
            window['AppInsightTrace'].trackTrace(`User Profile Exception: ${err}`);


            this.setState({
                errorCode: 'genericError',
                showErrorModal: true
            });
            window['AppInsightTrace'].trackTrace(`API call in Header component to get userprofile for email : ${loggedInEmail}, Success: false, error: ${err}`);
            let errObj = {
                apiUrl: profileUrl,
                statusCode: err.status,
                userEmail: GafOktaManager.storage.info.profile?.email,
                userFirstName: userFirstName,
                userLastName: userLastName,
                extendedProfile: extendedProfile,
                message: err.message,
                err: err.stack,
                errorDescription: `Error in fetching user profile.`
            }
            apiCallSendErrorReport('Header Menu', errObj);
        });
    };
    loginClick = () => {
        // this.props.history.push("/login");
        // ----------------------------------
        // GTM settings
        const tagManagerArgs = {
            dataLayer: {
                event: 'clickLogin'
            },
            dataLayerName: 'PageDataLayer'
        }
        TagManager.dataLayer(tagManagerArgs);
        // -------------------------------------
        sessionStorage.removeItem('welcomeModal');
        GafOktaManager.login();
    }

    guestSiteStatus = () => {
        const { isDebug } = this.props;
        const utcDate = new Date().toISOString();
        const token = this.recaptcha.getResponse();
        window['AppInsightTrace'].trackTrace(`API call for SiteStatus for Guest User`);
        
        const profileUrl = baseUrl + `/Guest/SiteStatus`;
        this.props.turnOn();

        postGuestSiteStatus(profileUrl, utcDate, token).then((res) => {

            // Storing in redux 
            this.props.getOpenClose({
                openCloseStatus: res.siteStatus,
                averageResponseTime: res.siteAverageResponseTime,
                siteAverageResponseTimeMFCommercial: res.siteAverageResponseTimeMFCommercial
            });
            this.setState({
                isSiteStatusCallFinished: true,
            })
            this.props.turnOff();
        }).catch((err) => {
            this.props.turnOff();
            window['AppInsightTrace'].trackTrace(`Guest Site Status Api exception: ${err}`);
            let errObj = {
                apiUrl: profileUrl,
                utcDate: utcDate,
                statusCode: err.status,
                message: err.message,
                err: err.stack,
                errorDescription: `Error in fetching Guest SiteStatus api.`
            }
            this.setState({
                errorCode: 'genericError',
                showErrorModal: true
            });
            Log(err, isDebug);
            apiCallSendErrorReport('Header Component For Guest', errObj);
            window['AppInsightTrace'].trackTrace(`API call in Header component for Guest SiteStatus, Success : False,${err}`);
        })
    }
    loadReacptcha = () => {
        this.setState({
            loadSiteStatus: true,
        }, () => this.recaptcha.execute())
    }
    onResolved = () => {
        if (this.state.loadSiteStatus) {
            this.guestSiteStatus();
            this.setState({
                loadSiteStatus: false,
            });
        } 
        if (this.state.shouldGetGuestUserProfile) {
            sessionStorage.setItem("Captcha", JSON.stringify(this.recaptcha.getResponse()));
            const userEmail = btoa(guestUserEmail.toLowerCase());
            const userFirstName = "FirstName";
            const userLastName = "LastName";
            const extendedProfile = true;
            const requestPayload = {
                emailAddress: userEmail,
                firstName: userFirstName,
                lastName: userLastName,
                extendedProfile: extendedProfile
            }
            this.props.getGuestUserProfileData(requestPayload);
            this.setState({
                shouldGetGuestUserProfile: false,
            });
        } 
        if(this.state.shouldGenerateCaptchaForZenDesk){
            sessionStorage.setItem("Captcha", JSON.stringify(this.recaptcha.getResponse()));
            this.props.guestLookupZenDeskVal();
            this.setState({
                shouldGenerateCaptchaForZenDesk: false,
                isRequestSentForZenDesk: true,
            })
        }
    }
    redoCaptcha = () => {
        this.recaptcha.current.reset();
        this.recaptcha.current.execute();
    }

    hideErrorModal = () => {
        this.setState({ showErrorModal: false, errorCode: null });
    };

    hideMenu = () => {
        this.setState({ showModal: false });
    };

    render() {
        const {
            // availableCredits,
            availableBalance,
            emailAddress,
            displayGAFCertification,
            profileType,
            profileName,
            associatedGAFCertification,
            loading, guestUser
        } = this.props;

        // For menu backdrop is shaded curtain on Menu open
        let backdrop;
        if (this.state.drawerOpen) {
            backdrop = <Backdrop close={this.backdropClickHandler} />;
        }

        return (

            <div className="header-bar-desktop">
                {/* TimeOut Modal will only load for logged in user */}
                {!guestUser && <Idle />}

                <Nav className="container-fluid navbar navbar-light px-4">
                    <Navbar.Brand href={`${guestUser === true ? "/guest-home-page" : "/home-page"}`}>
                        {/* <QuickMeasureLogo /> */}
                        <img src={businessServicesLogo} alt="Business Services Logo" className="logo-img" />
                    </Navbar.Brand>

                    <Navbar className="justify-content-end menu-right pr-md-0">
                        <Navbar.Text>
                            {guestUser !== true ?
                                <>

                                    <AvailableBalance
                                        availableBalance={availableBalance ? availableBalance : 0}
                                    />
                                    <div className="d-none d-md-inline">
                                        <Credits />
                                    </div>
                                </>
                                : <button
                                    className="d-none d-sm-inline-block ButtonCopy gaf-btn-secondary login-btn"
                                    onClick={() => this.loginClick()}
                                >
                                    Login
                                </button>
                            }
                            <div className="toggle-menu">

                                <button
                                    className="btn btn-hamburger-menu p-0"
                                    type="button"
                                    onClick={this.drawerToggleClickHandler}>
                                    <img src={hamburgerMenuIcon}
                                        alt="MenuIcon"
                                        className="menuIcon" />

                                </button>
                                {/* SideDrawer contains all menu options */}
                                < SideDrawer show={this.state.drawerOpen} close={this.drawerToggleClickHandler}
                                    emailAddress={emailAddress} displayGAFCertification={displayGAFCertification}
                                    profileName={profileName} associatedGAFCertification={associatedGAFCertification}
                                    guestUser={guestUser}
                                    clearSearchAddress={() => this.props.clearSearchAddress("")}
                                    />
                                {backdrop}

                            </div>

                        </Navbar.Text>
                    </Navbar>


                </Nav>

                <ResponseBar guestUser={guestUser} />


                {this.props.successPromoCodeModal && (
                    <div>
                        <SuccessPromoCodeModal
                            closeSuccessPromoCodeModal={() =>
                                this.props.getSuceessPromoCodeModal({
                                    success: false,
                                    message: "",
                                })
                            }
                            message={this.props.modalMessage}
                        />
                    </div>
                )}
                {this.state.showErrorModal && (
                    <ErrorModal
                        errorCode={this.state.errorCode}
                        close={() => this.hideErrorModal()}
                        show={this.state.showErrorModal}
                    />
                )}
                {guestUser === true &&
                    <Recaptcha
                        ref={ref => this.recaptcha = ref}
                        sitekey={sitekey}
                        onResolved={this.onResolved}
                        onExpired={this.redoCaptcha}
                        onLoaded={this.loadReacptcha}
                    />
                }
                {this.state.isLoading && <Loader />}
            </div>
        );
    }
}

export function mapDispatchToProps(dispatch) {
    return {
        // getUrlFromQuery: bindActionCreators(getUrlFromQuery, dispatch),
        turnOff: bindActionCreators(turnOff, dispatch),
        turnOn: bindActionCreators(turnOn, dispatch),
        getDebugFromQueryString: bindActionCreators(getDebugFromQueryString, dispatch),
        getOpenClose: bindActionCreators(getOpenClose, dispatch),
        getSuceessPromoCodeModal: bindActionCreators(getSuceessPromoCodeModal, dispatch),
        getUserProfileData: bindActionCreators(getUserProfileData, dispatch),
        isUserGuestUser: bindActionCreators(isUserGuestUser, dispatch),
        getGuestUserProfileData: bindActionCreators(getGuestUserProfileData, dispatch),
        lookupZenDeskVal: bindActionCreators(lookupZenDeskVal, dispatch),
        guestLookupZenDeskVal:  bindActionCreators(guestLookupZenDeskVal, dispatch),
        clearSearchAddress: bindActionCreators(clearSearchAddress, dispatch),
        TrackSessionExpired: bindActionCreators(TrackSessionExpired, dispatch),
    };
}
const mapStateToProps = (state) => {
    const { isDebug, isLoadingZenDeskVal, zenDeskVal } = state.Header;

    const { loading } = state.Loader;
    const {
        // availableCredits, 
        availableBalance,
        emailAddress,
        displayGAFCertification,
        profileType,
        profileName,
        associatedGAFCertification,
    } = state.HomePage.userProfile;


    const { successPromoCodeModal, modalMessage, guestUser, openCloseStatus } = state.HomePage;
    const GuestHomePage = state.GuestHomePage;
    return {
        isDebug,
        loading,
        // availableCredits, 
        availableBalance,
        emailAddress,
        displayGAFCertification,
        profileType,
        profileName,
        successPromoCodeModal,
        associatedGAFCertification,
        modalMessage, guestUser, openCloseStatus, GuestHomePage, isLoadingZenDeskVal, zenDeskVal
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Header) as React.ComponentType<
        any
    >
);
