import { put, takeLatest, call, takeEvery } from "redux-saga/effects";

import { baseUrl } from "../../../../envConfig";
import { doGetRequest, doPostRequest } from "../../../utils/utils";

import * as getCustomerOrderDetailsCreator from "./actionCreater";
import * as CUSTOMER_PAGE_ACTIONS from "./actionTypes";

export function* transferBalance(action) {
    const endpointUrl = baseUrl + "/TransferBalance";
    try {
        const response = yield doPostRequest(
            endpointUrl,
            JSON.stringify(action.payload)
        );
        window["AppInsightTrace"].trackTrace(
            `Transfer Balance - POST request for Customer Details Page - Payload: ` +
                action.payload
        );

        if (response) {
            window["AppInsightTrace"].trackTrace(
                `Transfer Balance - POST request for Customer Details Page: Success : ` +
                    response
            );

            yield put(
                getCustomerOrderDetailsCreator.transferCreditSuccess(response)
            );
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `Transfer Balance - POST request for Customer Details Page: Failed : ` +
                err
        );
        yield put(getCustomerOrderDetailsCreator.transferCreditFailure(err));
    }
}

export function* CustomerDetailPageWatcher() {
    yield takeEvery(CUSTOMER_PAGE_ACTIONS.TRANSFER_BALANCE, transferBalance);
}
