import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import * as CustomerDetailsPageActions from "../containers/concierge/CustomerDetails/actionTypes";

//import IProductsProps from '../models/HomePage/IProductSelection'
// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
export interface CustomerDetailsPageState {
    orders: any;
    transactions: any;
    addParentEmailError: any;
    singleUserProfile: any;
    specialPriceSelected: any;
    rejectReportReason: any;
    navigationInParentPage: boolean;
    isTransferingBalance: boolean;
    isTransferBalanceSuccess: boolean;
    isTransferBalanceError: boolean;
    transferBalance: any;
}

export interface GetCurtomersRecordsScuccess {
    type: any;
    payload: any;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = GetCurtomersRecordsScuccess;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestAPI: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            type: CustomerDetailsPageActions.CUSTOMER_ORDER_DETAILS_RECORDS,
            payload: "",
        });
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultProps: CustomerDetailsPageState = {
    orders: {
        paging: {},
        result: [],
    },
    transactions: {
        paging: {},
        result: [],
    },
    addParentEmailError: {
        emailError: false,
        emailErrorMessage: "",
        emailAddressInput: "",
    },
    singleUserProfile: {},
    specialPriceSelected: "",
    rejectReportReason: "",
    navigationInParentPage: false,
    isTransferingBalance: false,
    isTransferBalanceSuccess: false,
    isTransferBalanceError: false,
    transferBalance: {},
};

export const reducer: Reducer<CustomerDetailsPageState> = (
    state: CustomerDetailsPageState = defaultProps,
    incomingAction: Action
) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case CustomerDetailsPageActions.CUSTOMER_ORDER_DETAILS_RECORDS:
            return {
                ...state,
                orders: action.payload,
            };

        case CustomerDetailsPageActions.CUSTOMER_TRANSACTION_DETAILS_RECORDS:
            return {
                ...state,
                transactions: action.payload,
            };

        case CustomerDetailsPageActions.ADDPARENT_MODAL_EMAIL_ERROR:
            return {
                ...state,
                addParentEmailError: {
                    emailAddressInput: action.payload.emailAddressInput,
                    emailError: action.payload.emailError,
                    emailErrorMessage: action.payload.emailErrorMessage,
                },
            };

        case CustomerDetailsPageActions.SINGLE_USER_PROFILE:
            return {
                ...state,
                singleUserProfile: action.payload,
            };

        case CustomerDetailsPageActions.SPEICAL_PRICE_SELECTED:
            return {
                ...state,
                specialPriceSelected: action.payload,
            };

        case CustomerDetailsPageActions.REJECT_REPORT_REASON:
            return {
                ...state,
                rejectReportReason: action.payload,
            };
        case CustomerDetailsPageActions.NAVIGATION_IN_PARENT_PAGE:
            return {
                ...state,
                navigationInParentPage: action.payload,
            };
        case CustomerDetailsPageActions.TRANSFER_BALANCE:
            return {
                ...state,
                isTransferingBalance: true,
                isTransferBalanceSuccess: false,
                isTransferBalanceError: false,
                transferBalance: action.payload,
            };
        case CustomerDetailsPageActions.TRANSFER_BALANCE_SUCCESS:
            return {
                ...state,
                isTransferingBalance: false,
                isTransferBalanceSuccess: true,
                isTransferBalanceError: false,
                transferBalance: action.payload,
            };
        case CustomerDetailsPageActions.TRANSFER_BALANCE_FAILURE:
            return {
                ...state,
                isTransferingBalance: false,
                isTransferBalanceSuccess: false,
                isTransferBalanceError: true,
                transferBalance: action.payload,
            };
        case CustomerDetailsPageActions.CLEAR_ERROR:
            return {
                ...state,
                isTransferingBalance: false,
                isTransferBalanceSuccess: false,
                isTransferBalanceError: false,
                transferBalance: {},
            };
        default:
        // The following line guarantees that every action in the KnownAction union has been covered by a case above
        // const exhaustiveCheck: never = action;
    }

    return state || defaultProps;
};
