import { put, takeLatest, call, takeEvery } from "redux-saga/effects";

import * as guestUserPageActionCreators from "./actionCreater";
import * as GUEST_USER_PAGE_ACTIONS from "./actionTypes";
import { baseUrl } from "../../../envConfig";
import { postRequest, doPostRequest } from "../../utils/utils";

const mapUserProfile = (res) => {
    let profilePrices = [];
    res.result[0].profile.profileProducts.forEach((profile) => {
        profilePrices.push({
            amount: profile.price,
            numberOfCredits: profile.numberOfCredits,
        });
    });
    window.sessionStorage.setItem(
        "profilePrices",
        JSON.stringify(profilePrices)
    );
    window.sessionStorage.setItem(
        "profileProducts",
        JSON.stringify(res.result[0].profile.profileProducts)
    );

    return {
        // availableCredits: res.result[0].availableCredits,
        // availableBalance: res.result[0].availableBalance,
        emailAddress: res.result[0].emailAddress,
        // recipientEmailAddresses: res.result[0].recipientEmailAddresses,
        // parentUserID: res.result[0].parentUserID,
        // parentEmailAddress: res.result[0].parentEmailAddress,
        profilePrices: profilePrices,
        profileProducts: res.result[0].profile.profileProducts,
        productPreferences: res.result[0].productPreferences,
        profileProductDiscounts: res.result[0].profile.profileProductDiscounts
        // displayGAFCertification: res.result[0].profile.displayGAFCertification,
        // profileType: res.result[0].profile.profileType,
        // profileName: res.result[0].profile.profileName,
        // associatedGAFCertification:
        // res.result[0].profile.associatedGAFCertification,
        // companyName: res.result[0].gafCustomerName,
        // userFirstName: res.result[0].firstName,
        // userLastName: res.result[0].lastName,
        // gafCustomerID: res.result[0].gafCustomerID,
        // activeNotification: res.result[0].activeNotification,
    };
    // return userProfileData;
};

export function* guestUserProfileData(action) {
    const endpointUrl = baseUrl + `/Guest/GetUserProfile`;
    try {
        const response = yield postRequest(
            endpointUrl,
            JSON.stringify(action.payload)
        );
        if (response) {
            yield put(
                guestUserPageActionCreators.getGuestUserProfileDataSuccess(
                    mapUserProfile(response)
                )
            );
        }
    } catch (error) {
        guestUserPageActionCreators.getGuestUserProfileDataFailure(error);
    }
}

export function* searchUser(action) {
    const endpointUrl = baseUrl + `/Guest/SearchUserProfile`;
    window["AppInsightTrace"].trackTrace(
        `Guest Searching User profile Payload: ` +
            JSON.stringify(action.payload)
    );
    try {
        const response = yield postRequest(
            endpointUrl,
            JSON.stringify(action.payload)
        );
        if (response) {
            window["AppInsightTrace"].trackTrace(
                `Guest Searching User profile  - successs: ` +
                    JSON.stringify(response)
            );
            yield put(
                guestUserPageActionCreators.guestSearchUserSuccess(response)
            );
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `Guest Searching User profile  - Failure: ` +
                JSON.stringify(err)
        );
        yield put(guestUserPageActionCreators.guestSearchUserFailure(err));
    }
}

export function* guestSetTermsAndCondition(action) {
    const endpointUrl = baseUrl + "/Guest/acceptTermsAndConditions";
    try {
        const response = yield postRequest(
            endpointUrl,
            JSON.stringify(action.payload)
        );
        window["AppInsightTrace"].trackTrace(
            `Guest Update Terms and Condition Payload: ` +
                JSON.stringify(action.payload)
        );

        if (response) {
            window["AppInsightTrace"].trackTrace(
                `Guest Update Terms and Condition - successs: ` +
                    JSON.stringify(response)
            );

            yield put(
                guestUserPageActionCreators.GuestUpdateTAndCSuccess(response)
            );
        }
    } catch (err) {
        window["AppInsightTrace"].trackTrace(
            `Guest Update Terms and Condition Failed: ` + JSON.stringify(err)
        );
        yield put(guestUserPageActionCreators.GuestUpdateTAndCFailure(err));
    }
}


export function* trackVendorActivity(action) {
    let endpointUrl;
    let response;

    try {
        if (sessionStorage.getItem('associatedGAFCertification')) { 
            endpointUrl = baseUrl + "/user/track";
            response = yield doPostRequest( endpointUrl, JSON.stringify(action.payload));
        } else {
            endpointUrl = baseUrl + "/guest/track";
            response = yield postRequest( endpointUrl, JSON.stringify(action.payload));
        }
        
        if (response) {
            yield put(
                guestUserPageActionCreators.TrackVendorActivitySuccess(response)
            );
        }
    } catch (err) {
        yield put(guestUserPageActionCreators.TrackVendorActivityFailure(err));
    }
}

export function* GuestUserPageWatcher() {
    yield takeEvery( GUEST_USER_PAGE_ACTIONS.GUEST_USER_PROFILE_DATA, guestUserProfileData);
    yield takeEvery( GUEST_USER_PAGE_ACTIONS.GUEST_UPDATE_T_AND_C, guestSetTermsAndCondition);
    yield takeEvery(GUEST_USER_PAGE_ACTIONS.GUEST_SEARCH_USER, searchUser);
    yield takeEvery(GUEST_USER_PAGE_ACTIONS.TRACK_ACTIVITY, trackVendorActivity);
}
