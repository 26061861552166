import { string } from "prop-types";
import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import * as SERVICE_ACTIONS from "../containers/concierge/Services/actionTypes";

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
export interface ServiceState {
    SelectedService: any;
    isAddNewServiceClicked: boolean;
    allServices: any;
    isGettingServices: boolean;
    isError: boolean;
    saveServices: any;
    isSavingServices: boolean;
}

export interface ServiceAdmin {
    actData: any;
    type: any;
    payload: any;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = ServiceAdmin;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestAPI: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({
            actData: "",
            type: SERVICE_ACTIONS.SELECTED_SERVICE,
            payload: "",
        });
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const defaultProps: ServiceState = {
    SelectedService: {},
    isAddNewServiceClicked: false,
    allServices: [],
    isGettingServices: false,
    isError: false,
    saveServices: [],
    isSavingServices: false,
};

export const reducer: Reducer<ServiceState> = (
    state: ServiceState = defaultProps,
    incomingAction: Action
) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case SERVICE_ACTIONS.SELECTED_SERVICE:
            return {
                ...state,
                SelectedService: action.payload,
            };
        case SERVICE_ACTIONS.ADD_NEW_SERVICE:
            return {
                ...state,
                isAddNewServiceClicked: action.payload,
            };
        case SERVICE_ACTIONS.GET_SERVICES:
            return {
                ...state,
                allServices: action.payload,
                isGettingServices: true,
                isError: false,
            };
        case SERVICE_ACTIONS.GET_SERVICES_FAILURE:
            return {
                ...state,
                allServices: action.payload,
                isGettingServices: false,
                isError: true,
            };
        case SERVICE_ACTIONS.GET_SERVICES_SUCCESS:
            return {
                ...state,
                allServices: action.payload,
                isGettingServices: false,
                isError: false,
            };
        case SERVICE_ACTIONS.ADD_EDIT_SERVICES:
            return {
                ...state,
                saveServices: action.payload,
                isSavingServices: true,
                isError: false,
            };
        case SERVICE_ACTIONS.ADD_EDIT_SERVICES_FAILURE:
            return {
                ...state,
                saveServices: action.payload,
                isSavingServices: false,
                isError: true,
            };
        case SERVICE_ACTIONS.ADD_EDIT_SERVICES_SUCCESS:
            return {
                ...state,
                saveServices: action.payload,
                isSavingServices: false,
                isError: false,
            };
        default:
        // The following line guarantees that every action in the KnownAction union has been covered by a case above
        // const exhaustiveCheck: never = action;
    }

    return state || defaultProps;
};
