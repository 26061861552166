import {
    GafOktaBrowser,
    GafOktaCallbacks,
    WindowLocation
} from "@gaf/typescript-authentication-okta";
import { OKTA_CONFIG } from "../environments/environment";
import {
    GafTokenManager,
    GafStorageManagerSession
} from "@gaf/typescript-authentication-general";

export const callbacks = {
    getScopesKeyFromUrl: url => "audience"
};

export const GafOktaManager = new GafOktaBrowser(
    OKTA_CONFIG,
    callbacks,
    new GafTokenManager(),
    new GafStorageManagerSession(),
    new WindowLocation()
);

GafOktaManager.start();